
import * as types from "../Utilities/Type"
const INITIAL_STATE = {
    acvReport: [],
    acvExport: [],
};

export function getAcvReportData(state=INITIAL_STATE,action){
    switch (action.type){
        case types.GET_ACV_REPORT:
             return{
                ...state,
                acvReport:action.payload
             }
        case types.ACV_EXPORT_EXCEL:
            return{
                ...state,
                acvExport:action.payload
            }
        case '3':  
             break;  
        default:return state        

    }
}
