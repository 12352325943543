
import * as types from "../Utilities/Type"
const INITIAL_STATE = {
    pmoReport: [],
    pmoExport: [],
};

export function getPmoReportData(state=INITIAL_STATE,action){
    switch (action.type){
        case types.GET_PMO_REPORT:
             return{
                ...state,
                pmoReport:action.payload
             }
        case types.PMO_EXPORT_EXCEL:
            return{
                ...state,
                pmoExport:action.payload
            }
        case '3':  
             break;  
        default:return state        

    }
}
