import { postData,getData } from "../Services/Helper";
import { handleResponse } from "../Services/HandleResponse";
import * as types from "../Utilities/Type"
import { getBackEndConstant } from "../Config/Constant";

export function getPmoReportData(dataObj) {
  return (dispatch) => {
    const requestOptions = postData(dataObj);
    let url = getBackEndConstant().backend_url + "get_pmo_ms_report";
    return fetch(url, requestOptions)
      .then((response) => handleResponse(response))
      .then((response) => {
        dispatch({
          type: types.GET_PMO_REPORT,
          payload: (response.result) ? response.result : {},
        });
      });
  };
}

export function pmoExportExcel(dataObj) {
  return (dispatch) => {
    const requestOptions = postData(dataObj);
    let url = getBackEndConstant().backend_url  + "export_as_excel_pmo_ms_report";
    return fetch(url, requestOptions)
      .then((response) => handleResponse(response))
      .then((response) => {
        dispatch({
          type: types.PMO_EXPORT_EXCEL,
          payload: (response.result) ? response.result : {},
        });
      });
  };
}

