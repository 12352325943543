import React, { Component } from "react";
import {Modal,Button, Form} from 'react-bootstrap';
import "../Reports/Report.css";
import { getBackEndConstant } from "../../Config/Constant";

export default class PredictionPopUp extends Component {
  constructor(props){
    super(props);
    this.state = {
        reserveStrength : 0,
        formValid: false,
    }
  }

  handleChange = (e) =>{
    let originalState = JSON.parse(localStorage.getItem('record'));
    let formValid = Number(e.target.value) <= (originalState['totalBenchHrs'] / 160) ? false : true;
    this.props.item['reserveStrength'] = e.target.value;
    this.props.item['reserveMilestone'] = e.target.value * (getBackEndConstant().predictionMsValue);
    this.props.item['totalMSValue'] = (e.target.value * (getBackEndConstant().predictionMsValue));
    this.props.item['totalMSValue'] = originalState['totalMSValue'] + (e.target.value * (getBackEndConstant().predictionMsValue));
    this.props.item['totalBenchHrs'] = originalState['totalBenchHrs'] - (e.target.value * (getBackEndConstant().predictionMsHrs));
    this.props.item['allotedHrs'] = originalState['allotedHrs'] + (e.target.value * (getBackEndConstant().predictionMsHrs));
    this.setState ({
        reserveStrength : e.target.value,
        formValid : formValid
    })
  }

  render() {
    return (
        <>   
          <Modal style={{width:"25rem",marginLeft: "37rem"}} show={this.props.isOpen} onHide={this.props.closeModal}>
            <Modal.Body>
              <Form.Group>
                <Form.Label>Enter People to be placed from Reserve Strength :</Form.Label>
                <Form.Control type="text" name='msusers' onChange={(e) => this.handleChange(e)} value={this.state.reserveStrength} placeholder="Enter value " />
                <br></br>
                {this.state.formValid && <Form.Label style={{color:"red"}}> *Value Exceed Total Developers Count </Form.Label>}
              </Form.Group>

            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" type='submit' disabled={this.state.formValid} onClick={(e) => this.props.handleSubmit(e)}>
                    Predict
                </Button>
            </Modal.Footer>
          </Modal>
        </>
      );
  }
}