import React, { Component } from "react";
import "./pms.css";
import Select from "react-select";
import {
  getLoginUser,
  getReportFilter,
  getLoginUserPermission,
  getReportFilterPmo,
  getPmsQuestion,
  setAddClass,
  deletePmsQuestion,
  getChapterLead,
  getLevel,
  editPmsQuestion, getStartEndDate,
  updatePmsQuestionOrder,
  getStartedSurveyBu
} from "../../Action";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { connect } from "react-redux";
import * as constants from "../../Config/Constant";
import Cookies from "js-cookie";
import PageNotFound from "../Reports/PageNotFound";
import StarRating from "./StarRating";
import { Row, Button, Col, Modal, Form } from "react-bootstrap";
import Alert from 'react-bootstrap/Alert';
import AddQuestion from "./AddQuestion";
import Swal from "sweetalert2";
import moment from 'moment';

const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  showCloseButton: true,
  customClass: {
    popup: "colored-toast",
    icon: "white-toast-icon",
  },
  timer: 3000,
  onOpen: (toast) => {
    toast.addEventListener("mouseenter", Swal.stopTimer);
    toast.addEventListener("mouseleave", Swal.resumeTimer);
  },
});

class PmsManage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      queryParams: new URLSearchParams(window.location.search),
      redirect: 0,
      sname: [],
      snameArray: [],
      report_practice: [],
      report_service: [],
      pmsQuestionList: this.props.pmsQuestionList,
      practiceHead: [],
      loginUserData: {},
      loginUserPermission: {},
      token: "",
      loading: false,
      bu_id: "",
      selectService: [],
      selectBu: [],
      selectBuFilter: [],
      reportList: [],
      serviceName: [],
      checkedList: [],
      usersService: [],
      pmsId: 0,
      showDeleteModal: false,
      pmsPopUp: false,
      isModalClose: false,
      isEditModal: false,
      deletePmsData: "",
      filter_end_date: new Date(),
      filter_start_date: new Date(),
      pmsQuestion: [],
      deleteQuestionId: "",
      isEditQuestion: false,
      isManage: true,
      isEditOpen: true,
      chapterLead: [],
      chapterLeadValue: [],
      level: [],
      selectLevel: [],
      editQuestion: [],
      editBu: [],
      editService: [],
      editChapter: [],
      editOption: [],
      editQuestionType: [],
      editQuestionOption: [],
      startQuesDate: '',
      endQuesDate: '',//moment().format('DD-MM-YYYY'),
      batch: '',
      filteredBU: [],
      filteredLos: [],
      filteredChapter: [],
      isManageQuestionFiltered: false,
      isQuestionOpen: false,
      defaultActiveKey: 'question_view',
      draggingItem: null,
      showQueModal: false,
      startedBuName:[],
      startedBuid :[],
      isAddBtnDisabled: false,
    };
    this.manageQuestion = this.manageQuestion.bind(this.state);
    this.backToManage = this.backToManage.bind(this.state);
  }
  /**
   * Initial function call
   */
  componentDidMount() {
    let token =
      this.state.queryParams.get("token") === null
        ? Cookies.get("auth_data")
        : this.state.queryParams.get("token");
    this.setState({
      token: token,
      apiLink: constants.API_LINK,
      angularUrl: constants.FRONT_LINK,
      excelUrl: constants.EXCEL_LINK,
    });
    this.getUser();
  }
  componentDidUpdate(prevProps) {
    if(prevProps.pmsQuestionList != this.props.pmsQuestionList){
      this.setState({
        pmsQuestionList: this.props.pmsQuestionList,
      });
    }
  }

  /**
   * Notification message
   * @param {*} icon
   * @param {*} title
   * @returns
   */
  ToastAlert(icon, title) {
    return Toast.fire({
      target: document.getElementById("form-modal"),
      icon: `${icon}`,
      title: `${title}`,
    });
  }

  /**
   * Get the login user details
   */
  getUser = () => {
    this.setState({ loading: true });
    this.props.getLoginUser().then((response) => {
      this.props.getLoginUserPermission().then((response) => {
        this.setState({
          loading: false,
          loginUserData: this.props.loginDetails.loginDetails,
          loginUserPermission: this.props.loginDetails.loginUserPermission,
          role_id: this.props?.loginDetails?.loginDetails?.role_id,
        });
        this.getDateaAndBatch();
        this.getPracticeService();
      });
    });
  };

  resetPage = () => {
    this.setState(
      {
        report: {
          service: [],
          practice: [],
        },
        selectService: [],
        searchBy: "",
        reportService: [],
      },
      () => {
        // this.getMsAllocationReportData(this.state);
      }
    );
  };

  getPracticeService = (isAllPractice) => {
    const { role_id } = this.state;
    this.setState({
      loading: true,
      isAllPractices: isAllPractice,
      filterService: [],
      report: {
        service: [],
      },
      sname: [],
      allService: [],
      serviceName: [],
      bu_id: this.state.loginUserData.business_unit.id,
    });
    let dateValue = new Date();
    let date = new Date(dateValue.getFullYear(), dateValue.getMonth(), 2);
    let start_date = this.state.filter_start_date
      ? this.state.filter_start_date
      : date;
    let end_date = this.state.filter_end_date
      ? this.state.filter_end_date
      : date;
    let dataObject = {};
    dataObject.start_date = start_date;
    dataObject.end_date = end_date;
    dataObject.filter = isAllPractice;

    let c = 0;
    this.props.getReportFilter(dataObject).then(
      (_response) => {        
        this.props.pmoFilter.directors.forEach((value) => {
          if (this.state.bu_id === value.bu_id) {
            c = 1;
          }
        });
        let bu_id = c === 1 ? this.state.bu_id : "";
        let selectBuFilter = c === 1 ? this.state.selectBuFilter : [];
        let sname = [];
        let allService = [],
          serviceName = [];
        if (
          this.state.bu_id !== "" &&
          this.state.bu_id !== undefined &&
          this.state.bu_id !== 0
        ) {
          sname = this.props.pmoFilter?.services
            //.filter((service) => this.state.bu_id === service.bu)
            .filter((item) =>
              // role_id === 8 ? item.head === this.state?.loginUserData?.id : item
              item
            );
          this.props.pmoFilter.allservices.forEach((service) => {
            //if (this.state.bu_id === service.bu) {
              allService.push(service);
            //}            
          });
        } else {
          sname = this.props.pmoFilter?.services.filter((item) =>
            // role_id === 8 ? item.head === this.state?.loginUserData?.id : item
            item
          );
          allService = this.props.pmoFilter?.allservices;
        }
        this.setState({
          usersService: this.props.pmoFilter?.services.filter((item) =>
            // role_id === 8 ? item.head === this.state?.loginUserData?.id : item
            item
          ),
          snameArray: this.props.pmoFilter?.services.filter((item) =>
            // role_id === 8 ? item.head === this.state?.loginUserData?.id : item
            item
          ),
          bu_id: bu_id,
          selectBuFilter: selectBuFilter,
          sname: sname,
          allService: allService,
        });
        this.state.sname.forEach((value) => {
          let opt = {};
          opt.label = value.value;
          opt.id = value.id;
          opt.value = value.value;
          opt.head = value.head;
          opt.bu = value.bu;
          serviceName.push(opt);
        });
        // for practicehead
        let practiceHead = [];
        this.props.pmoFilter.directors.forEach((id) => {
          practiceHead.push(id);
        });
        let buName = [];
        let directorArray = this.props.pmoFilter.directors;
        /*   this.state.role_id === 8
            ?
            this.props.pmoFilter.directors
            this.props.pmoFilter.directors.filter(
              (item) => item.id === this.state?.loginUserData?.id
            )
            : this.props.pmoFilter.directors; */
        directorArray.forEach((value) => {
          let opt = {};
          opt.label = value.bu;
          opt.value = value.bu;
          opt.bu_id = value.bu_id;
          opt.id = value.id;
          opt.bu = value.bu;
          opt.name = value.name;
          buName.push(opt);
        });
        let selectService = [];
        let selectBu = buName;
        this.getReportByHead(selectBu);
        if (this.state.role_id === 10) {
          let serviceName = [];
          this.state.sname.forEach((value) => {

            let opt = {
              label: value.value,
              id: value.id,
              value: value.id,
              head: value.head,
              bu: value.bu
            };
            serviceName.push(opt);
          });

          // Update the state outside of the forEach loop
          this.setState({
            serviceName: serviceName
          });
          selectService = this.state.serviceName.filter((item) => {
            return this.state.role_id === 10
              ? this.state.serviceName
              : item.bu === selectBu.bu_id;
          })[0];
          if (this.state.role_id === 10) {

          }
          if (selectService){
          selectService["label"] = selectService?.label;
          }
        }
        // selectService = this.state.role_id === 10 && this.props.pmoFilter.usersService.length > 0 ? [selectService] : [];
        selectService = this.state.role_id === 10 ? [] : [];
        this.setState({
          practiceHead: practiceHead,
          buName: buName,
          buLength : this.props.pmoFilter.directors.length,
          report_all_practices: this.props.pmoFilter.practices,
          qes_list: this.props.pmoFilter.qes_list,
          selectService: selectService,
          selectBuFilter: [],
        });
        this.getChapterLead(selectService);
      },
      (error) => {
        this.setState({
          usersService: [],
          snameArray: [],
          bu_id: "",
          selectBuFilter: [],
          sname: [],
          allService: [],
        });
      }
    );
  };

  /* filter practice based on service and head */


  filterService = (e) => {
    if (e) {
      let selectedOption = e;
      let chapterLead = [];
      if (selectedOption.length) {
        this.state.chapterClone.forEach((lead) => {
          selectedOption.forEach((service) => {
            if (service && service.id) {
            if (lead.service === service.id) {
              chapterLead.push(lead);
            }
          }

          });
        });
        this.setState(
          {
            serviceError: [],
            report: {
              service: selectedOption,
            },
            selectService: selectedOption,
            chapterLead: chapterLead,
            chapterLeadValue: [],
            searchBy: "",
            selectChapter: [],
          },
          () => { }
        );
      } else {
        this.setState(
          {
            serviceError: [],
            report: {
              service: selectedOption,
            },
            selectService: selectedOption,
            chapterLead: [],
            chapterLeadValue: [],
            searchBy: "",
            selectChapter: [],
          },
          () => { }
        );
      }
    } else {
    }
  };
  /**
   * Get the chapter lead list
   */
  getChapterLead = (selectService) => {
    let data = [];
    let chapter = [];
    this.props.getChapterLead(data).then(
      (response) => {
        this.props.chapterLeadList.length &&
          this.props.chapterLeadList.forEach((item) => {
            chapter.push({
              label: item.chapter_name,
              value: item.id,
              service: item.service,
              reporting_to: item.reporting_to,
            });
          });
        let chapterLead = [];
        this.setState(
          {
            chapterLead: chapterLead,
            chapterClone: chapter,
            selectChapter: '',

          },
          () => {

            this.filterService(selectService);
          }
        );
        this.getLevel();
        this.manageQuestion();
      },
      (error) => {
        this.ToastAlert("error", "Something went wrong");
      }
    );
  };

  /**
   * Get the Level list
   */
  getLevel = () => {
    this.props.getLevel().then((response) => {
      let levelArray = [];
      if (this.state.loginUserData.role_id == 1 || this.state.loginUserData.role_id == 8 || this.state.loginUserData.role_id == constants.HR) {
          this.props.levelList.length && this.props.levelList.map((item, key) => {
              levelArray.push({
                  label: item.emp_level, value: item.emp_level
              });
          });
          levelArray.sort((a,b) => (a.value > b.value) ? 1 : ((b.value > a.value) ? -1 : 0));
          this.setState({ level: levelArray });
      } else {
        const userLevel = parseInt(this.state.loginUserData.emp_level.split('-')[1].trim());
        const filteredLevels = this.props?.levelList.filter(item => {
            const numericPart = item.emp_level.split('-')[1]?.trim();
            const level = numericPart ? parseInt(numericPart) : null;
            return level !== null && level < userLevel;
        });
        filteredLevels.map((item, key) => {
            levelArray.push({
                label: item.emp_level, value: item.emp_level
            });
        });
        levelArray.sort((a,b) => (a.value > b.value) ? 1 : ((b.value > a.value) ? -1 : 0));
        this.setState({ level: levelArray });
      }
    }, (error) => {
        this.ToastAlert("error", error);
    });
};

  /**
   * get values in chapter
   * @param {*} chapterOption
   */
  handleChapter = (chapterOption) => {
    this.setState({
      chapterLeadValue: chapterOption,
      chapterError: [],
      selectChapter: chapterOption,
    });
  };

  /**
     * get values in Level
     * @param {*} chapterOption 
     */
  handleLevel = (selectedOption) => {
    this.setState({ selectLevel: selectedOption });
  };

  getReportByHead = (e) => {
    if (e !== undefined && e !== null && e !== "") {
      this.setState(
        {
          bu_id: e.bu_id,
          head_id: e.label,
          selectBuFilter: e,
          chapterLeadValue: [],
          chapterLead: [],
          searchBy: "",
        },
        () => {
          this.getBuFilter(e);
        }
      );
    } else {
      this.setState(
        {
          bu_id: "",
          head_id: "All",
          selectBuFilter: [],
          selectChapter: [],
          searchBy: "",
        },
        () => {
          this.getBuFilter(this.state);
        }
      );
    }
  };

  /* business unit filter function  */
  getBuFilter = (e) => {
    let serviceArray = [];
    let service_head = [];
    if (this.state.head_id !== "All") {
      // for BU filter
      this.state.snameArray.forEach((service) => {
        if (e.bu_id === service.bu) {
          service_head.push(service);
        }
      });
      this.setState({
        serviceName: service_head,
        selectService: [],
        chapterLeadValue: [],
        chapterLead: [],
        report: {
          service: [],
        },
      });
    } else {
      // when All BU is selected
      this.setState({
        serviceName: [],
        selectService: [],
        selectBuFilter: [],
        chapterLeadValue: [],
        chapterLead: [],
      });

      // ****
      //  enable the below condition if service options needed without any BU selected
      // ****

      // if (this.state.report.service.length === 0) {
      //   this.state.snameArray.forEach((service) => {
      //     service_head.push(service);
      //   });
      // } else {
      //   service_head = this.state.sname;
      //   this.setState({
      //     chapterLeadValue: [],
      //     chapterLead: [],
      //     serviceName: [],
      //     selectService: [],
      //     report: {
      //       service: [],
      //     },
      //   });
      // }
    }
    service_head.forEach((value) => {
      let opt = {};
      opt.label = value.value;
      opt.id = value.id;
      opt.value = value.value;
      opt.head = value.head;
      opt.bu = value.bu;
      serviceArray.push(opt);
    });
    this.setState({
      serviceName: serviceArray,
    });
  };

  /**,
   * Show the data for PMS question
   */
  manageQuestion = () => {
    this.setState({
      loading: true,
      isManage: true,
      isEditQuestion: false,
    });
    let dataObject = {};
    let payload = {};
    if (this.state.loginUserData.role_id != 1 && this.state.loginUserData.role_id != 8 &&
      this.state.loginUserData.role_id != constants.HR && this.state.loginUserData.role_id != 10) {
      let bu = this.state.buName.filter((bu) => bu.bu_id === this.state.loginUserData.business_unit.id);
      dataObject.business_unit = bu[0];
      dataObject.los = this.state.snameArray.filter((service) => service.id === this.state.loginUserData.service)
      dataObject.chapter = this.state.loginUserData.chapter_name && this.state.loginUserData.chapter_name != 'null' ? [this.state.loginUserData.chapter_name] : [];
    } 
    else {
      if (this.state.loginUserData.role_id == 10 && this.props.pmoFilter &&
        this.props.pmoFilter.usersService && this.props.pmoFilter.usersService.length > 0 && this.props.pmoFilter.directors.length == 1) {
        let bu = this.state.buName.filter((bu) => bu.bu_id === this.state.loginUserData.business_unit.id);
        dataObject.business_unit = bu[0];
        // this.state.selectBuFilter = bu[0];
      } else {
        dataObject.business_unit =
          this.state.selectBuFilter && (this.state.role_id === 1 || this.state.role_id === constants.HR || this.state.role_id === 8)
            ? this.state.selectBuFilter
            : [];
      }

      dataObject.los =
        this.state.selectService && (this.state.role_id === 1 || this.state.role_id === constants.HR || this.state.role_id === 8 || this.state.role_id === 10)
          ? this.state.selectService
          : [];
      let chapterManage = (this.state.chapterLeadValue && this.state.chapterLeadValue != undefined)
        ? this.state.chapterLeadValue.label : [];
      dataObject.chapter = chapterManage ? chapterManage : []
    }

    dataObject.page = "pms_manage";
    dataObject.date = "2023-09-01";
    dataObject.pageFrom = 1;//to differentiate the page data from manage 
    payload["data"] = dataObject;

    this.props.getPmsQuestion(payload).then(
      (response) => {
        this.setState({
          loading: false,
          pmsQuestion: this.props.pmsQuestion,
        });
      },
      (error) => {
        this.ToastAlert("error", "Something went wrong");
      }
    );
  };

  /**
   * Show popup for delete
   * @param {*} id
   */
  deleteQuestion = (event, key) => {
    this.setState({
      showDeleteModal: true,
      deleteQuestionId: key,
    });
  };

  /**
   * Delete Question
   * @param {*} event
   * @param {*} key
   */
  confirmDeleteQuestion = (event, key) => {
    const payload = { id: this.state.deleteQuestionId };
    this.props.deletePmsQuestion(payload).then(
      (response) => {
        this.manageQuestion(this.state);
        this.ToastAlert("success", "Question Deleted Successfully");
      },
      (error) => {
        this.ToastAlert("error", "Something went wrong");
      }
    );
    this.setState(
      {
        showDeleteModal: false,
      },
      () => {
        // this.props.GridFunction();
        // { this.ToastAlert('success', "Data deleted successfully") }
      }
    );
  };

  /**
   * edit Question
   */
  editQuestion = (event, id) => {
    let payload = { id: id };
    // let option = {};
    this.setState({
      //   loading:true
    });
    this.props.editPmsQuestion(payload).then(
      (response) => {
        this.setState({
          isManage: true,
          isEditQuestion: true,
          editQuestion: this.props.editQuestion,
          isQuestionOpen: true,
          isEditOpen: true,
          isManageQuestionFiltered: true,
          defaultActiveKey: 'add_question_view',
          // isQuestionOpen: true,
          // loading: false,

        });
      },
      (error) => {
        this.ToastAlert("error", "Something went wrong");
      }
    );
  };

  /**
   * Back to manage question
   * @param {*} e
   */
  backToManage = (e) => {
    this.setState({
      isManage: true,
      isEditQuestion: false,
    });
  };

  addQuestion = (e) => {

    this.setState({
      isManage: true,
      isQuestionOpen: true,
      isEditOpen: false,
      editQuestion: [],
    });
  };
  stripHtmlTags = (text) => {
    return text.replace(/<\/?[^>]+(>|$)/g, "");
  };

  /**
     * 
     * @param {*} get the start date and end date  
     */
  getDateaAndBatch = () => {
    let startDate, endDate, batch,start_date = "", end_date= "";
    this.props.getStartEndDate().then((response) => {
      console.log(this.props.dateList);
      this.props.dateList.length && this.props.dateList.map(item => {
        if (item.status == 0) {
          if(start_date =="" || start_date > item.start_date){
             start_date = item.start_date;
          }
          if(end_date =="" || end_date < item.end_date){
             end_date = item.start_date;
          }
          batch = item.batch;
          this.getStartedSurveyBu(batch);
        }        
      });
      startDate = moment(start_date).utcOffset('+05:30').format('DD-MM-YYYY');
      endDate = moment(end_date).utcOffset('+05:30').format('DD-MM-YYYY');
      this.setState(
        {
          batch: batch,
          startQuesDate: startDate,
          endQuesDate: endDate
        },
      );
    }, (error) => {
      this.ToastAlert("error", error);
    });
  };
  getStartedSurveyBu = (batch) => {
    let objData = {};
    objData.batch = batch;
    this.props.getStartedSurveyBu(objData).then((response) => {     
        if(this.props.getStatedBU.result.length){
          let startedBuName = this.props.getStatedBU.result.map((item) => item.bu_name);
          let startedBuid = this.props.getStatedBU.result.map((item) => item.bu_id);
          const buRemvIds = this.props.getStatedBU.result.map(item => parseInt(item.bu_id));
          const filteredBuValue = this.state.buName.filter(item => item.bu_id && !buRemvIds.includes(item.bu_id));
          this.setState({ startedBuName: startedBuName, startedBuid: startedBuid});
          if(startedBuName[0] == "all" || filteredBuValue.length == 0){
            this.setState({ isAddBtnDisabled: true });            
          }
        }
    });
  }
  /**
   * generateQuestion
   * @returns
   */

  generateQuestion = (e) => {
    let dataObject = {};
    let payload = {};
    dataObject.chapter = [];
    dataObject.level = [];
    if (this.state.loginUserData.role_id != 1 && this.state.loginUserData.role_id != 8 &&
      this.state.loginUserData.role_id != constants.HR && this.state.loginUserData.role_id != 10) {
      let bu = this.state.buName.filter((bu) => bu.bu_id === this.state.loginUserData.business_unit.id);
      dataObject.business_unit = bu[0];
      this.state.selectBuFilter = bu[0];
      dataObject.los = this.state.snameArray.filter((service) => service.id === this.state.loginUserData.service)

    } else {
      if (this.state.loginUserData.role_id == 10 && this.props.pmoFilter.directors.length == 1) {
        let bu = this.state.buName.filter((bu) => bu.bu_id === this.state.loginUserData.business_unit.id);
        dataObject.business_unit = bu[0];
        this.state.selectBuFilter = bu[0];
      } else {
        dataObject.business_unit =
          this.state.selectBuFilter && (this.state.role_id === 1 || this.state.role_id === 10 || this.state.role_id === constants.HR || this.state.role_id === 8)
            ? this.state.selectBuFilter
            : [];
      }
      dataObject.los =
        this.state.selectService && (this.state.role_id === 1 || this.state.role_id === constants.HR || this.state.role_id === 8 || this.state.role_id === 10)
          ? this.state.selectService
          : [];
      // if (Array.isArray(this.state.selectChapter) && this.state.selectChapter && this.state.selectChapter.length > 0) {
      //   this.state.selectChapter.forEach((item) => {
      //     dataObject.chapter.push(item.label);
      //   });
      // }
    }
    if (Array.isArray(this.state.selectChapter) && this.state.selectChapter && this.state.selectChapter.length > 0) {
      this.state.selectChapter.forEach((item) => {
        dataObject.chapter.push(item.label);
      });
    } else if(this.state.loginUserData.role_id == 4) {
      dataObject.chapter = this.state.loginUserData.chapter_name && this.state.loginUserData.chapter_name != 'null' && this.state.loginUserData.chapter_name != 0 ? [this.state.loginUserData.chapter_name] : [];
    }

    if(this.state.selectLevel.length > 0){
      this.state.selectLevel.forEach((item) => {
        dataObject.level.push(item.label);
      });
    }

    dataObject.date = "2023-09-01";
    dataObject.pageFrom = 1;//to differentiate the page data from manage 
    payload["data"] = dataObject;
    this.setState({
      loading: true,
    });
    let selectedBU = this.state.selectBuFilter;
    let selectedLos = this.state.selectService;
    if(selectedBU) { selectedBU.value = selectedBU.bu_id };

    if (this.state.selectService && this.state.selectService.length > 0) {
      selectedLos.map((val, index) => {
        val.value = val.id
      })
    }

    let selectedChapter = this.state.selectChapter;
    if (Array.isArray(this.state.selectChapter) && this.state.selectChapter && this.state.selectChapter.length > 0) {
      selectedChapter.map((val, index) => {
        val.value = val.label
        val.isDisabled = false
      })
    }

    this.setState({ filteredBU: selectedBU, isManageQuestionFiltered: true, filteredLos: selectedLos, filteredChapter: selectedChapter })
    this.props.getPmsQuestion(payload).then(
      (response) => {
        this.setState({
          loading: false,
          pmsQuestion: this.props.pmsQuestion,
        });
      },
      (error) => {
        this.ToastAlert("error", "Something went wrong");
      }
    );
  };

  handleCloseModal = (e) => {
    this.setState({
      isQuestionOpen: false,
      defaultActiveKey: 'question_view'
    });
  };

  changeTabView = (tabName) => {
    if(tabName == 'add_question_view'){
      this.addQuestion();
    }

    this.setState({
      isEditQuestion: false,
      defaultActiveKey: tabName,
    });
  };

  handleDragStart = (e, item) => { 
    this.setState({ draggingItem: item }); 
    e.dataTransfer.setData('text/plain', ''); 
  }; 

  handleDragEnd = () => { 
      this.setState({ draggingItem: null }); 
  }; 

  handleDragOver = (e) => { 
      e.preventDefault(); 
  }; 

  handleDrop = (e, targetItem) => { 
    let draggingOrder = [];
    const { draggingItem, pmsQuestionList } = this.state; 
    if (!draggingItem) return; 

    const currentIndex = pmsQuestionList.indexOf(draggingItem); 
    const targetIndex = pmsQuestionList.indexOf(targetItem); 

    if (currentIndex !== -1 && targetIndex !== -1) { 
      pmsQuestionList.splice(currentIndex, 1); 
      pmsQuestionList.splice(targetIndex, 0, draggingItem); 

      pmsQuestionList.map((pmsQuestion, index) => {
        draggingOrder.push({ id: pmsQuestion.id, orderNumber: index }); 
      });

      this.setState({ 
        pmsQuestionList,
      }); 

      let postData = {
        draggingOrder: draggingOrder
      };

      this.props.updatePmsQuestionOrder(postData).then(
        (response) => {
          this.setState({
            loading: false,
          });
        },
        (error) => {
          this.ToastAlert("error", "Something went wrong");
        }
      );
    } 
  }; 
  questionPreview = () => {
    this.setState({showQueModal: true});
  }
  isModalClose = () =>{
    this.setState({showQueModal: false});
  }
  render() {
    var formattedCurrentDate = new Date();
    var formattedstartDate = new Date(moment(this.state.startQuesDate, 'DD-MM-YYYY').
      format('YYYY-MM-DD').toString() + " 0:00:00");
    var questionNumber = 0;
    if (this.state.redirect === 1 || this.state.loginUserData === null) {
      return <PageNotFound url={this.state.angularUrl} />;
    } else {
      return (
        <div
          className={
            "page-content px-4 " + (this.props.addClass ? "active" : "")
          }
        >
          {this.state.showDeleteModal && (
            <Modal
              className="delete-question-modal"
              show={this.state.showDeleteModal}
              onHide={() => this.setState({ showDeleteModal: false })}
            >
              <Modal.Header Confirm Delete closeButton>
                <Modal.Title className="custom-modal-title potential-heading">
                  <h4 className="pull-left">Delete Question</h4>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                Are you sure you want to delete this question?
              </Modal.Body>
              <Modal.Footer className="delete-modal">
                <Button
                  className="btn  back_button cancel_back_btn"
                  onClick={() => this.setState({ showDeleteModal: false })}
                >
                  CANCEL
                </Button>
                <Button
                  className="me-2"
                  onClick={(e) => this.confirmDeleteQuestion(e)}
                >
                  Delete
                </Button>
              </Modal.Footer>
            </Modal>
          )}
          {this.state.loading && (
            <div id="preloader">
              <div id="status"></div>
            </div>
          )}
          <div className="col-xs-12 fourty-fluid fourty-fluid m-t-50 pms-qst">
            {this.state.isManage && (
              <div>
                <div className="title-bar">
                  <h3>Manage Question</h3>
                </div>
                <div className="bgwhite service-container p-2 mt-3 mx-0 pb-5 multiselect-scrollbar">
                  <Row
                    xs={2}
                    md={3}
                    lg={6}
                  >
                    <Col className={`text-left d-flex flex-column w-25 text-start justify-content-end`}>
                      <label className="m-b-0 fs-12 p-t-5 style-label m-r-10">
                        Business Unit
                      </label>
                      {this.state.loginUserData.role_id &&
                        (this.state.loginUserData.role_id === 1 || this.state.loginUserData.role_id === 8 || (this.state.loginUserData.role_id === 10 && this.state.buLength > 1)||
                          this.state.loginUserData.role_id === constants.HR) ? (
                        <div className="selectpicker_dropdown hide_bar w-100 selectpicker_dropdown_orange">

                          <Select
                            className="basic-single-select"
                            classNamePrefix={"select_dropdown"}
                            placeholder="All"
                            isClearable
                            isSearchable={true}
                            options={this.state.buName}
                            value={this.state.selectBuFilter}
                            onChange={(e) => this.getReportByHead(e)}
                          />
                        </div>
                      ) : (
                        <div className="selectpicker_dropdown bu-name">
                          <div className="style-service fs-20">
                            {this.state.loginUserData.business_unit
                              ? this.state.loginUserData.business_unit
                                .bu_name
                              : ""}
                          </div>
                        </div>
                      )}
                    </Col>
                    <Col className={`text-left d-flex flex-column w-25  justify-content-end text-start`}>
                      <label className="m-b-0 fs-12 p-t-5 style-label m-r-10">
                        Line of Services
                      </label>
                      {this.state.loginUserData.role_id &&
                        (this.state.loginUserData.role_id === 1 || this.state.loginUserData.role_id === 8 || this.state.loginUserData.role_id === constants.HR || this.state.loginUserData.role_id === 10) ? (
                        <div className="selectpicker_dropdown w-100 hide_bar w-100 selectpicker_dropdown_orange">
                          <Select
                            className="basic-single-select"
                            classNamePrefix={"select_dropdown"}
                            placeholder="All"
                            isClearable
                            isSearchable={true}
                            onChange={(e) => this.filterService(e)}
                            value={this.state.selectService}
                            options={this.state.serviceName}
                            isMulti
                          ></Select>
                        </div>
                      ) : (
                        <div className="selectpicker_dropdown bu-name">
                          <div className="style-service fs-20">
                            {this.state.loginUserData.business_unit
                              ? this.state.loginUserData.business_unit
                                .serviceName
                              : ""}
                          </div>
                        </div>
                      )}
                    </Col>

                    <Col className={`text-left d-flex flex-column w-25 justify-content-end text-start`}>
                      <label className="m-b-0 fs-12 p-t-5 style-label m-r-10">
                        Chapter
                      </label>
                      {(this.state.loginUserData.role_id &&
                        ((constants.allowedRoleIds.includes(this.state.loginUserData.role_id))) ? (
                        <div>
                          <div className="selectpicker_dropdown hide_bar w-100 selectpicker_dropdown_orange">
                            <Select
                              className="basic-single-select"
                              classNamePrefix={"select_dropdown"}
                              placeholder="All"
                              isClearable
                              isSearchable={true}
                              options={this.state.chapterLead}
                              value={this.state.selectChapter}
                              onChange={(e) => this.handleChapter(e)}
                              isMulti
                            />
                          </div>
                        </div>
                      ) : <div className="selectpicker_dropdown bu-name">
                        <div className="style-service fs-20">
                          {this.state.loginUserData.chapter_name !== "null" && this.state.loginUserData.chapter_name !== null ? this.state.loginUserData.chapter_name: 'Not available'}
                        </div>
                      </div>)}

                    </Col>
                    <Col className={`text-left d-flex flex-column w-25 justify-content-end text-start`}>
                      <label className="m-b-0 fs-12 p-t-5 style-label m-r-10">
                        Level
                      </label>
                      <div className="selectpicker_dropdown hide_bar w-100 selectpicker_dropdown_orange">
                        <Select
                          className="basic-single-select"
                          classNamePrefix={"select_dropdown"}
                          placeholder="All"
                          isClearable
                          isSearchable={true}
                          options={this.state.level}
                          value={this.state.selectlevel}
                          onChange={(e) => this.handleLevel(e)}
                          isMulti
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row 
                      xs={12}
                      md={12}
                      lg={12}
                      className="pull-right p-t-7 p-r-10"
                    >
                      <button
                        className="btn btn-primary p-2"
                        onClick={(e) => this.generateQuestion(e)}
                      >
                        Apply Filter
                      </button>
                  </Row>
                </div>
              </div>
            )}
            {this.state.pmsQuestionList.length > 0 &&
              <div className="scrollable-content bgwhite p-3 mt-3 pms-tabs"> 
                <a
                  className="c-orange"
                  href="javascript:void(0)"
                  onClick={this.questionPreview}
                >
                  Click to preview the questions
                </a>
              </div>
            }
            <div className="scrollable-content bgwhite p-3 mt-3 pms-tabs">           
              {Boolean(this.state.startQuesDate && formattedstartDate <= formattedCurrentDate && this.state.startedBuName.length) && (
                <Alert variant="warning">
                  <p>
                    <i className='fa fa-exclamation-circle'></i> 
                    
                    { this.state.startedBuName[0] != "all" && 
                      <>
                      {this.state.startedBuName.map((bu, index) => (
                            <span key={index}>
                              {bu}
                              {index !== this.state.startedBuName.length - 1 && <span>&nbsp;, </span>}
                            </span>
                      ))}
                      &nbsp;  These survey dates started, and you are not able to add / modify questions for this Business Unit. 
                      </>  
                    }
                    { this.state.startedBuName[0]=="all" && 
                      <>                      
                       Survey date started, can not able to add / modify questions. 
                      </>  
                    }
                  </p>
                </Alert>
              )}
              {this.state.isManage && (
                <div>
                  <Tabs
                    activeKey={this.state.defaultActiveKey}
                    id="main_view"
                    className="mb-3"
                    fill
                    onSelect={(event) => {
                        this.changeTabView(event);
                    }}
                  >
                    <Tab
                      eventKey="question_view"
                      title="Questions"
                    >
                      {!this.state.loading &&
                        this.state.pmsQuestionList.length === 0 && (
                          <span>No data available</span>
                        )}
                      <ul>
                        <li>
                          {this.state.pmsQuestionList.length > 0 &&
                            this.state.pmsQuestionList.map((question, key) => (
                              <>        
                                <div
                                  draggable={(!this.state.startQuesDate || formattedstartDate <= formattedCurrentDate) ? "false" : "true" }
                                  onDragStart={(e) => this.handleDragStart(e, question)} 
                                  onDragEnd={this.handleDragEnd} 
                                  onDragOver={this.handleDragOver} 
                                  onDrop={(e) => this.handleDrop(e, question)}
                                >
                                  {(question.parent_id === null && question.updated_by === null) && (                             
                                  
                                  <div
                                  className={`row d-inline-flex w-100 ml-2 list-qst ${(!this.state.startQuesDate || formattedstartDate <= formattedCurrentDate) ? "" : "drag-list"}`}
                                  key={question.id}>
                                  <div className="col-md-10 questions-list">
                                    <p className="m-b-5">
                                      {  question.mandatory == 0 && 
                                        <div dangerouslySetInnerHTML={{ __html: ++questionNumber +'.' + question.question }} />  
                                      }
                                      {  question.mandatory == 1 && 
                                        <div dangerouslySetInnerHTML={{ __html: ++questionNumber +'.' +'<span class="text-red">*</span>' + question.question}} />  
                                      }
                                      
                                    </p>
                                    <span className="f-s-14 text-left font-bold m-l-25" style={{ color: '#f5821f' }}>
                                      {question.business_unit_name} | {question.service_name} | {question.chapter == '' || question.is_chapter_all ? 'All' : question.chapter} | {question.level}
                                    </span>
                                  </div>
                                  
                                  <div className="col-md-2 btn-list text-end pe-0">

                                  {(question.mandatory === 0 && formattedstartDate > formattedCurrentDate && (this.state.loginUserData.role_id === constants.HR || this.state.loginUserData.role_id === 1)) && 
                                      <div>
                                        <Button onClick={(e) => this.editQuestion(e, question.id)} value="edit"
                                          className="me-2" > <i className="fs-16 fa fa-edit"> </i></Button>
                                        <Button id={key} onClick={(e) => this.deleteQuestion(e, question.id)}
                                          value="delete" className="" >  <i className="fs-16 fa fa-trash"></i></Button>
                                      </div>
                                    }
                                    {(question.mandatory === 0 && formattedstartDate > formattedCurrentDate && (this.state.loginUserData.id === question.created_by || this.state.loginUserData.role_id == 10) && question.hr_id !== constants.HR ) &&
                                      <div>
                                        <Button onClick={(e) => this.editQuestion(e, question.id)} value="edit"
                                          className="me-2" > <i className="fs-16 fa fa-edit"> </i></Button>
                                        <Button id={key} onClick={(e) => this.deleteQuestion(e, question.id)}
                                          value="delete" className="" >  <i className="fs-16 fa fa-trash"></i></Button>
                                      </div>
                                    }
                                    {(question.mandatory === 1 && formattedstartDate > formattedCurrentDate && (this.state.loginUserData.role_id === constants.HR || this.state.loginUserData.role_id === 1)) &&
                                      <div>
                                        <Button onClick={(e) => this.editQuestion(e, question.id)} value="edit"
                                          className="me-2" > <i className="fs-16 fa fa-edit"> </i></Button>
                                        <Button id={key} onClick={(e) => this.deleteQuestion(e, question.id)}
                                          value="delete" className="" >  <i className="fs-16 fa fa-trash"></i></Button></div>
                                    }

                                  </div>
                                  </div>
                                    )} 
                                    {(question.parent_id === null && question.updated_by !== null && question.updated_by !== this.state.loginUserData.id && question.created_by === this.state.loginUserData.id ) && (
                                  <div
                                  className={`row d-inline-flex w-100 ml-2 list-qst ${(!this.state.startQuesDate || formattedstartDate <= formattedCurrentDate) ? "" : "drag-list"}`}
                                  key={question.id}>
                                  <div class="col-md-10 questions-list">
                                    <p className="m-b-5">
                                      {  question.mandatory == 0 && 
                                        <div dangerouslySetInnerHTML={{ __html: ++questionNumber +'.' + question.question }} />  
                                      }
                                      {  question.mandatory == 1 && 
                                        <div dangerouslySetInnerHTML={{ __html: ++questionNumber +'.' + '<span class="text-red">*</span>' + question.question }} />  
                                      }
                                    </p>
                                    <span className="f-s-14 text-left font-bold m-l-25" style={{ color: '#f5821f' }}>
                                      {question.business_unit_name} | {question.service_name} | {question.chapter == '' || question.is_chapter_all ? 'All' : question.chapter} | {question.level}
                                    </span>
                                  </div>
                                  
                                  <div class="col-md-2 btn-list text-end pe-0">

                                  {(question.mandatory === 0 && formattedstartDate > formattedCurrentDate && (this.state.loginUserData.role_id === constants.HR || this.state.loginUserData.role_id === 1)) &&
                                      <div>
                                        <Button onClick={(e) => this.editQuestion(e, question.id)} value="edit"
                                          className="me-2" > <i className="fs-16 fa fa-edit"> </i></Button>
                                        <Button id={key} onClick={(e) => this.deleteQuestion(e, question.id)}
                                          value="delete" className="" >  <i className="fs-16 fa fa-trash"></i></Button>
                                      </div>
                                    }
                                    {(question.mandatory === 0 && formattedstartDate > formattedCurrentDate && (this.state.loginUserData.id === question.created_by || this.state.loginUserData.role_id == 10) && question.hr_id !== constants.HR ) &&
                                      <div>
                                        <Button onClick={(e) => this.editQuestion(e, question.id)} value="edit"
                                          className="me-2" > <i className="fs-16 fa fa-edit"> </i></Button>
                                        <Button id={key} onClick={(e) => this.deleteQuestion(e, question.id)}
                                          value="delete" className="" >  <i className="fs-16 fa fa-trash"></i></Button>
                                      </div>
                                    }
                                    {(question.mandatory === 1 && formattedstartDate > formattedCurrentDate && (this.state.loginUserData.role_id === constants.HR || this.state.loginUserData.role_id === 1)) &&
                                      <div>
                                        <Button onClick={(e) => this.editQuestion(e, question.id)} value="edit"
                                          className="me-2" > <i className="fs-16 fa fa-edit"> </i></Button>
                                        <Button id={key} onClick={(e) => this.deleteQuestion(e, question.id)}
                                          value="delete" className="" >  <i className="fs-16 fa fa-trash"></i></Button></div>
                                    }

                                  </div>
                                  </div>
                                    )}
                                    {(question.parent_id !== null && question.updated_by !== this.state.loginUserData.id ) && (
                                  <div
                                  className={`row d-inline-flex w-100 ml-2 list-qst ${(!this.state.startQuesDate || formattedstartDate <= formattedCurrentDate) ? "" : "drag-list"}`}
                                  key={question.id}>
                                  <div class="col-md-10 questions-list">
                                    <p className="m-b-5">
                                      {  question.mandatory == 0 && 
                                        <div dangerouslySetInnerHTML={{ __html: ++questionNumber +'.' + question.question }} />  
                                      }
                                      {  question.mandatory == 1 && 
                                        <div dangerouslySetInnerHTML={{ __html: ++questionNumber +'.' + '<span class="text-red">*</span>' + question.question}} />  
                                      }
                                    </p>
                                    <span className="f-s-14 text-left font-bold m-l-25" style={{ color: '#f5821f' }}>
                                      {question.business_unit_name} | {question.service_name} | {question.chapter == '' || question.is_chapter_all ? 'All' : question.chapter} | {question.level}
                                    </span>
                                  </div>
                                  
                                  <div class="col-md-2 btn-list text-end pe-0">

                                  {(question.mandatory === 0 && !this.state.startedBuid.includes(question.business_unit_id) && this.state.startedBuName[0] != "all" && (this.state.loginUserData.role_id === constants.HR || this.state.loginUserData.role_id === 1)) &&
                                      <div>
                                        <Button onClick={(e) => this.editQuestion(e, question.id)} value="edit"
                                          className="me-2" > <i className="fs-16 fa fa-edit"> </i></Button>
                                        <Button id={key} onClick={(e) => this.deleteQuestion(e, question.id)}
                                          value="delete" className="" >  <i className="fs-16 fa fa-trash"></i></Button>
                                      </div>
                                    }
                                    {(question.mandatory === 0 && !this.state.startedBuid.includes(question.business_unit_id) && this.state.startedBuName[0] != "all" && (this.state.loginUserData.id === question.created_by || this.state.loginUserData.role_id == 10) && question.hr_id !== constants.HR ) &&
                                      <div>
                                        <Button onClick={(e) => this.editQuestion(e, question.id)} value="edit"
                                          className="me-2" > <i className="fs-16 fa fa-edit"> </i></Button>
                                        <Button id={key} onClick={(e) => this.deleteQuestion(e, question.id)}
                                          value="delete" className="" >  <i className="fs-16 fa fa-trash"></i></Button>
                                      </div>
                                    }
                                    {(question.mandatory === 1 && !this.state.startedBuid.includes(question.business_unit_id) && this.state.startedBuName[0] != "all" && (this.state.loginUserData.role_id === constants.HR || this.state.loginUserData.role_id === 1)) &&
                                      <div>
                                        <Button onClick={(e) => this.editQuestion(e, question.id)} value="edit"
                                          className="me-2" > <i className="fs-16 fa fa-edit"> </i></Button>
                                        <Button id={key} onClick={(e) => this.deleteQuestion(e, question.id)}
                                          value="delete" className="" >  <i className="fs-16 fa fa-trash"></i></Button></div>
                                    }

                                  </div>
                                  </div>
                                  )}
                                </div>
                              </>
                            ))}
                        </li>
                      </ul>
                    </Tab>
                    <Tab
                      eventKey="add_question_view"
                      title="Add/Edit Questions"
                      //disabled={!this.state.startQuesDate || formattedstartDate <= formattedCurrentDate}
                      disabled={this.state.isAddBtnDisabled}
                    >
                      {!this.state.isEditQuestion && this.state.isManage && this.state.isQuestionOpen && (
                        <AddQuestion
                          manageQuestion={this.manageQuestion}
                          backToManage={this.backToManage}
                          isAddQuestionModal={this.state.isQuestionOpen}
                          selectedFilter={this.state}
                          handleCloseModal={this.handleCloseModal}
                        />
                      )}
                      {/* Show edit question */}
                      {this.state.isEditQuestion && this.state.isQuestionOpen && (
                        <div>
                          <br></br>
                          <AddQuestion
                            isEditFlag={this.state.isEditOpen}
                            editQuestion={this.state.editQuestion}
                            isQuestion={true}
                            manageQuestion={this.manageQuestion}
                            backToManage={this.backToManage}
                            isAddQuestionModal={this.state.isQuestionOpen}
                            handleCloseModal={this.handleCloseModal}
                            selectedFilter={this.state}
                          />
                        </div>
                      )}
                      {/* End of edit question */}
                    </Tab>
                  </Tabs>
                </div>
              )}
            </div>
          </div>
          <div id="questionPreview">
            <Modal show={this.state.showQueModal}>
                <Modal.Header >
                    <Modal.Title className='custom-modal-title question-preview chapter-modal f-14 h5'>
                       Question Preview
                    </Modal.Title>
                    <button className="potential-button pull-right" onClick={() => this.isModalClose()}>X</button>
                </Modal.Header>
                <Modal.Body>       
                  <Form.Group > 
                    {this.state.pmsQuestionList.length > 0 &&
                      this.state.pmsQuestionList.map((question, key) => (   
                        <div key={key}>
                          <Form.Label>
                            {  question.mandatory == 0 && 
                              <div dangerouslySetInnerHTML={{ __html: ++key +'.' + this.stripHtmlTags(question.question) }} />  
                            }
                            {  question.mandatory == 1 && 
                              <div dangerouslySetInnerHTML={{ __html: ++key +'.' + this.stripHtmlTags(question.question) +'<span class="text-red">*</span>'}} />  
                            }
                          </Form.Label>
                            {question.question_type === 1 && (
                              <div>
                                <p class="mb-2">Employee Answer</p>
                                  <Form.Control
                                    as="textarea"
                                    name={key}
                                    rows={2}
                                    readOnly
                                />
                                <p class="mb-2 mt-2">Lead Comment:</p>
                                  <Form.Control
                                    as="textarea"
                                    name={key}
                                    rows={2}
                                    readOnly
                                />
                              </div>
                            )}
                            {question.question_type === 2 && (
                              <div>
                                 <p class="mb-2">Employee Rating</p>
                                <StarRating
                                  value={this.state.rating}
                                  starColor={"#F07F1E"}
                                  isSelectable={false}
                                  className="mr-20"
                                  label=""                                
                                />
                                <p class="mb-2">Employee Answer</p>
                                  <Form.Control
                                    as="textarea"
                                    name={key}
                                    rows={2}
                                    readOnly
                                />
                                <p class="mb-2 mt-2">Lead Rating</p>
                                <StarRating
                                  value={this.state.rating}
                                  starColor={"#F07F1E"}
                                  isSelectable={false}
                                  className="mr-20"
                                  label=""                                
                                />
                                <p class="mb-2 mt-2">Lead Comment:</p>
                                  <Form.Control
                                    as="textarea"
                                    name={key}
                                    rows={2}
                                    readOnly
                                />
                              </div>
                            )}
                            {question.question_type === 3 && (
                              <div>
                                 {Object.values(question.is_option).map(
                                      (questionOption, keyOption) => (
                                        <span>
                                          <input
                                            id={keyOption}
                                            type="radio"
                                            value={keyOption}     
                                            style={{width : "20px"}}                                    
                                          />
                                          {questionOption}
                                        </span>
                                      )
                                    )}
                                      <p class="mb-2 mt-2">Lead Comment:</p>
                                        <Form.Control
                                          as="textarea"
                                          name={key}
                                          rows={2}
                                          readOnly
                                      />
                              </div>
                            )}   
                            {question.question_type === 4 && (
                              <div>
                                 {Object.values(question.is_option).map(
                                      (questionOption, keyOption) => (
                                        <span>
                                          <input
                                            id={keyOption}
                                            type="checkbox"
                                            value={keyOption}     
                                            style={{width : "20px"}}                                    
                                          />
                                          {questionOption}
                                        </span>
                                      )
                                    )}
                                      <p class="mb-2 mt-2">Lead Comment:</p>
                                        <Form.Control
                                          as="textarea"
                                          name={key}
                                          rows={2}
                                          readOnly
                                      />
                              </div>
                            )}   
                          {this.state.pmsQuestionList.length > key && <hr />}
                        </div>
                        
                      ))
                    }
                  </Form.Group>
                </Modal.Body>
                <Modal.Footer style={{display:"block"}}>
                  <div>                 
                    <p class="mb-2">Lead Overall Comment:</p>
                      <Form.Control
                        as="textarea"
                        name="lead-comment"
                        rows={2}
                        readOnly
                    />
                    <p class="mb-2">Lead Overall Rating:</p>
                    <StarRating
                      value={this.state.rating}
                      starColor={"#F07F1E"}
                      isSelectable={false}
                      className="mr-20"
                      label=""                                
                    />                 
                    <p class="mb-2 mt-2">Manager Overall Comment:</p>
                      <Form.Control
                        as="textarea"
                        name="manage-cmt"
                        rows={2}
                        readOnly
                    />
                    <p class="mb-2 mt-2">Manager Overall Rating:</p>
                    <StarRating
                      value={this.state.rating}
                      starColor={"#F07F1E"}
                      isSelectable={false}
                      className="mr-20"
                      label=""                                
                    />
                  </div>
                </Modal.Footer>
            </Modal>
          </div>
        </div >
      );
    }
  }
}
const mapStateToProps = (state) => {

  return {
    loginDetails: state.loginDetails,
    reportFilter: state.reportFilterDetails.reportFilter,
    pmoFilter: state.reportFilterDetails.reportFilter,
    pmsQuestionList: state.pmsDetails.pmsQuestion,
    deletePms: state.pmsDetails.question,
    chapterLeadList: state.pmsDetails.chapterLead,
    levelList: state.pmsDetails.getLevel,
    editQuestion: state.pmsDetails.questionId,
    addClass: state.pmsDetails.addClass,
    dateList: state.pmsDetails.getStartEndDate,
    getStatedBU : state.pmsDetails.getStatedBU
  };
};

const mapDispatchToProps = {
  getLoginUser,
  getReportFilter,
  getLoginUserPermission,
  getReportFilterPmo,
  getPmsQuestion,
  deletePmsQuestion,
  getChapterLead,
  getLevel,
  editPmsQuestion,
  setAddClass,
  getStartEndDate,
  updatePmsQuestionOrder,
  getStartedSurveyBu
};

export default connect(mapStateToProps, mapDispatchToProps)(PmsManage);