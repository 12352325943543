import React from "react";
import { Urls } from "../../Config/Config";
import "../Reports/Report.css";
import Select from "react-select";
import {
  Row,
  Col,
  Button,
} from "react-bootstrap";
import Swal from "sweetalert2";
import Cookies from "js-cookie";
import PageNotFound from "../Reports/PageNotFound";
import "react-datepicker/dist/react-datepicker.css";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  getLoginUser, getReportFilter, getLoginUserPermission, getAcvReportData, acvExportExcel
} from "../../Action";
import { connect } from "react-redux";
import * as constants from "../../Config/Constant";
import { getReportFilterService, getUserService } from "../../Utilities/Helper";
import { Dropdown } from "rsuite";
import { getBackEndConstant } from "../../Config/Constant";
import "rsuite/dist/rsuite.min.css";
import accessdenied from "../../access-denied.png";
var _ = require('lodash');

const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  showCloseButton: true,
  customClass: {
    popup: "colored-toast",
    icon: "white-toast-icon",
  },
  timer: 3000,
  onOpen: (toast) => {
    toast.addEventListener("mouseenter", Swal.stopTimer);
    toast.addEventListener("mouseleave", Swal.resumeTimer);
  },
});

class AcvReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      queryParams: new URLSearchParams(window.location.search),
      redirect: 0,
      current_report: Urls.reports_lists[10],
      sname: [],
      sname1: [],
      report_practice: [],
      report_service: [],
      practiceHead: [],
      loginUserData: {},
      loginUserPermission: {},
      token: "",
      loading: false,
      bu_id: "",
      selectService: [],
      selectBu: [],
      selectBuFilter: [],
      reportList: [],
      serviceName: [],
      checkedList: [],
      reportF: 0,
      usersService: [],
      pmoReportData: [],
      totalAllocationValue: 0,
      pmoReportDataClone: [],
      report: {
        service: []
      },
      reportService: [],
      totalDevelopers: 0,
      rangeOptions: getBackEndConstant().rangeFilterValue,
      monthlyRateOptions: getBackEndConstant().runRateFilter,
      rangeValue: [],
      runRateValue: [],
    };
  }

  reset_page = () => {
    this.setState({
      report: {
        service: [],
        practice: [],
      },
      selectService: [],
      searchBy: '',
      reportService: []
    }, () => {
      this.getAcvReportData(this.state);
    });
  };

  componentDidMount() {
    let token = this.state.queryParams.get("token") == null ? Cookies.get("auth_data") :
      this.state.queryParams.get("token");
    this.setState({
      token: token,
      apiLink: constants.API_LINK,
      angularUrl: constants.FRONT_LINK,
      excelUrl: constants.EXCEL_LINK
    });
    this.getUser();
  }

  /**
   * Get the login user details
   */
  getUser = () => {
    this.setState({ loading: true });
    this.props.getLoginUser().then((response) => {
      this.props.getLoginUserPermission().then((response) => {
        this.setState({
          loading: false, loginUserData: this.props.loginDetails.loginDetails,
          loginUserPermission: this.props.loginDetails.loginUserPermission
        });
        this.getPracticeService();
      });
    });
  }

  //filter practice based on service and head
  filterService = (e) => {
    this.state.searchBy = '';
    if (e) {
      let selectedOption = e;
      this.setState({
        report: {
          service: selectedOption,
        },
        selectService: selectedOption,
      }, () => {
        this.getAcvReportData(this.state);
      });
    }
    else {
      this.getAcvReportData();
    }

  };

  getAcvReportData = () => {
    let dataObj = this.getInputData();
    this.setState({ loading: true });
    this.props.getAcvReportData(dataObj).then((response) => {
      this.setState({
        loading: false,
        acvReportData: this.props.acvReportDetails,
        acvReportDataClone: this.props.acvReportDetails,
      });
    }, (error) => {
      this.setState({
        loading: false,
        acvReportData: [],
        acvReportDataClone: [],
      });
      this.ToastAlert("error", error);
    });


  };

  generateAcvReportAsExcel = (e) => {
    e.currentTarget.blur(); // removing focus
    let dataObj = this.getInputData();
    this.setState({ loading: true });
    this.props.acvExportExcel(dataObj).then((response) => {
      this.setState({ loading: false });
      this.ToastAlert("success", "Report generated Successfully");
      document.location = this.state.excelUrl + this.props.acvExport.filename;
    }, (error) => {
      this.setState({ loading: false });
      this.ToastAlert("error", error);
    });
  };

  getInputData = () => {
    let dataObj = {}, reportData = {};
    reportData.business_unit = this.state.bu_id;
    let ser = [];
    if (this.state.report.service.length > 0) {
      reportData.service = [this.state.report.service[0].id]
      this.state.report.service.forEach(svalue => {
        ser.push(svalue.id)
      })
      reportData.service = ser;
    }
    else {
      // getting user associated service list
      reportData.service = getReportFilterService(this.state.serviceName, this.state.loginUserData, this.state.usersService);
    }
    dataObj['reportData'] = reportData;
    return dataObj;
  }

  ToastAlert(icon, title) {
    return Toast.fire({
      target: document.getElementById("form-modal"),
      icon: `${icon}`,
      title: `${title}`,
    });
  }

  resetReport = (e) => {
    e.currentTarget.blur(); // removing focus
    this.setState({
      searchBy: '',
    });
    this.getAcvReportData();
  };

  searchBy = (e) => {
    let searchInput = e.target.value;
    let filteredData = [];
    this.setState({
      rangeValue: [],
      runRateValue: []
    })
    if (searchInput) {
      Object.values(this.state.acvReportDataClone).map((keys) => {
        const filter = Object.values(keys).filter((value) => {
          return Object.values(value).join("").toLowerCase().includes(searchInput.toLowerCase());
        });
        if (filter.length > 0) {
          filteredData.push(filter);
        }
      });
      this.setState({
        searchBy: e.target.value
      });
    }
    else {
      this.setState({
        searchBy: ''
      });
      filteredData = this.state.acvReportDataClone;
    }
    this.setState({
      acvReportData: filteredData,
    })
  }

  getPracticeService = (isAllPractice) => {
    this.setState({
      loading: true,
      isAllPractices: isAllPractice,
      filterService: [],
      report: {
        service: []
      },
      sname: [],
      allService: [],
      serviceName: []
    });
    let dateValue = new Date();
    let date = new Date(dateValue.getFullYear(), dateValue.getMonth(), 2);
    let start_date = this.state.filter_start_date
      ? this.state.filter_start_date
      : date;
    let end_date = this.state.filter_end_date
      ? this.state.filter_end_date
      : date;
    let dataObject = {};
    dataObject.start_date = start_date;
    dataObject.end_date = end_date;
    dataObject.filter = isAllPractice;

    let c = 0;
    this.props.getReportFilter(dataObject).then((response) => {
      this.props.reportFilter.directors.forEach((value) => {
        if (this.state.bu_id == value.bu_id) {
          c = 1;
        }
      });
      let bu_id = c == 1 ? this.state.bu_id : "";
      let selectBuFilter = c == 1 ? this.state.selectBuFilter : [];
      let sname = [];
      let allService = [], serviceName = [];
      if (
        this.state.bu_id != "" &&
        this.state.bu_id != undefined &&
        this.state.bu_id != 0
      ) {
        this.props.reportFilter.services.forEach((service) => {
          if (this.state.bu_id == service.bu) {
            sname.push(service);
          }
        });
        this.props.reportFilter.allservices.forEach((service) => {
          if (this.state.bu_id == service.bu) {
            allService.push(service);
          }
        });
      } else {
        sname = this.props.reportFilter.services;
        allService = this.props.reportFilter.allservices;
      }
      this.setState({
        usersService: this.props.reportFilter.services,
        sname1: this.props.reportFilter.services,
        bu_id: bu_id,
        selectBuFilter: selectBuFilter,
        sname: sname,
        allService: allService
      })
      this.state.sname.forEach((value) => {
        let opt = {};
        opt.label = value.value;
        opt.id = value.id;
        opt.value = value.value;
        opt.head = value.head;
        opt.bu = value.bu;
        serviceName.push(opt);
      });
      // for practicehead
      let practiceHead = [];
      this.props.reportFilter.directors.forEach((id) => {
        practiceHead.push(id);
      });
      let buName = [];
      this.props.reportFilter.directors.forEach((value) => {
        let opt = {};
        opt.label = value.bu;
        opt.value = value.bu;
        opt.bu_id = value.bu_id;
        opt.id = value.id;
        opt.bu = value.bu;
        opt.name = value.name;
        buName.push(opt);
      });
      this.setState({
        practiceHead: practiceHead,
        buName: buName,
        report_all_practices: this.props.reportFilter.practices,
        qes_list: this.props.reportFilter.qes_list,
        serviceName: serviceName
      });
      if (this.state.loginUserData.role_id == 8) {
        this.setState({
          searchByPracticeHead: this.state.loginUserData.role_id,
        })
      }
    }, (error) => {
      this.setState({
        usersService: [],
        sname1: [],
        bu_id: '',
        selectBuFilter: [],
        sname: [],
        allService: []
      })
    });
    this.getAcvReportData();
  };

  getReportByHead = (e) => {
    this.state.searchBy = '';
    if (e != undefined) {
      this.setState({
        bu_id: e.bu_id,
        head_id: e.label,
        selectBuFilter: e
      }, () => {
        this.getBuFilter(this.state);
      });
    } else {
      this.setState({
        bu_id: '',
        head_id: 'All',
        selectBuFilter: []
      }, () => {
        this.getBuFilter(this.state);
      });
    }
  };

  getBuFilter = () => {
    let serviceArray = [];
    let service_head = [];
    if (this.state.head_id != "All") {
      // for BU filter    
      this.state.sname1.forEach((service) => {
        if (this.state.bu_id == service.bu) {
          service_head.push(service);
        }
      });
      this.setState({
        serviceName: [],
        selectService: [],
        report: {
          service: []
        }
      })
    } else {
      // when All BU is selected
      this.setState({
        serviceName: [],
        selectService: [],
        selectBuFilter: []
      })
      if (this.state.report.service.length == 0) {
        this.state.sname1.forEach((service) => {
          service_head.push(service);
        });
      } else {
        service_head = this.state.sname;
        this.setState({
          report: {
            service: []
          }
        })
      }
    }
    service_head.forEach((value) => {
      let opt = {};
      opt.label = value.value;
      opt.id = value.id;
      opt.value = value.value;
      opt.head = value.head;
      opt.bu = value.bu;
      serviceArray.push(opt);
    });
    this.setState({
      serviceName: serviceArray,
    }, () => {
      this.getAcvReportData(this.state);
    });
  }

  rangeFilter = (e) => {
    let filtered = [];
    this.setState({
      rangeValue: e,
      runRateValue: [],
      searchBy: ''
    })
    if (e && e.value != "All") {
      Object.values(this.state.acvReportDataClone).map((keys) => {
        const filter = Object.values(keys).filter((value) => {
          return Object.values(value).join("").toLowerCase().includes(e.value.toLowerCase());
        });
        if (filter.length > 0) {
          filtered.push(filter);
        }
      });
      this.setState({
        acvReportData: filtered
      });
    } else {
      return this.setState({
        acvReportData: this.state.acvReportDataClone
      });
    }
  }

  monthRunRateFilter(e) {
    let filtered = [];
    this.setState({
      runRateValue: e,
      rangeValue: [],
      searchBy: ''
    })
    if (e && e.value != "All") {
      Object.entries(this.state.acvReportDataClone).map(([key, value]) => {
        const filteredObject = Object.values(value).filter((item) => {
          return item.monthly_runrate_range === e.value
        });

        if (filteredObject.length > 0) {
          filtered.push(filteredObject);
        }
      });
      this.setState({
        acvReportData: filtered
      });
    } else {
      return this.setState({
        acvReportData: this.state.acvReportDataClone
      });
    }
  }

  /**
   * main render
   * @returns
   */
  render() {
    console.log(this.state.acvReportData, 'this.state.acvReportData');
    let index = 1;
    if (this.state.redirect == 1 || this.state.loginUserData == null) {
      return <PageNotFound url={this.state.angularUrl} />
    }
    else {
      return (
        <>
          <div className="page-content px-4">
            <Row>
              <div className="col-xs-12 fourty-fluid fourty-fluid">
                <Row className="mb-3">
                  <div className="col-sm-4 col-sm-4 col-xs-12 text-left">
                    <h1 className="B-G-bold f-s-18 d-gray t-t-upper pull-left mt-10 mr-20 page-head">Reports</h1>
                  </div>
                  <div className="col-lg-3 col-sm-4 col-xs-12">
                    <div className="selectpicker_dropdown hide_cross hide_bar orange_arrow remove_border_btm select_dropdown_picker">
                      <Dropdown title={this.state.current_report.value} activeKey="11">
                        <Dropdown.Item as="a" eventKey="1" href="/pw_report" target="_blank">Practice Wise Allocation Report</Dropdown.Item>
                        <Dropdown.Item as="a" eventKey="2" href="/ms_report" target="_blank">Milestone Value Report</Dropdown.Item>
                        <Dropdown.Item as="a" eventKey="3" href="/collections_report" target="_blank">Collections Report</Dropdown.Item>
                        <Dropdown.Item as="a" eventKey="4" href="/collections_potential_report" target="_blank">Collection Potential Report</Dropdown.Item>
                        <Dropdown.Item as="a" eventKey="5" href="/inventory_report" target="_blank">Inventory Report</Dropdown.Item>
                        <Dropdown.Item as="a" eventKey="6" href="/inventory_allocation_report" target="_blank">Inventory Allocation Metrics</Dropdown.Item>
                        <Dropdown.Item as="a" eventKey="7" href="/inventory_milestone_report" target="_blank">Inventory Milestone Metrics</Dropdown.Item>
                        <Dropdown.Item as="a" eventKey="8" href="/utilization_report" target="_blank">Resource Utilization Report</Dropdown.Item>
                        <Dropdown.Item as="a" eventKey="9" href="/csm_report" target="_blank">Csm Report</Dropdown.Item>
                        <Dropdown.Item as="a" eventKey="10" href="/pmo_ms_report" target="_blank">Pmo Ms Report</Dropdown.Item>
                        <Dropdown.Item as="a" eventKey="11">Annual Contract Value Report</Dropdown.Item>
                        <Dropdown.Item as="a" eventKey="12" href="/ms-allocation-report" target="_blank">Ms Allocation Report</Dropdown.Item>
                      </Dropdown>
                    </div>
                  </div>
                </Row>
                <Row>
                  <div className="col-lg-6 clearfix m-b-20">
                    <div className="row align-items-center">
                      <label class="f-s-12 text-muted pt-1 w-13 col-lg-2 col-md-6 col-sm-12 text-left">Line of Service :</label>
                      <div className="col-lg-7 col-sm-5 text-left ">
                        <Row>
                          <div className="col-lg-10 p-l-0">
                            <div className="selectpicker_dropdown w-100 hide_bar w-100 selectpicker_dropdown_orange">
                              <Select
                                className="basic-single-select"
                                classNamePrefix={"select_dropdown"}
                                placeholder="All"
                                isClearable
                                isSearchable={true}
                                onChange={(e) => this.filterService(e)}
                                value={this.state.selectService}
                                options={this.state.serviceName} isMulti>
                              </Select>
                            </div>
                          </div>
                          <div className="col-lg-2 p-1-0">
                            {this.state.report.service != undefined && this.state.report.service.length > 0 &&
                              <a href="javascript:void(0)" title="Reset" tooltip-placement="top" className="reset-icon_old  reset-color p-l-5 m-l-4" onClick={this.reset_page}><i class="fs-16 fa fa-refresh"></i></a>
                            }
                          </div>
                        </Row>
                      </div>
                    </div>
                  </div>
                  {this.state.loginUserData.role_id != 8 &&
                    <div className="col-lg-4 clearfix m-b-20">
                      <div className="row">
                        <div class="col-sm-5 col-xs-12">
                          <label className="text-uppercase m-b-0 fs-13 p-t-5">Business Unit :</label>
                        </div>
                        <div className="col-sm-7 col-xs-12">
                          <div className="selectpicker_dropdown hide_bar w-100 selectpicker_dropdown_orange">
                            <Select
                              className="basic-single-select"
                              classNamePrefix={"select_dropdown"}
                              placeholder="All"
                              isClearable
                              isSearchable={true}
                              onChange={(e) => this.getReportByHead(e)}
                              value={this.state.selectBuFilter}
                              options={this.state.buName} >
                            </Select>

                          </div>
                        </div>
                      </div>
                    </div>}
                  <div className="col-lg-2 col-sm-2 col-xs-12 justify-content-end text-end">
                    <a href={this.state.angularUrl} className="btn btn-primary">Back</a>
                  </div>
                </Row>
                <hr className="m-t-5 col-sm-12 m-b-8 p-0"></hr>
                {this.props.loginDetails.loginUserPermission && this.props.loginDetails.loginUserPermission.permission && this.props.loginDetails.loginUserPermission.permission.view_acv_report &&
                  <>
                    <div className="col-lg-12 col-sm-12 col-xs-12 p-b-15-xs p-r-15-xs">
                      <Row className=" mt-3 mb-3">
                        <Col className="col-lg-6 text-start">
                          <div className="form-group search-icon col-sm-12 col-xs-12">
                            <input type="text" name="project_filter" value={this.state.searchBy} className="form-control B-G-bold f-s-16 d-gray pl-30" placeholder="Search..." onChange={(e) => this.searchBy(e)} />
                            <i class="fa fa-search"></i>
                          </div>
                        </Col>
                        <Col className="col-lg-6 text-end">
                          <Row className="m-0 mt-1">
                            <Col className="col-lg-3">
                              <Button onClick={(e) => this.resetReport(e)} value="Generate" className="me-2" >Generate</Button>

                            </Col>
                            <Col className="col-lg-3">
                              <Button onClick={(e) => this.generateAcvReportAsExcel(e)} value="Excel" className="me-2" disabled={this.state.acvReportData && Object.keys(this.state.acvReportData).length == 0}>Export as Excel</Button>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <Row className=" mt-3 mb-3">
                        <Col className="col-lg-12 ">
                          <Row className="m-0 mt-1">
                            <Col className="col-lg-6">
                              <div className="d-flex align-items-center" >
                                <label className="text-uppercase m-b-0 fs-13 p-t-7">RunRate Filter:</label>
                                <div className="selectpicker_dropdown w-50 hide_bar w-50 selectpicker_dropdown_orange m-l-15">
                                  <Select
                                    className="basic-single-select"
                                    classNamePrefix={"select_dropdown"}
                                    placeholder="All"
                                    isClearable
                                    isSearchable={true}
                                    onChange={(e) => this.monthRunRateFilter(e)}
                                    value={this.state.runRateValue}
                                    options={this.state.monthlyRateOptions}>
                                  </Select>
                                </div>
                              </div>
                            </Col>
                            <Col className="col-lg-6">
                              <div className="d-flex  align-items-center" >
                                <label className="text-uppercase m-b-0 fs-13 p-t-5">Range Filter :</label>
                                <div className="selectpicker_dropdown w-50 hide_bar w-50 selectpicker_dropdown_orange m-l-15">
                                  <Select
                                    className="basic-single-select"
                                    classNamePrefix={"select_dropdown"}
                                    placeholder="All"
                                    isClearable
                                    isSearchable={true}
                                    onChange={(e) => this.rangeFilter(e)}
                                    value={this.state.rangeValue}
                                    options={this.state.rangeOptions} >
                                  </Select>
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </div>
                    <hr className="m-t-5 col-sm-12 m-b-8 p-0"></hr>
                    <div className="table-responsive white-table-class acv-report">
                      <div className="scrollable">
                        <div className="scrollable-content">
                          <table className="table m-b-0 table-wrap">
                            <thead>
                              <tr className="theadInner busi_unit_bg">
                                <th className="text-start" style={{ "width": "5%" }}>S.NO</th>
                                <th className="text-start" style={{ "width": "10%" }}>Project Name</th>
                                <th className="text-start" style={{ "width": "10%" }}>Project Type</th>
                                <th className="text-start" style={{ "width": "10%" }}>Client Name</th>
                                <th className="text-start" style={{ "width": "10%" }}>Contract Start Date</th>
                                <th className="text-start" style={{ "width": "10%" }}>Contract End Date</th>
                                <th className="text-start" style={{ "width": "10%" }}>No of Months</th>
                                <th className="text-start" style={{ "width": "10%" }}>Monthly RunRate</th>
                                <th className="text-start" style={{ "width": "10%" }}>Allocation Value</th>
                                <th className="text-start" style={{ "width": "10%" }}>Annual contract Value</th>
                                <th className="text-start" style={{ "width": "10%" }}>ACV Range</th>
                              </tr>
                            </thead>

                            {this.state.loading &&
                              <div id="preloader">
                                <div id="status"></div>
                              </div>
                            }
                            {!this.state.loading && this.state.acvReportData && Object.keys(this.state.acvReportData).length == 0 &&
                              <tr>
                                <td colSpan="8" className="r-no-data text-center">No data available</td>
                              </tr>
                            }
                            {!this.state.loading && this.state.acvReportData && Object.keys(this.state.acvReportData).length > 0 && Object.values(this.state.acvReportData).map((report) => (
                              <>
                                <tbody>
                                  {Object.values(report).map((value, key) => (
                                    <tr key={index}>
                                      <td>{index++}</td>
                                      <td className="text-start">
                                        <span className="name p-l-5 pmo-truncate p-t-3 fs-13" style={{ "maxWidth": "200px", "textAlign": "left" }} title={`${value.project_name}`}>{value.project_name}</span>
                                      </td>
                                      <td className="text-start">
                                        <span>{value.project_type} </span>
                                      </td>
                                      <td className="text-start">
                                        {key == 0 && <span className="name p-l-5 pmo-truncate p-t-3 fs-13" style={{ "maxWidth": "200px", "textAlign": "left" }} title={`${value.client_name}`}>{value.client_name}</span>
                                        }
                                      </td>
                                      <td className="text-start">
                                        <span>{value.start_date} </span>
                                      </td>
                                      <td className="text-start">
                                        <span>{value.end_date} </span>
                                      </td>
                                      <td className="text-start">{value.no_of_months}</td>
                                      <td className="text-start">{value.monthly_rate}</td>
                                      <td className="text-start">
                                        <span> {value.allocation_amount} </span>
                                      </td>
                                      <td className="text-start">
                                        <span>
                                          {/* {value.allocationValue} */}
                                          {key == 0 &&
                                            ((report && Object.values(report).reduce((sum, current) => {
                                              return sum + current.allocation_amount
                                            }, 0))).toLocaleString(undefined, {
                                              minimumFractionDigits: 2,
                                              maximumFractionDigits: 2
                                            })
                                          }
                                        </span>
                                      </td>
                                      <td>
                                        <span>{value.clientAmount}</span>
                                      </td>
                                    </tr>
                                  ))
                                  }
                                </tbody>
                                <tbody></tbody>
                              </>
                            ))}
                          </table>
                        </div>
                      </div>
                    </div>
                  </>
                }
                {this.props.loginDetails.loginUserPermission && this.props.loginDetails.loginUserPermission.permission && !this.props.loginDetails.loginUserPermission.permission.view_acv_report &&
                  <div className="page-content">
                    <div className="col-md-12 m-b-30">
                      <div className="four_not_err access_denied">
                        <div className="for_content">
                          <img alt="sad" width="" src={accessdenied} />
                          <h1>You don't have permission to access this page</h1>
                        </div>
                      </div>
                    </div>
                  </div>
                }
              </div>
            </Row>
          </div>
        </>
      );
    }
  }
}

const mapStateToProps = state => {
  return {
    acvReportDetails: state.acvReportDetails.acvReport,
    acvExport: state.acvReportDetails.acvExport,
    loginDetails: state.loginDetails,
    reportFilter: state.reportFilterDetails.reportFilter,
  };
};

const mapDispatchToProps = {
  getAcvReportData,
  getLoginUser,
  getReportFilter,
  getLoginUserPermission,
  acvExportExcel
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AcvReport);
