import React, { Component } from 'react';
import { Button, Form, FormControl, Modal } from 'react-bootstrap';
import "../Reports/Report.css";
import {

    getCurrentMonthProjectName, getCurrentProjectDetails, saveCurrentPotentialProject,
    getPotentialProjectList, getReportFilter, getReportFilterPmo, getLoginUser, getLoginUserPermission,

} from "../../Action";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import moment from 'moment';
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";


const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    showCloseButton: true,
    customClass: {
        popup: "colored-toast",
        icon: "white-toast-icon",
    },
    timer: 3000,
    onOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
});
class AddPotentialPopUp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            projectName: '',
            target: '',
            status: '',
            lineOfSerivce: [],
            businessUnit: [],
            csm: [],
            serviceId: [],
            formValid: false,
            potentialCurrentProjectDetails: [],
            selectProjectId: '',
            mode: '',
            loadingPotential: false,
            project: [],
            projectId: 0,
            buId: [],
            potential_date: moment(),
            validationErrors: {},
            potentialProjectList: [],
            selectedOptions: [],
            csmName: [],
            editPotentialData: [],
            selectedProjectId: "",
            potentialDate: new Date(),
            bu_id: "",
            selectService: [],
            selectBuFilter: [],
            editData: {},
            isModalOpen: true,
        };

    }
    async componentDidMount() {
        await this.getPotentialProjectList();
        await this.getUser();
        if (this.props.isEditModal) {
            this.setState({
                loading: true,
                editData: this.props?.isfilterEdit ? this.props.isfilterEdit : null,
            })
            const { service, buName } = this.props.isfilterEdit;
            let selectedCsm = [];
            let unselectedCsm = [];
            let data = this.props.isfilterEdit ? JSON.parse(this.props?.isfilterEdit.csm) : [];

            data.length && data.map(item => {
                unselectedCsm.push({
                    label: item.label,
                    value: item.value
                });
            });

            this.props?.isfilterEdit?.csmMember.forEach((value) => {
                selectedCsm.push(value);
            });

            const serviceId = this.props.isfilterEdit?.serviceId.map(Number);
            const serviceData = this.props.pmoFilter?.services.filter(item => serviceId.includes(item.id));
            const serviceName = serviceData.map(value => ({
                label: value.value,
                id: value.id,
                value: value.value,
                head: value.head,
                bu: value.bu
            }));

            const buId = this.props.isfilterEdit?.buId.map(Number);
            const businessUnitData = this.props.pmoFilter?.directors.filter(item => buId.includes(item.bu_id));
            const businessUnitName = businessUnitData.map(value => ({
                label: value.bu,
                id: value.id,
                value: value.bu,
                head: value.head,
                bu: value.bu,
                bu_id: value.bu_id,
                name: value.name,
            }));
            this.setState({
                selectService: [...serviceName],
                selectBuFilter: [...businessUnitName],
                csmMember: selectedCsm,
                selectedOptions: unselectedCsm,
                loading: false,
            });

        }

        let projectId = "";
        if (this.props.isEditModal && this.props.isfilterEdit.project_name !== undefined) {
            this.props.isProjectName.forEach((val, index) => {
                if (val.project_name === this.props.isfilterEdit.project_name) {
                    this.setState({ selectedProjectId: val.id });
                    projectId = val.id;
                }
            });
        }

        if (!this.props.isEditModal && projectId !== "") {
            await this.getProjectNameList(projectId);
        }
    }


    /**
  * Get the login user details
  */
    getUser = () => {
        this.setState({ loading: true });
        this.props.getLoginUser().then((response) => {
            this.props.getLoginUserPermission().then((response) => {
                this.setState({
                    loading: false, loginUserData: this.props.loginDetails.loginDetails,
                    loginUserPermission: this.props.loginDetails.loginUserPermission
                });
                this.getPracticeService();
            });
        });
    }

    getPracticeService = (isAllPractice) => {
        this.setState({
            loading: true,
            isAllPractices: isAllPractice,
            filterService: [],
            report: {
                service: []
            },
            sname: [],
            allService: [],
            serviceName: [],
            sname1: [],
        });
        let dateValue = new Date();
        let date = new Date(dateValue.getFullYear(), dateValue.getMonth(), 2);
        let start_date = this.state.filter_start_date
            ? this.state.filter_start_date
            : date;
        let end_date = this.state.filter_end_date
            ? this.state.filter_end_date
            : date;
        let dataObject = {};
        dataObject.start_date = start_date;
        dataObject.end_date = end_date;
        dataObject.filter = isAllPractice;

        let c = 0;
        this.props.getReportFilter(dataObject).then((response) => {
            this.props.pmoFilter.directors.forEach((value) => {
                if (this.state.bu_id == value.bu_id) {
                    c = 1;
                }
            });
            let bu_id = c == 1 ? this.state.bu_id : "";
            let selectBuFilter = c == 1 ? this.state.selectBuFilter : this.state.selectBuFilter;
            let sname = [];
            let allService = [], serviceName = [];
            if (
                this.state.bu_id != "" &&
                this.state.bu_id != undefined &&
                this.state.bu_id != 0
            ) {
                this.props.pmoFilter.services.forEach((service) => {
                    if (this.state.bu_id == service.bu) {
                        sname.push(service);
                    }
                });
                this.props.pmoFilter.allservices.forEach((service) => {
                    if (this.state.bu_id == service.bu) {
                        allService.push(service);
                    }
                });
            } else {
                sname = this.props.pmoFilter.services;
                allService = this.props.pmoFilter.allservices;
            }
            this.setState({
                usersService: this.props.pmoFilter.services,
                sname1: this.props.pmoFilter.services,
                bu_id: bu_id,
                selectBuFilter: selectBuFilter,
                sname: sname,
                allService: allService
            })
            !this.props.isEditModal && this.state.sname1?.forEach((value) => {
                let opt = {};
                opt.label = value.value;
                opt.id = value.id;
                opt.value = value.value;
                opt.head = value.head;
                opt.bu = value.bu;
                serviceName.push(opt);
            });
            if (this.props.isEditModal) {
                const buId = this.props.isfilterEdit?.buId.map(Number);
                const serviceOption = this.props.pmoFilter?.services.filter(item => buId.includes(item.bu));
                const serviceValue = serviceOption.map(value => ({
                    label: value.value,
                    id: value.id,
                    value: value.value,
                    head: value.head,
                    bu: value.bu
                }));
                serviceName = serviceValue;

            }
            // for practicehead
            let practiceHead = [];
            this.props.pmoFilter.directors.forEach((id) => {
                practiceHead.push(id);
            });
            let buName = [];
            this.props.pmoFilter.directors.forEach((value) => {
                let opt = {};
                opt.label = value.bu;
                opt.value = value.bu;
                opt.bu_id = value.bu_id;
                opt.id = value.id;
                opt.bu = value.bu;
                opt.name = value.name;
                buName.push(opt);
            });
            this.setState({
                practiceHead: practiceHead,
                buName: buName,
                report_all_practices: this.props.pmoFilter.practices,
                qes_list: this.props.pmoFilter.qes_list,
                serviceName: serviceName
            });
        }, (error) => {
            this.setState({
                usersService: [],
                sname1: [],
                bu_id: '',
                selectBuFilter: [],
                sname: [],
                allService: []
            })
        });
    };


    getReportByHead = (e) => {
        const prevSelectedValues = this.state.selectBuFilter;
        const removedValue = prevSelectedValues.find(
            (prevValue) => !e.some((e) => e.value === prevValue.value)
        );
        if (removedValue) {
            const buIdsToRemove = removedValue.bu_id;
            const filteredArray = this.state.selectService.filter(item => item.bu != buIdsToRemove);
            this.setState({
                selectService: filteredArray,
            });
        }
        this.state.searchBy = '';
        if (e) {
            this.setState({
                bu_id: e.bu_id,
                head_id: e.label,
                selectBuFilter: e
            }, () => {
                this.getBuFilter(this.state);
            });
        } else {
            this.setState({
                bu_id: '',
                head_id: 'All',
                // selectBuFilter: []
            }, () => {
                this.getBuFilter(this.state);
            });
        }

    };



    /* business unit filter function  */
    getBuFilter = () => {
        let serviceArray = [];
        let service_head = [];
        if (this.state?.head_id != "All") {
            let filteredData = this.props.pmoFilter?.services?.filter(item1 => {
                return this.state.selectBuFilter.some(item2 => item2.bu_id == item1.bu);
            });
            service_head = filteredData;
            this.setState({
                serviceName: [],
                report: {
                    service: []
                }
            })
        } else {
            // when All BU is selected
            this.setState({
                serviceName: [],
                selectService: [],
            })
        }
        if (service_head) {
            service_head.forEach((value) => {
                let opt = {};
                opt.label = value.value;
                opt.id = value.id;
                opt.value = value.value;
                opt.head = value.head;
                opt.bu = value.bu;
                serviceArray.push(opt);
            });
        }
        this.setState({
            serviceName: serviceArray,
            validationErrors: {
                ...this.state.validationErrors, projectBusinessUnit: '',
            },


        });

    }


    getProjectNameList = (value) => {
        const startDate = moment().startOf('month').format('YYYY-MM-01');
        const endDate = moment().endOf('month').format('YYYY-MM-DD');
        const payload = {
            project_id: value,
            start_date: startDate,
            end_date: endDate,
        };
        this.props.getCurrentProjectDetails(payload).then((response) => {
            let selectedCsm = [];

            let unselectedCsm = [];
            let data = this.props.isfilterEdit ? JSON.parse(this.props.isfilterEdit.csm) : [];
            {
                data.length && data.map(item => {
                    unselectedCsm.push({
                        label: item.label, value: item.value
                    });
                });
            }
            // const serviceId = this.props.potentialCurrentProjectDetails?.project?.service.map(Number);
            const serviceId = this.props.potentialCurrentProjectDetails?.project?.service.split(",").map(Number);
            const filteredData = this.props.pmoFilter?.services.filter(item => serviceId.includes(item.id));
            const serviceName = filteredData.map(value => ({
                label: value.value,
                id: value.id,
                value: value.value,
                head: value.head,
                bu: value.bu
            }));
            const businessId = this.props.potentialCurrentProjectDetails?.project?.buId.split(",").map(Number);
            const businessUnitData = this.props.pmoFilter?.directors.filter(item => businessId.includes(item.bu_id));
            const businessUnitName = businessUnitData.map(value => ({
                label: value.bu,
                id: value.id,
                value: value.bu,
                head: value.head,
                bu: value.bu,
                bu_id: value.bu_id,
                name: value.name,
            }));
            this.setState({
                selectService: [...serviceName],
                selectBuFilter: [...businessUnitName],
                validationErrors: {
                    ...this.state.validationErrors, projectBusinessUnit: '',
                    projectService: '',
                    projectName: '',
                },

            });
            this.getReportByHead(this.state.selectBuFilter);
            this.props.potentialCurrentProjectDetails.csmMember.forEach((value) => {
                selectedCsm.push(value);
            });
            this.setState({
                loadingPotential: true,
                potentialCurrentProjectDetails: this.props.potentialCurrentProjectDetails,
                project: this.props.potentialCurrentProjectDetails.project,
                csmMember: selectedCsm,
                selectedOptions: unselectedCsm,
                potentialDate: this.props.isEditModal ? new Date(this.props.isfilterEdit.potential_date) : ''
            });
        }, (error) => {
            this.setState({
                loadingPotential: false,
                potentialCurrentProjectDetails: [],
                project: [],
            });
            this.ToastAlert("error", error);
        });

    }

    ToastAlert(icon, title) {
        return Toast.fire({
            target: document.getElementById("form-modal"),
            icon: `${icon}`,
            title: `${title}`,
        });
    }

    handleInputChange = (event) => {
        const { name, value } = event.target;
        const { loadingPotential } = this.props.isLoading;
        const startDate = moment().startOf('month').format('YYYY-MM-01');
        const endDate = moment().endOf('month').format('YYYY-MM-DD');
        this.getProjectNameList(value);
    };

    getStatusValue = (event) => {
        this.setState({
            status: event.target,
        });
    }

    getPotentialProjectList = async () => {
        const startDate = moment(this.props.onstart_date)
            .utcOffset('+05:30')
            .format('YYYY-MM-01');
        const endDate = moment(this.props.onstart_date)
            .utcOffset('+05:30')
            .format('YYYY-MM-31');
        const payload = {
            start_date: startDate,
            end_date: endDate,
        };
        try {
            await this.props.getPotentialProjectList(payload);
            this.setState({
                potentialProjectList: this.props.potentialProjectList,

            });
        } catch (error) {
            this.setState({
                potentialProjectList: {},
            });
            this.ToastAlert('error', error);
        }
    };


    handleSubmit = async (event) => {
        event.preventDefault();
        let payload = {}; // Initialize payload as an object
        if (this.props.isEditModal) {
            payload['potential_date'] = this.state.potentialDate;
            payload['csm'] = this.state.selectedOptions;
            payload['potential_value'] = this.props.isfilterEdit.potential_value;
            payload['status'] = this.state.editData.status;
            payload['bu_id'] = this.state.selectBuFilter.map(item => item.bu_id);
            payload['los'] = this.state.selectService.map(item => item.id);
            payload['id'] = this.props.isfilterEdit.id;
            payload['project_id'] = this.props.isfilterEdit?.projectId;
        }
        if (!this.props.isEditModal) {

            const idArray = this.state.selectService.map(item => String(item.id));
            payload['service'] = idArray;
            payload['bu_id'] = this.state.selectBuFilter.map(item => item.bu_id);
            payload['los'] = this.state.selectService.map(item => item.id);
            payload['potential_date'] = this.props.isStartDate;
            payload['csm'] = this.state.selectedOptions;
            payload['potential_value'] = this.state.project?.potential_value;
            payload['project_id'] = this.state.project?.project_id;
            payload['project_classification'] = this.state.project?.project_classification;
            payload['status'] = this.state.project?.status;
        }

        if (!this.validateForm()) {
            return; // Stop submission if form is not valid
        }
        try {
            await this.props.saveCurrentPotentialProject(payload);
            this.setState({}, () => {
                this.props.isModalClose();
                this.getPotentialProjectList();
                this.props.GridFunction();
                { this.props.isEditModal ? this.ToastAlert('success', "Potential Updated Successfully") : this.ToastAlert('success', "Potential Added Successfully") }
            });
        } catch (error) {
            this.setState({
                potentialProjectList: {},
            });
            this.ToastAlert('error', error);
        }

    }

    /*  validateForm = () => {
         const { project } = this.state;
         const errors = {};
 
         if (!project.potential_value && !this.props.isEditModal) {
             errors.projectValue = 'Project Value is required.';
         }
 
         if (!project.status && !this.props.isEditModal) {
             errors.projectStatus = 'Project Status is required.';
         }
         if (!this.state.selectBuFilter.length) {
             errors.projectBusinessUnit = 'Business Unit is required.';
         }
         if (this.props.isEditModal && !this.props.isfilterEdit.potential_value) {
             errors.projectValue = 'Project Value is required.';
         }
         if (!this.state.selectService.length) {
             errors.projectService = 'Line Of Service  is required.';
         }
         this.setState({ validationErrors: errors }); // Set validation errors in state
 
         return Object.keys(errors).length === 0; // Return true if no errors
     } */

    handleSelectChange = (selectedOptions) => {
        this.setState(project => ({
            selectedOptions, // Update the selected options
            project: {
                ...project.project,
                csm: selectedOptions.map(option => option.value),
                csmName: selectedOptions.map(option => option.label),
                potential_date: this.props.isStartDate,
            }
        }));
    };
    handleProjectValue = (event) => {
        const value = event.target.value;
        let errorMessage = "";

        // Check if the input is not empty and not a valid number
        if (value.trim() !== "" && !/^\d+$/.test(value)) {
            errorMessage = "Please enter a valid number.";
        }

        // Update the validation error and project value in the state
        this.setState((prevState) => ({
            validationErrors: {
                ...prevState.validationErrors,
                projectValue: errorMessage,
            },
            project: {
                ...prevState.project,
                potential_value: errorMessage ? prevState.project.potential_value : value,
            },
        }));

        // If in edit mode, call the updatePotentialValue method
        if (this.props.isEditModal) {
            this.props.updatePotentialValue(event);
            this.setState({
                validationErrors: {
                    ...this.state.validationErrors, projectValue: '',
                },

            })
        }
    };


    handleProjectValue = (event) => {
        const value = event.target.value;
        let errorMessage = "";

        // Check if the input is not empty and not a valid number
        if (value.trim() !== "" && !/^\d+$/.test(value)) {
            errorMessage = "Please enter a valid number.";
        }

        // Update the validation error and project value in the state
        this.setState((prevState) => ({
            validationErrors: {
                ...prevState.validationErrors,
                projectValue: errorMessage,
            },
            project: {
                ...prevState.project,
                potential_value: errorMessage ? prevState.project.potential_value : value,
            },
        }));

        // If in edit mode, call the updatePotentialValue method
        if (this.props.isEditModal) {
            this.props.updatePotentialValue(event);
        }
    };

    validateForm = () => {
        const { project } = this.state;
        const errors = {};

        if (!project.project_name && !this.props.isEditModal) {
            errors.projectName = 'Project is required.';
        }

        // Check for potential_value validation
        if (!project.potential_value && !this.props.isEditModal) {
            errors.projectValue = 'Project Value is required.';
        } else if (!this.props.isEditModal && !/^\d+$/.test(project.potential_value)) {
            errors.projectValue = 'Please enter a valid number.';
        }

        if (this.props.isEditModal && !this.props?.isfilterEdit?.potential_value) {
            errors.projectValue = 'Project Value is required.';
        } else if (this.props.isEditModal && !/^\d+$/.test(this.props?.isfilterEdit?.potential_value)) {
            errors.projectValue = 'Please enter a valid number.';
        }


        // Check for status validation
        if (!project.status && !this.props.isEditModal) {
            errors.projectStatus = 'Project Status is required.';
        }

        // Check for selectBuFilter validation
        if (!this.state.selectBuFilter.length) {
            errors.projectBusinessUnit = 'Business Unit is required.';
        }

        // Check for selectService validation
        if (!this.state.selectService.length) {
            errors.projectService = 'Line Of Service is required.';
        }

        this.setState({ validationErrors: errors }); // Set validation errors in state

        return Object.keys(errors).length === 0; // Return true if no errors
    }

    handleProjectValue = (event) => {
        const value = event.target.value;
        let errorMessage = "";

        // Check if the input is not empty and not a valid number
        if (value.trim() !== "" && !/^\d+$/.test(value)) {
            errorMessage = "Please enter a valid number";
        }

        // Update the validation error and project value in the state
        this.setState((prevState) => ({
            validationErrors: {
                ...prevState.validationErrors,
                projectValue: errorMessage,
            },
            project: {
                ...prevState.project,
                potential_value: errorMessage ? prevState.project.potential_value : value,
            },
        }));

        // If in edit mode, call the updatePotentialValue method
        if (this.props.isEditModal) {
            this.props.updatePotentialValue(event);

        }
    };

    handleProjectStatus = (event) => {
        const selectedValue = event.target.value;
        if (this.props.isEditModal) {
            this.setState(prevState => ({
                editData: {
                    ...prevState.editData,
                    status: selectedValue,

                }
            }));
        } else {
            this.setState(prevState => ({
                project: {
                    ...prevState.project,
                    status: selectedValue,

                }
            }));
        }
        this.setState({
            validationErrors: {
                ...this.state.validationErrors, projectStatus: '',
            },
        })
    };




    setPotentialDate = (date) => {
        this.setState({ potentialDate: date });
    };

    //filter practice based on service and head
    filterService = (e) => {
        this.state.searchBy = '';
        if (e) {
            let selectedOption = e;
            this.setState({
                report: {
                    service: selectedOption,
                },
                selectService: selectedOption,
            });
        }
        this.setState({
            validationErrors: {
                ...this.state.validationErrors, projectService: '',
            },
        });
    };

    render() {
        const { isModalOpen } = this.props;
        return (
            <div>
                < Modal show={isModalOpen} >
                    <Modal.Header >
                        {/*  {this.state.loading && (
                            <div id="preloader">
                                <div id="status"></div>
                            </div>
                        )} */}
                        <Modal.Title className='custom-modal-title potential-heading'>
                            {this.props.isEditModal ? "EDIT POTENTIAL" : "ADD POTENTIAL"}
                        </Modal.Title>
                        <button className="potential-button pull-right" onClick={() => this.props.isModalClose()}>X</button>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group >
                            <Form.Label>Project Name:</Form.Label>
                            {this.props.isEditModal && (
                                <Form.Control
                                    type="text"
                                    name="projectName"
                                    placeholder="Enter Project Value"
                                    onChange={(e) =>
                                        this.setState({
                                            projectValue: e.target.value,
                                        })
                                    }
                                    defaultValue={
                                        this.props.isEditModal ? this.props.isfilterEdit.project_name : ''
                                    }
                                    disabled
                                />
                            )}
                            {!this.props.isEditModal && (
                                <Form.Control
                                    as="select"
                                    isSearchable="true"
                                    name="projectName"
                                    placeholder="Enter value"
                                    onChange={this.handleInputChange}
                                >
                                    <option value="">Select Project</option>
                                    {this.props.isProjectName.map((value) => (
                                        <option key={value.id} value={value.id}>
                                            {value.project_name}
                                        </option>
                                    ))}
                                </Form.Control>
                            )}
                            {this.state.validationErrors.projectName && (
                                <Form.Text className="text-danger">
                                    {this.state.validationErrors.projectName}
                                </Form.Text>
                            )}
                            <br />
                            <Form.Label>Project Value:</Form.Label>
                            <Form.Control
                                type="text"
                                name="target"
                                placeholder="Enter Project Value"
                                onChange={(e) => this.handleProjectValue(e)
                                }
                                defaultValue={this.props.isEditModal ? (this.props.isfilterEdit.potential_value) : ""}
                            />
                            {this.state.validationErrors.projectValue && (
                                <Form.Text className="text-danger">
                                    {this.state.validationErrors.projectValue}
                                </Form.Text>
                            )}
                            <br />
                            <Form.Label>Mode:</Form.Label>
                            <Form.Control
                                type="text"
                                name="mode"
                                placeholder="Enter Mode Value"
                                value={
                                    this.props.isEditModal
                                        ? this.props.isfilterEdit.mode === 0 || this.props.isfilterEdit.mode === 2
                                            ? 'T&M'
                                            : this.props.isfilterEdit.mode === 1
                                                ? 'Fixed'
                                                : ''
                                        : this.state.project.project_classification === 0 ||
                                            this.state.project.project_classification === 2
                                            ? 'T&M'
                                            : this.state.project.project_classification === 1
                                                ? 'Fixed'
                                                : ''
                                }

                                disabled
                            />
                            <br />
                            {/* status field */}


                            <Form.Label>Status:</Form.Label>
                            <Form.Control
                                as="select"
                                size="md"
                                onChange={(e) => this.handleProjectStatus(e)}
                                custom
                                value={
                                    this.props.isEditModal
                                        ? this.state.editData.status
                                        : this.state.project.status
                                }
                            >
                                {this.props.isEditModal ? (
                                    <>
                                        <option value="1">InProgress</option>
                                        <option value="2">Awarded</option>
                                    </>
                                ) : (
                                    <>
                                        <option value="">Select status</option>
                                        <option value="1">InProgress</option>
                                        <option value="2">Awarded</option>
                                    </>
                                )}
                            </Form.Control>



                            {this.state.validationErrors.projectStatus && (
                                <Form.Text className="text-danger">
                                    {this.state.validationErrors.projectStatus}
                                </Form.Text>
                            )}

                            <br />
                            <Form.Label>Business Unit:</Form.Label>
                            <Select
                                className="basic-single-select"
                                classNamePrefix={"select_dropdown"}
                                placeholder="All"
                                isClearable={false}
                                isSearchable={true}
                                onChange={(e) => this.getReportByHead(e)}
                                /* defaultValue={
                                    this.props.isEditModal ?
                                        buName :
                                        []
                                } */
                                // value={this.props.isEditModal ? this.props.isfilterEdit.buName : this.state.selectBuFilter}
                                value={this.state.selectBuFilter}
                                options={this.state.buName}
                                isMulti

                            //   isDisabled={this.state.detailed_view == 'ms_potential_view'} 
                            >
                            </Select>
                            {this.state.validationErrors.projectBusinessUnit && (
                                <Form.Text className="text-danger">
                                    {this.state.validationErrors.projectBusinessUnit}
                                </Form.Text>
                            )}
                            {/* <Form.Control
                                type="text"
                                name="businessUnit"
                                placeholder="Enter value"
                                value={this.props.isEditModal ? this.props.isfilterEdit.buName : (this.state.project.buName || '')}
                                disabled
                            /> */}
                            <br />

                            <Form.Label>Line Of Service:</Form.Label>
                            <Select
                                className="basic-single-select"
                                classNamePrefix={"select_dropdown"}
                                placeholder="All"
                                isClearable={false}
                                isSearchable={true}
                                onChange={(e) => this.filterService(e)}
                                value={this.state.selectService}
                                // value={this.props.isEditModal ? this.props.isfilterEdit.service : this.state.selectService}
                                options={this.state.serviceName}

                                isMulti>
                            </Select>
                            {this.state.validationErrors.projectService && (
                                <Form.Text className="text-danger">
                                    {this.state.validationErrors.projectService}
                                </Form.Text>
                            )}
                            {/* <Form.Control
                                type="text"
                                name="lineOfService"
                                placeholder="Enter Line of Service value"
                                value={this.props.isEditModal ? this.props.isfilterEdit.serviceName : (this.state.project.serviceName || '')}
                                disabled
                            /> */}
                            <br />

                            {this.props.isEditModal &&
                                <> <Form.Label>Potential Date:</Form.Label>
                                    <div className="row align-items-center mb-4 date_picker_align">
                                        <DatePicker
                                            className="datepicker-control-section-status"
                                            onChange={this.setPotentialDate}
                                            dateFormat="MM/yyyy"
                                            showMonthYearPicker
                                            todayButton={"Today"}
                                            name="potential_date"
                                            selected={this.state.potentialDate}
                                        />
                                        <span class="cal_icon_date" style={{ zIndex: '0' }} role="button" tabindex="0">
                                            <i class="icon-calendar"></i>
                                        </span>
                                    </div></>
                            }
                            <Form.Label>CSM:</Form.Label>
                            <Select
                                isMulti
                                isClearable={false}
                                options={this.state.csmMember}
                                value={this.state.selectedOptions}
                                onChange={this.handleSelectChange}
                                defaultValue={this.state.csmMember}
                            />
                            <br />

                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer className='potential-footer'>
                        <Button
                            variant="primary"
                            type="submit"
                            onClick={this.handleSubmit}

                            style={{ backgroundColor: '#f5821f', borderColor: '#f5821f', width: '100px', height: '42px', fontSize: '14px' }}
                        >
                            {this.props.isEditModal ? 'UPDATE' : 'SAVE'}
                        </Button>
                    </Modal.Footer>
                </Modal >
            </div >
        );
    }
}

const mapStateToProps = state => {
    return {
        potentialCurrentProjectDetails: state.msAllocationReportDetails.msPotentialProjectDetails,
        potentialProjectList: state.msAllocationReportDetails.msPotentialProjectList,
        reportFilter: state.reportFilterDetails.reportFilter,
        loginDetails: state.loginDetails,
        pmoFilter: state.reportFilterDetails.pmoFilter,
    };
};

const mapDispatchToProps = {
    getCurrentMonthProjectName,
    getCurrentProjectDetails,
    saveCurrentPotentialProject,
    getPotentialProjectList,
    getReportFilter,
    getReportFilterPmo,
    getLoginUser,
    getLoginUserPermission


};

AddPotentialPopUp.displayName = 'AddPotentialPopUp';

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddPotentialPopUp);


