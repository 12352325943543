import React, { Component } from "react";
import { Modal, Button, Form } from 'react-bootstrap';

export default class TargetPopUp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            formErrors: {},
            isValueChange: true,
        };
    }

    validateForm = () => {
        // You can add more validation logic if needed
        return Object.values(this.state.formErrors).every(error => error === "");
        // return Object.values(this.state.formErrors).every(error => error === "");
    };

    handleChange = (e, item) => {
        const inputText = e.target.value;
        const month = this.props.dataKey;
        const quarter = this.props.quarter;
        this.setState({
            isValueChange: false,
        });
        // Validate if the input is a number
        if (/^\d*$/.test(inputText)) {
            this.setState((prevState) => {

                const updatedMsSummaryViewData = this.props.MsSummaryViewData;
                if (
                    updatedMsSummaryViewData?.[quarter]?.[month]?.separatedTargetValue
                ) {
                    const separatedTargetValue = [...updatedMsSummaryViewData[quarter][month].separatedTargetValue];

                    // Find the index of the item.id in the array
                    const index = separatedTargetValue.findIndex(target => target.id === item.id);
                    if (index >= 0) {
                        // Update the value for the corresponding item.id
                        separatedTargetValue[index].target_value = parseInt(inputText);
                    }

                    updatedMsSummaryViewData[quarter][month].separatedTargetValue = separatedTargetValue;
                }

                return {
                    MsSummaryViewData: updatedMsSummaryViewData,
                    formErrors: {
                        ...prevState.formErrors,
                        [`${month}_${item.id}`]: "", // Reset error when input is valid
                    },
                };
            });
        } else {
            // Input is not a number, set form error
            this.setState((prevState) => ({
                formErrors: {
                    ...prevState.formErrors,
                    [`${month}_${item.id}`]: "Target Value should be a positive number.",
                },
            }));
        }
    };
    /* [
        {
            "label": "Machine Learning",
            "id": 60,
            "value": "Machine Learning",
            "head": 253,
            "bu": 9
        },
        {
            "label": "Data Engineering",
            "id": 61,
            "value": "Data Engineering",
            "head": 253,
            "bu": 9
        },
        {
            "label": "Operations - D-AI / ",
            "id": 77,
            "value": "Operations - D-AI / ",
            "head": 253,
            "bu": 9
        },
        {
            "label": "Datalabs",
            "id": 88,
            "value": "Datalabs",
            "head": 253,
            "bu": 9
        }
    ] */

    render() {
        const { formErrors } = this.state;
        let formData;
        const { serviceName, selectService, allService, selectBuFilter } = this.props.isAllValue;
        if (selectBuFilter && Object.keys(selectBuFilter).length !== 0 && selectService.length === 0) {
            formData = serviceName;
        } else if (Object.keys(selectBuFilter).length == 0 && selectService.length === 0) {
            let all = [
                {
                    "label": "All",
                    "id": 'All',
                    "value": "All",
                }
            ];
            formData = all;
        } else {
            formData = this.props?.isAllValue?.selectService;
        }
        return (

            <>
                <Modal className="targetPopupModal" style={{ width: "50rem", marginLeft: "32rem", overflow: "hidden", }} show={this.props.isOpen} onHide={this.props.closeModal}>
                    <Modal.Header>
                        <Modal.Title className='custom-modal-title potential-heading'>
                            Target Value
                        </Modal.Title>
                        <button className="potential-button pull-right" onClick={this.props.closeModal}>X</button>
                    </Modal.Header>
                    <Modal.Body style={{ overflowY: "scroll", maxHeight: "70vh" }}>
                        <h4 style={{ textAlign: "center" }}>{this.props?.dataKey}</h4>
                        <br></br>
                        {formData.map((item, index) => (
                            <Form.Group key={index}>
                                <Form.Label>{`Enter The Target Value for ${item.label}:`}</Form.Label>
                                <Form.Control
                                    type="text"
                                    name={`target_${item.id}`}
                                    onChange={(e) => this.handleChange(e, item)}
                                    value={this.props?.MsSummaryViewData?.[this.props.quarter]?.[this.props.dataKey]?.separatedTargetValue?.[index]?.target_value || 0}
                                    placeholder={`Enter value for ${item.label}`}
                                />

                                {formErrors[`${this.props.dataKey}_${this.props.dataKey}_${item.id}`] && (
                                    <Form.Text className="text-danger">{formErrors[`${this.props.dataKey}_${this.props.dataKey}_${item.id}`]}</Form.Text>
                                )}
                                <br></br>
                            </Form.Group>
                        ))}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" type='button' disabled={this.state.isValueChange} onClick={() => this.props.handleSubmit(this.props.MsSummaryViewData?.[this.props.quarter]?.[this.props.dataKey]?.separatedTargetValue)}>
                            ADD
                        </Button>
                    </Modal.Footer>
                </Modal >
            </>
        );
    }
}
