import { postData, getData } from "../Services/Helper";
import { handleResponse } from "../Services/HandleResponse";
import * as types from "../Utilities/Type"
import { getBackEndConstant } from "../Config/Constant";

export function getMsAllocationReportData(dataObj) {
  return (dispatch) => {
    const requestOptions = postData(dataObj);
    let url = getBackEndConstant().backend_url + "get_ms_allocation_report";
    return fetch(url, requestOptions)
      .then((response) => handleResponse(response))
      .then((response) => {
        dispatch({
          type: types.GET_MS_ALLOCATION_REPORT,
          payload: (response.result) ? response.result : {},
        });
      });
  };
}

export function msAllocationExportExcel(dataObj) {
  return (dispatch) => {
    const requestOptions = postData(dataObj);
    let url = getBackEndConstant().backend_url + "export_as_excel_ms_allocation_report";
    return fetch(url, requestOptions)
      .then((response) => handleResponse(response))
      .then((response) => {
        dispatch({
          type: types.MS_ALLOCATION_EXPORT_EXCEL,
          payload: (response.result) ? response.result : {},
        });
      });
  };
}


export function getMsSummaryReport(data) {
  return (dispatch) => {
    const requestOptions = postData(data);
    let url = getBackEndConstant().backend_url + "get_ms_summary_report";
    return fetch(url, requestOptions)
      .then((response) => handleResponse(response))
      .then((response) => {
        dispatch({
          type: types.GET_MS_SUMMARY_REPORT,
          payload: (response.result) ? response.result : {},
        });

      });
  };
}


export function getCurrentMonthProjectName(data) {
  return (dispatch) => {
    const requestOptions = postData(data);
    let url = getBackEndConstant().backend_url + "get_ms_potential_project_name";
    return fetch(url, requestOptions)
      .then((response) => handleResponse(response))
      .then((response) => {
        dispatch({
          type: types.MS_POTENTIAL_PROJECT_NAME,
          payload: (response.result) ? response.result : {},
        });

      });
  };
}
export function getCurrentProjectDetails(data) {
  return (dispatch) => {
    const requestOptions = postData(data);
    let url = getBackEndConstant().backend_url + "get_ms_potential_report";
    return fetch(url, requestOptions)
      .then((response) => handleResponse(response))
      .then((response) => {
        dispatch({
          type: types.MS_POTENTIAL_PROJECT_DETAILS,
          payload: (response.result) ? response.result : {},
        });

      });
  };

}


export function getPotentialProjectList(data) {
  return (dispatch) => {
    const requestOptions = postData(data);
    let url = getBackEndConstant().backend_url + "get_ms_potential_report";
    return fetch(url, requestOptions)
      .then((response) => handleResponse(response))
      .then((response) => {
        dispatch({
          type: types.MS_POTENTIAL_PROJECT_LIST,
          payload: (response.result) ? response.result : {},
        });
      });
  };
}


export function deleteCurrentPotentialProject(data) {

  return (dispatch) => {
    const requestOptions = postData(data);
    let url = getBackEndConstant().backend_url + "delete_ms_potential";
    return fetch(url, requestOptions)
      .then((response) => handleResponse(response))
      .then((response) => {
        dispatch({
          type: types.MS_DELETE_CURRENT_POTENTIAL_PROJECT,
          payload: (response) ? response : {},
        });
      });
  };
}

export function msPotentialExportExcel(dataObj) {
  return (dispatch) => {
    const requestOptions = postData(dataObj);
    let url = getBackEndConstant().backend_url + "get_ms_potential_excel";
    return fetch(url, requestOptions)
      .then((response) => handleResponse(response))
      .then((response) => {
        dispatch({
          type: types.MS_POTENTIAL_EXPORT_EXCEL,
          payload: (response.result) ? response.result : {},
        });
      });
  };
}


export function saveCurrentPotentialProject(data) {

  return (dispatch) => {
    const requestOptions = postData(data);
    let url = getBackEndConstant().backend_url + "save_ms_potential_report";
    return fetch(url, requestOptions)
      .then((response) => handleResponse(response))
      .then((response) => {
        dispatch({
          type: types.MS_SAVE_CURRENT_POTENTIAL_PROJECT,
          payload: (response) ? response : {},
        });
      });
  };
}


/**
 * Get Pms question for grid
 * @param {*} dataObj
 * @returns
 */
export function editProjectPotential(dataObj) {
  return (dispatch) => {
    const requestOptions = postData(dataObj);
    let url = getBackEndConstant().backend_url + "edit_project_potential";
    return fetch(url, requestOptions)
      .then((response) => handleResponse(response))
      .then((response) => {
        dispatch({
          type: types.EDIT_PROJECT_POTENTIAL,
          payload: response.result ? response.result : {},
        });
      });
  };
}

export function saveMsSummaryTargetValue(dataObj) {
  return (dispatch) => {
    const requestOptions = postData(dataObj);
    let url = getBackEndConstant().backend_url + "save_ms_summary_target_value";
    return fetch(url, requestOptions)
      .then((response) => handleResponse(response))
      .then((response) => {
        dispatch({
          type: types.SAVE_TARGET_VALUE,
          payload: response.result ? response.result : {},
        });
      });
  };
}




