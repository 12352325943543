import React, { Component } from "react";

export class CsmDetailedView extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    let index = 1;
    console.log("detailesd",this.props.total_invoice_data)
    return (
      <>
        <div className="scrollable-content">
          {this.props.loading && (
            <div id="preloader">
              <div id="status"></div>
            </div>
          )}
          {!this.props.loading && ((this.props.serviceName && this.props.serviceName.length==0) || 
            (this.props.csmProjectList && (!this.props.csmProjectList.length  || !this.props.total_invoice_data )) )&& (
              <div>
                <div className="r-no-data text-center">
                  No data available
                </div>
              </div>
            )}
           {!this.props.loading &&
            (this.props.csmProjectList && this.props.csmProjectList.length > 0 && this.props.total_invoice_data > 0) && (    
              <div className="table-responsive white-table-class reports--grid">
                  <div className="scrollable">
                      <div className="scrollable-content">
                          <table className="table m-b-0 table-wrap">
                              <thead>
                                  <tr className="theadInner invntry-mw-th">
                                      <th rowSpan="2" className='thead_csm'>S.No</th>
                                      <th rowSpan="2" className='thead_csm'>Project Code</th>
                                      <th rowSpan="2" className='thead_csm' >Account Name</th>
                                      <th rowSpan="2" className='thead_csm'>Csm Name</th>
                                      { this.props.months_list.map(function(month) {
                                            return (
                                              <>
                                               <th colSpan="3" className='thead_csm'>Invoice Value - {month} (USD)</th>
                                              <th colSpan="3" className='thead_csm'>Collection Value - {month}  (USD)</th>
                                              </>
                                             
                                            )                                       
                                        })
                                      }
                                      <th rowSpan="2" className='thead_csm'>Total Invoice Value (USD)</th>
                                      <th rowSpan="2" className='thead_csm'>Total Collection Value (USD)</th>
                                      <th rowSpan="2" className='thead_csm'>Total C/F (USD)</th>
                                  </tr>
                                  <tr className="theadInner invntry-mw-th">
                                     { this.props.months_list.map(function() {
                                            return (
                                              <>
                                              <th className="thead_csm">Achieved</th>
                                              <th className="thead_csm">Kickoff</th>
                                              <th className="thead_csm">CarryForward</th>
                                              <th className="thead_csm">Col.Achieved</th>
                                              <th className="thead_csm">Col.Kickoff</th>
                                              <th className="thead_csm">Col.CarryForward</th>
                                              </>                                             
                                            )                                       
                                        })
                                      }
                                  </tr>
                              </thead>
                              <tbody>                           
                                    { this.props.csmProjectList.length > 0 &&  this.props.csmProjectList.map((csmProjectList) => (
                                      Object.values(csmProjectList).map((item) => (   
                                        item.projectId && (item.invoice > 0 || item.collection > 0 || item.pending > 0 ) &&
                                      <tr id="class_grey_rows_">
                                        <td>{index++}</td>                                               
                                        <td className="text-center" >
                                            <span className="text-truncate">{item.projectCode}</span>
                                        </td>   
                                        <td className="text-start">
                                            <span className="text-truncate">{item.projectName}</span>
                                        </td>
                                        <td className="text-start">
                                          <span className="text-truncate">{item.csm_users}</span>
                                        </td>
                                       { Object.values(item.data).map((data) => ( 
                                        <>
                                         <td className="text-start">
                                          <span className="text-truncate">$ { parseFloat(data.invoiceAmount).toFixed(2)}</span>
                                          </td>
                                          <td className="text-start">
                                            <span className="text-truncate">$ { parseFloat(data.collectedKickoffValue).toFixed(2)}</span>
                                          </td>
                                          <td className="text-start">
                                            <span className="text-truncate">$ { parseFloat(data.invoiceCarryValue).toFixed(2)}</span>
                                          </td>
                                          <td className="text-start">
                                            <span className="text-truncate">$ { parseFloat(data.collectionAmount).toFixed(2)}</span>
                                          </td>
                                          <td className="text-start">
                                            <span className="text-truncate">$ { parseFloat(data.collectedKickoffValue).toFixed(2)}</span>
                                          </td>
                                          <td className="text-start">
                                            <span className="text-truncate">$ { parseFloat(data.collectionCarryValue).toFixed(2)}</span>
                                          </td>
                                        </>
                                       ))}
                                       
                                        <td className="text-start">
                                          <span className="text-truncate">$ {(item.invoice).toLocaleString(undefined, {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2
                                          })}</span>
                                        </td>
                                        <td className="text-start">
                                          <span className={item.pending == 0 ? "green_report_row": "pending_danger_report_row"}>${(item.collection).toLocaleString(undefined, {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2
                                          })}</span>
                                        </td>
                                        <td className="text-start">
                                        <span>$ { parseFloat(item.pending).toFixed(2)}</span>
                                        </td>                                                                          
                                      </tr >
                                     ))
                                     ))
                                  }                  
                              </tbody>
                          </table>
                      </div>
                  </div>
              </div>                              
            )}
        </div>
      </>
    );
  }
}
