import React, { Component } from "react";
import "../Reports/Report.css";
import {
  getLoginUser, getReportFilter, getLoginUserPermission, getReportFilterPmo, getPmsQuestion, getAnswer
} from "../../Action";
import { connect } from "react-redux";
import * as constants from "../../Config/Constant";
import Cookies from "js-cookie";
import PageNotFound from "../Reports/PageNotFound";
import { Row, Button, Col } from "react-bootstrap";
import Swal from "sweetalert2";


const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  showCloseButton: true,
  customClass: {
    popup: "colored-toast",
    icon: "white-toast-icon",
  },
  timer: 3000,
  onOpen: (toast) => {
    toast.addEventListener("mouseenter", Swal.stopTimer);
    toast.addEventListener("mouseleave", Swal.resumeTimer);
  },
});

class PmsView extends Component {

  constructor(props) {
    super(props);
    this.state = {
      queryParams: new URLSearchParams(window.location.search),
      redirect: 0,
      sname: [],
      sname1: [],
      report_practice: [],
      report_service: [],
      practiceHead: [],
      loginUserData: {},
      loginUserPermission: {},
      token: "",
      loading: false,
      bu_id: "",
      selectService: [],
      selectBu: [],
      selectBuFilter: [],
      reportList: [],
      serviceName: [],
      checkedList: [],
      usersService: [],
      pmsId: 0,
      showDeleteModal: false,
      pmsPopUp: false,
      isModalClose: false,
      isEditModal: false,
      deletePmsData: '',
      question: [],
      inputFields: [''],
      selectedOption: [],
    };
  }

  /**
     * Notification message
     * @param {*} icon 
     * @param {*} title 
     * @returns 
     */
  ToastAlert(icon, title) {
    return Toast.fire({
      target: document.getElementById("form-modal"),
      icon: `${icon}`,
      title: `${title}`,
    });
  }

  /**
   * Initial function call
   */
  componentDidMount() {
    let token = this.state.queryParams.get("token") == null ? Cookies.get("auth_data") :
      this.state.queryParams.get("token");
    this.setState({
      token: token,
      apiLink: constants.API_LINK,
      angularUrl: constants.FRONT_LINK,
      excelUrl: constants.EXCEL_LINK
    });
    this.getUser();
  }
  /**
     * Get the login user details
     */
  getUser = () => {
    this.setState({ loading: true });
    this.props.getLoginUser().then((response) => {
      this.props.getLoginUserPermission().then((response) => {
        this.setState({
          loading: false, loginUserData: this.props.loginDetails.loginDetails,
          loginUserPermission: this.props.loginDetails.loginUserPermission
        });
        this.manageAnswer();
      });
    });
  }

  /**
     * Show the data for PMS question
     */
  manageAnswer = () => {
    let dataObj = {}; let payload = {};
    dataObj.business_unit = (this.state.selectBuFilter) ? this.state.selectBuFilter : [];
    dataObj.los = (this.state.selectService) ? this.state.selectService : [];
    dataObj.chapter = [];//this.state.loginUserData.chapter;
    dataObj.id=97;//this.state.userId;
    payload['data'] = dataObj;
    this.props.getAnswer(payload).then((response) => {
      this.setState({
        loading: false,
        answerList: this.props.getAnswerList,
      });
    }, (error) => {
      this.ToastAlert("error", "Something went wrong");
    });
  };

  
  render() {
    if (this.state.redirect == 1 || this.state.loginUserData == null) {
      return <PageNotFound url={this.state.angularUrl} />
    } else {
      return (
        <div className="table-responsive white-table-class pmo-report">
          <div className="scrollable">
            <h3>View</h3> <br></br>
            <Row>
              <Col className="col-lg-12 text-end">
              </Col>
              <div className="col-lg-4 clearfix m-b-12">
              </div>
              <div className="form-container">
                {!this.state.loading && (this.props.getAnswerList).length === 0 &&
                  <span >
                    No data available
                  </span>
                }
                <ul>
                  {this.props.getAnswerList.length && this.props.getAnswerList.map((answer, key) => (
                    <li key={key} className="pl-10 pr-10 f-right-xs">
                      <p>{key + 1} . {answer.answer} </p><br></br>
                      <form className="custom-form" onSubmit={this.saveFinalAnswer}>
                          <div className="form-group">
                            {/* <textarea key={question.id} id={question.id} type="text" placeholder="Answer"
                              defaultValue="" className="form-control" onChange={(e) => this.handleInputChange(question.id, e,key)} /> */}
                          </div>
                          <div className="form-group">
                            ******
                          </div>
                      </form>
                    </li>
                  ))}
                  <Button onClick={(e) => this.saveFinalAnswer(e)} value="save" className="me-2" >Save
                  </Button>
                </ul>
              </div>
            </Row>
            <br></br>
          </div>
        </div>
      )
    }
  }
}
const mapStateToProps = state => {
  return {
    loginDetails: state.loginDetails,
    pmsQuestionList: state.pmsDetails.pmsQuestion,
    getAnswerList: state.pmsDetails.getAnswer,
  }
};

const mapDispatchToProps = {
  getLoginUser,
  getReportFilter,
  getLoginUserPermission,
  getReportFilterPmo,
  getPmsQuestion,
  getAnswer
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PmsView);