import logo from './logo.svg';
import './App.css';
import './Components/Reports/Report.css';
import Routes from './Routes/Routes';
import MenuBar from './Components/MenuComponent/MenuBar';


function App() {
  return (
    <div className="App">
      <Routes />
    </div>
  );
}

export default App;
