import React from "react";
import NoPageImg from "../../404.svg";
import { Urls } from '../../Config/Config';

class Gantt_chart extends React.Component {
  
    constructor(props) {
      super(props);
      this.state = {
              
        ganttViewData:[]
      }
          
    }
    componentDidMount() {
      // `newBugs`constant holds the bugs passed down from IndexPage
     // const ganttViewData = this.props.bugs;
     this.setState({
      ganttViewData : this.props.user
     })
      // BugsApi.getBugData(data => {
      //   this.setState({bugs: data})
      // })
      console.log(this.props.user)
    }
    render() {
        return (
            <div>
           <div className="col-sm-12 m-b-10 m-t-10 p-0">
                <div className="row">
                    <div className="col-sm-12">
                    <div className="row">
                        <div className="col-sm-9">
                        <h1 className="p-0 col-sm-4 B-G-bold f-s-18 d-gray m-t-3 m-b-10 page-head">GANTT CHART</h1>
                        </div>
                        <div className="col-sm-1 p-t-5 p-l-0">
                        <span className="btn-danger badge-tag fs-13 p-l-5 p-r-5 m-r-5 pull-left">W</span>
                        <span className="text-red p-t-5">Worst</span>
                        </div>
                        <div className="col-sm-1 p-l-0 p-r-0 p-t-5">
                        <span className="badge-tag btn-primary fs-13 p-l-5 p-r-5 m-r-5 pull-left">A</span>
                        <span className="c-orange p-t-5">Average</span>
                        </div>
                        <div className="col-sm-1 p-l-0 p-t-5">
                        <span className="badge-tag btn-success fs-13 p-l-5 p-r-5 m-r-5 pull-left">G</span>
                        <span className="text-success p-t-5">Good</span>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
              <div className="col-sm-12 p-l-0 p-r-0 p-t-0 p-b-10 white-table-className ganttview--reportpage">
                <div className="table-responsive" ng-scrollable="">
                <table className="col-sm-12 p-0 table-bordered table-striped m-b-0 bor-no" >
                    
                      <tbody>
                      
                        <tr className="theadInner">
                          <td className="bor-no p-t-20 p-b-5"></td>
                          {/* <td className="text-center p-t-20 p-b-5 bor-no" ng-repeat="month in monthSeries">{month}}</td> */}
                          <td className="text-center p-t-20 p-b-5 bor-no">Upcommings</td>
                        </tr>
                        { this.props.user.map((user) => (
                          <tr>
                          <td className="text-right p-r-15 bor-no" style="width: 15%;">{user.username}</td>
                          {/* <td  className="p-t-8 p-b-8 fs-13 text-center co-white"  ng-repeat="month_data in user.monthlyData" tabindex="0" ng-mouseover="setGantPopoverData(user, month_data)">
                            <p ng-if="month_data.available_hrs" className="m-b-0 p-b-5 p-t-5 {{percentageFinder(month_data.alloted_hrs, month_data.available_hrs)}}" uib-tooltip="{{tooltipContent(month_data)}}" tooltip-placement="top">&nbsp;</p>
                            <p ng-if="!month_data.available_hrs" className="m-b-0 p-b-5 p-t-5 {{percentageFinder(month_data.alloted_hrs, month_data.available_hrs)}}">&nbsp;</p>
                          </td> */}
                          <td></td>
                        </tr>
                        )) }
                      </tbody>
                    </table>
                  </div>
          </div>
            </div>
        );
    }
};

export default Gantt_chart;