import React, { Component } from 'react'

export class MsDetailedView extends React.Component {
  render() {
    return (
      <div className="table-responsive white-table-class pmo-report">
        <div className="scrollable">
          <div className="scrollable-content">
            {!this.props.loading &&
              Object.keys(this.props.msAllocationReportData).length == 0 &&
              <span>No data available</span>
            }
            {Object.keys(this.props.msAllocationReportData).length != 0 && <table className="table m-b-0 table-wrap">
              <thead>
                <tr className="theadInner busi_unit_bg">
                  <th className="text-start" style={{ "width": "15%" }}>Project Name</th>
                  <th className="text-start" style={{ "width": "10%" }}>Business Unit</th>
                  <th className="text-start" style={{ "width": "7%" }}>Developers</th>
                  <th className="text-start" style={{ "width": "5%" }}>Mode</th>
                  <th className="text-start" style={{ "width": "10%" }}>Type</th>
                  <th className="text-start" style={{ "width": "10%" }}>Technology</th>
                  <th className="text-start" style={{ "width": "10%" }}>Line of Service</th>
                  <th className="text-start" style={{ "width": "10%" }}>Tech Lead</th>
                  <th className="text-start" style={{ "width": "10%" }}>Product Owners</th>
                  <th className="text-start" style={{ "width": "10%" }}>MS Allocation</th>
                  <th className="text-start" style={{ "width": "10%" }}>Domain</th>
                </tr>
              </thead>
              <tbody>
                {this.props.loading &&
                  <div id="preloader">
                    <div id="status"></div>
                  </div>
                }
                {!this.props.loading && this.props.msAllocationReportData.length > 0 && this.props.msAllocationReportData.map((item) => (
                  <tr>
                    <td className="text-start">
                      <span className="name p-l-5 pmo-truncate p-t-3 fs-13" style={{ "maxWidth": "200px", "textAlign": "left" }} title={`${item.projectName}`}>{item.projectName}</span>
                    </td>
                    <td className="text-start">
                      <span>{item.buName} </span>
                    </td>
                    <td className="text-center">
                      <span>{item.developerCount && item.developerCount || 'kickoff'} </span>
                    </td>
                    <td className="text-start">
                      <span>{item.mode} </span>
                    </td>
                    <td className="text-start">
                      <span> {item.type} </span>
                    </td>
                    <td className="text-start">
                      <span>{item.practiceName} </span>
                    </td>
                    <td className="text-start">
                      <span>{item.serviceName} </span>
                    </td>
                    <td className="text-start">
                      <span>{item.squadLead} </span>
                    </td> <td className="text-start">
                      <span> {item.productOwner} </span>
                    </td> <td className="text-start">
                      <span>$ {item.userMilestoneValue} </span>
                    </td> <td className="text-start">
                      <span> {item.domain} </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>}
          </div>
        </div>
      </div>
    )
  }
}