import React, { Component } from "react";
import {
  getPmsDashboard,
  getReportFilter,
  getLoginUserPermission,
  getReportFilterPmo,
  getChapterLead,
  setAddClass,
  downloadStatistics,
  getStartEndDate,
  downloadPmsReport,
  downloadZip,
  pmsExportExcel,
  getPmsPermission,
  getStartedSurveyBu

} from "../../Action";
import { connect } from "react-redux";
import * as constants from "../../Config/Constant";
import PageNotFound from "../Reports/PageNotFound";
import { Row, Col } from "react-bootstrap";
import Swal from "sweetalert2";
import PmsComments from "./PmsComments";
import PmsUserGrid from "./PmsUserGrid";
import PmsFilter from "./PmsFilter";
import Select from "react-select";
import accessdenied from "../../access-denied.png";
import Cookies from "js-cookie";
import { Link } from "react-router-dom";
import moment from "moment";

const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  showCloseButton: true,
  customClass: {
    popup: "colored-toast",
    icon: "white-toast-icon",
  },
  timer: 3000,
  onOpen: (toast) => {
    toast.addEventListener("mouseenter", Swal.stopTimer);
    toast.addEventListener("mouseleave", Swal.resumeTimer);
  },
});

class PmsDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      queryParams: new URLSearchParams(window.location.search),
      redirect: 0,
      sname: [],
      snameArray: [],
      practiceHead: [],
      loginUserData: {},
      loginUserPermission: {},
      loading: false,
      bu_id: "",
      selectService: [],
      selectBu: [],
      selectBuFilter: [],
      serviceName: [],
      usersService: [],
      filter_end_date: new Date(),
      filter_start_date: new Date(),
      chapterLead: [],
      chapterLeadValue: [],
      role_id: 0,
      dashboardDetails: {},
      getUserGrid: [],
      filteredUserGrid: [],
      isActionsPage: false,
      actionProperty: {},
      selectedLevel: "all",
      searchedValue: "",
      performanceDateYear: {},
      performanceDate: "",
      dashboardLoader: false,
      pmsPermission: false,
      leverData: [],
      startQuesDate: "",
      endQuesDate: "", //moment().format('DD-MM-YYYY'),
      batch: "",
      filterId: this.props.match.params.filter_id,
      filterName: this.props.match.params.filter_name,
      deadlineBu : []
    };
  }

  componentDidMount() {
    let token =
      this.state.queryParams.get("token") === null
        ? Cookies.get("auth_data")
        : this.state.queryParams.get("token");
    let url =
      this.state.queryParams.get("url") == null
        ? Cookies.get("url_code")
        : this.state.queryParams.get("url");
    this.setState({
      token: token,
      apiLink: constants.API_LINK,
      angularUrl: constants.FRONT_LINK,
      excelUrl: constants.EXCEL_LINK,
      url: url,
    });
    Cookies.set("auth_data", token);
    Cookies.set("url_code", url);
    this.state.queryParams.delete("token");
    this.state.queryParams.delete("url");
    if (this.props.history != undefined) {
      this.props.history.replace({
        search: this.state.queryParams.toString(),
      });
    }
    if (token == "undefined" || token == undefined || token == null) {
      this.state.redirect = 1;
    } else {
      this.getUser();
    }
  }

  componentWillMount() {
    this.props.history.listen((location, action) => {
        if(location?.state?.filter_id){
          this.setState({
              filterId: location.state.filter_id,
              filterName: location.state.filter_name
          }, () => {
            this.getPracticeService();
          });
        }
    });
  }

  componentDidUpdate(prevProps) {
    if ( JSON.stringify(this.props.loginDetails) !== JSON.stringify(prevProps.loginDetails)) {
      this.getUser();
    }
  }

  /**
   * Get the login user details
   */
  getUser = () => {
    const role_id = this.props?.loginDetails?.loginDetails?.role_id;
    this.setState({
      loginUserData: this.props?.loginDetails?.loginDetails,
      loginUserPermission: this.props?.loginDetails?.loginUserPermission,
      role_id,
      dashboardLoader: true,
    });
    if (role_id != null || role_id != undefined || role_id != "undefined") {
      this.getPerformanceDate();
      this.getPracticeService();
      this.getDateAndBatch();
    }
    this.getPmsPermission();
  };
  getPmsPermission = () => {
    this.props.getPmsPermission()
    .then((response) =>{
        if(this.props.getPmsPermissionData?.result){
            let checkUser = this.props.getPmsPermissionData.result;
            checkUser = checkUser.some(users => users.id == this.state.loginUserData.id);
            console.log(this.state.loginUserData.id);
            if(checkUser){
                this.setState({
                    loading: false,
                    pmsPermission: true
                });                   
            } else {
                this.setState({
                    loading: false,                        
                });
            }
        }
    });
  }
  getPracticeService = (isAllPractice) => {
    const { role_id } = this.state;
    this.setState({
      loading: true,
      isAllPractices: isAllPractice,
      filterService: [],
      report: {
        service: [],
      },
      sname: [],
      allService: [],
      serviceName: [],
    });
    let dateValue = new Date();
    let date = new Date(dateValue.getFullYear(), dateValue.getMonth(), 2);
    let start_date = this.state.filter_start_date
      ? this.state.filter_start_date
      : date;
    let end_date = this.state.filter_end_date
      ? this.state.filter_end_date
      : date;
    let dataObject = {};
    dataObject.start_date = start_date;
    dataObject.end_date = end_date;
    dataObject.filter = isAllPractice?.isAllPractice;

    let c = 0;
    this.props.getReportFilter(dataObject).then(
      (_response) => {
        //console.log(this.props.reportFilter);
        this.props.pmoFilter.directors?.forEach((value) => {
          if (this.state.bu_id === value.bu_id) {
            c = 1;
          }
        });
        let bu_id = c === 1 ? this.state.bu_id : "";
        let selectBuFilter = c === 1 ? this.state.selectBuFilter : [];
        let sname = [];
        let allService = [],
          serviceName = [];
        if (
          this.state.bu_id !== "" &&
          this.state.bu_id !== undefined &&
          this.state.bu_id !== 0
        ) {
          sname = this.props.pmoFilter.services?.filter((service) => this.state.bu_id === service.bu)
            .filter((item) =>
              role_id === 8 ? item.head === this.state?.loginUserData?.id : item
            );
          this.props.pmoFilter.allservices?.forEach((service) => {
            if (this.state.bu_id === service.bu) {
              allService.push(service);
            }
          });
        } else {
          sname = this.props.pmoFilter.services?.filter((item) =>
            role_id === 8 ? item.head === this.state?.loginUserData?.id : item
          );
          allService = this.props.pmoFilter?.allservices;
        }
        this.setState({
          usersService: this.props.pmoFilter?.services.filter((item) =>
            role_id === 8 ? item.head === this.state?.loginUserData?.id : item
          ),
          snameArray: this.props.pmoFilter?.services.filter((item) =>
            role_id === 8 ? item.head === this.state?.loginUserData?.id : item
          ),
          bu_id: bu_id,
          selectBuFilter: selectBuFilter,
          sname: sname,
          allService: allService,
        });
        this.state.sname.forEach((value) => {
          let opt = {};
          opt.label = value.value;
          opt.id = value.id;
          opt.value = value.value;
          opt.head = value.head;
          opt.bu = value.bu;
          serviceName.push(opt);
        });
        // for practicehead
        let practiceHead = [];
        this.props.pmoFilter.directors?.forEach((id) => {
          practiceHead.push(id);
        });
        let buName = [];
        let directorArray = practiceHead;
        // this.state.role_id === 8
        //   ? this.props.pmoFilter.directors.filter(
        //     (item) => item.id === this.state?.loginUserData?.id
        //   )
        //   : this.props.pmoFilter.directors;
        directorArray.forEach((value) => {
          let opt = {};
          opt.label = value.bu;
          opt.value = value.bu;
          opt.bu_id = value.bu_id;
          opt.id = value.id;
          opt.bu = value.bu;
          opt.name = value.name;
          buName.push(opt);
        });
        let selectService = [];
        let loginBusinessUnit = this.state.loginUserData.business_unit;
        const matchedItem = buName.find(item => item.id === loginBusinessUnit);
        let selectBu =
          this.state.role_id === 1 ||
            this.state.role_id === 8 ||
            this.state.pmsPermission ||
            this.state.role_id === constants.HR
            ? []
            : matchedItem;
        this.getReportByHead(selectBu);
        if (
          this.state.role_id === 10 &&
          this.state.loginUserData.business_unit.id !== 14
        ) {
          selectService = this.state.sname.filter((item) => {
            return this.state.role_id === 10
              ? item.value ===
              this.state.sname
              : item.bu === selectBu.bu_id;
          });
          selectService = selectService.map(item => {
            return {
              ...item,
              label: item.value
            };
          });
        }

        this.setState({
          practiceHead: practiceHead,
          buName: buName,
          report_all_practices: this.props.pmoFilter.practices,
          qes_list: this.props.pmoFilter.qes_list,
          // selectService,
          selectService: selectService,
          selectBuFilter: selectBu,
        }, () => {
          this.getChapterLead(selectService)
        });

      },
      (error) => {
        this.setState({
          usersService: [],
          snameArray: [],
          bu_id: "",
          selectBuFilter: [],
          sname: [],
          allService: [],
        });
      }
    );
  };

  /* filter practice based on service and head */
  filterService = (e, chapterLeadOptions) => {
    if (e) {
      let selectedOption = e;
      let chapterLead = chapterLeadOptions
        ? chapterLeadOptions
        : this.getAllChapterOPtions(this.state?.chapterClone);
      if (selectedOption.length) {
        chapterLead = [];
        this.state.chapterClone.forEach((lead) => {
          selectedOption.forEach((service) => {
            if (lead.service === service.id) {
              chapterLead.push(lead);
            }
          });
        });
      }
      this.setState(
        {
          serviceError: [],
          report: {
            service: selectedOption,
          },
          selectService: selectedOption,
          chapterLead: chapterLead,
          chapterLeadValue: [],
          searchBy: "",
        },
        () => { }
      );
    } else {
    }
  };

  getAllChapterOPtions = (chapter = []) => {
    const serviceOptionsList = this.state.serviceName.map((item) => item.id);
    const chapterLeadList = chapter.filter((item) =>
      serviceOptionsList.includes(item.service)
    );
    return chapterLeadList;
  };

  /**
   * Get the chapter lead list
   */
  getChapterLead = (selectService) => {
    let data = [];
    let chapter = [];
    this.props.getChapterLead(data).then(
      (response) => {
        this.props.chapterLeadList.length &&
          this.props.chapterLeadList.forEach((item) => {
            chapter.push({
              label: item.chapter_name,
              value: item.id,
              service: item.service,
              reporting_to: item.reporting_to,
            });
          });
        let chapterLead = this.getAllChapterOPtions(chapter);
        this.setState(
          {
            chapterLead: chapterLead,
            chapterClone: chapter,
          },
          () => {
            this.filterService(selectService, chapterLead);
          }
        );
        this.generateFilterResult();
      },
      (error) => {
        this.ToastAlert("error", error);
      }
    );
  };

  /**
   * get values in chapter
   * @param {*} chapterOption
   */
  handleChapter = (chapterOption) => {
    this.setState({
      chapterLeadValue: chapterOption,
      chapterError: [],
    });
  };
  getStartedSurveyBu = (batch) => {
    let objData = {};
    objData.batch = batch;
    this.props.getStartedSurveyBu(objData).then((response) => {
      console.log(this.props.getStatedBU);
        if(this.props.getStatedBU.result){      
          //console.log(this.props.getStatedBU.result);
          this.groupById(this.props.getStatedBU.result);
        }
    });
  }
  groupById(data) {
    const groupedData = data.reduce((acc, item) => {
      // If the id does not exist in the accumulator, create a new entry
      if (!acc[item.id]) {
        acc[item.id] = {
          ...item,
          bu_names: [item.bu_name],
          bu_ids: [item.bu_id]
        };
      } else {
        // If the id exists, update the existing entry
        acc[item.id].bu_names.push(item.bu_name);
        acc[item.id].bu_ids.push(item.bu_id);
      }
      return acc;
    }, {});

    // Convert the grouped data object back to an array
    const newData = Object.values(groupedData);
    this.setState({
      deadlineBu: newData,
    });
  }
  getReportByHead = (e) => {
    if (e !== undefined && e !== null) {
      this.setState(
        {
          bu_id: e.bu_id,
          head_id: e.label,
          selectBuFilter: e,
          chapterLeadValue: [],
          chapterLead: [],
          searchBy: "",
        },
        () => {
          this.getBuFilter();
        }
      );
    } else {
      this.setState(
        {
          bu_id: "",
          head_id: "All",
          selectBuFilter: [],
          searchBy: "",
        },
        () => {
          this.getBuFilter();
        }
      );
    }
  };

  /* business unit filter function  */
  getBuFilter = () => {
    let serviceArray = [];
    let service_head = [];
    if (this.state.head_id !== "All") {
      // for BU filter
      this.state.snameArray.forEach((service) => {
        if (this.state.bu_id === service.bu) {
          service_head.push(service);
        }
      });
      this.setState({
        serviceName: [],
        selectService: [],
        chapterLeadValue: [],
        chapterLead: this.getAllChapterOPtions(this.state?.chapterClone),
        report: {
          service: [],
        },
      });
    } else {
      // when All BU is selected
      this.setState({
        serviceName: this.state.serviceName,
        selectService: [],
        selectBuFilter: [],
        chapterLeadValue: [],
        chapterLead: this.state.chapterClone,
      });

      // ****
      //  enable the below condition if service options needed without any BU selected
      // ****

      if (this.state.report.service.length === 0) {
        this.state.snameArray.forEach((service) => {
          service_head.push(service);
        });
      } else {
        service_head = this.state.sname;
        this.setState({
          serviceName: [],
          selectService: [],
          report: {
            service: [],
          },
        });
      }
    }
    service_head.forEach((value) => {
      let opt = {};
      opt.label = value.value;
      opt.id = value.id;
      opt.value = value.value;
      opt.head = value.head;
      opt.bu = value.bu;
      serviceArray.push(opt);
    });
    this.setState(
      {
        serviceName: serviceArray,
      },
      () => {
        this.updateChapterState();
      }
    );
  };

  updateChapterState = () => {
    const chapterLead = this.getAllChapterOPtions(this.state.chapterClone);
    this.setState({
      chapterLead,
    });
  };

  /**
   * generateResultForFilter
   * @returns
   */

  generateFilterResult = (e) => {
    this.setState({ searchedValue: "" });
    let dataObject = {};

    dataObject.business_unit = this.state.selectBuFilter
      ? this.state.selectBuFilter?.bu_id
      : this.state.loginUserData.business_unit?.id;
    if (this.state.loginUserData.role_id == 10) {
      dataObject.los = this.state.selectService.length
        ? this.state.selectService.map((item) => item.id)
        : this.state.serviceName.map((item) => item.id);

    } else {
      dataObject.los = this.state.selectService.length
        ? this.state.selectService.map((item) => item.id)
        : [];
    }

    dataObject.chapter =
      this.state.chapterLeadValue ? this.state.chapterLeadValue.map((item) => item.label)
        : [];
    dataObject.performanceDate = this.state.performanceDate;
    this.getPmsDashboardAPI(true, this.state.performanceDate, dataObject);
  };

  /* toast message function  */
  ToastAlert(icon, title) {
    return Toast.fire({
      target: document.getElementById("form-modal"),
      icon: `${icon}`,
      title: `${title}`,
    });
  }

  getPmsDashboardAPI = (
    isloading = false,
    performanceDate = this.state.performanceDate,
    payloadData
  ) => {
    this.setState({ loading: true, dashboardLoader: isloading });
    let payload = {};
    if (payloadData) {
      payload["data"] = payloadData;
    } else {
      payload = this.state.dashboardPayload;
    }
    payload.data["performanceDate"] = performanceDate;
    this.props.getPmsDashboard(payload).then(
      (_response) => {
        const dashboardDetails = this.props.pmsDetails.pmsDashboard;
        const chapterPercentDetails =
          this.props.pmsDetails.pmsDashboard.chapterPercent;
        this.getDataListDetails(dashboardDetails);
        this.setState(
          {
            loading: false,
            dashboardLoader: false,
            chapterPercentDetails,
            searchedValue: "",
            dashboardPayload: payload,
          },
          () => {
            this.setSelectedLevelFilter("all");
          }
        );
      },
      (error) => {
        this.setState({ loading: false, dashboardLoader: false });
        this.ToastAlert("error", "Something went wrong");
      }
    );
  };

  getDataListDetails = (dashboardDetails) => {
    const dataKeys = Object.keys(dashboardDetails.data);

    let completeEmployeeData = [];
    dataKeys.forEach((item) => {
      completeEmployeeData.push(
        ...this.convertObjectToArray(dashboardDetails.data[item])
      );
    });

    completeEmployeeData = completeEmployeeData?.sort((a, b) => {
      return a.userName.localeCompare(b.userName, "en", {
        numeric: true,
        sensitivity: "base",
      });
    });
    let levelList = [];
    let availableLevels = [];
    let EmployeeListLevels = {};
    levelList.push({ id: 0, value: `all`, label: `All`, key: "all" });
    completeEmployeeData?.forEach((item) => {
      if (!item.level || item?.level === "Not Applicable") {
        if (availableLevels.includes("NA")) {
          EmployeeListLevels["NA"].push(item);
        } else {
          EmployeeListLevels = {
            ...EmployeeListLevels,
            [`NA`]: [item],
          };
          levelList.push({
            id: "NA",
            value: `NA`,
            label: `NA`,
            key: "NA",
          });
          availableLevels.push("NA");
        }
      } else {
        const EL = item?.level?.split("-")[1].split(" ")[1];
        if (!availableLevels.includes(EL)) {
          levelList = [
            ...levelList,
            { id: EL, value: `EL${EL}`, label: `L${EL}`, key: `EL${EL}` },
          ];
          EmployeeListLevels = {
            ...EmployeeListLevels,
            [`EL${EL}`]: [item],
          };
          availableLevels.push(EL);
        } else {
          EmployeeListLevels[`EL${EL}`].push(item);
        }
      }
    });
    const finalDataList = {
      all: completeEmployeeData,
      ...EmployeeListLevels,
    };

    this.setState({
      dashboardDetails,
      getUserGrid: finalDataList,
      levelList: levelList.sort((a, b) => {
        return a.label.localeCompare(b.label, "en", {
          numeric: true,
          sensitivity: "base",
        });
      }),
      filteredUserGrid: finalDataList[this.state.selectedLevel],
      leverData: finalDataList,
    });
  };

  convertObjectToArray = (objectOfObjects) => {
    const arrayOfObjects =
      objectOfObjects &&
      Object.keys(objectOfObjects).map((key) => {
        const innerObject = objectOfObjects[key];
        return { ...innerObject };
      });
    return arrayOfObjects || [];
  };

  getClassname = () => {
    return {
      Completed: "success-li",
      "Not Yet Started": "warning-li",
      "Not Completed": "danger-li",
    };
  };

  handleSearchChange = (event) => {
    const value = event.target.value;
    const filteredUserGrid = this.state.getUserGrid[
      this.state.selectedLevel
    ].filter((item) =>
      item.userName.toLowerCase().includes(value.toLowerCase())
    );
    this.setState({
      ...this.state,
      searchedValue: value,
      filteredUserGrid,
    });
  };

  handleCommentsQuestion = (item = {}, isAction = false) => {
    const filterId = this.state.filterId;
    this.setState({
      filterId: null,
      filterName: null,
      isActionsPage: isAction,
      actionProperty: item,
    }, () => {
      if (filterId) {
        this.props.history.push('/pms_dashboard');
      }
    });
    if (isAction === false) {
      this.getPmsDashboardAPI(true);
    }
  };

  handleDownloadReport = (item = {}) => {
    this.setState({ dashboardLoader: true });
    let dataObject = { id: item.answerId, pageFrom: 1,isDownloadPdf:1 };
    let payload = {};
    payload["data"] = dataObject;
    this.props.downloadPmsReport(payload).then(
      (response) => {
        this.setState({ dashboardLoader: false });
        this.ToastAlert("success", "Report generated Successfully");
        let url = this.props.pmsDetails.pmsReportResponse.result.url;
        let fileName = this.props.pmsDetails.pmsReportResponse.result.file_name;
        this.downloadFile(true, url, fileName);
      },
      (error) => {
        this.setState({ dashboardLoader: false });
        this.ToastAlert("error", "Something went wrong");
      }
    );
  };

  handleDownloadZip = (item = {}) => {
    this.setState({ dashboardLoader: true });
    let dataObject = {};
    dataObject.business_unit = this.state.selectBuFilter
      ? this.state.selectBuFilter?.bu_id
      : [];
    dataObject.los = this.state.selectService.length
      ? this.state.selectService.map((item) => item.id)
      : [];
    dataObject.chapter =
      this.state.chapterLeadValue 
      ? this.state.chapterLeadValue.map((item) => item.label)
      : [];
    dataObject.performanceDate = this.state.performanceDate;

    let payload = {};
    payload["data"] = dataObject;
    this.props.downloadZip(payload).then(
      (response) => {
        this.setState({ dashboardLoader: false });
        this.ToastAlert("success", "Zip generated Successfully");
        let url = this.props.pmsDetails.downloadZipResponse.result.url;
        let fileName = this.props.pmsDetails.downloadZipResponse.result.file_name;
        this.downloadFile(false, url, fileName);
      },
      (error) => {
        this.setState({ dashboardLoader: false });
        this.ToastAlert("error", "Something went wrong");
      }
    );
  };
  pmsExcelExport = (item = {}) => {
    this.setState({ dashboardLoader: true });
    let dataObject = {};
    dataObject.business_unit = this.state.selectBuFilter
      ? this.state.selectBuFilter?.bu_id
      : [];
    dataObject.los = this.state.selectService.length
      ? this.state.selectService.map((item) => item.id)
      : [];
    dataObject.chapter = this.state.chapterLeadValue 
      ? this.state.chapterLeadValue.map((item) => item.label)
      : [];
    dataObject.performanceDate = this.state.performanceDate;
    let payload = {};
    payload["data"] = dataObject;
    this.props.pmsExportExcel(payload).then((response) => {
      this.setState({ dashboardLoader: false });
      this.ToastAlert("success", "Report generated Successfully");
      document.location = this.state.excelUrl + this.props.pmsExportData.filename;
    },(error) => {
      this.setState({ dashboardLoader: false });
      this.ToastAlert("error", error);  
    });
  };

  downloadFile = (isNewtab = false, url, fileName) => {
    const a = document.createElement("a");
    a.href = url;
    a.target = isNewtab ? "_blank" : "_self";
    a.download = fileName; // Specify the desired filename
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  setSelectedLevelFilter = (selectedLevel) => {
    const { getUserGrid, searchedValue } = this.state;
    this.setState({
      selectedLevel,
      filteredUserGrid: getUserGrid[selectedLevel].filter((item) =>
        item.userName.toLowerCase().includes(searchedValue.toLowerCase())
      ),
    });
  };

  getPerformanceDateYearOption = () => {
    const currentYear = new Date().getFullYear();
    const options = Array(5)
      .fill(1)
      .map((_item, index) => {
        return {
          id: index,
          label: currentYear + index,
          value: currentYear + index,
        };
      });
    return options;
  };

  performanceDateYearhandle = (e) => {
    this.setState({
      performanceDateYear: e,
      performanceDate: `${e.label}-04-01`,
      searchedValue: "",
    });
    this.getPmsDashboardAPI(true, `${e.label}-04-01`);
  };

  handlePerformanceDate = (date) => {
    this.setState({
      performanceDate: date,
      searchedValue: "",
    });
    this.getPmsDashboardAPI(true, date);
  };

  getPerformanceDate = () => {
    const currentMonth = new Date().getMonth();
    const currentYear = new Date().getFullYear();
    const performanceMonth = "04";
    // const performanceMonth = constants
    //   .getBackEndConstant()
    //   .performanceMonthValue["04"].includes(currentMonth)
    //   ? "04"
    //   : "10";
    this.setState({
      performanceDateYear: {
        label: currentYear,
        value: currentYear,
      },
      performanceDate: `${currentYear}-${performanceMonth}-01`,
    });
  };

  getPercentStatusValue = (percent) => {
    let status = {};
    if (percent < 50) {
      status = { label: "Pending", value: "pending" };
    } else if (percent < 75) {
      status = { label: "Pending", value: "not yet started" };
    } else {
      status = { label: "Completed", value: "completed" };
    }
    return status;
  };

  downloadStatisticsAPI = () => {
    this.setState({ dashboardLoader: true });
    let dataObject = {};
    let payload = {};
    dataObject.data = this.state.dashboardPayload;
    payload["data"] = dataObject;
    this.props.downloadStatistics(payload).then(
      (response) => {
        this.setState({ dashboardLoader: false });
        this.ToastAlert("success", "Report generated Successfully");
        const a = document.createElement("a");
        a.href = `${constants.EXCEL_LINK}${response.filename}`;
        a.download = "pms.pdf"; // Specify the desired filename
        a.click();
      },
      (error) => {
        this.setState({ loading: false });
        this.ToastAlert("error", "Something went wrong");
      }
    );
  };

  getDateAndBatch = () => {
    let startDate, endDate, batch;
    this.props.getStartEndDate().then(
      (response) => {
        this.props.dateList.length &&
          this.props.dateList.map((item) => {
            if (item.status == 0) {
              startDate = moment(item.start_date)
                .utcOffset("+05:30")
                .format("DD-MM-YYYY");
              endDate = item.end_date ? moment(item.end_date)
                .utcOffset("+05:30")
                .format("DD-MM-YYYY") : null;
              batch = item.batch;
              this.getStartedSurveyBu(batch);
            }
          });
        this.setState({
          batch: batch,
          startQuesDate: startDate,
          endQuesDate: endDate,
        });
        
      },
      (error) => {
        this.ToastAlert("error", error);
      }
    );
  };

  render() {
    const formattedCurrentDate = new Date();
    const formattedstartDate = new Date(moment(this.state.startQuesDate, 'DD-MM-YYYY').
      format('YYYY-MM-DD').toString() + " 0:00:00");
    const formattedEndDate = this.state.endQuesDate;
    let { isActionsPage, actionProperty } = this.state;
    const {
      filteredUserGrid,
      getUserGrid,
      selectedLevel,
      redirect,
      dashboardLoader,
      loginUserData,
      performanceDate,
      performanceDateYear,
      chapterPercentDetails,
      role_id,
      levelList = [],
      dashboardDetails: {
        questionPercent = 0,
        overAllPercent = 0,
        commentPercentage = 0,
        teamCount = 0,
        answerCount = 0,
        answerStatus,
        deadline = new Date()
          .toISOString()
          .split("T")[0]
          .split("-")
          .reverse()
          .join("/"),
      },
      leverData,
    } = this.state;

    if (this.state.filterId != null && this.state.filterName != null && filteredUserGrid.length != 0) {
      let actionProperties = filteredUserGrid?.find(user => user.answerId == this.state.filterId)
      if (actionProperties != null) {
        isActionsPage = true;
        actionProperty = actionProperties;
      }
    }

    if (redirect === 1 || loginUserData === null) {
      return <PageNotFound url={constants.FRONT_LINK} />;
    } else if (isActionsPage) {
      return (
        <>
          <PmsComments
            handleCommentsQuestion={this.handleCommentsQuestion}
            handleDownloadReport={this.handleDownloadReport}
            actionProperty={actionProperty}
            pageFrom={1}
            userId={loginUserData?.id}
          />
        </>
      );
    } else {

      return (
        <div
          className={`page-content px-4 pms-dashboard-container ${this.props.addClass ? "active" : ""
            }`}
        >
          {dashboardLoader ? (
                      <div id="preloader">
                        <div id="status"></div>
                      </div>
                    ): ''
          }
          <Row>
            <div className="col-xs-12 fourty-fluid fourty-fluid">
              <Row className="row">
                <div className="col-sm-4 col-sm-4 col-xs-12 col-md-12 text-left">
                  <h1 className="B-G-bold f-s-18 d-gray t-t-upper pull-left mt-15 mr-20 page-head lh-10">
                    PMS-DASHBOARD
                  </h1>
                </div>
              </Row>

              <PmsFilter
                stateValue={this.state}
                generateFilterResult={this.generateFilterResult}
                filterService={this.filterService}
                handleChapter={this.handleChapter}
                getReportByHead={this.getReportByHead}
              />
              <Row className="service-container px-2">
                <Col className="text-left bgwhite mr-10">
                  {(role_id === 8 ||
                    role_id === 10 ||
                    role_id === 1 ||
                    role_id === 4 ||
                    this.state.pmsPermission||
                    role_id === constants.HR) && (
                      <div className="d-flex align-items-baseline performance-year">
                        <label className="style-label fs-12 m-r-20">
                          Select Year
                        </label>
                        <div className="selectpicker_dropdown hide_bar selectpicker_dropdown_orange">
                          <Select
                            className="basic-single-select"
                            classNamePrefix={"select_dropdown"}
                            onChange={(e) => this.performanceDateYearhandle(e)}
                            value={performanceDateYear}
                            options={this.getPerformanceDateYearOption()}
                          ></Select>
                        </div>
                      </div>
                    )}
                  <div className="col-sm-4 col-sm-4 col-xs-12 col-md-12 text-left">
                    <Col>
                      <div className="project-list text-left custom-li-size">
                        {(role_id === 8 ||
                          role_id === 10 ||
                          role_id === 1 ||
                          role_id === 4 ||
                          this.state.pmsPermission ||
                          role_id === constants.HR) && (
                            <ul className="p-l-0 d-flex text-center">
                              <li
                                className={`B-G-bold f-s-12 px-3 mr-5 py-2 c-pointer ${`${performanceDateYear.value}-04-01` ===
                                  performanceDate
                                  ? "pr-l-active"
                                  : "pr-l-gray"
                                  }`}
                                onClick={() =>
                                  this.handlePerformanceDate(
                                    `${performanceDateYear.value}-04-01`
                                  )
                                }
                              >
                                Batch- April {performanceDateYear.value}
                              </li>
                              <li
                                className={`B-G-bold f-s-12 px-3 mr-5 py-2 c-pointer ${`${performanceDateYear.value}-10-01` ===
                                  performanceDate
                                  ? "pr-l-active"
                                  : "pr-l-gray"
                                  }`}
                                onClick={() =>
                                  this.handlePerformanceDate(
                                    `${performanceDateYear.value}-10-01`
                                  )
                                }
                              >
                                Batch- October {performanceDateYear.value}                             
                              </li>
                            </ul>
                          )}
                      </div>
                      {role_id !== 8 &&
                        role_id !== 1 &&
                        role_id !== 4 &&
                        role_id !== 10 &&
                        !this.state.pmsPermission &&
                        role_id !== constants.HR && (
                          <div className="col-sm-4 col-sm-4 col-xs-12 col-md-12 text-left d-flex justify-content-between items-center w-100  px-3 py-4">
                            <div className="d-flex flex-column">
                              <Link
                                className=""
                                to={"/pms_review"}
                                onClick={this.toggleMenu}
                              >
                                <h6 className="text-black font-bold">
                                  Self Appraisal Completion Percentage
                                </h6>
                                {(formattedCurrentDate >= formattedstartDate) && formattedEndDate ? (
                                  <span className="style-deadline">
                                    Deadline: {formattedEndDate}
                                  </span>
                                ) : (
                                  <span></span>
                                )}
                              </Link>
                              <span className="style-deadline"></span>
                            </div>
                            <div
                              className={`style-percentage-overall style-percentage-${constants.getBackEndConstant().statusClasses[
                                this.getPercentStatusValue(questionPercent)
                                  .value
                              ]
                                }`}
                            >
                              <span>
                                {" "}
                                {questionPercent && questionPercent != 0 ? `${questionPercent?.toFixed(2)} %` : '0.00 %'}
                              </span>
                            </div>
                          </div>
                        )}
                      <div></div>
                    </Col>
                  </div>
                </Col>
                {(role_id === 8 ||
                  role_id === 10 ||
                  role_id === 4 ||
                  this.state.pmsPermission ||
                  role_id === constants.HR) &&
                  (<Col className="bgwhite differ-login py-2 mr-10">
                    {(role_id === 8 ||
                      role_id === 10 ||
                      role_id === 4 ||
                      this.state.pmsPermission ||
                      role_id === constants.HR) && (
                        <div>
                          <div className="col-sm-4 col-sm-4 col-xs-12 col-md-12 text-left d-flex justify-content-between items-center w100 align-items-center mb-2">
                            <div className="d-flex flex-column">
                              <h6>Overall Team Completion</h6>
                            </div>
                            <div
                              className={`style-percentage-overall style-percentage-${constants.getBackEndConstant().statusClasses[
                                this.getPercentStatusValue(overAllPercent).value
                              ]
                                }`}
                            >
                              <span>
                                {(overAllPercent && overAllPercent !== 0) ?
                                  (Number.isInteger(overAllPercent) ? overAllPercent : overAllPercent.toFixed(1)) :
                                  0
                                }
                                %
                              </span>                              
                            </div>
                            <div
                              className={`style-percentage-overall count style-percentage-${constants.getBackEndConstant().statusClasses[
                                this.getPercentStatusValue(overAllPercent).value
                              ]
                                }`}
                            > 
                            <span>
                            {answerCount}/
                              {teamCount}                                
                              </span>
                            </div>
                          </div>

                          <span className="style-deadline">
                            {deadline ?
                              <span>Deadline: {deadline} </span> : ''
                            }

                          </span>
                        </div>
                      )}
                  </Col>)}

                  <Col className="bgwhite differ-login py-2">
                    {(role_id == 8 && <div
                      className="col-sm-4 col-sm-4 col-xs-12 col-md-12 text-left d-flex justify-content-between 
                      items-center w100 align-items-center"
                    >
                      {role_id === 8 && (
                        <>
                          <div className="d-flex flex-column">
                            <h6>Team Comments Completion</h6>
                          </div>
                          <div
                            className={`style-percentage-overall style-percentage-${constants.getBackEndConstant().statusClasses[
                              this.getPercentStatusValue(commentPercentage)
                                .value
                            ]
                              }`}
                          >
                            <span>
                              {(commentPercentage && commentPercentage !== 0) ?
                                (Number.isInteger(commentPercentage) ? commentPercentage : commentPercentage.toFixed(1)) :
                                0
                              }
                              %
                            </span>
                          </div>
                        </>
                      )}
                    </div>)}
                    {(role_id === 1) && (
                      <div>
                        <div className="col-sm-4 col-sm-4 col-xs-12 col-md-12 text-left d-flex justify-content-between items-center w100 align-items-center mb-2">
                          <div className="d-flex flex-column">
                            <h6>Overall Team Completion</h6>
                          </div>
                          <div
                            className={`style-percentage-overall admin style-percentage-${constants.getBackEndConstant().statusClasses[
                              this.getPercentStatusValue(overAllPercent).value
                            ]
                              }`}
                          >
                            <span>
                              {(overAllPercent && overAllPercent !== 0) ?
                                (Number.isInteger(overAllPercent) ? overAllPercent : overAllPercent.toFixed(1)) :
                                0
                              }
                              %
                            </span>
                          </div>
                          <div
                              className={`style-percentage-overall count style-percentage-${constants.getBackEndConstant().statusClasses[
                                this.getPercentStatusValue(overAllPercent).value
                              ]
                                }`}
                            > 
                            <span>
                            {answerCount}/
                              {teamCount}                                
                              </span>
                            </div>
                        </div>
                        <div
                          className="col-sm-4 col-sm-4 col-xs-12 col-md-12 text-left d-flex justify-content-between 
                         items-center w100 align-items-center"
                        >
                          {/* {role_id === 8 && (
                               <>
                                 <div className="d-flex flex-column">
                                   <h6>Team Comments Completion</h6>
                                 </div>
                                 <div
                                   className={`style-percentage-overall style-percentage-${constants.getBackEndConstant().statusClasses[
                                     this.getPercentStatusValue(commentPercentage)
                                       .value
                                   ]
                                     }`}
                                 >
                                   <span>
                                     {Number.isInteger(commentPercentage)
                                       ? commentPercentage
                                       : commentPercentage.toFixed(1)}
                                     %
                                   </span>
                                 </div>
                               </>
                             )} */}
                        </div>
                        <span className="style-deadline">
                        {this.state.deadlineBu && this.state.deadlineBu?.map((item) => (
                          <>
                            <p>Business Units: {item.bu_names.join(', ')}</p>
                            <br/>
                              Deadline: {moment(item.end_date).utcOffset("+05:30").format("DD-MM-YYYY")}
                            <hr />
                          </>
                        ))}
                        </span>
                      </div>
                    )}
                    {role_id != 1 && role_id != 8 && (
                      <>
                        {questionPercent == 100 &&
                          this.state.dashboardDetails.answerStatus == 1 && (
                            <Link
                              className=""
                              to={"/pms_review"}
                              onClick={this.toggleMenu}
                            >
                              <div>
                                {" "}
                                <h6 className="buClass font-bold pull-left">
                                  Self Appraisal got successfully submitted <i className="fs-16 fa fa-eye"></i>
                                </h6>
                              </div>
                            </Link>
                          )}
                        {questionPercent == 100 &&
                          this.state.dashboardDetails.answerStatus &&
                          this.state.dashboardDetails.answerStatus == 2 && (
                            <Link
                              className=""
                              to={"/pms_review"}
                              onClick={this.toggleMenu}
                            >
                              <div>
                                {" "}
                                <h6 className="buClass font-bold pull-left">
                                  Please submit
                                  the form to make it ready for review. <i className="fs-16 fa fa-eye"></i>
                                </h6>
                              </div>
                            </Link>
                          )}
                        {questionPercent > 50 && questionPercent < 100 && (
                          <Link
                            className=""
                            to={"/pms_review"}
                            onClick={this.toggleMenu}
                          >
                            <div>
                              {" "}
                              <h6 className="buClass font-bold pull-left">
                                Almost answered the questions. Please submit the
                                form <i className="fs-16 fa fa-eye"></i>
                              </h6>
                            </div>
                          </Link>
                        )}
                        {questionPercent > 0 && questionPercent <= 50 && (
                          <Link
                            className=""
                            to={"/pms_review"}
                            onClick={this.toggleMenu}
                          >
                            <div>
                              {" "}
                              <h6 className="buClass font-bold pull-left">
                                You are currently in the middle of responding.
                                Kindly fill out this form. <i className="fs-16 fa fa-eye"></i>
                              </h6>
                            </div>
                          </Link>
                        )}
                        {questionPercent == 0 && (
                          <Link
                            className=""
                            to={"/pms_review"}
                            onClick={this.toggleMenu}
                          >
                            <div>
                              <h6 className="buClass font-bold pull-left">
                              {(formattedstartDate < formattedCurrentDate &&  this.state.dashboardDetails.checkUserQuestionCount > 0) ? "Click here to answer the survey" : "Review Not Yet Started"} <i className="fs-16 fa fa-eye"></i>
                              </h6>
                            </div>
                          </Link>
                        )}
                      </>
                    )}
                  </Col>
              </Row>
              <hr className="m-t-5 col-sm-12 m-b-8 p-0"></hr>
              {(role_id === 8 ||
                role_id === 10 ||
                role_id === 1 ||
                role_id === 4 ||
                this.state.pmsPermission ||
                role_id === constants.HR) && (
                  <div>
                    {chapterPercentDetails && (
                      <Row className="justify-content-between">
                        {chapterPercentDetails?.map((item, index) => {
                          const status = this.getPercentStatusValue(
                            item.percentage
                          );
                          const className =
                            constants.getBackEndConstant().statusClasses[
                            status.value
                            ];
                          return (
                            <Col
                              className="w-13"
                              xs={12}
                              md={3}
                              sm={4}
                              lg
                              key={`chapterpercent-${index}`}
                            >
                              <div className="bgwhite text-left d-flex justify-content-between align-items-center p-2 my-1">
                                <div className="chapter-percent-details">
                                  <p className="f-s-12">{item.chapterName}</p>
                                  <span className="style-deadline mt-1 f-s-10">
                                    Status: {status?.label}
                                  </span>
                                </div>
                                <div
                                  className={`style-percentage style-percentage-${className}`}
                                >
                                  <span>
                                    {(item.percentage && item.percentage !== 0) ?
                                      (Number.isInteger(item.percentage) ? item.percentage : item.percentage.toFixed(1)) :
                                      0
                                    }
                                    %
                                  </span>
                                </div>
                              </div>
                            </Col>
                          );
                        })}
                      </Row>
                    )}
                    <div className="row m-0">
                      <div className="p-0">
                        <div className="form-group search-icon m-b-0">
                          <input
                            type="text"
                            name="user_grid_filter"
                            value={this.state.searchedValue}
                            className="form-control f-s-16 d-gray pl-30 ng-valid ng-dirty ng-valid-parse ng-empty ng-touched"
                            placeholder="Search ..."
                            onChange={(e) => this.handleSearchChange(e)}
                          />
                          <i className="fa fa-search"></i>
                        </div>
                      </div>
                    </div>
                    <Row className="my-2">
                      <Col className="d-flex">
                        {levelList?.map((level) => {
                          return (
                            getUserGrid[level.key]?.length > 0 && (
                              <div
                                key={level.id}
                                className={`level-filter ${level.value === selectedLevel && "active"
                                  }`}
                                onClick={() =>
                                  this.setSelectedLevelFilter(level.value)
                                }
                              >
                                {level.label} ({leverData[level.key].length})
                              </div>
                            )
                          );
                        })}
                      </Col>
                    </Row>
                    <Row className="my-3">
                      <Col sm={6} className="text-left">
                        Total Team Members:{" "}
                        <span className="warning-color">{teamCount}</span>
                      </Col>
                      <Col sm={2} className="text-end warning-color">
                        <div
                          className="c-pointer"
                          onClick={this.downloadStatisticsAPI}
                        >
                          {/* <i className="fs-16 fa fa-download mr-5" />
                        Download team statistics */}
                        </div>
                      </Col>
                      
                      <Col sm={4} className="text-end warning-color">
                          <button class={`btn btn-primary btn-sm p-2 m-r-10`} onClick={this.pmsExcelExport}>Export as Excel</button>
                          <button class={`btn btn-primary btn-sm p-2 ${answerCount == 0 ? 'disable' : ''}`} onClick={this.handleDownloadZip} disabled={answerCount == 0}><i class="fa fa-file-archive-o" aria-hidden="true"></i> Download Zip</button>
                      </Col>
                    </Row>
                    {dashboardLoader ? (
                      <div></div>
                    ) : (
                      <PmsUserGrid
                        dataList={filteredUserGrid}
                        handleCommentsQuestion={this.handleCommentsQuestion}
                        handleDownloadReport={this.handleDownloadReport}
                      />
                    )}
                  </div>
                )}
            </div>
          </Row>
        </div>
      );
    }
  }
}
const mapStateToProps = (state) => {
  return {
    loginDetails: state.loginDetails,
    pmsDetails: state.pmsDetails,
    reportFilter: state.reportFilterDetails.reportFilter,
    pmoFilter: state.reportFilterDetails.reportFilter,
    chapterLeadList: state.pmsDetails.chapterLead,
    addClass: state.pmsDetails.addClass,
    saveAnswerList: state.pmsDetails.saveAnswer,
    dateList: state.pmsDetails.getStartEndDate,
    pmsExportData : state.pmsDetails.pmsExportExcel,
    getPmsPermissionData: state.pmsDetails.getPmsPermissionData,
    getStatedBU : state.pmsDetails.getStatedBU
  };
};

const mapDispatchToProps = {
  getPmsDashboard,
  getReportFilter,
  getLoginUserPermission,
  getReportFilterPmo,
  getChapterLead,
  setAddClass,
  downloadStatistics,
  getStartEndDate,
  downloadPmsReport,
  downloadZip,
  pmsExportExcel,
  getPmsPermission,
  getStartedSurveyBu
};

export default connect(mapStateToProps, mapDispatchToProps)(PmsDashboard);