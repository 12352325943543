import React, { Component } from 'react'
import { connect } from "react-redux";
import moment from 'moment';
import {
    getPotentialProjectList,
    deleteCurrentPotentialProject,
    getCurrentMonthProjectName,
    editProjectPotential
} from "../../Action";
import Swal from "sweetalert2";
import {
    Button,
    Modal
} from "react-bootstrap";
import AddPotentialPopUp from './AddPotentialPopUp';

const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    showCloseButton: true,
    customClass: {
        popup: "colored-toast",
        icon: "white-toast-icon",
    },
    timer: 3000,
    onOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
});

class MsPotentialView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            loading: true,
            showDeleteModal: false,
            potentialProjectList: [],
            isEditModal: false,
            msDeleteCurrentPotentialProjects: '',
            currentProjectId: 0,
            isModalOpen: false,
            isModalClose: false,
            potentialCurrentProjectName: [],
            filterEdit: {},

        };
        this.confirmDelete = this.confirmDelete.bind(this);
    }

    componentDidMount() {
        this.getPotentialProjectList();
        this.getCurrentPotentialProjectName();
    };


    getPotentialProjectList = async () => {
        const startDate = moment(this.props.onstart_date)
            .utcOffset('+05:30')
            .format('YYYY-MM-31');
        const endDate = moment(this.props.onstart_date)
            .utcOffset('+05:30')
            .format('YYYY-MM-31');
        const payload = {
            start_date: startDate,
            end_date: endDate,
        };
        try {
            await this.props.getPotentialProjectList(payload);
            this.setState({
                potentialProjectList: this.props.potentialProjectList,

            });
        } catch (error) {
            this.setState({
                potentialProjectList: {},
            });
            this.ToastAlert('error', error);
        }
    };


    handleSubmit = (e) => {
        e.currentTarget.blur(); // removing focus
        this.setState({
            isOpen: false
        });
    };
    openModal = (e, item, dataKey) => {
        localStorage.setItem('record', JSON.stringify(item));
        this.setState({
            isOpen: true,
            selectedDataKey: dataKey,
        });
    };

    closeModal = (e) => {
        e.currentTarget.blur(); // removing focus
        this.setState({
            isOpen: false
        });
    };
    ToastAlert(icon, title) {
        return Toast.fire({
            target: document.getElementById("form-modal"),
            icon: `${icon}`,
            title: `${title}`,
        });
    };

    editProject = async (id) => {
        try {
            this.setState({
                loading: true,
            });
            const payload = { id: id };
            await this.props.editProjectPotential(payload);
            this.setState({
                loading: false,
                filterEdit: this.props.editProject,
                isEditModal: true,
                isModalOpen: true,
            });
        } catch (error) {
            this.ToastAlert("error", "Something went wrong");
            this.setState({
                loading: false,
            });
        }
    };


    deletePotentialProject = async (id) => {
        this.setState({
            showDeleteModal: true,
            currentProjectId: id,
        });
    };

    confirmDelete = async () => {
        const payload = {
            id: this.state.currentProjectId,
        };
        try {
            await this.props.deleteCurrentPotentialProject(payload);
            this.setState({
                msDeleteCurrentPotentialProjects: this.props.msDeleteCurrentPotentialProject,
                showDeleteModal: false,
            }, () => {
                this.props.GridFunction();
                { this.ToastAlert('success', this.state.msDeleteCurrentPotentialProjects.result) }

            });

        } catch (error) {
            this.setState({
                potentialProjectList: {},
            });
            this.ToastAlert('error', error);
        }
    };

    closePotentialModal = (e) => {
        this.setState({
            isModalOpen: false,
            filterEdit: {},

        });
    };

    getCurrentPotentialProjectName = () => {
        const data = this.getCurrentMonthStartAndEndDates();
        this.props.getCurrentMonthProjectName(data).then((response) => {
            this.setState({
                potentialCurrentProjectName: this.props.potentialCurrentProjectName
            });
        }, (error) => {
            this.setState({
                potentialCurrentProjectName: [],
            });
            this.ToastAlert("error", error);
        });
    };

    getCurrentMonthStartAndEndDates = () => {
        const startDate = moment().startOf('month').format('YYYY-MM-DD');
        const endDate = moment().endOf('month').format('YYYY-MM-DD');
        return {
            start_date: startDate,
            end_date: endDate,
        };
    };

    updateFormDetails = (event) => {
        this.setState((prevState) => ({
            filterEdit: {
                ...prevState.filterEdit,
                potential_value: event.target.value
            }
        }));
    };

    updateFormStatus = (event) => {
        this.setState((prevState) => ({
            filterEdit: {
                ...prevState.filterEdit,
                status: event.target.value
            }
        }));
    };

    render() {

        return (
            <div className="table-responsive white-table-class pmo-report">
                {this.state.showDeleteModal && <Modal show={this.state.showDeleteModal} onHide={() => this.setState({ showDeleteModal: false })}>
                    <Modal.Header Confirm Delete closeButton>
                        <Modal.Title className='custom-modal-title potential-heading' >DELETE POTENTIAL</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Are you sure you want to delete this item?
                    </Modal.Body>
                    <Modal.Footer className='delete-modal'>
                        <Button className='btn  back_button cancel_back_btn' onClick={() => this.setState({ showDeleteModal: false })}>
                            Cancel
                        </Button>
                        <Button className="me-2" onClick={this.confirmDelete}>
                            Delete
                        </Button>
                    </Modal.Footer>
                </Modal>}

                {this.props.loading && (
                    <div id="preloader">
                        <div id="status"></div>
                    </div>
                )}
                {!this.props.loading && (Object.keys(this.props.isPotentialData).length === 0) &&
                    <span>No Data Available</span>
                }
                {!this.props.loading && Object.keys(this.props.isPotentialData).length > 0 &&
                    <div className="scrollable">
                        <div className="scrollable-content">
                            <table className="table m-b-0 table-wrap">
                                <thead>
                                    <tr className="theadInner busi_unit_bg">
                                        <th className="text-start" style={{ "width": "15%" }}>Project Name</th>
                                        <th className="text-start" style={{ "width": "10%" }}>Value</th>
                                        <th className="text-start" style={{ "width": "10%" }}>Mode</th>
                                        <th className="text-start" style={{ "width": "10%" }}>Status</th>
                                        <th className="text-start" style={{ "width": "15%" }}>Business Unit</th>
                                        <th className="text-start" style={{ "width": "15%" }}>Line Of Service</th>
                                        <th className="text-start" style={{ "width": "10%" }}>CSM</th>
                                        <th className="text-start" style={{ "width": "20%" }}>ACTION</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {!this.props.loading && Object.keys(this.props.isPotentialData).length > 0 && Object.entries(this.props.isPotentialData).map(([key, value]) => (
                                        < tr >
                                            <td className="text-start">
                                                <span>{value.project_name} </span>
                                            </td>
                                            <td className="text-start">
                                                <span>{value.potential_value} </span>
                                            </td>
                                            <td className="text-start">
                                                <span>{value.mode ? "Fixed" : "T&M"} </span>
                                            </td>
                                            <td className="text-start">
                                                <span>{value.status == 1 ? "In Progress" : "Awarded"} </span>
                                            </td>
                                            <td className="text-start">
                                                <span>{value.buName} </span>
                                            </td>
                                            <td className="text-start">
                                                <span>{value.serviceName} </span>
                                            </td>
                                            <td className="text-start">
                                                {value.csm && value.csm !== "[]" && JSON.parse(value.csm.length) ? (
                                                    <span>
                                                        {JSON.parse(value.csm).map((item, index) => (
                                                            <span key={index}>
                                                                {item.label}
                                                                {index !== (JSON.parse(value.csm).length) - 1 && ', '}
                                                            </span>
                                                        ))}
                                                    </span>
                                                ) : (
                                                    <span>NA</span>
                                                )}
                                            </td>
                                            <td className="text-start">
                                                <Button onClick={(e) => this.editProject(value.id)} value="edit" className="me-2" > <i className="fs-16 fa fa-edit"> </i></Button>
                                                <Button onClick={(e) => this.deletePotentialProject(value.id)} value="delete" className="me-2" ><i className="fs-16 fa fa-trash"></i></Button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div >}
                {
                    this.state.isModalOpen && <AddPotentialPopUp
                        isModalOpen={this.state.isModalOpen}
                        isProjectName={this.state.potentialCurrentProjectName}
                        isLoading={false}
                        isModalClose={() => this.closePotentialModal()}
                        isEditModal={true}
                        isfilterEdit={this.state.filterEdit}
                        GridFunction={this.props.GridFunction}
                        updatePotentialValue={(e) => {
                            this.updateFormDetails(e)
                        }}
                        updateStatus={(e) => {
                            this.updateFormStatus(e)
                        }}
                    />
                }

            </div >
        )
    }

}

const mapStateToProps = state => {
    return {
        potentialProjectList: state.msAllocationReportDetails.msPotentialProjectList,
        msDeleteCurrentPotentialProject: state.msAllocationReportDetails.msDeletePotentialProject,
        potentialCurrentProjectName: state.msAllocationReportDetails.msPotentialProjectName,
        editProject: state.msAllocationReportDetails.msEditProjectPotential,
    };

};

const mapDispatchToProps = {
    getPotentialProjectList,
    deleteCurrentPotentialProject,
    getCurrentMonthProjectName,
    editProjectPotential

};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MsPotentialView);


