/**
 *
 * Config file
 * Reports Frontend
 * 
 */

 export const Urls = {
   milestone_achieved_dropdown_value : [{'id':'0', 'value':'All'}, {'id':'1', 'value':'New'}, {'id':'2', 'value':'Invoice Raised'}, {'id':'3', 'value':'Invoice Collected'}, {'id':'4', 'value':'Invoice Pending'}],
   user_types : [{'id':'1','value': 'Moolah'}, {'id': '2', 'value': 'Boolah'}, {'id': '3', 'value': 'Support'},{'id': '4', 'value': 'Billable-Business Consulting'},{'id': '5', 'value': 'Vendor'},{'id': '6', 'value': 'Intern'}],
   resource_types : [{'id':0, 'value':'All'}, {'id':1, 'value':'Optisol'}, {'id':2, 'value':'Vendor'}],
   invoice_status: [{ 'id': '0', 'value': 'All' }, { 'id': '1', 'value': 'New' }, { 'id': '2', 'value': 'Invoice Pending' }, { 'id': '3', 'value': 'Invoice Collected' }, { 'id': '4', 'value': 'Part Payment Pending' }, { 'id': '5', 'value': 'Not Sure' }, { 'id': '6', 'value': 'Critical Account' }],
   due_date_status: [{ 'id': '0', 'value': 'All' }, { 'id': '1', 'value': 'Not Yet Due' }, { 'id': '2', 'value': 'On Due' }],
   invoice_value: [{ 'id': '0', 'value': 'All' }, { 'id': '1', 'value': 'Below 30 Days' }, { 'id': '2', 'value': 'Between 30 to 60 Days' }, { 'id': '3', 'value': 'Between 60 to 90 Days' }, { 'id': '4', 'value': 'Between 3 to 6 Months' }, { 'id': '5', 'value': 'Between 6 to 1 Year' }, { 'id': '6', 'value': 'More than a year' }],

   reports_lists : [
      {'id':1, 'value':'Practice Wise Allocation Report'},
      {'id':2,'value':'Milestone Value Report'},
      {'id':3, 'value':'Collections Report'},
      {'id':4, 'value':'Collection Potential Report'},
      {'id':5, 'value':'Inventory Report'},
      {'id':6, 'value':'Inventory Allocation Metrics'},
      {'id':7, 'value':'Inventory Milestone Metrics'},
      {'id':8, 'value':'Resource Utilization Report'},
      {'id':9, 'value':'Csm Report'},
      {'id':10, 'value':'Pmo Ms Report'},
      {'id':11, 'value':'Annual Contract Value Report'},
      { 'id': 12, 'value': 'Ms Allocation Report' }
    ],

    reports_lists1 : [
      {'id':1, 'value':'Practice Wise Allocation Report'},
      {'id':2,'value':'Milestone Value Report'},
      {'id':3, 'value':'Collections Report'},
      {'id':4, 'value':'Collection Potential Report'},
      {'id':5, 'value':'Inventory Report'},
      {'id':6, 'value':'Inventory Allocation Metrics'},
      {'id':7, 'value':'Inventory Milestone Metrics'},
      {'id':9, 'value':'Csm Report'}],
     
    bu_qes : 10,
    practice_qes : 245,
    service_qes : 62,
    staging_bu_qes : 19,
    staging_practice_qes:  244,
    staging_service_qes : 85,
    csm_head_role : 27,
    sub_admin_role : 26,
    acv_monthly_runrate_dropdown_value: [{ 'id': '0', 'value': 'All' }, { 'id': '5000', 'value': 'Above 5k' }, { 'id': '10000', 'value': 'Above 10k ' }, { 'id': '20000', 'value': ' Above 20k' }, { 'id': '30000', 'value': 'Above 30k ' }],
    acv_monthly_acvrange_dropdown_value: ["All", "Above 250K", "Below 50K", "Between 100K and 150K", "Between 50K and 100K", "Between 150K and 200K"], 
}

