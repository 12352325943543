import * as types from "../Utilities/Type";
const INITIAL_STATE = {
  notificationLists: [],
  markNotification: {},
};

export function notificationDetails(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.GET_NOTIFICATIONS:
      return {
        ...state,
        notificationLists: action.payload,
      };
    case types.MARK_NOTIFICATION:
      return {
        ...state,
        markNotification: action.payload,
      };
    default:
      return state;
  }
}