
 function practiceRename(practice) {
    let renamedPractice = practice;
    switch (practice) {
      case "Mean":
        renamedPractice = "ME";
        break;
      case "Mobile":
        renamedPractice = "MOB";
        break;
      case "Java":
        renamedPractice = "JV";
        break;
      case "Python":
        renamedPractice = "PY";
        break;
      case "Mobile - RN":
        renamedPractice = "M-R";
        break;
      case "Finance":
        renamedPractice = "FIN";
        break;
      case "Java script":
        renamedPractice = "JS";
        break;
      case "Machine Learning":
        renamedPractice = "ML";
        break;
      case "others":
        renamedPractice = "O";
        break;
      case "Business Consulting":
        renamedPractice = "BC";
        break;
      case "WordPress":
        renamedPractice = "WP";
        break;
      case ".NET USEC":
        renamedPractice = ".N-U";
        break;
      case "Business Team":
        renamedPractice = "BT";
        break;
      case "Business Team-BCG  1":
        renamedPractice = "BT - 1";
        break;
      case "BI USEC":
        renamedPractice = "BI-U";
        break;
      case "BT USEC":
        renamedPractice = "BT-U";
        break;
      case "AX USEC":
        renamedPractice = "AX-U";
        break;
      case "Techlead":
        renamedPractice = "TL";
        break;
      case "MOBILE Android":
        renamedPractice = "MA";
        break;
      case "Xamarin":
        renamedPractice = "XM";
        break;
      case "Android":
        renamedPractice = "AD";
        break;
      case "DevOps":
        renamedPractice = "DOP";
        break;
      case "Data Labs":
        renamedPractice = "DL";
        break;
      case "Client Manager":
        renamedPractice = "CM";
        break;
      case "Flutter":
        renamedPractice = "FL";
        break;
      case "Zamarin":
        renamedPractice = "ZM";
        break;
      case "3D Developer":
        renamedPractice = "3D-D";
        break;
      case "3D Artist":
        renamedPractice = "3D-A";
        break;
      case "Data Annotation":
        renamedPractice = "DA";
        break;
      case "Cross Platform":
        renamedPractice = "CP";
        break;
      case "Talent Acquisition":
        renamedPractice = "TA";
        break;
      case "DataOps":
        renamedPractice = "DOP";
        break;
      case "OSDI":
        renamedPractice = "OSDI";
        break;
      default:
        break;
    }
    return renamedPractice;
  }

  export function returnFloatingValueforInventory(arg) {
    let int_number = arg;
    let return_value ;
    if (int_number != undefined) {
       return_value =
        int_number % 1 == 0
          ? parseInt(int_number)
          : int_number.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          });
    } else return_value = 0;
    return return_value;
  }
