import React, { Component } from 'react';
import TargetPopUp from './TargetPopUp';
import {
  getCurrentMonthProjectName, saveMsSummaryTargetValue,
  getPotentialProjectList, getReportFilter, getReportFilterPmo, getLoginUser, getLoginUserPermission
} from "../../Action";
import { connect } from "react-redux";
import Swal from "sweetalert2";




const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  showCloseButton: true,
  customClass: {
    popup: "colored-toast",
    icon: "white-toast-icon",
  },
  timer: 3000,
  onOpen: (toast) => {
    toast.addEventListener("mouseenter", Swal.stopTimer);
    toast.addEventListener("mouseleave", Swal.resumeTimer);
  },
});

class MsSummaryView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      loading: false,
      selectedMonth: "",
      msSummaryTargetValue: [],
      // targetValue: this.props?.allValue?.
    };
  }

  handleSubmit = (value) => {
    this.setState({
      targetValue: value,
      isOpen: false
    }, () => {
      this.saveTargetValue();
    });
  };
  openModal = (e, item, quarter, dataKey) => {
    localStorage.setItem('record', JSON.stringify(item));
    this.setState({
      isOpen: true,
      selectedDataKey: dataKey,
      selectedMonth: quarter,
    });
  };
  ToastAlert(icon, title) {
    return Toast.fire({
      target: document.getElementById("form-modal"),
      icon: `${icon}`,
      title: `${title}`,
    });
  }


  closeModal = (e) => {
    e.currentTarget.blur(); // removing focus
    this.setState({
      isOpen: false
    });
  };
  saveTargetValue = async () => {
    const { allValue, loading } = this.props;
    const { selectService, selectBuFilter } = allValue;
    const selectedServiceIds = Object(selectService).length ? selectService.map(obj => obj.id) : [];
    const parseDate = new Date(Date.parse(this.state.selectedDataKey + ' 01'));
    const month = (parseDate.getMonth() + 1).toString().padStart(2, '0');
    const year = parseDate.getFullYear();
    const reportData = {
      business_unit: selectBuFilter.bu_id ? selectBuFilter.bu_id : [],
      service: selectedServiceIds,
      targetValue: this.state?.targetValue,
      month: month,
      year: year,
    };

    // Make the API call and wait for the response
    try {
      await this.props.saveMsSummaryTargetValue(reportData).then((response) => {
        this.setState({
          loading: false,
          msSummaryTargetValue: this.props.targetvalueReponse,
        }, () => {
          this.props.summaryViewApi();
        });
        this.ToastAlert('success', this.props?.targetvalueReponse);
      }, (error) => {
        this.setState({
          loading: false,
          MsSummaryViewData: [],
        });
        this.ToastAlert("error", error);
      });
    } catch (error) {
      this.ToastAlert('error', "something went wrong");
    }

  }

  render() {
    return (
      <div className="table-responsive white-table-class pmo-report">
        <div className="scrollable">
          <div className="scrollable-content">
            {this.props.loading && (
              <div id="preloader">
                <div id="status"></div>
              </div>
            )}
            {this.state.isOpen ? (
              <TargetPopUp
                closeModal={(e) => this.closeModal(e)}
                isOpen={this.state.isOpen}
                dataKey={this.state.selectedDataKey}
                quarter={this.state.selectedMonth}
                handleSubmit={(e) => this.handleSubmit(e)}
                MsSummaryViewData={this.props.MsSummaryViewData}
                isAllValue={this.props.allValue}
              />
            ) : null}
            {!this.props.loading && this.props.MsSummaryViewData && Object.keys(this.props.MsSummaryViewData).length > 0 &&
              <table className="table m-b-0 table-wrap">
                {/* <tbody> */}
                {Object.entries(this.props.MsSummaryViewData).map(([quarter, quarterData]) => (
                  <div key={quarter}>
                    <h4 className='quarter-heading'>{quarter}</h4>
                    <table className="table m-b-0 table-wrap">
                      <thead>
                        <tr className="theadInner busi_unit_bg">
                          <th className="text-start" style={{ width: "5%" }}>Month</th>
                          <th className="text-start" style={{ width: "5%" }}>Ms Allocation Value</th>
                          <th className="text-start" style={{ width: "5%" }}>Target</th>
                          <th className="text-start" style={{ width: "5%" }}>Difference</th>
                        </tr>
                      </thead>
                      <tbody>
                        {Object.entries(quarterData).map(([month, value]) => (
                          <tr key={month}>
                            <td className="text-start">
                              <span>{month}</span>
                            </td>
                            <td className="text-start">
                              <span>{value.totalAllocationValue}</span>
                            </td>
                            <td className="text-start">
                              <>
                                <button
                                  className="fs-16 fa fa-edit"
                                  // disabled={(this.props.allValue?.selectBuFilter?.length === 0 && this.props.allValue?.selectService?.length === 0)}
                                  onClick={(e) => this.openModal(e, value, quarter, month)}
                                ></button>
                                &nbsp;&nbsp;&nbsp;{value.targetValue}
                              </>
                            </td>
                            <td className="text-start">
                              {value.targetValue - value.totalAllocationValue}
                            </td>
                          </tr>
                        ))}
                        <tr style={{ backgroundColor: 'rgb(161 197 228)' }}  >
                          <td className="text-start" >Total</td>
                          <td className="text-start">
                            {
                              Object.keys(quarterData).length > 0
                              && Object.entries(quarterData).reduce((sum, [key, value]) => {
                                return sum + value.totalAllocationValue;
                              }, 0)
                            }
                          </td>
                          <td className="text-start">
                            {
                              Object.keys(quarterData).length > 0
                              && Object.entries(quarterData).reduce((sum, [key, value]) => {
                                return sum + parseInt(value.targetValue);
                              }, 0)
                            }
                          </td>
                          <td className="text-start">
                            {
                              Object.keys(quarterData).length > 0
                              && Object.entries(quarterData).reduce((sum, [key, value]) => {
                                return sum + value.totalAllocationValue - value.targetValue;
                              }, 0)
                            }
                          </td>
                        </tr>
                        <tr>
                          <td className="text-start" >Average</td>
                          <td className="text-start">
                            {
                              Object.keys(quarterData).length > 0
                              && Object.entries(quarterData).reduce((sum, [key, value]) => {
                                return sum + value.totalAllocationValue / 3;
                              }, 0).toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                              })
                            }
                          </td>
                          <td className="text-start"> {
                            Object.keys(quarterData).length > 0
                            && Object.entries(quarterData).reduce((sum, [key, value]) => {
                              return sum + value.targetValue / 3;
                            }, 0).toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2
                            })
                          }</td>
                          <td className="text-start">{
                            Object.keys(quarterData).length > 0
                            && Object.entries(quarterData).reduce((sum, [key, value]) => {
                              return sum + (value.totalAllocationValue - value.targetValue) / 3;
                            }, 0).toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2
                            })
                          }</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                ))}
                {/* </tbody> */}
              </table>
            }
          </div>
        </div >
      </div >
    )
  }

}


const mapStateToProps = state => {
  return {
    targetvalueReponse: state.msAllocationReportDetails.msSummaryTargetValue,
  };
};
const mapDispatchToProps = {
  getCurrentMonthProjectName,
  getPotentialProjectList,
  getReportFilter,
  getReportFilterPmo,
  getLoginUser,
  getLoginUserPermission,
  saveMsSummaryTargetValue,
};



export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MsSummaryView);