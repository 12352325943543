import { postData } from "../Services/Helper";
import { handleResponse } from "../Services/HandleResponse";
import { getBackEndConstant } from "../Config/Constant";
import * as types from "../Utilities/Type";

export function getNotifications(dataObj) {
  return (dispatch) => {
    const requestOptions = postData(dataObj);
    let url = getBackEndConstant().backend_url + "get_notifications";
    return fetch(url, requestOptions)
      .then((response) => handleResponse(response))
      .then((response) => {
        dispatch({
          type: types.GET_NOTIFICATIONS,
          payload: response.result ? response.result : [],
        });
      });
  };
}

export function markReadNotification(dataObj) {
  return (dispatch) => {
    const requestOptions = postData(dataObj);
    let url = getBackEndConstant().backend_url + "mark_read_notification";
    return fetch(url, requestOptions)
      .then((response) => handleResponse(response))
      .then((response) => {
        dispatch({
          type: types.MARK_NOTIFICATION,
          payload: response.result ? response.result : {},
        });
      });
  };
}