import React from "react";
import { Urls } from "../../Config/Config";
import "../Reports/Report.css";
import Select from "react-select";
import {
  Row,
  Col,
  Button,
  Tabs,
  Tab,
} from "react-bootstrap";
import moment from "moment";
import Swal from "sweetalert2";
import Cookies from "js-cookie";
import PageNotFound from "../Reports/PageNotFound";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  getLoginUser, getReportFilter, getLoginUserPermission, msAllocationExportExcel, getMsAllocationReportData, getMsSummaryReport, getReportFilterPmo, getCurrentMonthProjectName, getPotentialProjectList, msPotentialExportExcel,
} from "../../Action";
import { connect } from "react-redux";
import * as constants from "../../Config/Constant";
import { getReportFilterService, getUserService } from "../../Utilities/Helper";
import { Dropdown } from "rsuite";
import { getBackEndConstant } from "../../Config/Constant";
import "rsuite/dist/rsuite.min.css";
import accessdenied from "../../access-denied.png";
import MsSummaryView from "./MsSummaryView";
import QuarterCalendarPicker from "./QuarterCalendarPicker";
import { MsDetailedView } from "./MsDetailedView";
import MsPotentialView from "./MsPotentialView";
import TargetPopUp from "./TargetPopUp";
import AddPotentialPopUp from "./AddPotentialPopUp";



var _ = require('lodash');

const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  showCloseButton: true,
  customClass: {
    popup: "colored-toast",
    icon: "white-toast-icon",
  },
  timer: 3000,
  onOpen: (toast) => {
    toast.addEventListener("mouseenter", Swal.stopTimer);
    toast.addEventListener("mouseleave", Swal.resumeTimer);
  },
});

class MsAllocationReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      queryParams: new URLSearchParams(window.location.search),
      redirect: 0,
      current_report: Urls.reports_lists[11],
      userTypes: Urls.user_types,
      filter_start_date: new Date(),
      filter_end_date: new Date(),
      sname: [],
      sname1: [],
      report_practice: [],
      report_service: [],
      practiceHead: [],
      loginUserData: {},
      loginUserPermission: {},
      token: "",
      loading: false,
      start_month: moment().utcOffset("+05:30").format("YYYY-MM"),
      end_month: moment().utcOffset("+05:30").format("YYYY-MM"),
      bu_id: "",
      selectService: [],
      selectBu: [],
      selectBuFilter: [],
      reportList: [],
      serviceName: [],
      checkedList: [],
      reportF: 0,
      usersService: [],
      msAllocationReportData: [],
      MsSummaryViewData: [],
      totalAllocationValue: 0,
      msAllocationReportDataClone: [],
      report: {
        service: []
      },
      reportService: [],
      totalDevelopers: 0,
      detailed_view: 'ms_summary_view',
      filterData: {},
      potentialPopUp: true,
      potentialCurrentProjectName: [],
      isModalClose: false,
      potentialProjectList: [],
      filterStatus: 0,
      potentialProjectListClone: {},
      totalPotential: 0,
      potentialStatusFilter: getBackEndConstant().potentialStatusFilter,
      defaultValue: 'All',

    };
    this.getMspotentialView = this.getMspotentialView.bind(this);
  }

  reset_page = () => {
    this.setState({
      report: {
        service: [],
        practice: [],
      },
      selectService: [],
      searchBy: '',
      reportService: []
    }, () => {
      this.getMsAllocationReportData(this.state);
    });
  };

  setStartDateForFilter = (e) => {
    let today = new Date(e);
    this.setState({
      filter_start_date: today,
    }, () => {
      if (this.state.detailed_view != 'ms_potential_view') {
        this.businessFilter();
      }
      this.getCurrentPotentialProjectName();
      if (this.state.detailed_view === 'ms_potential_view') {
        this.setState({ loading: true, filterStatus: 0 }, () => {
          this.getMspotentialView(this.state);
        });
      }
      else if(this.state.detailed_view === 'ms_detailed_view'){
        this.setState({ loading: true, filterStatus: 0 }, () => {
          this.getDetailedView();
        });
      }
    });

  };

  componentDidMount() {
    let token = this.state.queryParams.get("token") == null ? Cookies.get("auth_data") :
      this.state.queryParams.get("token");
    this.setState({
      token: token,
      apiLink: constants.API_LINK,
      angularUrl: constants.FRONT_LINK,
      excelUrl: constants.EXCEL_LINK
    });
    this.getUser();

    this.getCurrentPotentialProjectName();
  }

  /**
   * Get the login user details
   */
  getUser = () => {
    this.setState({ loading: true });
    this.props.getLoginUser().then((response) => {
      this.props.getLoginUserPermission().then((response) => {
        this.setState({
          loading: false, loginUserData: this.props.loginDetails.loginDetails,
          loginUserPermission: this.props.loginDetails.loginUserPermission
        });
        this.getPracticeService();
      });
    });
  }

  /* filter practice based on service and head */
  filterService = (e) => {
    this.state.searchBy = '';
    if (e) {
      let selectedOption = e;
      this.setState({
        report: {
          service: selectedOption,
        },
        selectService: selectedOption,
      }, () => {
        this.getMsAllocationReportData(this.state);
      });
    }
    else {
      this.getMsAllocationReportData();
    }
  };


  /* handle date change function  */
  handleDateChange = async (startDate, endDate) => {
    let data = {}, reportData = {};
    reportData.business_unit = this.state.bu_id;
    let ser = [];
    if (this.state.report.service.length > 0) {
      reportData.service = [this.state.report.service[0].id]
      this.state.report.service.forEach(svalue => {
        ser.push(svalue.id)
      })
      reportData.service = ser;
    }
    else {
      // getting user associated service list
      reportData.service = getReportFilterService(this.state.serviceName, this.state.loginUserData, this.state.usersService);
    }
    reportData.start_date = startDate ? startDate : '';
    reportData.end_date = endDate ? endDate : '';
    data['reportData'] = reportData;
    this.setState({
      filterData: data,
    });
    return data;
  };
  getMsSummaryReportData = async () => {
    try {
      const data = await this.handleDateChange(this.state.filterData?.reportData?.start_date, this.state.filterData?.reportData?.end_date, '');
      this.setState({ loading: true });
      await this.props.getMsSummaryReport(data).then((response) => {
        this.setState({
          loading: false,
          MsSummaryViewData: this.props.MsSummaryViewDetails,
        });
      }, (error) => {
        this.setState({
          loading: false,
          MsSummaryViewData: [],
        });
        this.ToastAlert("error", error);
      });
    } catch (error) {
      this.setState({
        loading: false,
      });
      this.ToastAlert('error', error);
    }
  }

  getDetailedView = async () => {
    try {
      let dataObj = this.getInputData();
      this.setState({ loading: true });
      await this.props.getMsAllocationReportData(dataObj).then((response) => {
        let sortReport = _.sortBy(this.props.msAllocationReportDetails.milestoneReport,
          [function (data) { return data.projectName; }]);
        this.setState({
          loading: false,
          msAllocationReportData: sortReport,
          msAllocationReportDataClone: sortReport,
          totalAllocationValue: this.props.msAllocationReportDetails.totalAllocationValue,
          totalDevelopers: this.props.msAllocationReportDetails.totalDevelopers
        });
      }, (error) => {
        this.setState({
          msAllocationReportData: [],
          msAllocationReportDataClone: [],
          totalAllocationValue: 0,
          totalDevelopers: 0
        });
        this.ToastAlert("error", error);
      });
    } catch (error) {

      this.ToastAlert('error', "something went wrong");
    }
  }

  /* action file backend api call */
  getMsAllocationReportData = async () => {
    if (this.state.detailed_view == 'ms_detailed_view') {
      this.setState({
        loading: true,
      })
      await this.getDetailedView();
    }
    if (this.state.detailed_view == "ms_summary_view") {
      this.setState({
        loading: true,
      })
      await this.getMsSummaryReportData();
    }
    await this.getMspotentialView();

  }

  /* generate ms allocation report excel function  */
  generateMsAllocationReportAsExcel = (e) => {
    e.currentTarget.blur(); // removing focus
    let dataObj = this.getInputData();
    this.setState({ loading: true });
    this.props.msAllocationExportExcel(dataObj).then((response) => {
      this.setState({ loading: false });
      this.ToastAlert("success", "Report generated Successfully");
      document.location = this.state.excelUrl + this.props.msAllocationExport.filename;
    }, (error) => {
      this.setState({ loading: false });
      this.ToastAlert("error", error);
    });
  };

  /* generate ms potential report as excel */
  generateMsPotentialReportAsExcel = (e) => {
    e.currentTarget.blur(); // removing focus
    const startDate = this.state.filter_start_date;
    const payload = {
      start_date: startDate,
    };
    this.setState({ loading: true });
    this.props.msPotentialExportExcel(payload).then((response) => {
      this.setState({ loading: false });
      this.ToastAlert("success", "Report generated Successfully");
      document.location = this.state.excelUrl + this.props.potentialExport.filename;
    }, (error) => {
      this.setState({ loading: false });
      this.ToastAlert("error", error);
    });
  };

  /* for get input Date for Ms alloction api call */
  getInputData = () => {
    let dataObj = {}, reportData = {};
    reportData.business_unit = this.state.bu_id;
    let ser = [];
    if (this.state.report.service.length > 0) {
      reportData.service = [this.state.report.service[0].id]
      this.state.report.service.forEach(svalue => {
        ser.push(svalue.id)
      })
      reportData.service = ser;
    }
    else {
      // getting user associated service list
      reportData.service = getReportFilterService(this.state.serviceName, this.state.loginUserData, this.state.usersService);
    }
    reportData.start_date = moment(this.state.filter_start_date)
      .utcOffset('+05:30')
      .format('YYYY-MM-01');
    reportData.end_date = moment(this.state.filter_end_date)
      .utcOffset('+05:30')
      .format('YYYY-MM-01');
    dataObj['reportData'] = reportData;
    return dataObj;
  }

  /* toast message function  */
  ToastAlert(icon, title) {
    return Toast.fire({
      target: document.getElementById("form-modal"),
      icon: `${icon}`,
      title: `${title}`,
    });
  }

  resetReport = (e) => {
    e.currentTarget.blur(); // removing focus
    this.setState({
      searchBy: '',
    });
    if (moment(this.state.filter_start_date)
      .utcOffset("+05:30")
      .format("YYYY-MM-01") >= getBackEndConstant().filterDate) {
      this.getMsAllocationReportData();
    } else {
      this.getPracticeService();
    }
  };

  potentialGenerate = (e) => {
    e.currentTarget.blur(); // removing focus
    this.setState({
      searchBy: '',
    });

    var start_date = moment(this.state.filter_start_date)
      .utcOffset("+05:30")
      .format("YYYY-MM-01") >= getBackEndConstant().filterDate;
    var end_date = moment(this.state.filter_start_date)
      .utcOffset("+05:30")
      .format("YYYY-MM-31") >= getBackEndConstant().filterDate;
    if (start_date >= getBackEndConstant().filterDate) {
      this.getMsAllocationReportData()
    }

  };

  searchBy = (e) => {
    let searchInput = e.target.value;
    let filteredData = [];
    if (searchInput) {
      filteredData = this.state.msAllocationReportDataClone.filter((item) => {
        return Object.values(item).join('').toLowerCase().includes(searchInput.toLowerCase())
      })
      this.setState({
        searchBy: searchInput
      });
    }
    else {
      this.setState({
        searchBy: ''
      });
      filteredData = this.state.msAllocationReportDataClone;
    }
    let totalMs = _.sumBy(filteredData, 'userMilestoneValue');
    let totalCount = _.sumBy(filteredData, 'developerCount');
    totalCount = (totalCount == undefined) ? 0 : totalCount;
    this.setState({
      msAllocationReportData: filteredData,
      totalAllocationValue: totalMs,
      totalDevelopers: totalCount
    })
  }

  potentialSearchBy = (e) => {
    let searchInput = e.target.value;
    let filteredData = [];
    const potentialProjectListClone = this.props.potentialPopUp;
    if (searchInput) {
      filteredData = this.state.msAllocationReportDataClone.filter((item) => {
        return Object.values(item).join('').toLowerCase().includes(searchInput.toLowerCase())
      })
      this.setState({
        searchBy: searchInput
      });
    }
    else {
      this.setState({
        searchBy: ''
      });
      filteredData = this.state.msAllocationReportDataClone;
    }
    let totalMs = _.sumBy(filteredData, 'userMilestoneValue');
    let totalCount = _.sumBy(filteredData, 'developerCount');
    totalCount = (totalCount == undefined) ? 0 : totalCount;
    this.setState({
      msAllocationReportData: filteredData,
      totalAllocationValue: totalMs,
      totalDevelopers: totalCount
    })
  }

  businessFilter = () => {
    let dateValue = new Date();
    let date = new Date(dateValue.getFullYear(), dateValue.getMonth(), 2);
    let start_date, end_date;
    if (this.state.detailed_view === 'ms_summary_view') {
      start_date = date;
      end_date = date;
    } else {
      start_date = this.state.filter_start_date || date;
      end_date = this.state.filter_end_date || date;
    }
    // dataObject.filter = isAllPractice;
    let dataObject = {
      start_date: start_date,
      end_date: end_date,
    };
    let c = 0;
    this.setState({
      loading: true,
    });
    this.props.getReportFilterPmo(dataObject).then((response) => {
      this.props.pmoFilter.directors.forEach((value) => {
        if (this.state.bu_id == value.bu_id) {
          c = 1;
        }
      });

      let bu_id = c == 1 ? this.state.bu_id : "";
      let selectBuFilter = c == 1 ? this.state.selectBuFilter : [];
      let sname = [];
      let allService = [], serviceName = [];
      if (
        this.state.bu_id != "" &&
        this.state.bu_id != undefined &&
        this.state.bu_id != 0
      ) {
        this.props.pmoFilter.services.forEach((service) => {
          if (this.state.bu_id == service.bu) {
            sname.push(service);
          }
        });
        this.props.pmoFilter.allservices.forEach((service) => {
          if (this.state.bu_id == service.bu) {
            allService.push(service);
          }
        });
      } else {
        sname = this.props.pmoFilter.services;
        allService = this.props.pmoFilter.allservices;
      }

      this.setState({

        usersService: this.props.pmoFilter.services,
        sname1: this.props.pmoFilter.services,
        bu_id: bu_id,
        selectBuFilter: selectBuFilter,
        sname: sname,
        allService: allService
      })

      /* if (this.state.detailed_view != 'ms_summary_view') {
        let result = allService.filter(aservice => this.state.selectService.some(selectedService => aservice.value === selectedService.value));

        if (result?.length == 0) {
          this.setState({
            selectService: [],
            selectBuFilter: []
          });
        }
      } */

      this.state.sname1.forEach((value) => {
        let opt = {};
        opt.label = value.value;
        opt.id = value.id;
        opt.value = value.value;
        opt.head = value.head;
        opt.bu = value.bu;
        serviceName.push(opt);
      });
      // for practicehead
      let practiceHead = [];
      this.props.pmoFilter.directors.forEach((id) => {
        practiceHead.push(id);
      });
      let buName = [];
      this.props.pmoFilter.directors.forEach((value) => {
        let opt = {};
        opt.label = value.bu;
        opt.value = value.bu;
        opt.bu_id = value.bu_id;
        opt.id = value.id;
        opt.bu = value.bu;
        opt.name = value.name;
        buName.push(opt);
      });
      this.setState({
        practiceHead: practiceHead,
        buName: buName,
        report_all_practices: this.props.pmoFilter.practices,
        qes_list: this.props.pmoFilter.qes_list,
        serviceName: serviceName
      });
      if (this.state.loginUserData.role_id == 8) {
        this.setState({
          searchByPracticeHead: this.state.loginUserData.role_id,
        })
      }
    }, (error) => {
      this.setState({
        usersService: [],
        sname1: [],
        bu_id: '',
        selectBuFilter: [],
        sname: [],
        allService: []
      })
    });
  }

  /* get the service filter value */
  getPracticeService = async (isAllPractice) => {
    this.setState({
      loading: true,
      isAllPractices: isAllPractice,
      filterService: [],
      report: {
        service: []
      },
      sname: [],
      allService: [],
      serviceName: [],
    });
    await this.businessFilter();
    await this.getMsAllocationReportData();
  };

  getReportByHead = (e) => {
    this.state.searchBy = '';
    if (e != undefined) {
      this.setState({
        bu_id: e.bu_id,
        head_id: e.label,
        selectBuFilter: e
      }, () => {
        this.getBuFilter(this.state);
      });
    } else {
      this.setState({
        bu_id: '',
        head_id: 'All',
        selectBuFilter: []
      }, () => {
        this.getBuFilter(this.state);
      });
    }
  };

  /* business unit filter function  */
  getBuFilter = () => {
    let serviceArray = [];
    let service_head = [];
    if (this.state.head_id != "All") {
      // for BU filter    
      this.state.sname1.forEach((service) => {
        if (this.state.bu_id == service.bu) {
          service_head.push(service);
        }
      });
      this.setState({
        serviceName: [],
        selectService: [],
        report: {
          service: []
        }
      })
    } else {
      // when All BU is selected
      this.setState({
        serviceName: [],
        selectService: [],
        selectBuFilter: []
      })
      if (this.state.report.service.length == 0) {
        this.state.sname1.forEach((service) => {
          service_head.push(service);
        });
      } else {
        service_head = this.state.sname;
        this.setState({
          report: {
            service: []
          }
        })
      }
    }
    service_head.forEach((value) => {
      let opt = {};
      opt.label = value.value;
      opt.id = value.id;
      opt.value = value.value;
      opt.head = value.head;
      opt.bu = value.bu;
      serviceArray.push(opt);
    });
    this.setState({
      serviceName: serviceArray,
    }, () => {
      this.getMsAllocationReportData(this.state);
    });
  }

  /* tab change get value function */
  changeView = async (flag) => {
    if (flag === 'ms_summary_view') {
      if (moment(this.state.filter_start_date).utcOffset("+05:30").format("YYYY-MM-01") < getBackEndConstant().filterDate) {
        const resetState = {
          selectService: [],
          selectBuFilter: [],
        };

        this.setState({
          detailed_view: flag,
        })
        await this.setState({
          ...resetState,
          bu_id: "",
          report: {
            ...this.state.report,
            service: [],
            // Update other nested state properties if needed
          },
        });
      }

      this.businessFilter();
      this.getMsSummaryReportData();
    } else if (flag === 'ms_detailed_view') {
      if (moment(this.state.filter_start_date).utcOffset("+05:30").format("YYYY-MM-01") < getBackEndConstant().filterDate) {
        const resetState = {
          selectService: [],
          selectBuFilter: [],
        };
        await this.setState(resetState);
      }

      this.setState({
        detailed_view: flag,
      })
      this.businessFilter();
      this.getDetailedView();
    } else if (flag === 'ms_potential_view') {
      if (moment(this.state.filter_start_date).utcOffset("+05:30").format("YYYY-MM-01") < getBackEndConstant().filterDate) {
        this.state.filter_start_date = new Date();
      }

      this.setState({
        filter_start_date: this.state.filter_start_date,
        detailed_view: flag,
      })
      this.businessFilter();
      this.getMspotentialView();
    }

    this.setState({
      detailed_view: flag,
    });
  };


  getQuarter = (date) => {
    const month = date.getMonth();
    if (month >= 0 && month <= 2) {
      return 1; // Q1: January to March
    } else if (month >= 3 && month <= 5) {
      return 2; // Q2: April to June
    } else if (month >= 6 && month <= 8) {
      return 3; // Q3: July to September
    } else if (month >= 9 && month <= 11) {
      return 4; // Q4: October to December
    } else {
      return 0; // Invalid month
    }
  };

  getQuarterStartDate = (date) => {
    const quarter = this.getQuarter(date);
    const financialStartMonth = 3; // April (0-indexed month)
    const adjustedMonth = (quarter - 1) * 3 + financialStartMonth;
    return new Date(date.getFullYear(), adjustedMonth, 1);
  };

  getQuarterEndDate = (date) => {
    const quarter = this.getQuarter(date);
    const financialStartMonth = 3; // April (0-indexed month)
    const adjustedMonth = quarter * 3 + financialStartMonth - 1;
    return new Date(date.getFullYear(), adjustedMonth + 1, 0);
  };

  getCurrentPotentialProjectName = () => {
    const data = this.getCurrentMonthStartAndEndDates();
    this.props.getCurrentMonthProjectName(data).then((response) => {
      this.setState({
        potentialCurrentProjectName: this.props.potentialCurrentProjectName
      });
    }, (error) => {
      this.setState({
        potentialCurrentProjectName: [],
      });
      this.ToastAlert("error", error);
    });
  };

  /* get curren month start date function  */
  getCurrentMonthStartAndEndDates = () => {
    const startDate = moment().startOf('month').format('YYYY-MM-01');
    const endDate = moment().endOf('month').format('YYYY-MM-DD');
    return {
      start_date: startDate,
      end_date: endDate,
    };
  };

  /* add potential popup show  */
  addPotentialPopUp = (e) => {
    this.setState({
      potentialPopUp: true,
    });
  };

  /* add potential popup close  */
  closePotentialModal = (e) => {
    this.setState({
      potentialPopUp: false,
    });
  };

  getMspotentialView = async () => {
    this.setState({
      loading: true,
    })
    const startDate = moment(this.state.filter_start_date)
      .utcOffset('+05:30')
      .format('YYYY-MM-01');
    const endDate = moment(startDate)
      .utcOffset('+05:30')
      .endOf('month')
      .format('YYYY-MM-DD');
    let payload = {
      start_date: startDate,
      end_date: endDate,
    };
    try {
      await this.props.getPotentialProjectList(payload);
      this.setState({
        loading: false,
        potentialProjectList: this.props.potentialProjectList,
        potentialProjectListClone: this.props.potentialProjectList,
      });
      const sumOfPotentialValues = this.state.potentialProjectList.reduce((sum, current) => {
        return sum + current.potential_value;
      }, 0);
      this.setState({
        totalPotential: sumOfPotentialValues,
        filterStatus: 0,
      });
    } catch (error) {
      this.setState({
        potentialProjectList: {},
        filterStatus: 0,
      });
      this.ToastAlert('error', error);
    }
  };


  handleStatusFilter = (e) => {
    const dropDownValue = parseInt(e.value);
    let statusFilter = [];
    if (dropDownValue === 0) {
      statusFilter = [...this.state.potentialProjectListClone];
    } else {
      statusFilter = Object.values(this.state.potentialProjectListClone).filter(item => item.status === dropDownValue);
    }
    const totalPotential = statusFilter.reduce((sum, current) => {
      return sum + current.potential_value;
    }, 0);
    this.setState({
      filterStatus: e,
      potentialProjectList: statusFilter,
      totalPotential: totalPotential,
    });
  };


  /* showPotentialView() {
    this.setState({
      detailed_view: 'ms_potential_view',
    })
    if (this.state.activeTab === 'ms_potential_view') {
      return (
        <MsPotentialView
          isPotentialData={this.state.potentialProjectList}
          isStartDate={this.state.filter_start_date}
          GridFunction={this.getMspotentialView}
        />
      );
    } else {
      // You can return another component or null if needed
      return null;
    }
  } */


  redirectToViewPotential = () => {
    this.setState({
      potentialPopUp: true,
    })
    this.changeView('ms_potential_view');
  };




  /**
  * main render
  * @returns
  */
  render() {
    let quarterValue = Object.keys(this.state?.MsSummaryViewData);
    let selectedQuarter = quarterValue.length == 4 ? 'All' : quarterValue[0];
    let selectedYearQuarter = selectedQuarter == 'All' ? 'Q1' : selectedQuarter;
    const years = [...new Set(
      Object.keys(this.state?.MsSummaryViewData[selectedYearQuarter] || {}) // Check if MsSummaryViewData and quarterValue are defined
        .map(key => key.split(' ')[1])
        .filter(year => year) // Filter out undefined or empty years
    )];

    if (this.state.redirect == 1 || this.state.loginUserData == null) {
      return <PageNotFound url={this.state.angularUrl} />
    }
    else {
      return (
        <>
          <div className={"page-content px-4 "}>
            {this.state.loading && (
              <div id="preloader">
                <div id="status"></div>
              </div>
            )}
            <Row>
              <div className="col-xs-12 fourty-fluid fourty-fluid">
                <Row className="mb-3">
                  <div className="col-sm-4 col-sm-4 col-xs-12 text-left">
                    <h1 className="B-G-bold f-s-18 d-gray t-t-upper pull-left mt-10 mr-20 page-head">Reports</h1>
                  </div>
                  <div className="col-lg-3 col-sm-4 col-xs-12">
                    <div className="selectpicker_dropdown hide_cross hide_bar orange_arrow remove_border_btm select_dropdown_picker">
                      <Dropdown title={this.state.current_report.value} activeKey="12">
                        <Dropdown.Item as="a" eventKey="1" href="/pw_report" target="_blank">Practice Wise Allocation Report</Dropdown.Item>
                        <Dropdown.Item as="a" eventKey="2" href="/ms_report" target="_blank">Milestone Value Report</Dropdown.Item>
                        <Dropdown.Item as="a" eventKey="3" href="/collections_report" target="_blank">Collections Report</Dropdown.Item>
                        <Dropdown.Item as="a" eventKey="4" href="/collections_potential_report" target="_blank">Collection Potential Report</Dropdown.Item>
                        <Dropdown.Item as="a" eventKey="5" href="/inventory_report" target="_blank">Inventory Report</Dropdown.Item>
                        <Dropdown.Item as="a" eventKey="6" href="/inventory_allocation_report" target="_blank">Inventory Allocation Metrics</Dropdown.Item>
                        <Dropdown.Item as="a" eventKey="7" href="/inventory_milestone_report" target="_blank">Inventory Milestone Metrics</Dropdown.Item>
                        <Dropdown.Item as="a" eventKey="8" href="/utilization_report" target="_blank">Resource Utilization Report</Dropdown.Item>
                        <Dropdown.Item as="a" eventKey="9" href="/csm_report" target="_blank">Csm Report</Dropdown.Item>
                        <Dropdown.Item as="a" eventKey="10" href="/pmo_ms_report" target="_blank">Pmo Ms Report</Dropdown.Item>
                        <Dropdown.Item as="a" eventKey="11" href="/acv_report" target="_blank">Annual Contract Value Report</Dropdown.Item>
                        <Dropdown.Item as="a" eventKey="12">MS Allocation Report</Dropdown.Item>
                      </Dropdown>
                    </div>
                  </div>
                </Row>
                <Row>
                  {this.state.detailed_view != 'ms_potential_view' &&
                    <div className="col-lg-6 clearfix m-b-20">
                      <div className="row align-items-center">
                        <div class="col-sm-4 col-xs-12 text-left ">
                          <label class="text-uppercase m-b-0 fs-13 p-t-5">Line of Service:</label>
                        </div>
                        <div className="col-lg-7 col-sm-5 text-left ">
                          <Row>
                            <div className="col-lg-10 p-l-0">
                              <div className="selectpicker_dropdown w-100 hide_bar w-100 selectpicker_dropdown_orange">
                                <Select
                                  className="basic-single-select"
                                  classNamePrefix={"select_dropdown"}
                                  placeholder="All"
                                  isClearable
                                  isSearchable={true}
                                  onChange={(e) => this.filterService(e)}
                                  value={this.state.selectService}
                                  options={this.state.serviceName}
                                  isDisabled={this.state.detailed_view == 'ms_potential_view'} isMulti>
                                </Select>
                              </div>
                            </div>
                            <div className="col-lg-2 p-1-0">
                              {this.state.report.service != undefined && this.state.report.service.length > 0 &&
                                <a href="javascript:void(0)" title="Reset" tooltip-placement="top" className="reset-icon_old  reset-color p-l-5 m-l-4" onClick={this.reset_page}><i className="fs-16 fa fa-refresh"></i></a>
                              }
                            </div>
                          </Row>
                        </div>
                      </div>
                    </div>
                  }
                  {this.state.loginUserData.role_id != 8 && this.state.detailed_view != 'ms_potential_view' &&
                    <div className="col-lg-4 clearfix m-b-20">
                      <div className="row">
                        <div className="col-sm-5 col-xs-12">
                          <label className="text-uppercase m-b-0 fs-13 p-t-5">Business Unit :</label>
                        </div>
                        <div className="col-sm-7 col-xs-12">
                          <div className="selectpicker_dropdown hide_bar w-100 selectpicker_dropdown_orange">
                            <Select
                              className="basic-single-select"
                              classNamePrefix={"select_dropdown"}
                              placeholder="All"
                              isClearable
                              isSearchable={true}
                              onChange={(e) => this.getReportByHead(e)}
                              value={this.state.selectBuFilter}
                              options={this.state.buName}
                              isDisabled={this.state.detailed_view == 'ms_potential_view'} >
                            </Select>
                          </div>
                        </div>
                      </div>
                    </div>
                  }
                  {this.state.detailed_view == 'ms_potential_view' &&
                    <Row className=" mt-3 mb-3">
                      <Col className="col-lg-12 text-start col p-0">
                        <Row className="m-0 mt-1">
                          <Col className="col-lg-1.5 text-start">
                            <label className="p-0 B-G-bold f-s-2 d-gray m-t-3 m-b-10 page-head pull-left">Month Filter:</label>
                          </Col>
                          <Col className="col-lg-1.5">
                            <div className="row align-items-center mb-2 date_picker_align">
                              <DatePicker
                                className="datepicker-control-section"
                                onChange={this.setStartDateForFilter}
                                dateFormat="MM/yyyy"
                                showMonthYearPicker
                                todayButton={"Today"}
                                name="start_date"
                                selected={this.state.filter_start_date}
                                minDate={new Date(2022, 3, 1)}
                              />
                              <span className="cal_icon_date" role="button" tabIndex="0">
                                <i className="icon-calendar"></i>
                              </span>
                            </div>
                          </Col>
                          <Col className="col-lg-5">
                            <Button onClick={(e) => this.getMspotentialView()} value="Generate" className="me-2" >Generate</Button>
                            <Button onClick={(e) => this.generateMsPotentialReportAsExcel(e)} value="Excel" className="me-2" disabled={this.state.potentialProjectList.length == 0 ? true : false}>Export as Excel</Button>
                          </Col>
                          <Col className="col-lg-4 text-end">
                            <a href={this.state.angularUrl} className="btn btn-primary">Back</a>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  }
                  {this.state.detailed_view != 'ms_potential_view' &&
                    <div className="col-lg-2 col-sm-2 col-xs-12 justify-content-end text-end">
                      <a href={this.state.angularUrl} className="btn btn-primary">Back</a>
                    </div>
                  }

                </Row>
                <hr className="m-t-5 col-sm-12 m-b-8 p-0"></hr>
                {this.state.detailed_view === 'ms_summary_view' &&
                  <div className="col-lg-12 col-sm-12 col-xs-12 p-b-15-xs p-r-15-xs">
                    <Row className=" mt-3 mb-3">
                      <Col className="col-lg-8 text-start col p-0">
                        <Row className="m-0 mt-1">
                          <Col className="col-lg-2 p-r-2 general-content text-left">
                            <label className="msallocation--report-year-col-quarter">Quarter :</label>
                          </Col>
                          <Col className="col-lg-2">
                            <div className="row align-items-center mb-2 date_picker_align">
                              <div className="p-r-15">
                                <QuarterCalendarPicker onDateChange={this.handleDateChange}
                                  defaultQuarter={selectedQuarter}
                                  selectQuarter={this.state.selectQuarter}
                                  defaultYear={years[0]}
                                /* changeQuarter={(data) => {
                                  this.setState({
                                    selectQuarter: data
                                  })
                                }
                                } */
                                />
                              </div>
                            </div>
                          </Col>
                          <Col className="col-lg-5 pull left msallocation--report-generate">
                            <Button onClick={(e) => this.getMsAllocationReportData()} value="Generate" className="me-2" >Generate</Button>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </div>
                }
                {this.props.loginDetails.loginUserPermission && this.props.loginDetails.loginUserPermission.permission && this.props.loginDetails.loginUserPermission.permission.view_pmo_report &&
                  <>
                    {this.state.detailed_view === 'ms_detailed_view' &&
                      <div className="col-lg-12 col-sm-12 col-xs-12 p-b-15-xs p-r-15-xs">
                        <Row className=" mt-3 mb-3">
                          <Col className="col-lg-8 text-start col p-0">
                            <Row className="m-0 mt-1">
                              <Col className="col-lg-2 text-start">
                                <label className="p-0 B-G-bold f-s-2 d-gray m-t-3 m-b-10 page-head pull-left">Month Filter:</label>
                              </Col>

                              <Col className="col-lg-2">
                                <div className="row align-items-center mb-2 date_picker_align">
                                  <DatePicker
                                    className="datepicker-control-section"
                                    onChange={this.setStartDateForFilter}
                                    dateFormat="MM/yyyy"
                                    showMonthYearPicker
                                    todayButton={"Today"}
                                    name="start_date"
                                    selected={this.state.filter_start_date}
                                  />
                                  <span className="cal_icon_date" role="button" tabIndex="0">
                                    <i className="icon-calendar"></i>
                                  </span>
                                </div>
                              </Col>
                              <Col className="col-lg-6 text-end w-10">
                                <Button onClick={(e) => this.resetReport(e)} value="Generate" className="me-2" >Generate</Button>
                                <Button onClick={(e) => this.generateMsAllocationReportAsExcel(e)} value="Excel" className="me-2" disabled={this.state.msAllocationReportData.length == 0 ? true : false}>Export as Excel</Button>
                              </Col>
                            </Row>
                          </Col>
                          <Col className="col-lg-4 text-end">
                            <div className="form-group search-icon col-sm-12 col-xs-12">
                              <input type="text" name="project_filter" value={this.state.searchBy} className="form-control B-G-bold f-s-16 d-gray pl-30" placeholder="Search..." onChange={(e) => this.searchBy(e)} />
                              <i className="fa fa-search"></i>
                            </div>
                          </Col>
                        </Row>
                        <div className="center-block text-center">
                          <div className="col-lg-12 col-sm-12 col-xs-12 p-b-15-xs">
                            <div className="p-0 B-G-bold f-s-16 d-gray m-t-8 m-b-0 page-head">
                              <Row className="my-3">
                                <Col className="col-lg-5" style={{ "font-size": "15px" }}><div>Total MS Value: $ {this.state.totalAllocationValue}</div></Col>
                                <Col className="col-lg-6 text-right" style={{ "font-size": "15px" }}><div>Total Developers Count: {this.state.totalDevelopers}</div></Col>
                              </Row>
                            </div>
                          </div>
                        </div>
                      </div>
                    }

                    {this.state.detailed_view == 'ms_potential_view' &&
                      <div className="col-lg-12 col-sm-12 col-xs-12 p-b-15-xs p-r-15-xs">
                        <Row className=" mt-3 mb-3">
                          <Col className="col-lg-12 text-start col p-0">
                            <Row className="m-0 mt-1">
                              <Col className="col-lg-1.5 text-start">
                                <label className="p-0 B-G-bold f-s-2 d-gray m-t-3 m-b-10 page-head pull-left">Status Filter:</label>
                              </Col>
                              <Col className="col-lg-1.5">
                                <div className="selectpicker_dropdown hide_bar w-10 selectpicker_dropdown_orange">
                                  <Select
                                    className="basic-single-select"
                                    classNamePrefix={"select_dropdown"}
                                    placeholder="All"
                                    isSearchable={true}
                                    value={this.state.filterStatus}
                                    onChange={(e) => this.handleStatusFilter(e)}
                                    options={this.state.potentialStatusFilter}
                                  />
                                </div>
                              </Col>
                              <Col className="col-lg-5">
                              </Col>
                              <Col className="col-lg-4 text-end">
                                <Button onClick={(e) => this.addPotentialPopUp(e)} value="potential" className="me-2" >ADD POTENTIAL</Button>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                        <Row>
                          <div className="center-block text-center">
                            <div className="col-lg-12 col-sm-12 col-xs-12 p-b-15-xs">
                              <div className="p-0 B-G-bold f-s-16 d-gray m-t-8 m-b-0 page-head">
                                <Row className="my-3">
                                  <Col className="col-lg-6" style={{ "font-size": "15px" }}><div>Total Potential Value : $ {this.state.totalPotential}</div></Col>
                                  <Col className="col-lg-6 text-end" style={{ "font-size": "15px" }}><div>Total Projected Value : ${this.state.totalPotential + this.state.totalAllocationValue}</div></Col>
                                </Row>
                              </div>
                            </div>
                          </div>
                        </Row>

                        {this.state.potentialPopUp &&
                          <AddPotentialPopUp
                            isModalOpen={true}
                            isProjectName={this.state.potentialCurrentProjectName}
                            isLoading={true}
                            isModalClose={() => this.closePotentialModal()}
                            isEditModal={false}
                            isfilterEdit={null}
                            isStartDate={this.state.filter_start_date}
                            GridFunction={this.getMspotentialView}
                            updateForm={() => { }}
                            updateStatus={() => { }}
                          />}
                      </div>

                    }
                    <hr className="m-t-5 col-sm-12 m-b-8 p-0"></hr>
                    <div className="pro_listng bor-no">
                      <div className="invoice--report bg-transparent inventory_allo_table inventory_allo_tablereact p-l-0 p-r-0 p-t-0 p-b-0 m-t-5">
                      </div>
                      <div className="pro_listng bor-no">

                        <div className="invoice--report bg-transparent inventory_allo_table inventory_allo_tablereact p-l-0 p-r-0 p-t-0 p-b-0 m-t-5">
                          <>
                            {this.state.detailed_view === "ms_detailed_view" && <Button className="potential_button" onClick={this.redirectToViewPotential}>ADD POTENTIAL</Button>}
                            <Tabs defaultActiveKey="ms_summary_view"
                              id="uncontrolled-tab-example"
                              className="mb-3"
                              activeKey={this.state.detailed_view}
                              onSelect={(event) => {
                                this.changeView(event);
                              }}>
                              <Tab eventKey="ms_summary_view" title="Summary View">
                                <MsSummaryView MsSummaryViewData={this.state.MsSummaryViewData}
                                  loading={this.state.loading}
                                  allValue={this.state}
                                  summaryViewApi={this.getMsSummaryReportData}
                                />
                              </Tab>
                              <Tab eventKey="ms_detailed_view" title="Detailed View">
                                <MsDetailedView msAllocationReportData={this.state.msAllocationReportData} loading={this.state.loading} />

                              </Tab>
                              <Tab eventKey="ms_potential_view" title="View Potential" >
                                <MsPotentialView
                                  isPotentialData={this.state.potentialProjectList}
                                  isStartDate={this.state.filter_start_date}
                                  GridFunction={this.getMspotentialView}
                                />
                              </Tab>

                            </Tabs>

                          </>
                        </div>
                      </div>
                    </div>
                  </>
                }
                {this.props.loginDetails.loginUserPermission && this.props.loginDetails.loginUserPermission.permission && !this.props.loginDetails.loginUserPermission.permission.view_pmo_report &&
                  <div className={"page-content px-4 "}>
                    <div className="col-md-12 m-b-30">
                      <div className="four_not_err access_denied">
                        <div className="for_content">
                          <img alt="sad" width="" src={accessdenied} />
                          <h1>You don't have permission to access this page</h1>
                        </div>
                      </div>
                    </div>
                  </div>
                }
              </div>
            </Row >
          </div >
        </>
      );
    }
  }
}

const mapStateToProps = state => {

  return {
    msAllocationReportDetails: state.msAllocationReportDetails.msAllocationReport,
    msAllocationExport: state.msAllocationReportDetails.msAllocationExport,
    MsSummaryViewDetails: state.msAllocationReportDetails.msSummaryReport,
    loginDetails: state.loginDetails,
    reportFilter: state.reportFilterDetails.reportFilter,
    pmoFilter: state.reportFilterDetails.pmoFilter,
    potentialCurrentProjectName: state.msAllocationReportDetails.msPotentialProjectName,
    potentialProjectList: state.msAllocationReportDetails.msPotentialProjectList,
    potentialExport: state.msAllocationReportDetails.msPotentialExport,

  };
};

const mapDispatchToProps = {
  getMsAllocationReportData,
  getMsSummaryReport,
  msAllocationExportExcel,
  getLoginUser,
  getReportFilter,
  getLoginUserPermission,
  getReportFilterPmo,
  getCurrentMonthProjectName,
  getPotentialProjectList,
  msPotentialExportExcel,

};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MsAllocationReport);