import React, { Component } from "react";
import StarRatings from "react-star-ratings";

class StarRating extends Component {
  constructor(props) {
    super(props);

    this.state = {
      rating: props.value,
      ratingLabel: this.selectRatingLabel(props.value)
    };
  }

  changeRating = (newRating) => {
    if (!this.props.isSelectable) return;
    let ratingLabel = this.selectRatingLabel(newRating);
    if (this.props.handleCommentChange) {
    this.props.handleCommentChange(newRating);
    }
    if (this.props.handleRatingChange) {
      this.props.handleRatingChange(newRating);
    }
    if (this.props.handleManagerRatingChange) {
      this.props.handleManagerRatingChange(newRating);
    }
    if (this.props.handleSynergyLeadRatingChange) {
      this.props.handleSynergyLeadRatingChange(newRating);
    }
    // Explicitly update the state using setState callback
    this.setState((prevState) => ({
      rating: newRating,
      ratingLabel: ratingLabel
    }));
  };

  selectRatingLabel = (newRating) => {
    let ratingLabel = '';
    switch (newRating) {
      case 1: ratingLabel = 'Poor'; break;
      case 2: ratingLabel = 'Fair'; break;
      case 3: ratingLabel = 'Avg'; break;
      case 4: ratingLabel = 'Good'; break;
      case 5: ratingLabel = 'Excellent'; break;
      default:
        ratingLabel = '';
        break;
    }

    return ratingLabel;
  }

  render() {
    return (
      <div className={`ratings-wrapper`}>
        { this.props?.label ? 
            <p className="text-center mt-2 rating-label">
            {this.props?.label}
          </p> : ''
        }
        <StarRatings
          rating={this.state.rating}
          starRatedColor={this.props.starColor}
          {...(this.props.isSelectable ? {changeRating:this.changeRating} : {})}
          numberOfStars={5}
          starHoverColor={this.props.starColor}
          name="rating"
          starDimension="20px"
          starSpacing="2px"
        />
        <p className="text-center mt-2 rating-label">
          <div>{this.props?.label == 'Lead Rating' ? this.state.ratingLabel : ''}</div>
        </p>
      </div>
    );
  }
}

export default StarRating;
