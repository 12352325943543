import React, { Component } from 'react';
import Cookies from "js-cookie";
import * as constants from "../../Config/Constant";
import { connect } from "react-redux";
import { Row, Col } from "react-bootstrap";

import {
    getLoginUserPermission,
    getNotifications,
    markReadNotification
} from "../../Action";

class Notifications extends Component {
    constructor(props) {
        super(props);
        this.state = {
            queryParams: new URLSearchParams(window.location.search),
            loginUserData: {},
            loginUserPermission: {},
            loading: false,
            dataloading: false,
            isLoaded: true,
            role_id: 0,
            notificationList: [],
            totalNotificationCount: 0,
            listSkip: 0,
            listTake: 10
        };
    }

    componentDidMount() {
        let token =
          this.state.queryParams.get("token") === null
            ? Cookies.get("auth_data")
            : this.state.queryParams.get("token");
        let url =
          this.state.queryParams.get("url") == null
            ? Cookies.get("url_code")
            : this.state.queryParams.get("url");
        this.setState({
          token: token,
          apiLink: constants.API_LINK,
          angularUrl: constants.FRONT_LINK,
          excelUrl: constants.EXCEL_LINK,
          url: url,
        });
        Cookies.set("auth_data", token);
        Cookies.set("url_code", url);
        this.state.queryParams.delete("token");
        this.state.queryParams.delete("url");
        if (this.props.history != undefined) {
          this.props.history.replace({
            search: this.state.queryParams.toString(),
          });
        }
        if (token == "undefined" || token == undefined || token == null) {
          this.state.redirect = 1;
        } else {
          this.getUser();
        }

        this.getNotificationList('initial');
    }
    
    componentDidUpdate(prevProps) {
        if (
            JSON.stringify(this.props.loginDetails) !==
            JSON.stringify(prevProps.loginDetails)
        ) {
            this.getUser();
        }
    }

    componentWillMount(){
        window.addEventListener('scroll', () => {
            if ((window.innerHeight + document.documentElement.scrollTop  + 5) >= document.scrollingElement.scrollHeight && this.state.isLoaded) {
               if(this.state.notificationList.length < this.state.totalNotificationCount){
                    this.state.listSkip += this.state.listTake;

                    this.setState({
                        isLoaded: false,
                        listSkip: this.state.listSkip,
                    }, () => {
                        this.getNotificationList('more');
                    });
                }
            }
        });
    }

    getUser = () => {
        const role_id = this.props?.loginDetails?.loginDetails?.role_id;
        this.setState({
            loginUserData: this.props?.loginDetails?.loginDetails,
            loginUserPermission: this.props?.loginDetails?.loginUserPermission,
            role_id,
        });

        if (role_id != null || role_id != undefined || role_id != "undefined") {
            this.setState({ loading: false});
        }
    };

    getNotificationList = (isInitial) => {
        isInitial=='initial' ? this.setState({ loading: true }) : this.setState({ dataloading: true });

        let postdata = {
            is_new : false,
            skip: this.state.listSkip,
            take: this.state.listTake
        }

        this.props.getNotifications(postdata)
            .then((response) => {
                let nLists = this.state.notificationList;
                if(this.props.notificationDetails?.notificationLists?.lists.length > 0){
                    let notificationList = this.props.notificationDetails.notificationLists.lists;
                    notificationList.forEach(list => {
                        nLists.push(list);
                    });
                }

                this.setState({
                    loading: false,
                    dataloading: false,
                    isLoaded: true,
                    notificationList: nLists,
                    totalNotificationCount: this.props.notificationDetails?.notificationLists?.count
                });
        });
    }

    markAllRead = () => {
        this.setState({ loading: true });
        let postdata = {
            id: null
        }

        this.props.markReadNotification(postdata)
            .then((response) => {
                var notificationList = this.state.notificationList.map(lists => ({ ...lists, is_read: 1}));
                this.setState({ notificationList, loading: false });
                console.log(this.props.notificationDetails.markNotification);
            });
    }

    getNotificationItem = (event,list) =>{
        event.preventDefault();

        let postdata = {
           id: list.id
        }
        this.props.markReadNotification(postdata)
            .then((response) => {
            console.log(this.props.notificationDetails.markNotification);
        });

        const path = '/' + list.redirect_uri;
        this.props.history.push({
            pathname: path,
            state: {
                filter_name: list.filter_name,
                filter_id: list.module_id,
            }
        });
    }

    render() {
        return (
            <div className="page-content px-4" style={{ maxHeight: "650px"}}>
                <Row>
                    <div className="col-xs-12 fourty-fluid fourty-fluid">
                        <Row className="row">
                            <div className="col-sm-4 col-sm-4 col-xs-12 col-md-12 text-left">
                                <h1 className="B-G-bold f-s-18 d-gray t-t-upper pull-left mt-15 mr-20 page-head lh-10">
                                    ALL NOTIFICATIONS
                                </h1>
                            </div>
                        </Row>
                    </div>
                </Row>
                {this.state.notificationList?.length > 0 &&
                    <Row>
                        <div className="col-xs-12 m-t-30">
                            <button className="btn btn-primary pull-right" onClick={this.markAllRead}>
                                Mark All as Read
                            </button>
                        </div>
                    </Row>
                }
                <Row>
                    {this.state.loading == true &&
                        <div id="preloader">
                            <div id="status"></div>
                        </div>
                    }
                    {this.state.loading == false && this.state.notificationList?.length > 0 ? ( 
                        this.state.notificationList.map((lists, index) => {
                            return (
                                <div className={"col-xs-12 " + ( index==0 ? "mt-3" : " ")}>
                                    <div className={"scrollable-content border-bottom text-left mx-1 p-0 " + ( lists.is_read == 0 ? "notification-unread-bg" : "bgwhite")}>
                                        <div className="notification-item" onClick={(e) => this.getNotificationItem(e,lists)}> 
                                            <span><i className="fa fa-user"></i></span>
                                            <div className="text">
                                                <h5>{ lists.get_user?.first_name +' '+ (lists.get_user?.last_name || '')}</h5>
                                                <p className="text-wrap">{ lists?.text }</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    ) : (
                        <div className="col-xs-12 mt-5">
                            <div className="scrollable-content bgwhite text-center mx-1"  style={{ padding: "95px" }}>
                                No data found
                            </div>
                        </div>
                    )}
                    {this.state.dataloading == true &&
                        <div id="notification-preloader">
                            <div id="notification-status"></div>
                        </div>
                    }
                </Row>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
      loginDetails: state.loginDetails,
      notificationDetails: state.notificationDetails
    };
  };
  
const mapDispatchToProps = {
    getLoginUserPermission,
    getNotifications,
    markReadNotification
};

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);