import React from 'react';
import Select from 'react-select';
import {Modal} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';  
import Swal from "sweetalert2";
import moment from "moment";
import { connect } from "react-redux";
import { saveInvoiceComments, getUserList, getInvoiceComments } from "../../Action";

const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    showCloseButton: true,
    customClass: {
      popup: 'colored-toast',
      icon: 'white-toast-icon'
    },
    timer: 3000,
    onOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
});

class CommentSection extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        showCommentModal: false,
        commentInput: '',
        toUser: {},
        commentHistory: [],
        commentHistoryFilter: [],
        reportLeadOptions: [],
        searchFilter: null,
        selectedLeadValue: null,
        loading: false
      }

      this.myRef = React.createRef();
      this.myRef.current = {};
    }

    componentDidMount() {
      this.getUserList();
      this.getComments();
    }

    getUserList = () => {
      this.setState({ loading: true });
      this.props.getUserList().then(
        (response) => {
          this.setState({ loading: false });
          if(this.props.reportLead.length > 0){
            let reportLeadOptions = [];
            this.props.reportLead.filter((replead)  => { 
              var leadName = replead.first_name + ' ' + (replead.last_name || '');
              var lead = {value : replead.id, label: leadName};
              reportLeadOptions.push(lead);
            });
            this.setState({ reportLeadOptions });
          }
        },
        (error) => {
          this.setState({ loading: false });
          this.ToastAlert("error", "Server error");
        });
    }
    
    getComments = () => {
      this.setState({ loading: true });
      let dataObj = { 
        iteration_id: this.props.iterationDetails?.iterationId,
        client_name: this.props.iterationDetails?.clientName,
      }; 

      this.props.getInvoiceComments(dataObj).then(
        (response) => {
          let commentHistory = [];
          let searchFilter = null;
          if(this.props.commentResponse?.result?.length > 0){
            searchFilter = '';
            this.props.commentResponse.result.filter((comHistory) => {
              let userName = comHistory.get_user.first_name + ' ' + (comHistory.get_user.last_name || '');
              let toUserName = comHistory.get_to_user ? comHistory.get_to_user.first_name + ' ' + (comHistory.get_to_user.last_name || ''): null;
              let commentPost = { 
                userName: userName, 
                postDate: moment(comHistory.comment_date).format('DD MMM YYYY'), 
                postComment: comHistory.comment_text,
                toUserName: toUserName,
                commentId: comHistory.id
              };
              commentHistory.push(commentPost);
            });
          }
          this.setState({ loading: false, commentHistory, searchFilter, commentHistoryFilter:commentHistory });

          if(this.props.filterId){
            let commentIndex = commentHistory.findIndex(comment => comment.commentId == this.props.filterId);
            if (this.myRef.current) {
              this.myRef.current[commentIndex] && this.myRef.current[commentIndex].scrollIntoView({ behavior: 'smooth' });
            }
          }
        },
        (error) => {
          this.setState({ loading: false });
          this.ToastAlert("error", "Server error");
        });
    }

    ToastAlert(icon, title) {
      return Toast.fire({
        target: document.getElementById("form-modal"),
        icon: `${icon}`,
        title: `${title}`,
      });
    }
    
    handleInputChange = (evt) => {
      this.setState({ commentInput: evt.target.value });
    }
    
    handleLeadValue = (evt) => {
      let selectedLeadValue = evt;
      this.setState({ toUser: evt, selectedLeadValue });
    }

    handlePostComment = () => {
      let selectedLeadValue = null;
      let commentInput = this.state.commentInput;
      let commentHistory = this.state.commentHistory;
      let toUserId = this.state.toUser?.value;
      let toUserName = this.state.toUser?.label;

      let dataObj = { 
        iteration_id: this.props.iterationDetails?.iterationId,
        invoice_no: this.props.iterationDetails?.invoice_no,
        client_name: this.props.iterationDetails?.clientName,
        comment_text: commentInput,
        to_user_id: toUserId
      };

      this.props.saveInvoiceComments(dataObj).then(
      (response) => {
        let recentPost = { 
          userName: this.props.userName, 
          postDate: moment().format('DD MMM YYYY'), 
          postComment: commentInput,
          toUserName: toUserName
        };

        commentHistory.unshift(recentPost);
        this.setState({ commentHistory, commentHistoryFilter: commentHistory, commentInput: '', toUser: {}, selectedLeadValue, searchFilter: '' });

        console.log("success", this.props.commentSaveResponse);
      },
      (error) => {
        this.ToastAlert("error", "Something went wrong");
      });
    }

    searchHistory = (e) =>{
      var searchInput = e.target.value;
      var filteredData = [];
      if(searchInput){
          this.state.searchFilter = searchInput;
          filteredData = this.state.commentHistoryFilter.filter((item) => {
            return item.postComment.toLowerCase().indexOf(searchInput) !== -1;
          });
      }
      else{
          this.state.searchFilter = '';
          filteredData = this.state.commentHistoryFilter;          
      }

      this.setState({
        commentHistory : filteredData,
        searchFilter: this.state.searchFilter
      });
    }

    addCommentModal = () => {
        return (
          <>
            <Modal
              className="comment-section-popup"
              show={this.props.showCommentModal}
              onHide={() => this.props.handleCloseModal()}
            >
              <Modal.Header Confirm Delete closeButton>
                <Modal.Title className="custom-modal-title potential-heading">
                  <h4 className="pull-left">Comments <small className="text-theme"> - { this.props.iterationDetails?.clientName}</small></h4>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="row">
                    <div className="col-md-12">
                        <div className="d-flex flex-column">
                            { this.state.searchFilter!=null && 
                              <div className="form-group search-icon col-sm-12 col-xs-12">
                                  <input type="text" name="project_filter" className="form-control B-G-bold f-s-16 d-gray pl-30" placeholder="Search Comments" onChange={(e) => this.searchHistory(e)}  />
                                  <i class="fa fa-search m-t-3" style={{ "font-size": "16px" }}></i>                                   
                              </div>
                            }   
                            <div className="comment-modal scrollbar-design">   
                              { (this.state.commentHistory.length == 0) && 
                                  <div className="text-center">
                                      No history available
                                  </div>
                              }
                              { (this.state.commentHistory.length > 0) && 
                                  this.state.commentHistory?.map((historyItem, index) => {
                                    return (
                                      <div className="bg-white p-2" key={index} ref={(element) => this.myRef.current[index] = element}>
                                        <div className="d-flex flex-column justify-content-start ml-2"><span className="d-block font-weight-bold name text-theme">{ historyItem.userName } <span className="date text-black-50 float-end fs-12">{ historyItem.postDate }</span></span><span className="date text-black-50">{ historyItem.toUserName ? '@' + historyItem.toUserName : '' }</span></div>
                                        <div className="mt-1">
                                            <p className="comment-text">{ historyItem.postComment }</p>
                                        </div>
                                    </div>
                                    )
                                  })
                              }
                            </div>
                            { (this.props.iterationDetails?.invoice_status === 3) && 
                              <div className="text-center text-success my-3 border">
                                  Last status update: Invoice Collected on {this.props.iterationDetails.payment_date}
                              </div>
                            }
                            { (this.props.iterationDetails?.invoice_status != 3) && 
                                <div className="bg-light p-2">
                                  <Select
                                    className="basic-single-select mb-2"
                                    classNamePrefix={"select_dropdown"}
                                    placeholder="@ Add User"
                                    value={this.state.selectedLeadValue}
                                    options={this.state.reportLeadOptions}
                                    onChange={(e) => this.handleLeadValue(e)}
                                    isClearable
                                    isSearchable={true}
                                  ></Select>
                                  <div className="d-flex flex-row align-items-start"><textarea className="form-control ml-1 shadow-none" value={this.state.commentInput} onChange={this.handleInputChange}></textarea></div>
                                  <div className="mt-2 text-right"><button className={`btn btn-primary btn-sm shadow-none ${!this.state.commentInput ? ' disable' : ''}`} type="button" disabled={!this.state.commentInput} onClick={this.handlePostComment}>Post comment</button></div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
              </Modal.Body>
            </Modal>
          </>
        );
    };

    /**
     * main render
     * @returns 
     */
	render() {
        return (
            <> 
              {this.state.loading && (
                <div id="preloader">
                  <div id="status"></div>
                </div>
              )}

             {(this.state.showCommentModal ||
                this.props.showCommentModal) && !this.state.loading &&
                this.addCommentModal()}
            </>
        )
    }
}

const mapStateToProps = (state) => {
  return { 
    commentSaveResponse: state.reportFilterDetails.commentSaveResponse,
    reportLead: state.reportFilterDetails.reportLead,
    commentResponse: state.reportFilterDetails.commentResponse
  };
};
  
const mapDispatchToProps = {
  saveInvoiceComments, getUserList, getInvoiceComments
};
  
export default connect(mapStateToProps, mapDispatchToProps)(CommentSection);