
import * as types from "../Utilities/Type"
const INITIAL_STATE = {
    csmReport: [],
    csmExport: [],
    csmData: [],
    csmEmail:[],
};

export function getCsmReport(state=INITIAL_STATE,action){
    switch (action.type){
        case types.GET_CSM_REPORT:
             return{
                ...state,
                csmReport:action.payload
             }
        case types.CSM_EXPORT_EXCEL:
             return{
                ...state,
                csmExport:action.payload
             }
        case types.GET_CSM_SUMMARY_REPORT:  
            return{
                ...state,
                csmData:action.payload
            }
        case types.SEND_CSM_EMAIL:  
            return{
               ...state,
               csmEmail:action.payload
        }
        case types.CSM_APPROVE_STATUS:  
            return{
                ...state,
                csmApprove:action.payload
            }   
        case '3':  
             break;  
        default:return state        

    }
}
