import React, { Component } from 'react';

class QuarterDatePicker extends Component {
  constructor(props) {
    super(props);
    const currentDate = new Date();
    this.state = {
      selectedQuarter: this.props?.defaultQuarter ? this.props?.defaultQuarter : 'All', // Set the default quarter to 'All'
      selectedYear: this.props?.defaultYear ? Number(this.props?.defaultYear) : currentDate.getFullYear(),
      // selectedYear: currentDate.getFullYear(),
    };

    // Initialize quarterDates with the default values
    const quarterDates = this.getQuarterDates('All', this.state.selectedYear);

    // Pass the initial values to the parent component using the callback prop
    this.props.onDateChange(
      quarterDates.startDate,
      quarterDates.endDate
    );
  }


  getCurrentQuarter(date) {
    const month = date.getMonth() + 1;
    if (month >= 4 && month <= 6) {
      return 'Q1';
    } else if (month >= 7 && month <= 9) {
      return 'Q2';
    } else if (month >= 10 && month <= 12) {
      return 'Q3';
    } else {
      return 'Q4';
    }
  }

  getCurrentQuarter(date) {
    const month = date.getMonth() + 1;
    if (month >= 4 && month <= 6) {
      return 'Q1';
    } else if (month >= 7 && month <= 9) {
      return 'Q2';
    } else if (month >= 10 && month <= 12) {
      return 'Q3';
    } else {
      return 'Q4';
    }
  }

  handleQuarterChange = () => {
    const { selectedQuarter, selectedYear } = this.state;
    const quarterDates = this.getQuarterDates(selectedQuarter, selectedYear);

    // Pass the selected values to the parent component using the callback prop
    this.props.onDateChange(
      quarterDates.startDate,
      quarterDates.endDate,
    );
  };

  getQuarterDates(quarter, year) {
    switch (quarter) {
      case 'Q1':
        return {
          startDate: `${year}-04-01`,
          endDate: `${year}-06-30`,
        };
      case 'Q2':
        return {
          startDate: `${year}-07-01`,
          endDate: `${year}-09-30`,
        };
      case 'Q3':
        return {
          startDate: `${year}-10-01`,
          endDate: `${year}-12-31`,
        };
      case 'Q4':
        return {
          startDate: `${Number(year) + 1}-01-01`,
          endDate: `${Number(year) + 1}-03-31`,
        };
      case 'All':
        return {
          startDate: `${year}-04-01`,
          endDate: `${Number(year) + 1}-03-31`,
        };
      default:
        return null;
    }
  }

  render() {
    const quarters = [
      { value: 'Q1', label: '01-04 - 30-06' },
      { value: 'Q2', label: '01-07 - 31-09' },
      { value: 'Q3', label: '01-10 - 31-12' },
      { value: 'Q4', label: '01-01 - 31-03' },
      { value: 'All', label: '04-01 - 31-03' },
    ];
    const currentYear = new Date().getFullYear();
    const years = [currentYear - 1, currentYear, currentYear + 1];
    const { selectedQuarter, selectedYear } = this.state;
    const quarterDates = this.getQuarterDates(selectedQuarter, selectedYear);
    return (
      < div  >

        <div className="msallocation--report">
          <select
            value={selectedQuarter}
            onChange={(e) => this.setState({ selectedQuarter: e.target.value }, this.handleQuarterChange)}
          >
            {quarters.map((quarter) => (
              <option key={quarter.value} value={quarter.value}>
                {quarter.value}
              </option>
            ))}
          </select>
        </div>
        <span className='msallocation--report-year-col'>Year :</span>
        <div className='msallocation--report-year'>
          <select
            value={selectedYear}
            onChange={(e) => this.setState({ selectedYear: e.target.value }, this.handleQuarterChange)}
          >
            {years.map((year) => (
              <option key={year} value={year}>
                {year}
              </option>
            ))}
          </select>
        </div>


      </div >
    );
  }
}

export default QuarterDatePicker;




