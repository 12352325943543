import React, { Component } from "react";
import  * as constants from "../../Config/Constant";

export class CsmSummaryView extends Component {
  constructor(props) {
    super(props);
    this.state = { userId: '' }
  }

  render() {
    console.log(this.props.csmSummaryList)
    let index = 1;
    return (
      <>
        <div className="scrollable-content">
          {this.props.loading && (
            <div id="preloader">
              <div id="status"></div>
            </div>
          )}
          {!this.props.loading && 
            (this.props.csmSummaryList && this.props.csmSummaryList.length == 0) && (
              <div>
                <div className="r-no-data text-center">
                  No data available
                </div>
              </div>
            )}
           {!this.props.loading &&
            (this.props.csmSummaryList && this.props.csmSummaryList.length > 0) && (
             
              <div className="table-responsive white-table-class reports--grid">
                  <div className="scrollable">
                      <div className="scrollable-content">
                          <table className="table m-b-0 table-wrap">
                              <thead>
                                  <tr className="theadInner invntry-mw-th">
                                      <th rowSpan="2" className='thead_csm'>S.No</th>
                                      <th rowSpan="2" className='thead_csm'>Csm Name</th>
                                      <th colSpan={this.props.months_list.length} className='thead_csm'>Invoice (INR)</th>
                                      <th colSpan={this.props.months_list.length} className='thead_csm'>Collection (INR)</th>                                
                                      <th rowSpan="2" className='thead_csm'>Incentive Payable (INR)</th>
                                      { this.props.months_list.length == 1 && <th rowSpan="2" className='thead_csm'>Approval</th>}
                                  </tr>
                                  <tr className="theadInner invntry-mw-th">
                                     { this.props.months_list.map(function(month) {
                                            return (
                                              <>
                                              <th className="thead_csm">{month}</th>
                                              </>                                             
                                            )                                       
                                        })
                                      }
                                      { this.props.months_list.map(function(month) {
                                            return (
                                              <>
                                              <th className="thead_csm">{month}</th>
                                              </>                                             
                                            )                                       
                                        })
                                      }
                                      
                                  </tr>
                              </thead>
                              <tbody>   
                                                     
                              { this.props.csmSummaryList.map((item,key) => ( 
                                      <tr id="class_grey_rows_" >
                                        <td>{index++}</td>                                                               
                                        <td className="text-start">
                                          <span>{item.data[0].user_name}</span>
                                        </td>                              
                                        { Object.values(item.data).map(
                                          (user) =>
                                           (
                                            <td>
                                                <span>
                                                  {(((user.invoice)/100)* constants.DOLLAR_RATE).toLocaleString(undefined, {
                                                    minimumFractionDigits: 2,
                                                    maximumFractionDigits: 2
                                                  })} 
                                                </span>
                                            </td>                                                                            
                                            )
                                        )}
                                        { Object.values(item.data).map(
                                          (user) =>
                                           (
                                            <td>
                                                <span>
                                                  {(((user.collection )/100)* constants.DOLLAR_RATE).toLocaleString(undefined, {
                                                    minimumFractionDigits: 2,
                                                    maximumFractionDigits: 2
                                                  })}
                                                </span>                                              
                                                {
                                                  user.is_approval == 1 && <i class="fa fa-check"title='To be Approve by Finance Team' style={{color: "#f5821f", float: "right"}} ></i>                                                 
                                                }                                             
                                                {
                                                  user.is_approval == 2 && <i class="fa fa-check" title='Approved' style={{color: "green",float: "right" }}> </i>  
                                                }                                             
                                                {
                                                  !user.is_approval  && <i class="fa fa-times" title='To be Approve by CSM Head' style={{color: "red", float: "right"}}></i>  
                                                } 
                                            </td>                                                                            
                                            )
                                        )}
                                        <td title="Sum of collection amount">                                      
                                          { 
                                           ( (item && item.data && item.data.reduce((sum, current)=>{
                                              return sum + ( ((current.collection)/100)* constants.DOLLAR_RATE )
                                            }, 0)).toLocaleString(undefined, {
                                              minimumFractionDigits: 2,
                                              maximumFractionDigits: 2
                                            })) 
                                          }
                                        </td>
                                        {/* start csm head approved*/}
                                        { this.props.months_list.length == 1 && 
                                          <>
                                          {!item.approval_status && (this.props.userData.role_id == 1 || this.props.userData.roles.includes(constants.CSM_HEAD_ROLE)) && (
                                            <td>
                                               <button id={item.data[0].user }  className="me-2 mt-0 btn btn-primary" onClick={(e) => this.props.saveCsmApprovalStatus(item, 1)}> Approve </button>
                                            </td>
                                          )}
                                          {item.approval_status == 1 && (this.props.userData.role_id == 1 || this.props.userData.roles.includes (constants.CSM_HEAD_ROLE)) &&
                                              <td>
                                            <span title='Waiting for Finance Team Approval'>Approved </span> 
                                              </td>
                                          }
                                          {item.approval_status==2  && (this.props.userData.role_id == 1 || this.props.userData.roles.includes(constants.CSM_HEAD_ROLE)) &&
                                             <td>                                      
                                             Approved by Finance
                                             </td>
                                          } 
                                         {/* end csm head approved*/}
                                         {/* start finance team approved*/}
                                         {item.approval_status == 1  && this.props.userData.role_id == 12 &&
                                            <td>
                                             <button id={item.data[0].user} className="me-2 mt-0 btn btn-primary" title='Approved by CSM Head' onClick={(e) => this.props.saveCsmApprovalStatus(item, 2)}>
                                                  Pending
                                              </button>
                                          </td>
                                          }
                                          {!item.approval_status  && (this.props.userData.role_id == 12) &&
                                            <td>                                       
                                             To be Approve by CSM Head
                                            </td>
                                          }
                                          {item.approval_status==2  && (this.props.userData.role_id == 12) &&
                                            <td>  
                                             Approved &nbsp; <i class="fa fa-envelope" aria-hidden="true" title='Please click this to send mail' onClick={(e) => this.props.sendCsmReportEmail(item, 2)}></i>
                                          </td>
                                          } 
                                          </>
                                        }
                                        {/* End finance team approved*/}                      
                                      </tr >
                                     ))
                                  }               
                              </tbody>
                          </table>
                      </div>
                  </div>
              </div>                              
            )}


        </div>
      </>
    );
  }
}
