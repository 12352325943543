import { Row } from "react-bootstrap";
import { getBackEndConstant } from "../../Config/Constant";

const PmsUserGrid = (props) => {
  const { handleCommentsQuestion } = props;
  const { handleDownloadReport } = props;
  return (

    <>
      <Row>
        <div className="table-responsive white-table-class p-0">
          <div className="scrollable">
            <div className="scrollable-content">
              <table className="table m-b-0 user-grid-table">
                <thead>
                  <tr className="theadInner default-bg">
                    <th className="text-start" style={{ width: "35%" }}>
                      Name
                    </th>
                    <th className="text-start" style={{ width: "15%" }}>
                      Percentage
                    </th>
                    <th className="text-start" style={{ width: "15%" }}>
                      Status
                    </th>
                    <th className="text-start" style={{ width: "15%" }}>
                      Lead Review
                    </th>
                    <th className="text-start" style={{ width: "15%" }}>
                      View/Download
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {props?.dataList.length > 0 ? (
                    props.dataList?.map((item, index) => {
                      return (
                        <tr key={`usergriditem-${index}`}>
                          <td className="name-cell d-flex">
                            <span className="style-percentage style-percentage-warning initial">
                              {item.userName.split("")[0]}
                            </span>
                            <span className="d-flex flex-column">
                              <span className="warning-color">
                                {item.userName} &nbsp;({item.empNo})
                              </span>
                              <span className="f-s-12 text-left">
                                {item.roleName}
                              </span>
                            </span>
                          </td>
                          <td style={{ position: "relative" }}>
                            {item.userPercentage !== undefined && item.userPercentage !== null
                              ? (item.userPercentage % 1 === 0
                                ? `${Math.round(item.userPercentage)}%`
                                : `${item.userPercentage.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}%` // If it's a decimal, format with 2 decimal places and add '%'
                              )
                              : '0 %'}
                          </td>
                          <td style={{ position: "relative" }}>
                            <span
                              className={`intimate mr-5 ${getBackEndConstant().statusClasses[
                                item.status.toLowerCase()
                              ]
                                }-li`}
                            ></span>&nbsp;
                            {item.status}
                          </td>
                          <td>
                            {item.is_comments === "Added" && (
                              <span>
                                <i className="mr-5 fs-16 fa fa-check-circle success-color"></i>
                              </span>
                            )}
                            {item.is_comments}
                          </td>
                          <td>
                            <i
                              className={`fs-16 fa fa-eye  ${(item.status === "Completed" && item.answerId !== "")
                              ? "c-pointer"
                              : ``} pr-5 ${(item.is_comments === "Added" || item.status === "Completed")
                                ? "warning-color"
                                : `default-color ${item.status === "Not Completed"
                                  ? "c-not-allowed"
                                  : ""
                                }`
                                }`}
                              onClick={() =>
                                item.status === "Completed" && item.answerId !== "" &&
                                handleCommentsQuestion(item, true)
                              }
                            />

                            <i class={`icon-login icon-rotate-download ${item.status === "Completed" && (item.answerId !== "")
                                ? "c-pointer"
                                : ``} ${(item.status === "Completed")
                                ? "warning-color"
                                : `default-color ${item.status === "Not Completed"
                                  ? "c-not-allowed"
                                  : ""
                                }`
                                }`}
                                onClick={() =>
                                  item.status === "Completed"  && item.answerId !== "" &&
                                  handleDownloadReport(item)
                                }></i>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={4} className="text-center">No data found</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </Row>
    </>
  );
};
export default PmsUserGrid;