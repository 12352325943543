import *  as  constants from "../Config/Constant";
import { postData,getData } from "../Services/Helper";
import { handleResponse } from "../Services/HandleResponse";
import * as types from "../Utilities/Type"
import { getBackEndConstant } from "../Config/Constant";

export function getCsmReport(dataObj) {
  return (dispatch) => {
    const requestOptions = postData(dataObj);
    let url = getBackEndConstant().backend_url + "get_csm_report";
    return fetch(url, requestOptions)
      .then((response) => handleResponse(response))
      .then((response) => {
        dispatch({
          type: types.GET_CSM_REPORT,
          payload: (response.result) ? response.result : {},
        });
      });
  };
}

export function getCsmSummaryReport(dataObj) {
  return (dispatch) => {
    const requestOptions = postData(dataObj);
    let url = getBackEndConstant().backend_url + "get_csm_summary_report";
    return fetch(url, requestOptions)
      .then((response) => handleResponse(response))
      .then((response) => {
        dispatch({
          type: types.GET_CSM_SUMMARY_REPORT,
          payload: (response.result) ? response.result : {},
        });
      });
  };
}

export function csmExportExcel(dataObj) {
  return (dispatch) => {
    const requestOptions = postData(dataObj);
    let url = getBackEndConstant().backend_url  + "export_as_excel_csm_report";
    return fetch(url, requestOptions)
      .then((response) => handleResponse(response))
      .then((response) => {
        dispatch({
          type: types.CSM_EXPORT_EXCEL,
          payload: (response.result) ? response.result : {},
        });
      });
  };
}

/**
 * send email for csm user
 * @param  dataObj
 * @returns
 */
 export function sendCsmEmail(dataObj) {
  return (dispatch) => {
    const requestOptions = postData(dataObj);
    let url = getBackEndConstant().backend_url + "send_email_csm_report";
    return fetch(url, requestOptions)
      .then((response) => handleResponse(response))
      .then((response) => {
        dispatch({
          type: types.SEND_CSM_EMAIL,
          payload: (response.result) ? response.result : {},
        });
      });
  };
}

/**
 * send email for csm user
 * @param  dataObj 
 * @returns 
 */
 export function saveCsmApprovalStatus(dataObj) {
  return (dispatch) => {
    const requestOptions = postData(dataObj);
    let url = getBackEndConstant().backend_url + "save_csm_approval_status";
    return fetch(url, requestOptions)
      .then((response) => handleResponse(response))
      .then((response) => {
        dispatch({
          type: types.CSM_APPROVE_STATUS,
          payload: (response.result) ? response.result : {},
        });
      });
  };
}
