import * as types from "../Utilities/Type"

const INITIAL_STATE = {
  loginDetails: [],
};

export function getLoginUser(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.GET_LOGIN_USER:
      return {
        ...state,
        loginDetails: action.payload,
      };
    case types.GET_LOGIN_USER_PERMISSION:
      return {
        ...state,
        loginUserPermission: action.payload,
      };
    case "3":
      break;
    default:
      return state;
  }
}
