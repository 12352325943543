import React, { Component, useEffect, useState } from "react";
import { Urls } from "../../Config/Config";
import { Link, useHistory, Redirect } from "react-router-dom";
import "./Report.css";
import Select from "react-select";
import ReactDatePicker from "react-datepicker";
import {
  Grid,
  Row,
  Col,
  Button,
  Form,
  Spinner,
  ButtonGroup,
  CustomMenu,
  Tooltip,
  Overlay,
  OverlayTrigger,
} from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "bootstrap/dist/css/bootstrap.min.css";
import moment from "moment";
import Swal from "sweetalert2";
import Cookies from "js-cookie";
import MileStoneReport from "./msreport";
import PageNotFound from "./PageNotFound";
import Gantt_chart from "./Gantt_chart";
import CollectionPotentialReport from "./Collection_potential_report";
import InventoryReport from "./InventoryReport";
import CollectionsReport from "./Collections_report";
import InventoryAllocationReport from "./Inventory_Allocation_report";
import InventoryMilestoneReport from "./Inventory_milestone_report";
import ResourceUtilizationReport from "../UtilizationComponent/ResourceUtilizationReport";
import CsmReport from '../CsmReportComponent/CsmReport';
import {getUserService } from "../../Utilities/Helper";
import { Dropdown } from "rsuite";
import "rsuite/dist/rsuite.min.css";
var _ = require("lodash");

const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  showCloseButton: true,
  customClass: {
    popup: "colored-toast",
    icon: "white-toast-icon",
  },
  timer: 3000,
  onOpen: (toast) => {
    toast.addEventListener("mouseenter", Swal.stopTimer);
    toast.addEventListener("mouseleave", Swal.resumeTimer);
  },
});

//const bu_id='';

class PW_report extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      queryParams: new URLSearchParams(window.location.search),
      selected: "",
      setSelected: "",
      redirectUrl: "",
      redirect: 0,
      user_types: Urls.user_types,
      active: false,
      all_active: true,
      reports_lists: Urls.reports_lists,
      reports_lists1: Urls.reports_lists1,
      current_report: Urls.reports_lists[0],
      report: {},
      projectPractice: [],
      project_practice: [],
      isAllPractices: false,
      getUserscount: "",
      practiceleadName: "",
      report_practice: [],
      gridListBackup: [],
      gridList: [],
      planned_hrs: 0,
      support_hrs: 0,
      unallocated_hrs: 0,
      effective_yield: 0,
      expected_yield: 0,
      gap_in_allocation: 0,
      deficit: 0,
      userTypeId: 0,
      totalResAvHrCal: 0,
      msAlloc: 0,
      supphrs: 0,
      totalResourceAvailHrsFrontendCalcution: 0,
      msAllocatedHrs: 0,
      supportHrs: 0,
      benchHrs: 0,
      userTypes: Urls.user_types,
      isGanttView: false,
      updateLoader: false,
      current_Gant_popover: {},
      head_id: "",
      // for inventory report
      support_practice: [6, 12, 13, 21],
      inventory_isDetailedView: true,
      searchByPracticeHead: "",
      // for TL and BA
      techLeadList: [],
      businessAnalystList: [],
      techLeadId: "",
      businessAnalystId: "",
      searchByTechLead: "",
      searchByBusinessAnalyst: "",
      searchBy: "",
      isChecked: false,
      // for milestone report
      myPracticeMileStone: 0,
      allPracticeMileStone: 0,
      mileStoneValueReport: {},
      filteredMilestoneData: [],
      mileStoneReportPractice: 1,
      filterByPractice: "",
      filterMileStoneValue: "",
      milestone_achieved_dropdown_value: Urls.milestone_achieved_dropdown_value,
      invoice_dropdown_value: Urls.milestone_achieved_dropdown_value,
      // for collection-potential report
      currentFilter: { id: 0, value: "All" },
      filterNotSure: "",
      true_data: [],
      checked_data: {},
      sumoffilterMileStoneValue: 0,
      SumofMileStoneValue: 0,
      collection_potential_invoicestatus: Urls.invoice_status,
      return_to_pwreport: 0,
      resource_types: Urls.resource_types,
      resource_types_new: Urls.resource_types_new,
      // resourceFilter : {'id':0, 'value':'All'},
      resourceFilter: "All",
      resourceAllFilter: "All",
      filter_start_date: new Date(),
      // filter_start_date : '2022-01-01',
      filter_end_date: new Date(),
      sname: [],
      sname1: [],
      inventory_isDetailedView: false,
      report_practice: [],
      report_service: [],
      projectPractice: [],
      practiceHead: [],
      totalMileStoneValue: 0,
      totalKickoffValue: 0,
      totalCarryValue: 0,
      filterMileStoneValue: 0,
      filteredMilestoneData: [],
      milestoneReport: [],
      mileStoneValueReport: {},
      submitted: false,
      practiceReport: [],
      overallMileStoneValue: 0,
      sumOfNotSureValue: 0,
      notUpdatedValue: 0,
      totalNewValue: 0,
      totalRaisedValue: 0,
      totalCollectedValue: 0,
      totalPendingValue: 0,
      mileStoneReportMonth: "",
      mileStoneReportYear: "",
      myPracticeMileStone: 0,
      allPracticeMileStone: 0,
      filter: [],
      filterByPractice: [],
      filterMileStoneValue: 0,
      filterNotSure: 0,
      filterNotUpdated: 0,
      filterNewValue: 0,
      filterRaisedValue: 0,
      filterCollectedValue: 0,
      filterPendingValue: 0,
      selectedInvoiceStatus: "All",
      selectedInvoice: "All",
      loginUserData: {},
      token: "",
      // token : JSON.parse(Cookies.get('globals')).currentUser.authdata,
      loading: false,
      start_month: moment().utcOffset("+05:30").format("YYYY-MM"),
      end_month: moment().utcOffset("+05:30").format("YYYY-MM"),
      project_name_filter: "",
      bu_id: "",
      utilizationCounts: {},
      yieldCounts: {},
      reportPrac: [],
      practice_length: 0,
      selectService: [],
      selectBu: [],
      selectBuFilter: [],
      reportList: [],
      serviceName: [],
      monthSeries: [],
      moolahDeficit: 0,
      boolahDeficit: 0,
      bcDeficit: 0,
      vendorDeficit: 0,
      externDeficit: 0,
      internDeficit: 0,
      moolahDeficits: 0,
      boolahDeficits: 0,
      bcDeficits: 0,
      vendorDeficits: 0,
      externDeficits: 0,
      internDeficits: 0,
      checkedList: [],
      start: new Date(),
      reportF: 0,
      bu_qes: Urls.bu_qes,
      practice_qes: Urls.practice_qes,
      service_qes: Urls.service_qes,
      intern_count: 0,
      userPracticeCount: "",
      newCountarray: ",",
      typeId : 0,
      emp_category : ''
    };
  }

  reset_page = () => {
    this.state.report.service = [];
    this.state.report.practice = [];
    this.state.selectService = [];
    this.getPracticeWiseGridData();
  };

  setStartDateForFilter = (e) => {
    this.state.start_month = e;
    var today = new Date(e);
    this.state.filter_start_date = today;
    this.setState({
      filter_start_date: this.state.filter_start_date,
    });

    if(this.state.filter_start_date > this.state.filter_end_date){
      this.state.filter_end_date = today;
      this.ToastAlert("warning", "End date should greater than start date");
    }
  };

  setEndDateForFilter = (e) => {
    this.state.end_month = e;
    var today = new Date(e);
    this.setState({
      filter_end_date: today,
    });
  };

  componentDidMount() {
    this.state.reports_lists.forEach((value) => {
      var opt = {};
      opt.label = value.value;
      opt.id = value.id;
      opt.value = value.value;
      this.state.reportList.push(opt);
    });
    this.state.current_report = this.state.reportList[0];
    //this.state.loginUserData = JSON.parse(Cookies.get('loginuserDetail'));
    this.state.token =
      this.state.queryParams.get("token") == null
        ? Cookies.get("auth_data")
        : this.state.queryParams.get("token");
    this.state.url =
      this.state.queryParams.get("url") == null
        ? Cookies.get("url_code")
        : this.state.queryParams.get("url");
    Cookies.set("auth_data", this.state.token);
    Cookies.set("url_code", this.state.url);
    console.log(this.state.url);
    this.state.queryParams.delete("token");
    this.state.queryParams.delete("url");
    if (this.state.url == undefined || this.state.url == "undefined") {
      this.state.redirect = 1;
    } else {
      if (atob(this.state.url) === "localhost") {
        this.state.apiLink = "http://127.0.0.1:8000/api/";
        this.state.angularUrl = "http://localhost:22400/#!/";
        this.state.excelUrl = "http://localhost:8000/";
      } else if (atob(this.state.url) === "staging") {
        this.state.apiLink =
          "https://reflections-staging.optisolbusiness.com/api/";
        this.state.angularUrl =
          "https://reflectionsstaging.optisolbusiness.com/#!/";
        this.state.excelUrl =
          "https://reflections-staging.optisolbusiness.com/";
      } else if (atob(this.state.url) === "uat") {
        // this.state.reportList = [];
        this.state.apiLink =
          "https://reflectionsservicestg.optisolbusiness.com/api/";
        this.state.angularUrl =
          "https://reflectionsstg.optisolbusiness.com/#!/";
        this.state.excelUrl =
          "https://reflectionsservicestg.optisolbusiness.com/";
        // this.state.reports_lists1.forEach((value) => {
        //   var opt = {};
        //   opt.label = value.value;
        //   opt.id = value.id;
        //   opt.value = value.value;
        //   this.state.reportList.push(opt);
        // });
      } else if (atob(this.state.url) === "live") {
        // this.state.reportList = [];
        this.state.apiLink =
          "https://reflectionsservice.optisolbusiness.com/api/";
        this.state.angularUrl = "https://reflections.optisolbusiness.com//#!/";
        this.state.excelUrl = "https://reflectionsservice.optisolbusiness.com/";
        // this.state.reports_lists1.forEach((value) => {
        //   var opt = {};
        //   opt.label = value.value;
        //   opt.id = value.id;
        //   opt.value = value.value;
        //   this.state.reportList.push(opt);
        // });
        // this.state.reportList = this.state.reportList[0];
      } else {
        // do nothing
      }
    }
    if (this.props.history != undefined) {
      this.props.history.replace({
        search: this.state.queryParams.toString(),
      });
    }
    this.setState({
      token: this.state.token,
    });
    this.getUser();
    this.getPracticeService();
  }

  getUser = () => {
    var link = this.state.apiLink + "get_login_user";

    if (this.state.token == null) {
      this.state.token = Cookies.get("auth_data");
    }
    if (this.state.token == "undefined") {
      this.state.redirect = 1;
    } else {
      return fetch(link, {
        method: "GET",

        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: "Bearer " + this.state.token,
        },
      })
        .then((res) => res.json())
        .then((res) => {
          this.state.loginUserData = res.result;
        });
    }
  };

  getPracticeService = (e, isAllPractice) => {
    this.state.isAllPractices = isAllPractice;
    console.log(this.state.isAllPractices);
    this.setState({ loading: true });
    this.state.filterService = [];
    var date = new Date();
    var date = new Date(date.getFullYear(), date.getMonth(), 2);
    var start_date = this.state.filter_start_date
      ? this.state.filter_start_date
      : date;
    var end_date = this.state.filter_end_date
      ? this.state.filter_end_date
      : date;
    // this.state.report.service = [];
    var isAllPractices = isAllPractice;
    var link = this.state.apiLink + "get_report_filters_new";
    this.state.sname = [];
    this.state.allService = [];
    this.state.serviceName = [];
    if (this.state.token == null) {
      this.state.token = Cookies.get("auth_data");
    }
    if (this.state.token == "undefined") {
      this.state.redirect = 1;
    } else {
      return fetch(link, {
        method: "POST",
        body: JSON.stringify({
          start_date,
          end_date,
          filter: isAllPractice,
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: "Bearer " + this.state.token,
        },
      })
        .then((res) => res.json())
        .then((res) => {
          this.setState({
            loading: false,
            // sname : res.result.services,
            // sname1 : res.result.services,
            // allService : res.result.allservices,
          });

          var c = 0;
          res.result.directors.forEach((value) => {
            if (this.state.bu_id == value.bu_id) {
              c = 1;
            }
          });
          this.state.bu_id = c == 1 ? this.state.bu_id : "";
          this.state.selectBuFilter = c == 1 ? this.state.selectBuFilter : [];
          if (
            this.state.bu_id != "" &&
            this.state.bu_id != undefined &&
            this.state.bu_id != 0
          ) {
            res.result.services.forEach((service) => {
              if (this.state.bu_id == service.bu) {
                this.state.sname.push(service);
              }
            });
            res.result.allservices.forEach((service) => {
              if (this.state.bu_id == service.bu) {
                this.state.allService.push(service);
              }
            });
          } else {
            this.state.sname = res.result.services;
            this.state.allService = res.result.allservices;
          }
          this.state.sname1 = res.result.services;
          this.state.report.project_practice = [];
          this.state.sname.forEach((value) => {
            var opt = {};
            opt.label = value.value;
            opt.id = value.id;
            opt.value = value.value;
            opt.head = value.head;
            opt.bu = value.bu;
            this.state.serviceName.push(opt);
          });
          if (isAllPractice) {
            this.state.selectService = [];
            this.state.report.service = [];
            this.state.report.practice = [];
            this.state.projectPractice = [];
            this.state.selectBuFilter = [];
            this.state.head_id = "All";
            this.state.bu_id = "";
            this.state.selectBU = [];
            res.result.practices.forEach((pvalue) => {
              this.state.projectPractice.push(pvalue);
            });
          } else {
            var count = 0;
            if (this.state.report.service) {
              this.state.report.service.forEach((value) => {
                this.state.sname.forEach((svalue) => {
                  if (svalue.id == value.id) {
                    count = 1;
                  }
                });
              });
              if (count == 0) {
                this.state.report.service = [];
                let service = getUserService(this.state.serviceName,this.state.loginUserData,this.state.filter_start_date);
                this.state.report.service.push(service);
                this.state.selectService = service; 
              }
            } else {
              this.state.report.service = [];
              let service = getUserService(this.state.serviceName,this.state.loginUserData,this.state.filter_start_date);
              this.state.report.service.push(service);
              this.state.selectService = service;                        
            }
          }
          this.setState({
            report: {
              service: this.state.report.service,
              practice: this.state.report.practice,
            },
            //selectService : this.state.selectService
          });
          this.state.qes_list = res.result.qes_list;
          var head = {};

          this.setState({
            report_all_practices: res.result.practices,
            practiceHead: this.state.practiceHead,
          });
          // for practicehead
          res.result.directors.forEach((id) => {
            this.state.practiceHead.push(id);
          });

          var buName = [];
          res.result.directors.forEach((value) => {
            var opt = {};
            opt.label = value.bu;
            opt.value = value.bu;
            opt.bu_id = value.bu_id;
            opt.id = value.id;
            opt.bu = value.bu;
            opt.name = value.name;
            buName.push(opt);
          });
          this.setState({
            practiceHead: this.state.practiceHead,
            buName: buName,
          });
          // // this.state.practiceHeadId = this.state.practiceHead[1]['id'];
          if (this.state.loginUserData.role_id == 8) {
            this.state.searchByPracticeHead = this.state.loginUserData.role_id;
          } else {
            this.state.searchByPracticeHead = this.state.searchByPracticeHead;
          }

          if (!this.state.isAllPractices) {
            this.filterService("");
          } else {
            this.getPracticeWiseGridData();
          }
          this.changeViewPracite(this.state.isAllPractices);
        });
    }
  };

  //filter practice based on service and head
  filterService = (e) => {
    if (e) {
      let selectedOption = e;
      this.state.selectService = selectedOption;
      this.state.report.service = selectedOption;
    }
    this.setState({
      report: {
        service: this.state.report.service,
      },
    });
    var ser = [];
    var report = [];
    if (this.state.reports_lists[0].value === this.state.current_report.value) {
      // if(this.state.reports_lists[1].value === 'Milestone Value Report'){
      this.state.report.practice = [];
      this.state.projectPractice = [];
      this.state.report_all_practices.forEach((value) => {
        value.is_active = false;
      });
      if (this.state.isAllPractices) {
        this.state.projectPractice = this.state.report_all_practices;
      } else {
        var service = this.state.report.service;
        this.state.projectPractice = [];
        if (service.length == 0 && (this.state.loginUserData.role_id == 8  || this.state.loginUserData.role_id == 1)) {
          this.state.projectPractice = [];
        } else {
          if (service.length == 0) {
            this.state.report_all_practices.forEach((value) => {
              this.state.projectPractice.push(value);
            });
          } else {
            var proj_practice = [];
            service.forEach((svalue) => {
              this.state.report_all_practices.forEach((value) => {
                if (svalue.id == value.serviceId) {
                  this.state.projectPractice.push(value);
                }
              });
            });
          }
        }
      }
      this.setState({
        projectPractice: this.state.projectPractice,
      });
      // for sending all practice while reset
      if (this.state.projectPractice.length > 0) {
        this.state.projectPractice.forEach((value) => {
          this.state.report.practice.push(value.id);
        });
      }
      this.setState({
        report: {
          practice: this.state.report.practice,
          service: this.state.report.service,
        },
        // report_service:this.state.report_service
      });

      this.getPracticeWiseGridData(true);
    }
  };

  practiceRename = (practice) => {
    var renamedPractice = practice;
    switch (practice) {
      case "Mean":
        renamedPractice = "ME";
        break;
      case "Mobile":
        renamedPractice = "MOB";
        break;
      case "Java":
        renamedPractice = "JV";
        break;
      case "Python":
        renamedPractice = "PY";
        break;
      case "Mobile - RN":
        renamedPractice = "M-R";
        break;
      case "Finance":
        renamedPractice = "FIN";
        break;
      case "Java script":
        renamedPractice = "JS";
        break;
      case "Machine Learning":
        renamedPractice = "ML";
        break;
      case "others":
        renamedPractice = "O";
        break;
      case "Business Consulting":
        renamedPractice = "BC";
        break;
      case "WordPress":
        renamedPractice = "WP";
        break;
      case ".NET USEC":
        renamedPractice = ".N-U";
        break;
      case "Business Team":
        renamedPractice = "BT";
        break;
      case "Business Team-BCG  1":
        renamedPractice = "BT - 1";
        break;
      case "BI USEC":
        renamedPractice = "BI-U";
        break;
      case "BT USEC":
        renamedPractice = "BT-U";
        break;
      case "AX USEC":
        renamedPractice = "AX-U";
        break;
      case "Techlead":
        renamedPractice = "TL";
        break;
      case "MOBILE Android":
        renamedPractice = "MA";
        break;
      case "Xamarin":
        renamedPractice = "XM";
        break;
      case "Android":
        renamedPractice = "AD";
        break;
      case "DevOps":
        renamedPractice = "DOP";
        break;
      case "Data Labs":
        renamedPractice = "DL";
        break;
      case "Client Manager":
        renamedPractice = "CM";
        break;
      case "Flutter":
        renamedPractice = "FL";
        break;
      case "Zamarin":
        renamedPractice = "ZM";
        break;
      case "3D Developer":
        renamedPractice = "3D-D";
        break;
      case "3D Artist":
        renamedPractice = "3D-A";
        break;
      case "Data Annotation":
        renamedPractice = "DA";
        break;
      case "Cross Platform":
        renamedPractice = "CP";
        break;
      case "Talent Acquisition":
        renamedPractice = "TA";
        break;
      case "DataOps":
        renamedPractice = "DOP";
        break;
      case "OSDI":
        renamedPractice = "OSDI";
        break;
      default:
        break;
    }
    return renamedPractice;
  };

  changeViewPracite = (flag) => {
    if (this.state.loginUserData.role_id == 8 && this.state.isAllPractices) {
      this.state.isAllPractices = flag;
    } else {
      this.state.isAllPractices = flag;
    }
  };

  selectReport = (report) => {
    this.state.previous_report = this.state.current_report;
    this.state.current_report = report;
    this.state.project_filter = {};
    if (this.state.reports_lists[0].value == this.state.current_report.value) {
      //this.state.searchBy = '';
      var date = new Date();
      var date = new Date(date.getFullYear(), date.getMonth(), 2);
      this.state.filter_end_date = this.state.filter_end_date
        ? this.state.filter_end_date
        : date;
      this.state.filter_start_date = this.state.filter_start_date
        ? this.state.filter_start_date
        : date;
      this.state.report.practice = this.state.report.practice
        ? this.state.report.practice
        : [];
      this.state.report.head_id = "";
      this.selectPractice("");
      // this.state
    } else if (
      this.state.reports_lists[1].value === this.state.current_report
    ) {
      this.state.filter_start_date = new Date();
      this.state.techLeadId = "";
      this.state.businessAnalystId = "";
      this.state.searchByTechLead = "";
      this.state.searchByBusinessAnalyst = "";
      this.state.report.head_id = "";
      this.state.submitted = false;
      this.state.mileStoneReportPractice = 1;
      this.state.filterByPractice = "";
      this.state.filterMileStoneValue = "";

      // this.getTechLeadList();
      this.generateMileStoneValueReport(true);
    } else {
      //
    }
  };

  getReportByHead = (e) => {
    this.state.checkedList=[];
    if (e != undefined) {
      this.state.bu_id = e.bu_id;
      this.state.head_id = e.label;
      this.state.selectBuFilter = e;
    } else {
      this.state.head_id = "All";
      this.state.bu_id = "";
      this.state.selectBuFilter = [];
    }

    if (this.state.head_id != "All") {
      // for BU filter
      this.state.report.project_practice = [];
      var service_head = [];

      this.state.report.service = [];
      this.state.report.service = [];
      this.state.sname1.forEach((service) => {
        if (this.state.bu_id == service.bu) {
          service_head.push(service);
        }
      });
      this.state.serviceName = [];
      this.state.selectService = [];
      service_head.forEach((value) => {
        var opt = {};
        opt.label = value.value;
        opt.id = value.id;
        opt.value = value.value;
        opt.head = value.head;
        opt.bu = value.bu;
        this.state.serviceName.push(opt);
      });
      this.state.selectService = [];

      var service = this.state.report.service;
      if (service.length == 0) {
        this.state.projectPractice = [];
        this.state.report.practice = [];
        service_head.forEach((svalue) => {
          this.state.report_all_practices.forEach((value) => {
            if (svalue.id == value.serviceId) {
              this.state.projectPractice.push(value);
            }
          });
        });
        if (this.state.projectPractice.length > 0) {
          this.state.projectPractice.forEach((value) => {
            this.state.report.practice.push(value.id);
          });
        }
      } else {
        service.forEach((svalue) => {
          this.state.report_all_practices.forEach((value) => {
            if (svalue.id == value.serviceId) {
              this.state.projectPractice.push(value);
            }
          });
        });
        if (this.state.projectPractice.length > 0) {
          this.state.projectPractice.forEach((value) => {
            this.state.report.practice.push(value.id);
          });
        }
      }
    } else {
      // when All BU is selected
      this.filterService("");
      if(this.state.loginUserData.role_id == 8  || this.state.loginUserData.role_id == 1){
        var service_head = [];
        this.state.selectBuFilter = [];
        this.state.report.service = [];
        if (this.state.report.service.length == 0) {
          //nothing
          this.state.sname1.forEach((service) => {
            service_head.push(service);
          });
          this.state.serviceName = [];
          this.state.selectService = [];
          service_head.forEach((value) => {
            var opt = {};
            opt.label = value.value;
            opt.id = value.id;
            opt.value = value.value;
            opt.head = value.head;
            opt.bu = value.bu;
            this.state.serviceName.push(opt);
          });
          this.state.selectService = [];
        } else {
          this.state.report.service = [];
          this.state.sname.forEach((value) => {
            var opt = {};
            opt.label = value.value;
            opt.id = value.id;
            opt.value = value.value;
            opt.head = value.head;
            opt.bu = value.bu;
            this.state.serviceName.push(opt);
          });
          this.state.selectService = this.state.serviceName[0];
          this.state.report.service.push(this.state.serviceName[0]);
        }
      } else {
        this.filterService("");
      }
    }
    this.state.report_all_practices.forEach((value) => {
      value.is_active = false;
    });
    this.setState({
      checkedList:[],
      selectBuFilter: this.state.selectBuFilter,
      all_active: true,
    });
    this.getPracticeWiseGridData(true);
  };

  ToastAlert(icon, title) {
    return Toast.fire({
      target: document.getElementById("form-modal"),
      icon: `${icon}`,
      title: `${title}`,
    });
  }

  HandleClick() {
    Swal.fire({
      title: "Success",
      type: "success",
      text: "Your work has been saved.",
    });
  }

  resetReport = () => {
    this.state.resourceFilter = this.state.resourceAllFilter;
    this.state.searchBy = "";
    this.setState({
      resourceFilter: this.state.resourceFilter,
    });
    this.getPracticeService();
  };

  getPracticeWiseGridData = () => {
    this.state.loading = true;
    this.state.showNodata = false;
    this.state.isGanttView = false;
    var reportData = {};
    reportData.business_unit = this.state.bu_id;
    var service_head = [];
    var services = [];
    var date = moment().utcOffset("+05:30").format("YYYY-MM-01");
    if (reportData.business_unit != "") {
      this.state.report_service = [];
      this.state.report_practice = [];
      this.state.projectPractice = [];
      this.state.sname1.forEach((service) => {
        if (this.state.bu_id == service.bu) {
          service_head.push(service);
        }
      });
      services = service_head;
      if (this.state.projectPractice.length > 0) {
        this.state.projectPractice.forEach((value) => {
          this.state.report.practice.push(value.id);
        });
      }
    } else {
      this.state.sname1.forEach((service) => {
        services.push(service);
      });
    }
    var ser = [];
    console.log('this.state.report.service',this.state.report.service)
    if (this.state.report.service.length > 0) {
      reportData.service = [this.state.report.service[0].id];
      console.log('reportData.service',this.state.report.service[0].id)
      this.state.report.service.forEach((svalue) => {
        ser.push(svalue.id);
      });
      reportData.service = ser;
    } else {
      reportData.service = [];
    }
   
    if (this.state.report.practice.length == 0) {
      reportData.practice = this.state.report_practice;
      this.state.report_all_practices.forEach((value) => {
        this.state.projectPractice.push(value);
      });
    } else {
      reportData.practice = this.state.report.practice;
      this.state.report.practice.forEach((pracvalue) => {
        this.state.report_all_practices.forEach((value) => {
          if (pracvalue == value.id) {
            this.state.projectPractice.push(value);
          }
        });
      });
    }
    reportData.all = this.state.practice_length == 0 ? 1 : 0;
    //Generate
    if (this.state.checkedList.length > 0 && this.state.practice_length) {
      let me = this;
      reportData.practice = [];
      this.state.checkedList.map(function (checklistValue) {
        _.filter(me.state.report_all_practices, function (practiceValue) {
          if (checklistValue.id == practiceValue.id) {
            practiceValue.is_active = true;
            me.state.projectPractice.push(practiceValue);
            reportData.practice.push(checklistValue.id);
          }
        });
      });
    }
    reportData.start_date = moment(this.state.filter_start_date)
      .utcOffset("+05:30")
      .format("YYYY-MM-01");
    reportData.end_date = moment(this.state.filter_end_date)
      .utcOffset("+05:30")
      .format("YYYY-MM-01");
    reportData.allpractice = this.state.isAllPractices;
    var resourceFilter = {};
    this.state.resource_types.forEach((user) => {
      if (user.value == this.state.resourceFilter) {
        resourceFilter.id = user.id;
        resourceFilter.value = user.value;
      }
    });
    reportData.selectedUserType = resourceFilter;
    this.setState({ loading: true });
    let link = this.state.apiLink + "get_resource_allocation";
    let token = this.state.queryParams.get("token");
    var prac = [],
      ser = [],
      bu = [],
      qes_ids = [];
    this.state.qes_list.forEach((val) => {
      prac.push(val.practice_id);
      ser.push(val.service_id);
      bu.push(val.business_unit);
    });
    if (this.state.token == null) {
      this.state.token = Cookies.get("auth_data");
    }
    return fetch(link, {
      method: "POST",
      body: JSON.stringify({
        reportData,
      }),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        Authorization: "Bearer " + this.state.token,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        this.setState({ loading: false,typeId : 0,
          emp_category : '' });
        if (res.status) {
          this.state.report.practice = [];
          var array = [],
            array1 = [],
            array2 = [],
            array3 = [],
            pw_report = [],
            arrays = [],
            qes_array = [];
          res.result.data.forEach((value) => {
            value.emp_category =
              value.is_old_intern && value.is_old_intern == 1
                ? "intern"
                : value.emp_category;
            if (value.project_name == "Bench") {
              value.hrms_projectcode = null;
              if (array.indexOf(value.id) == -1) {
                array.push(value.id);
                array2.push(value);
              } else {
                array2.forEach((val) => {
                  if (val.id == value.id) {
                    value.available_hrs += val.available_hrs;
                    value.available_hrs =
                      value.available_hrs > value.month_size
                        ? value.available_hrs - value.month_size
                        : value.available_hrs;
                  }
                });
                array3.push(value);
              }
            } else {
              arrays.push(value);
            }
          });
          array3.forEach((value) => {
            arrays.push(value);
            array2.forEach((value1) => {
              if (value.id == value1.id) {
                value1.duplicate = 1;
              }
            });
          });
          array2.forEach((value1) => {
            if (value1.duplicate == undefined) {
              arrays.push(value1);
            }
          });
          arrays.forEach((value) => {
            if (value.project_name == "Bench") {
              if (
                value.available_hrs > 0 ||
                value.allocated_ms > 0 ||
                value.achieved_ms > 0
              ) {
                pw_report.push(value);
              }
            } else {
              pw_report.push(value);
            }
          });
          pw_report.forEach((pvalue) => {
            if (reportData.practice.length > 0) {
              if (
                reportData.practice.includes(pvalue.user_practice) ||
                reportData.practice.includes(this.state.practice_qes)
              ) {
                if (
                  prac.includes(pvalue.user_practice) ||
                  prac.includes(pvalue.user_project_practice)
                ) {
                  if (
                    pvalue.allocated_ms > 0 ||
                    pvalue.planned_hrs > 0 ||
                    pvalue.support_hrs > 0
                  ) {
                    pvalue.user_practice = this.state.practice_qes;
                    pvalue.service = this.state.service_qes;
                    pvalue.user_project_practice = this.state.practice_qes;
                    qes_ids.push(pvalue.id);
                    if (reportData.practice.includes(this.state.practice_qes)) {
                      qes_array.push(pvalue);
                    }
                  } else {
                    if (
                      reportData.practice.includes(reportData.user_practice) ||
                      !reportData.practice.includes(this.state.practice_qes)
                    ) {
                      qes_array.push(pvalue);
                    }
                  }
                } else {
                  qes_array.push(pvalue);
                }
              }
            } else {
              if (reportData.service.length > 0) {
                if (
                  reportData.service.includes(pvalue.service) ||
                  reportData.service.includes(this.state.service_qes)
                ) {
                  if (
                    prac.includes(pvalue.user_practice) ||
                    prac.includes(pvalue.user_project_practice)
                  ) {
                    if (
                      pvalue.allocated_ms > 0 ||
                      pvalue.planned_hrs > 0 ||
                      pvalue.support_hrs > 0
                    ) {
                      pvalue.user_practice = this.state.practice_qes;
                      pvalue.service = this.state.service_qes;
                      pvalue.user_project_practice = this.state.practice_qes;
                      qes_ids.push(pvalue.id);
                      if (
                        reportData.practice.includes(this.state.practice_qes)
                      ) {
                        qes_array.push(pvalue);
                      }
                    } else {
                      qes_array.push(pvalue);
                    }
                  } else {
                    qes_array.push(pvalue);
                  }
                }
              } else {
                if (
                  prac.includes(pvalue.user_practice) ||
                  prac.includes(pvalue.user_project_practice)
                ) {
                  if (
                    pvalue.allocated_ms > 0 ||
                    pvalue.planned_hrs > 0 ||
                    pvalue.support_hrs > 0
                  ) {
                    pvalue.user_practice = this.state.practice_qes;
                    pvalue.service = this.state.service_qes;
                    pvalue.user_project_practice = this.state.practice_qes;
                    qes_ids.push(pvalue.id);
                    if (
                      reportData.business_unit == this.state.bu_qes ||
                      reportData.business_unit == ""
                    ) {
                      qes_ids.push(pvalue.id);
                      qes_array.push(pvalue);
                    }
                  } else {
                    if (
                      bu.includes(reportData.business_unit) ||
                      reportData.business_unit == ""
                    ) {
                      qes_array.push(pvalue);
                    }
                  }
                } else {
                  qes_array.push(pvalue);
                }
              }
            }
          });

          this.state.gridListBackup = qes_array;
          this.state.return_to_pwreport = 0;

          var service = [];
          service = this.state.report.service;
          this.state.projectPractice = [];
          if (service.length == 0) {
            if (services.length > 0) {
              services.forEach((svalue) => {
                this.state.report_all_practices.forEach((value) => {
                  if (svalue.id == value.serviceId) {
                    this.state.projectPractice.push(value);
                  }
                });
              });
            }
          } else {
            service.forEach((svalue) => {
              this.state.report_all_practices.forEach((value) => {
                if (svalue.id == value.serviceId) {
                  this.state.projectPractice.push(value);
                }
              });
            });
          }
          this.setState({
            gridListBackup: this.state.gridListBackup,
            return_to_pwreport: this.state.return_to_pwreport,
            selectBU: this.state.selectBU,
          });
          this.gridCalculation(this.state.userTypeId);
        }
      });
  };

  //grid calculation
  gridCalculation = (userTypeId, e) => {
    var filteredData = [];
    if (e != undefined) {
      var searchInput = e.target.value;
      this.state.searchBy = searchInput;
      filteredData = this.state.gridListBackup.filter((item) => {
        return Object.values(item)
          .join("")
          .toLowerCase()
          .includes(searchInput.toLowerCase());
      });
    } else {
      filteredData = this.state.gridListBackup;
    }
    var effective_yield = 0;
    var expected_yield = 0;
    var gap_in_allocation = 0;
    var deficit = 0;
    this.state.msAllocatedHrs = 0;
    this.state.supportHrs = 0;
    this.state.benchHrs = 0;
    this.state.totalResourceAvailHrsFrontendCalcution = 0;

    this.state.utilizationCounts = {};
    this.state.utilizationCounts.totalAvailHrs = 0;
    this.state.utilizationCounts.milestoneAllocated = 0;
    this.state.utilizationCounts.supportHrs = 0;
    this.state.utilizationCounts.benchHrs = 0;

    this.state.utilizationCounts.moolahTotalAvailHrs = 0;
    this.state.utilizationCounts.moolahMilestoneHrs = 0;
    this.state.utilizationCounts.moolahSupportHrs = 0;
    this.state.utilizationCounts.moolahSupportHrs_value = 0;
    this.state.utilizationCounts.moolahSupportHrs_valueNew = 0;
    this.state.utilizationCounts.moolahBenchHrs = 0;
    this.state.utilizationCounts.moolahBenchHrsNew = 0;

    this.state.utilizationCounts.boolahTotalAvailHrs = 0;
    this.state.utilizationCounts.boolahMilestoneHrs = 0;
    this.state.utilizationCounts.boolahSupportHrs = 0;
    this.state.utilizationCounts.boolahSupportHrs_value = 0;
    this.state.utilizationCounts.boolahSupportHrs_valueNew = 0;
    this.state.utilizationCounts.boolahBenchHrs = 0;
    this.state.utilizationCounts.boolahBenchHrsNew = 0;

    this.state.utilizationCounts.supportUserMilestoneHrs = 0;
    this.state.utilizationCounts.supportUserSupportHrs = 0;
    this.state.utilizationCounts.supportUserTotalAvailHrs = 0;
    this.state.utilizationCounts.supportUserSupportHrs_value = 0;
    this.state.utilizationCounts.supportUserSupportHrs_valueNew = 0;
    this.state.utilizationCounts.supportUserBenchHrs = 0;
    this.state.utilizationCounts.supportUserBenchHrsNew = 0;

    this.state.utilizationCounts.vendorTotalAvailHrs = 0;
    this.state.utilizationCounts.vendorMilestoneHrs = 0;
    this.state.utilizationCounts.vendorSupportHrs = 0;
    this.state.utilizationCounts.vendorBenchHrs = 0;
    this.state.utilizationCounts.vendorBenchHrsNew = 0;
    this.state.utilizationCounts.vendorSupportHrs_value = 0;
    this.state.utilizationCounts.vendorSupportHrs_valueNew = 0;

    this.state.utilizationCounts.externTotalAvailHrs = 0;
    this.state.utilizationCounts.externMilestoneHrs = 0;
    this.state.utilizationCounts.externSupportHrs = 0;
    this.state.utilizationCounts.externBenchHrs = 0;
    this.state.utilizationCounts.externBenchHrsNew = 0;
    this.state.utilizationCounts.externSupportHrs_value = 0;
    this.state.utilizationCounts.externSupportHrs_valueNew = 0;

    this.state.front_actual_allocation_m = 0;
    this.state.moolahDeficit = 0;
    this.state.boolahDeficit = 0;
    this.state.bcDeficit = 0;
    this.state.vendorDeficit = 0;
    this.state.externDeficit = 0;
    this.state.internDeficit = 0;
    this.state.moolahDeficits = 0;
    this.state.boolahDeficits = 0;
    this.state.bcDeficits = 0;
    this.state.vendorDeficits = 0;
    this.state.externDeficits = 0;
    this.state.internDeficits = 0;

    this.state.yieldCounts = {};
    this.state.yieldCounts.yieldValue = 0;
    this.state.yieldCounts.allocatedAmount = 0;
    this.state.yieldCounts.kickoffAmount = 0;
    this.state.yieldCounts.allocatedAmount_m = 0;
    this.state.yieldCounts.allocatedAmount_b = 0;
    this.state.yieldCounts.allocatedAmount_s = 0;
    this.state.yieldCounts.allocatedAmount_v = 0;
    this.state.yieldCounts.allocatedAmount_i = 0;
    this.state.yieldCounts.allocatedAmount_e = 0;
    this.state.yieldCounts.achievedAmount = 0;
    this.state.yieldCounts.moolahAllocatedAmount = 0;
    this.state.yieldCounts.moolahAchievedAmount = 0;
    this.state.yieldCounts.boolahAllocatedAmount = 0;
    this.state.yieldCounts.boolahAchievedAmount = 0;

    this.state.totalResourceYieldValue = 0;
    this.state.totaleffectiveYieldValue = 0;
    this.state.sum_bench_value = 0;

    var tempUserAvailHrs = [];
    var filtered_user_list = [];
    this.state.gridList = [];
    var name = [];
    this.state.practiceleadName = "";
    var size = Object.keys(this.state.report.service).length;
    if (size == 0 && this.state.bu_id == "") {
      this.state.practiceHead.forEach((ph) => {
        if (ph.name != "All") {
          if (name.indexOf(ph.name) == -1) {
            name.push(ph.name);
          }
        }
      });
      this.state.practiceleadName = name.join();
    } else {
      if (size > 0) {
        this.state.practiceHead.forEach((ph) => {
          this.state.report.service.forEach((service) => {
            if (ph.id == service.head) {
              if (name.indexOf(ph.name) == -1) {
                name.push(ph.name);
              }
            }
          });
        });
        this.state.practiceleadName = name.join();
        // });
      } else {
        this.state.serviceName.forEach((servalue) => {
          this.state.practiceHead.forEach((ph) => {
            if (ph.id == servalue.head) {
              if (name.indexOf(ph.name) == -1) {
                name.push(ph.name);
              }
            }
          });
          this.state.practiceleadName = name.join();
        });
      }
    }
    /** Total inventory count */
    filteredData.forEach((value) => {
      if (typeof tempUserAvailHrs[value.user_id] === "undefined") {
        tempUserAvailHrs[value.user_id] = value.available_hrs;
      }
      filtered_user_list.push(value);
      let arr = [];
      filtered_user_list.forEach((entry) => {
        let uid = entry.id;
        if (arr.indexOf(uid) == -1) {
          arr.push(uid);
        }
      });

      this.state.getUserscount = arr;

      /* Count user inventory based on the practice */
      let me = this;
      this.state.projectPractice.map(function (value, i) { 
        let userPracticeCount = []; 
        let userList;
        _.filter(filtered_user_list, function (userId) { 
          if (
            value.id == userId.user_practice &&
            userPracticeCount.indexOf(userId.id) == -1
          ) {
            userPracticeCount.push(userId.id); 
          }  
          userList = userPracticeCount.length; 
          me.state.projectPractice[i].countList = userList; 
        }); 
      });

      var allocated_ms = value.allocated_ms;
      var kickoff_amount = 0;
      if(value.project_name=='Kickoff'){
        allocated_ms = 0;
        kickoff_amount = value.kickoff_amount;
      }
      value.teamlead = value.teamlead ? value.teamlead : "NA";
      this.state.unallocated_hrs = 0;
      this.state.unallocated_hrs =
        Number(value.available_hrs) > 0
          ? Number(value.available_hrs) -
            (Number(value.planned_hrs) + Number(value.support_hrs))
          : 0;
      value.unallocated_hrs =
        this.state.unallocated_hrs > 0 ? this.state.unallocated_hrs : 0;
      this.state.benchHrs += value.unallocated_hrs;

      effective_yield = (value.emp_category && value.emp_category === "intern") ? value.cost : value.available_hrs * value.cost;
      this.state.effective_yield = effective_yield;
      value.effective_yield = parseFloat(this.state.effective_yield).toFixed(2);

      expected_yield = value.available_hrs * value.price;
      this.state.expected_yield = expected_yield;
      if (Number.isInteger(this.state.expected_yield)) {
        value.expected_yield = this.state.expected_yield;
      } else {
        value.expected_yield = parseFloat(this.state.expected_yield).toFixed(2);
      }
      this.state.totalResourceYieldValue += Number(value.expected_yield);
      this.state.totalResourceYieldValueNew =
        this.state.totalResourceYieldValue;
      this.state.totalResourceYieldValueNewValue =
        this.state.totalResourceYieldValue -
        (this.state.utilizationCounts.moolahBenchHrs +
          this.state.utilizationCounts.boolahBenchHrs +
          this.state.utilizationCounts.supportUserBenchHrs);
      this.state.totalResourceYieldValueNewValueExp =
        this.state.totalResourceYieldValueNewValue;
      this.state.totaleffectiveYieldValue += Number(value.effective_yield);
      this.state.totaleffectiveYieldValueNew =
        this.state.totaleffectiveYieldValue;
      this.state.totaleffectiveYieldValueCount =
        this.state.totaleffectiveYieldValue / this.state.getUserscount.length;
      this.state.totaleffectiveYieldValueCountNew =
        this.state.totaleffectiveYieldValueCount;
      gap_in_allocation = value.expected_yield - value.allocated_ms;
      this.state.gap_in_allocation = gap_in_allocation;
      if (Math.sign(gap_in_allocation) == 0) {
        value.gap_in_allocation = this.state.gap_in_allocation;
      } else {
        value.gap_in_allocation = parseFloat(
          this.state.gap_in_allocation
        ).toFixed(2);
      }

      if (value.gap_in_allocation > 0 && value.expected_yield) {
        value.deficit = (
          (value.gap_in_allocation / value.expected_yield) *
          100
        ).toFixed(2);
      } else {
        value.deficit = 0;
      }
      this.state.gridList.push(value);

      if (userTypeId == 0) {
        //on page load
        this.state.totalResourceAvailHrsFrontendCalcution =
          this.state.totalResourceAvailHrsFrontendCalcution +
          Number(value.available_hrs);
        this.state.totalResAvHrCal =
          this.state.totalResourceAvailHrsFrontendCalcution;
        this.state.msAllocatedHrs =
          this.state.msAllocatedHrs + Number(value.planned_hrs);
        this.state.msAlloc = this.state.msAllocatedHrs;
        this.state.supportHrs =
          this.state.supportHrs + Number(value.support_hrs);
        this.state.supphrs = this.state.supportHrs;
      }
      this.state.yieldCounts.allocatedAmount =
        this.state.yieldCounts.allocatedAmount + Number(allocated_ms);
      this.state.yieldCounts.allocatedAmountNew =
        this.state.yieldCounts.allocatedAmount.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
      this.state.yieldCounts.kickoffAmount = this.state.yieldCounts.kickoffAmount + Number(kickoff_amount);
      this.state.yieldCounts.yieldValue =
        this.state.yieldCounts.yieldValue + value.price * value.available_hrs;
      this.state.sum_bench_value += Number(value.price * value.unallocated_hrs);
      this.state.yieldCounts.achievedAmount =
        this.state.yieldCounts.achievedAmount + Number(value.achieved_ms);
      this.state.yieldCounts.achievedAmountNew =
        this.state.yieldCounts.achievedAmount.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });

      if (value.user_type != 5 && value.user_type != 6) {
        value.user_type =
          value.old_usertype == null ? value.user_type : value.old_usertype;
      }
      if (
        value.user_type == this.state.user_types[0].id &&
        (value.emp_category == null || value.emp_category === "permanent")
      ) {
        //for moolah
        this.state.utilizationCounts.moolahTotalAvailHrs =
          this.state.utilizationCounts.moolahTotalAvailHrs +
          parseInt(value.available_hrs);
        this.state.utilizationCounts.moolahMilestoneHrs =
          Number(this.state.utilizationCounts.moolahMilestoneHrs) +
          Number(value.planned_hrs);
        this.state.utilizationCounts.moolahSupportHrs =
          Number(this.state.utilizationCounts.moolahSupportHrs) +
          Number(value.support_hrs);
        this.state.utilizationCounts.moolahSupportHrs_value =
          Number(this.state.utilizationCounts.moolahSupportHrs_value) +
          value.support_hrs * value.price;
        this.state.utilizationCounts.moolahSupportHrs_valueNew =
          this.state.utilizationCounts.moolahSupportHrs_value.toLocaleString(
            undefined,
            {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }
          );
        this.state.utilizationCounts.moolahBenchHrs =
          Number(this.state.utilizationCounts.moolahBenchHrs) +
          value.unallocated_hrs * value.price;
        this.state.utilizationCounts.moolahBenchHrsNew =
          this.state.utilizationCounts.moolahBenchHrs.toLocaleString(
            undefined,
            {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }
          );
        this.state.moolahDeficit += parseFloat(value.gap_in_allocation);
        this.state.moolahDeficits = this.state.moolahDeficit.toLocaleString(
          undefined,
          {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }
        );
        this.state.yieldCounts.allocatedAmount_m += value.allocated_ms;
      } else if (
        value.user_type == this.state.user_types[1].id &&
        (value.emp_category == null || value.emp_category === "permanent")
      ) {
        //for boolah
        this.state.utilizationCounts.boolahTotalAvailHrs =
          this.state.utilizationCounts.boolahTotalAvailHrs +
          parseInt(value.available_hrs);
        this.state.utilizationCounts.boolahMilestoneHrs =
          Number(this.state.utilizationCounts.boolahMilestoneHrs) +
          Number(value.planned_hrs);
        this.state.utilizationCounts.boolahSupportHrs =
          Number(this.state.utilizationCounts.boolahSupportHrs) +
          Number(value.support_hrs);
        this.state.utilizationCounts.boolahSupportHrs_value =
          Number(this.state.utilizationCounts.boolahSupportHrs_value) +
          value.support_hrs * value.price;
        this.state.utilizationCounts.boolahSupportHrs_valueNew =
          this.state.utilizationCounts.boolahSupportHrs_value.toLocaleString(
            undefined,
            {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }
          );
        this.state.utilizationCounts.boolahBenchHrs =
          Number(this.state.utilizationCounts.boolahBenchHrs) +
          value.unallocated_hrs * value.price;
        this.state.utilizationCounts.boolahBenchHrsNew =
          this.state.utilizationCounts.boolahBenchHrs.toLocaleString(
            undefined,
            {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }
          );
        this.state.boolahDeficit += parseFloat(value.gap_in_allocation);
        this.state.boolahDeficits = this.state.boolahDeficit.toLocaleString(
          undefined,
          {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }
        );
        this.state.yieldCounts.allocatedAmount_b += value.allocated_ms;
      } else if (
        value.user_type == this.state.user_types[2].id &&
        (value.emp_category == null || value.emp_category === "permanent")
      ) {
        //for support user
        this.state.utilizationCounts.supportUserTotalAvailHrs =
          this.state.utilizationCounts.supportUserTotalAvailHrs +
          parseInt(value.available_hrs);
        this.state.utilizationCounts.supportUserMilestoneHrs =
          Number(this.state.utilizationCounts.supportUserMilestoneHrs) +
          Number(value.planned_hrs);
        this.state.utilizationCounts.supportUserSupportHrs =
          Number(this.state.utilizationCounts.supportUserSupportHrs) +
          Number(value.support_hrs);
        this.state.utilizationCounts.supportUserSupportHrs_value =
          Number(this.state.utilizationCounts.supportUserSupportHrs_value) +
          value.support_hrs * value.price;
        this.state.utilizationCounts.supportUserSupportHrs_valueNew =
          this.state.utilizationCounts.supportUserSupportHrs_value.toLocaleString(
            undefined,
            {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }
          );
        this.state.utilizationCounts.supportUserBenchHrs =
          Number(this.state.utilizationCounts.supportUserBenchHrs) +
          value.unallocated_hrs * value.price;
        this.state.utilizationCounts.supportUserBenchHrsNew =
          this.state.utilizationCounts.supportUserBenchHrs.toLocaleString(
            undefined,
            {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }
          );
        this.state.bcDeficit += parseFloat(value.gap_in_allocation);
        this.state.bcDeficits = this.state.bcDeficit.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
        this.state.yieldCounts.allocatedAmount_s += value.allocated_ms;
      } else if (value.user_type == this.state.user_types[4].id) {
        // for vendor users
        this.state.utilizationCounts.vendorTotalAvailHrs =
          this.state.utilizationCounts.vendorTotalAvailHrs +
          parseInt(value.available_hrs);
        this.state.utilizationCounts.vendorMilestoneHrs =
          Number(this.state.utilizationCounts.vendorMilestoneHrs) +
          Number(value.planned_hrs);
        this.state.utilizationCounts.vendorSupportHrs =
          Number(this.state.utilizationCounts.vendorSupportHrs) +
          Number(value.support_hrs);
        this.state.utilizationCounts.vendorSupportHrs_value =
          Number(this.state.utilizationCounts.vendorSupportHrs_value) +
          value.support_hrs * value.price;
        this.state.utilizationCounts.vendorSupportHrs_valueNew =
          this.state.utilizationCounts.vendorSupportHrs_value.toLocaleString(
            undefined,
            {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }
          );
        this.state.utilizationCounts.vendorBenchHrs =
          Number(this.state.utilizationCounts.vendorBenchHrs) +
          value.unallocated_hrs * value.price;
        this.state.utilizationCounts.vendorBenchHrsNew =
          this.state.utilizationCounts.vendorBenchHrs.toLocaleString(
            undefined,
            {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }
          );
        this.state.vendorDeficit += parseFloat(value.gap_in_allocation);
        this.state.vendorDeficits = this.state.vendorDeficit.toLocaleString(
          undefined,
          {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }
        );
        this.state.yieldCounts.allocatedAmount_v += value.allocated_ms;
      } else if (value.user_type == this.state.user_types[5].id) {
        // for extern users
        this.state.utilizationCounts.externTotalAvailHrs =
          this.state.utilizationCounts.externTotalAvailHrs +
          parseInt(value.available_hrs);
        this.state.utilizationCounts.externMilestoneHrs =
          Number(this.state.utilizationCounts.externMilestoneHrs) +
          Number(value.planned_hrs);
        this.state.utilizationCounts.externSupportHrs =
          Number(this.state.utilizationCounts.externSupportHrs) +
          Number(value.support_hrs);
        this.state.utilizationCounts.externSupportHrs_value =
          Number(this.state.utilizationCounts.externSupportHrs_value) +
          value.support_hrs * value.price;
        this.state.utilizationCounts.externSupportHrs_valueNew =
          this.state.utilizationCounts.externSupportHrs_value.toLocaleString(
            undefined,
            {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }
          );
        this.state.utilizationCounts.externBenchHrs =
          Number(this.state.utilizationCounts.externBenchHrs) +
          value.unallocated_hrs * value.price;
        this.state.utilizationCounts.externBenchHrsNew =
          this.state.utilizationCounts.externBenchHrs;
        this.state.externDeficit += parseFloat(value.gap_in_allocation);
        this.state.externDeficits = this.state.externDeficit.toLocaleString(
          undefined,
          {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }
        );
        this.state.yieldCounts.allocatedAmount_e += value.allocated_ms;
      } else if (value.emp_category === "intern") {
        // for intern users
        this.state.internDeficit += parseFloat(value.gap_in_allocation);
        this.state.internDeficits = this.state.internDeficit.toLocaleString(
          undefined,
          {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }
        );
        this.state.yieldCounts.allocatedAmount_i += value.allocated_ms;
      }
      this.state.sum_bench_value =
        this.state.totalResourceYieldValue - this.state.sum_bench_value;
    });
    let count = 0;
    let intern_data = _.filter(this.state.gridList, function (data) {
      if (data.emp_category == "intern") {
        return data;
      }
    });
    count = intern_data ? intern_data.length : 0;
    if (this.state.gridList.length == 0) {
      this.state.totalResAvHrCal = 0;
      this.state.msAlloc = 0;
      this.state.supphrs = 0;
      this.state.getUserscount = "";
    }
    this.setState({
      gridList: this.state.gridList,
      practiceleadName: this.state.practiceleadName,
      utilizationCounts: this.state.utilizationCounts,
      intern_count: count,
    });
  };

  selectResource = (e) => {
    var index = e.target.selectedIndex;
    var el = e.target.childNodes[index];
    var user_id = el.getAttribute("id");
    this.state.resourceFilter = e.target.value;
    if (this.state.filter_start_date) {
      this.state.submitted = false;
      this.getPracticeWiseGridData();
    } else {
      this.state.submitted = true;
    }
  };

  filterMilestoneHrs = (e, msType) => {
    let filterRecords = [];
    let bench_hrs = 0,support_hrs = 0,milestone_hrs = 0;
    if (msType === "milestone") { 
      this.state.gridListBackup.forEach((reportValue) => {
        if (
          reportValue.planned_hrs != 0 &&
          filterRecords.indexOf(reportValue) == -1
        ) {
          milestone_hrs += reportValue.planned_hrs;
          filterRecords.push(reportValue);
        }
      });
    } else if (msType === "support") {
      this.state.gridListBackup.forEach((reportValue) => {
        if (
          reportValue.support_hrs != 0 &&
          filterRecords.indexOf(reportValue) == -1
        ) {
          support_hrs += reportValue.support_hrs;
          filterRecords.push(reportValue);
        }
      });
    } else if (msType === "bench") {     
      this.state.gridListBackup.forEach((reportValue) => {
        if (
          reportValue.unallocated_hrs != 0 &&
          filterRecords.indexOf(reportValue) == -1
        ) {
          filterRecords.push(reportValue);
          bench_hrs += reportValue.unallocated_hrs;
        }
      });
    } else if (msType === "intern") {
      this.state.gridListBackup.forEach((reportValue) => {
        if (
          reportValue.emp_category === "intern" &&
          filterRecords.indexOf(reportValue) == -1
        ) {
          filterRecords.push(reportValue);
        }
      });
    } else {
      filterRecords = this.state.gridListBackup;
      bench_hrs = this.state.benchHrs;
      support_hrs = this.state.support_hrs;
      milestone_hrs = this.state.milestone_hrs;
      this.gridCalculation(0);
    }

    this.state.gridList = filterRecords;
    this.setState({
      gridList: this.state.gridList,
      emp_category : '',
      typeId : 0,
      benchHrs : bench_hrs,
      support_hrs : support_hrs,
      milestone_hrs : milestone_hrs
    });
    //vmReports.calculateUtilization();
  };

  selectPractice = (e, id, name) => {
    var resultArray = [];
    if (this.state.projectPractice.length > 0) {
      this.state.checkedList = [];
      this.state.projectPractice.forEach((value) => {
        if (value.id == id) {
          value.is_active = !value.is_active;
          var obj = {};
          obj.id = value.id;
          obj.is_active = value.is_active;
          if (obj.is_active == true) this.state.checkedList.push(obj);
        } else {
          var obj = {};
          obj.id = value.id;
          obj.is_active = value.is_active;
          if (obj.is_active == true) this.state.checkedList.push(obj);
        }
      });
    }

    this.state.report.userTypeId = 0;

    if (id == "" || id == undefined) {
      if (this.state.active == true) {
        this.setState({ active: !this.state.active });
      }
      if (this.state.isAllPractices == true) {
        this.state.report.service = [];
      }
      this.state.report.practice = [];
      this.setState({ all_active: true });
      // this.state.report.practice = (this.state.report.practice != '') ? this.state.report.practice : [];
      this.state.projectPractice.forEach((value) => {
        value.is_active = false;
        this.state.report.practice.push(value.id);
      });
      this.state.practice_length = 0;
      this.submitGenerateReport();
    } else {
      const currentState1 = this.state.all_active;
      const currentState = this.state.active;
      this.state.practice_length = 1;
      this.state.report.practice = [];
      //e.target.style.background="#f07f1e";
      this.setState({ all_active: false });
      if (this.state.checkedList.length > 0) {
        this.state.checkedList.forEach((cvalue) => {
          this.state.projectPractice.forEach((value) => {
            if (cvalue.id == value.id) {
              value.is_active = true;
            }
          });
        });
      }
    }
    this.state.reportPrac = [];
    if (this.state.checkedList.length == 0) {
      this.setState({ all_active: true });
    }
    this.state.checkedList.forEach((cvalue) => {
      if (this.state.reportPrac.indexOf(cvalue.id) == -1) {
        //value not exists
        this.state.reportPrac.push(cvalue.id);
      }
    });
    this.state.report.practice = this.state.reportPrac;
  };

  submitGenerateReport = () => {
    //practicewise allocation report
    if (this.state.filter_start_date > this.state.filter_end_date) {
      return false;
    } else {
      this.state.report.userTypeId = 0;
      if (this.state.isGanttView) {
        this.changeGanttView();
      } else {
        // if (this.state.isAllPractices == true) {
          this.filterService();
        // } else {
        //   this.getPracticeWiseGridData();
        // }
      }
    }
  };

  generateReportByPracticesExportAsExls = () => {
    this.setState({ loading: true });
    this.state.showNodata = false;
    var reportDetails = this.practiceWiseReportExecelFormater();
    const FileDownload = require("js-file-download");
    var link =
      this.state.apiLink +
      "export_as_excel_get_resource_allocation_by_practice";
    var token = this.state.queryParams.get("token");
    if (this.state.token == null) {
      this.state.token = Cookies.get("auth_data");
    }
    return fetch(link, {
      method: "POST",
      body: JSON.stringify({
        reportDetails,
      }),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        Authorization: "Bearer " + this.state.token,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        this.setState({ loading: false });
        this.ToastAlert("success", "Report generated Successfully");
        var result = res.result;
        document.location = this.state.excelUrl + result.filename;
      });
  };

  practiceWiseReportExecelFormater = () => {
    var formatedResult = [];
    var start_date = this.state.filter_start_date;
    var end_date = this.state.filter_end_date;
    var start_month = start_date.getMonth();
    var end_month = end_date.getMonth();
    var start_year = start_date.getFullYear();
    var end_year = end_date.getFullYear();
    this.state.gridListBackup.forEach((value) => {
      var month_name = function (dt) {
        var mlist = [
          "Jan",
          "Feb",
          "March",
          "Apr",
          "May",
          "June",
          "July",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ];
        return mlist[dt.getMonth()];
      };
      if (start_month == end_month) {
        //for period column in excel
        var period = month_name(new Date(start_date));
        value.period = period + " " + start_year;
      } else {
        var period1 = month_name(new Date(start_date));
        var period2 = month_name(new Date(end_date));
        value.period =
          period1 + " " + start_year + " - " + period2 + " " + end_year;
      }
      //for returing user category name
      var type_of_user = value.user_type;
      var old_unalloc_hrs = value.unallocated_hrs;
      if (parseInt(value.user_type) == 1) {
        value.m_unutilized_hrs =
          Number(value.available_hrs) > 0
            ? Number(value.available_hrs) -
              (Number(value.planned_hrs) + Number(value.support_hrs))
            : 0;
        value.b_unutilized_hrs = 0;
        value.v_unutilized_hrs = 0;
        value.e_unutilized_hrs = 0;
        value.unallocated_hrs = 0;
      } else if (parseInt(value.user_type) == 2) {
        value.m_unutilized_hrs = 0;
        value.b_unutilized_hrs =
          Number(value.available_hrs) > 0
            ? Number(value.available_hrs) -
              (Number(value.planned_hrs) + Number(value.support_hrs))
            : 0;
        value.v_unutilized_hrs = 0;
        value.e_unutilized_hrs = 0;
        value.unallocated_hrs = 0;
      } else if (parseInt(value.user_type) == 5) {
        value.m_unutilized_hrs = 0;
        value.b_unutilized_hrs = 0;
        value.v_unutilized_hrs =
          Number(value.available_hrs) > 0
            ? Number(value.available_hrs) -
              (Number(value.planned_hrs) + Number(value.support_hrs))
            : 0;
        value.e_unutilized_hrs = 0;
        value.unallocated_hrs = 0;
      } else if (parseInt(value.user_type) == 6) {
        value.m_unutilized_hrs = 0;
        value.b_unutilized_hrs = 0;
        value.v_unutilized_hrs = 0;
        value.e_unutilized_hrs =
          Number(value.available_hrs) > 0
            ? Number(value.available_hrs) -
              (Number(value.planned_hrs) + Number(value.support_hrs))
            : 0;
        value.unallocated_hrs = 0;
      } else {
        value.m_unutilized_hrs = 0;
        value.b_unutilized_hrs = 0;
        value.v_unutilized_hrs = 0;
        value.unallocated_hrs =
          Number(value.available_hrs) > 0
            ? Number(value.available_hrs) -
              (Number(value.planned_hrs) + Number(value.support_hrs))
            : 0;
        value.e_unutilized_hrs = 0;
      }
      //category name
      if (value.user_type) {
        var category_name = "";
        if (parseInt(value.user_type) == 1) {
          category_name = "Moolah";
        } else if (parseInt(value.user_type) == 2) {
          category_name = "Boolah";
        } else if (parseInt(value.user_type) == 3) {
          category_name = "Support";
        } else if (parseInt(value.user_type) == 4) {
          category_name = "Business Consulting";
        } else if (parseInt(value.user_type) == 5) {
          category_name = "Vendor";
        } else if (parseInt(value.user_type) == 6) {
          category_name = "Intern";
        } else {
          category_name = "-";
        }
        value.user_type = category_name;
      }
      var project_practice = [];
      var proj_practice = [];
      var name_lead = [];
      var practice_name = "";
      var practice = "";
      if (value.project_practice != null) {
        project_practice = value.project_practice.split(",");
      }

      this.state.report_all_practices.forEach((pracvalue) => {
        // value.user_project_practice = parseInt(value.user_project_practice);
        // value.user_practice = parseInt(value.user_practice);
        if (
          value.user_project_practice == NaN ||
          value.user_project_practice == null
        ) {
          practice = value.user_practice;
        } else {
          practice = value.user_project_practice;
        }
        if (practice == pracvalue.id) {
          practice_name = pracvalue.id;
        }
        project_practice.forEach((val) => {
          if (val == pracvalue.id) {
            proj_practice.push(pracvalue.name);
            if (name_lead.indexOf(pracvalue.lead_name) == -1) {
              name_lead.push(pracvalue.lead_name);
            }
            // name_lead.push(pracvalue.lead_name);
          }
          value.lead = name_lead.join();
          value.project_practice = proj_practice.join();
        });
      });
      if (value.project_name == "Bench") {
        value.lead = "NA";
        value.project_practice = "NA";
        practice_name =
          value.user_practice == null
            ? parseInt(value.user_project_practice)
            : parseInt(value.user_practice);
      }
      value.unallocated_hrs = old_unalloc_hrs;
      let tempObject = {
        "Period": value.period,
        "Emp No": value.emp_no,
        "Vendor Emp No": value.vendor_emp_no,
        "Extern Emp No": value.extern_emp_no,
        "Resource": value.first_name,
        "Avg Cost/Hr": value.cost,
        "Avg Price/Hr": value.price,
        "Available Hours": value.available_hrs,
        "Project Code": value.hrms_projectcode ? value.hrms_projectcode : "-",
        "Project": value.project_name,
        "Practice Head": value.lead ? value.lead : "-",
        "Practice": value.project_practice,
        "Type": value.user_type,
        "BA": value.baname ? value.baname : "-",
        "Team Lead": value.teamlead,
        "Planned Hrs With MS": value.planned_hrs,
        "Hours planned for Support": value.support_hrs,
        "Moolah Unutilized hrs": value.m_unutilized_hrs,
        "Boolah Unutilized hrs": value.b_unutilized_hrs,
        "Vendor Unutilized hrs": value.v_unutilized_hrs,
        "Extern Unutilized hrs": value.e_unutilized_hrs,
        "Unutilized hrs": value.unallocated_hrs,
        "Practice Cost($)": value.effective_yield ? value.effective_yield : 0,
        "Expected Yield($)": value.expected_yield ? value.expected_yield : 0,
        "Allocated M.S.Value($)": value.allocated_ms ? value.allocated_ms : 0,
        "Gap in Allocation($)": value.gap_in_allocation
          ? value.gap_in_allocation
          : 0,
        "Deficit %": value.deficit ? value.deficit : 0,
        "Achieved M.S.Value($)": value.achieved_ms ? value.achieved_ms : 0,
        "Assigned Practice": practice_name,
        "Emp Category": value.emp_category ? value.emp_category : 'NA'
      };
      formatedResult.push(tempObject);
      value.user_type = type_of_user;
    });
    return formatedResult;
  };

  changeGanttView = () => {
    if (this.state.filter_start_date > this.state.filter_end_date) {
      //this.state.submitted = true;
      return false;
    }
    this.setState({ loading: true });
    // this.state.updateLoader = true;
    // this.state.ganttDataLoading = true;
    var date = moment().utcOffset("+05:30").format("YYYY-MM-01");
    var f_strat_date = moment(this.state.filter_start_date)
      .utcOffset("+05:30")
      .format("YYYY-MM-01");
    var f_end_date = moment(this.state.filter_end_date)
      .utcOffset("+05:30")
      .format("YYYY-MM-01");
    this.state.monthSeries = this.dateRange(f_strat_date, f_end_date);
    this.setState({
      monthSeries: this.state.monthSeries,
      //  monthName: this.state.monthName
    });
    var link = this.state.apiLink + "get_ganttchat_data";
    var pracArray = [];
    this.state.projectPractice.forEach((value) => {
      pracArray.push(value.id);
    });
    if (this.state.token == null) {
      this.state.token = Cookies.get("auth_data");
    }
    return fetch(link, {
      method: "POST",
      body: JSON.stringify({
        head_id: "",
        end_date: this.state.filter_end_date,
        practice: this.state.report.practice,
        service: this.state.report.service,
        start_date: this.state.filter_start_date,
        userTypeId: this.state.userTypeId,
        project_practice: pracArray,
      }),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        Authorization: "Bearer " + this.state.token,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        this.setState({ loading: false });
        this.state.ganttViewData = res.result;
        this.state.isGanttView = true;
        this.setState({ isGanttView: this.state.isGanttView });
      });
  };

  dateRange = (startDate, endDate) => {
    var start = startDate.split("-");
    var end = endDate.split("-");
    var startYear = parseInt(start[0]);
    var endYear = parseInt(end[0]);
    var dates = [];

    for (var i = startYear; i <= endYear; i++) {
      var endMonth = i != endYear ? 11 : parseInt(end[1]) - 1;
      var startMon = i === startYear ? parseInt(start[1]) - 1 : 0;
      for (var j = startMon; j <= endMonth; j = j > 12 ? j % 12 || 11 : j + 1) {
        var month = j + 1;
        var displayMonth = month < 10 ? "0" + month : month;
        dates.push([i, displayMonth, "01"].join("-"));
      }
    }
    return dates;
  };

  // for showing difference in colour
  percentageFinder = (workHrs, avilHrs) => {
    var percentate = (workHrs / avilHrs) * 100;
    var returnClass;
    if (!avilHrs || avilHrs == 0) {
      //user not joined
      returnClass = "m-b-0 p-b-5 p-t-5 process default";
    } else {
      if (percentate >= 80) {
        returnClass = "m-b-0 p-b-5 p-t-5 process low"; //for good
      } else if (percentate >= 50 && percentate < 80) {
        returnClass = "m-b-0 p-b-5 p-t-5 process medium"; //for average
      } else {
        returnClass = "m-b-0 p-b-5 p-t-5 process success"; //for worst
      }
    }
    return returnClass;
  };

  filterByUserType = (e, id) => {
    let array = [];
    if (id === "intern") {
      this.setState({emp_category : 'intern'});    
      this.state.gridListBackup.forEach((value) => {
        if (value.emp_category == id) {
          array.push(value);
        }
      });
    } else {
      this.setState({emp_category : 'permanent',typeId : id}); 
      this.state.gridListBackup.forEach((value) => {
        if (value.user_type == id) {
          array.push(value);
        }
      });
    }
    this.state.gridList = array;
    this.setState({
      gridList: this.state.gridList,
    });
    var effective_yield = 0;
    var expected_yield = 0;
    var gap_in_allocation = 0;
    var deficit = 0;
    this.state.msAllocatedHrs = 0;
    this.state.supportHrs = 0;
    this.state.benchHrs = 0;
    this.state.totalResourceAvailHrsFrontendCalcution = 0;

    this.state.utilizationCounts = {};
    this.state.utilizationCounts.totalAvailHrs = 0;
    this.state.utilizationCounts.milestoneAllocated = 0;
    this.state.utilizationCounts.supportHrs = 0;
    this.state.utilizationCounts.benchHrs = 0;

    this.state.utilizationCounts.moolahTotalAvailHrs = 0;
    this.state.utilizationCounts.moolahMilestoneHrs = 0;
    this.state.utilizationCounts.moolahSupportHrs = 0;
    this.state.utilizationCounts.moolahSupportHrs_value = 0;
    this.state.utilizationCounts.moolahSupportHrs_valueNew = 0;
    this.state.utilizationCounts.moolahBenchHrs = 0;
    this.state.utilizationCounts.moolahBenchHrsNew = 0;

    this.state.utilizationCounts.boolahTotalAvailHrs = 0;
    this.state.utilizationCounts.boolahMilestoneHrs = 0;
    this.state.utilizationCounts.boolahSupportHrs = 0;
    this.state.utilizationCounts.boolahSupportHrs_value = 0;
    this.state.utilizationCounts.boolahSupportHrs_valueNew = 0;
    this.state.utilizationCounts.boolahBenchHrs = 0;
    this.state.utilizationCounts.boolahBenchHrsNew = 0;

    this.state.utilizationCounts.supportUserMilestoneHrs = 0;
    this.state.utilizationCounts.supportUserSupportHrs = 0;
    this.state.utilizationCounts.supportUserTotalAvailHrs = 0;
    this.state.utilizationCounts.supportUserSupportHrs_value = 0;
    this.state.utilizationCounts.supportUserSupportHrs_valueNew = 0;
    this.state.utilizationCounts.supportUserBenchHrs = 0;
    this.state.utilizationCounts.supportUserHrsNew = 0;

    this.state.utilizationCounts.vendorTotalAvailHrs = 0;
    this.state.utilizationCounts.vendorMilestoneHrs = 0;
    this.state.utilizationCounts.vendorSupportHrs = 0;
    this.state.utilizationCounts.vendorBenchHrs = 0;
    this.state.utilizationCounts.vendorBenchHrsNew = 0;
    this.state.utilizationCounts.vendorSupportHrs_value = 0;
    this.state.utilizationCounts.vendorSupportHrs_valueNew = 0;

    this.state.utilizationCounts.externTotalAvailHrs = 0;
    this.state.utilizationCounts.externMilestoneHrs = 0;
    this.state.utilizationCounts.externSupportHrs = 0;
    this.state.utilizationCounts.externBenchHrs = 0;
    this.state.utilizationCounts.externBenchHrsNew = 0;
    this.state.utilizationCounts.externSupportHrs_value = 0;
    this.state.utilizationCounts.externSupportHrs_valueNew = 0;
    this.state.front_actual_allocation_m = 0;
    this.state.moolahDeficit = 0;
    this.state.boolahDeficit = 0;
    this.state.bcDeficit = 0;
    this.state.vendorDeficit = 0;
    this.state.externDeficit = 0;
    this.state.internDeficit = 0;
    this.state.moolahDeficits = 0;
    this.state.boolahDeficits = 0;
    this.state.bcDeficits = 0;
    this.state.vendorDeficits = 0;
    this.state.externDeficits = 0;
    this.state.internDeficits = 0;

    this.state.yieldCounts = {};
    this.state.yieldCounts.yieldValue = 0;
    this.state.yieldCounts.allocatedAmount = 0;
    this.state.yieldCounts.kickoffAmount = 0;
    this.state.yieldCounts.allocatedAmount_m = 0;
    this.state.yieldCounts.allocatedAmount_b = 0;
    this.state.yieldCounts.allocatedAmount_s = 0;
    this.state.yieldCounts.allocatedAmount_v = 0;
    this.state.yieldCounts.allocatedAmount_i = 0;
    this.state.yieldCounts.allocatedAmount_e = 0;
    this.state.yieldCounts.achievedAmount = 0;
    this.state.yieldCounts.moolahAllocatedAmount = 0;
    this.state.yieldCounts.moolahAchievedAmount = 0;
    this.state.yieldCounts.boolahAllocatedAmount = 0;
    this.state.yieldCounts.boolahAchievedAmount = 0;

    this.state.totalResourceYieldValue = 0;
    this.state.totaleffectiveYieldValue = 0;
    this.state.sum_bench_value = 0;

    var tempUserAvailHrs = [];
    var filtered_user_list = [];
    this.state.gridList1 = [];
    var name = [];

    this.state.report.service.forEach((servalue) => {
      this.state.practiceHead.forEach((ph) => {
        if (ph.id == servalue.head) {
          name.push(ph.name);
        }
      });
      this.state.practiceleadName = name.join();
    });
    this.state.gridList.forEach((value) => {
      if (typeof tempUserAvailHrs[value.user_id] === "undefined") {
        tempUserAvailHrs[value.user_id] = value.available_hrs;
      }
      filtered_user_list.push(value);
      var arr = [];
      filtered_user_list.forEach((entry) => {
        var uid = entry.id;
        if (arr.indexOf(uid) == -1) {
          arr.push(uid);
        }
      });
      this.state.getUserscount = arr;

      /* Count user inventory based on the practice */
      let me = this;
      this.state.projectPractice.map(function (value, i) { 
        let userPracticeCount = []; 
        let userList;
        _.filter(filtered_user_list, function (userId) { 
          if (
            value.id == userId.user_practice &&
            userPracticeCount.indexOf(userId.id) == -1
          ) {
            userPracticeCount.push(userId.id); 
          }  
          userList = userPracticeCount.length; 
          me.state.projectPractice[i].countList = userList; 
        }); 
      });

      value.teamlead = value.teamlead ? value.teamlead : "NA";
      this.state.unallocated_hrs = 0;
      this.state.unallocated_hrs =
        Number(value.available_hrs) > 0
          ? Number(value.available_hrs) -
            (Number(value.planned_hrs) + Number(value.support_hrs))
          : 0;
      value.unallocated_hrs = this.state.unallocated_hrs;
      this.state.benchHrs += value.unallocated_hrs;

      effective_yield = (value.emp_category && value.emp_category === "intern") ? value.cost : value.available_hrs * value.cost;
      this.state.effective_yield = effective_yield;
      value.effective_yield = parseFloat(this.state.effective_yield).toFixed(2);

      expected_yield = value.available_hrs * value.price;
      this.state.expected_yield = expected_yield;

      if (Number.isInteger(this.state.expected_yield)) {
        value.expected_yield = this.state.expected_yield;
      } else {
        value.expected_yield = parseFloat(this.state.expected_yield).toFixed(2);
      }
      gap_in_allocation = value.expected_yield - value.allocated_ms;
      this.state.gap_in_allocation = gap_in_allocation;
      if (Math.sign(gap_in_allocation) == 0) {
        value.gap_in_allocation = this.state.gap_in_allocation;
      } else {
        value.gap_in_allocation = parseFloat(
          this.state.gap_in_allocation
        ).toFixed(2);
      }
      if (value.gap_in_allocation > 0 && value.expected_yield) {
        value.deficit = (
          (value.gap_in_allocation / value.expected_yield) *
          100
        ).toFixed(2);
      } else {
        value.deficit = 0;
      }
      var allocated_ms = value.allocated_ms;
      var kickoff_amount = 0;
      if(value.project_name=='Kickoff'){
        allocated_ms = 0;
        kickoff_amount = value.kickoff_amount;
      }
      this.state.gridList1.push(value);
      this.state.totalResourceYieldValue += Number(value.expected_yield);
      this.state.totaleffectiveYieldValue += Number(value.effective_yield);
      this.state.totaleffectiveYieldValueNew =
        this.state.totaleffectiveYieldValue;
      this.state.totaleffectiveYieldValueCount =
        this.state.totaleffectiveYieldValue / this.state.getUserscount.length;
      this.state.totaleffectiveYieldValueCountNew =
        this.state.totaleffectiveYieldValueCount;
      this.state.totalResourceAvailHrsFrontendCalcution =
        this.state.totalResourceAvailHrsFrontendCalcution +
        Number(value.available_hrs);
      this.state.totalResAvHrCal =
        this.state.totalResourceAvailHrsFrontendCalcution;
      this.state.msAllocatedHrs =
        this.state.msAllocatedHrs + Number(value.planned_hrs);
      this.state.msAlloc = this.state.msAllocatedHrs;
      this.state.supportHrs = this.state.supportHrs + Number(value.support_hrs);
      this.state.supphrs = this.state.supportHrs;
      this.state.yieldCounts.allocatedAmount =
      this.state.yieldCounts.allocatedAmount + parseInt(allocated_ms);
      this.state.yieldCounts.allocatedAmountNew =
        this.state.yieldCounts.allocatedAmount.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
      this.state.yieldCounts.kickoffAmount = this.state.yieldCounts.kickoffAmount + Number(kickoff_amount);
      this.state.yieldCounts.yieldValue =
        this.state.yieldCounts.yieldValue + value.price * value.available_hrs;
      this.state.sum_bench_value += Number(value.price * value.unallocated_hrs);
      this.state.yieldCounts.achievedAmount =
        this.state.yieldCounts.achievedAmount + parseInt(value.achieved_ms);
        this.state.yieldCounts.achievedAmountNew =
      this.state.yieldCounts.achievedAmount.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
      if (
        value.user_type == this.state.user_types[0].id &&
        (value.emp_category == null || value.emp_category === "permanent")
      ) {
        //for moolah
        this.state.utilizationCounts.moolahTotalAvailHrs =
          this.state.utilizationCounts.moolahTotalAvailHrs +
          parseInt(value.available_hrs);
        this.state.utilizationCounts.moolahMilestoneHrs =
          Number(this.state.utilizationCounts.moolahMilestoneHrs) +
          Number(value.planned_hrs);
        this.state.utilizationCounts.moolahSupportHrs =
          Number(this.state.utilizationCounts.moolahSupportHrs) +
          Number(value.support_hrs);
        this.state.utilizationCounts.moolahSupportHrs_value =
          Number(this.state.utilizationCounts.moolahSupportHrs_value) +
          value.support_hrs * value.price;
        this.state.utilizationCounts.moolahSupportHrs_valueNew =
          this.state.utilizationCounts.moolahSupportHrs_value.toLocaleString(
            undefined,
            {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }
          );
        this.state.utilizationCounts.moolahBenchHrs =
          Number(this.state.utilizationCounts.moolahBenchHrs) +
          value.unallocated_hrs * value.price;
        this.state.utilizationCounts.moolahBenchHrsNew =
          this.state.utilizationCounts.moolahBenchHrs.toLocaleString(
            undefined,
            {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }
          );
        this.state.moolahDeficit += parseFloat(value.gap_in_allocation);
        this.state.moolahDeficits = this.state.moolahDeficit.toLocaleString(
          undefined,
          {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }
        );
        this.state.yieldCounts.allocatedAmount_m += value.allocated_ms;
      } else if (
        value.user_type == this.state.user_types[1].id &&
        (value.emp_category == null || value.emp_category === "permanent")
      ) {
        //for boolah
        console.log("HI", value.user_type);
        this.state.utilizationCounts.boolahTotalAvailHrs =
          this.state.utilizationCounts.boolahTotalAvailHrs +
          parseInt(value.available_hrs);
        this.state.utilizationCounts.boolahMilestoneHrs =
          Number(this.state.utilizationCounts.boolahMilestoneHrs) +
          Number(value.planned_hrs);
        this.state.utilizationCounts.boolahSupportHrs =
          Number(this.state.utilizationCounts.boolahSupportHrs) +
          Number(value.support_hrs);
        this.state.utilizationCounts.boolahSupportHrs_value =
          Number(this.state.utilizationCounts.boolahSupportHrs_value) +
          value.support_hrs * value.price;
        this.state.utilizationCounts.boolahSupportHrs_valueNew =
          this.state.utilizationCounts.boolahSupportHrs_value.toLocaleString(
            undefined,
            {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }
          );
        this.state.utilizationCounts.boolahBenchHrs =
          Number(this.state.utilizationCounts.boolahBenchHrs) +
          value.unallocated_hrs * value.price;
        this.state.utilizationCounts.boolahBenchHrsNew =
          this.state.utilizationCounts.boolahBenchHrs.toLocaleString(
            undefined,
            {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }
          );
        this.state.boolahDeficit += parseFloat(value.gap_in_allocation);
        this.state.boolahDeficits = this.state.boolahDeficit.toLocaleString(
          undefined,
          {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }
        );
        this.state.yieldCounts.allocatedAmount_b += value.allocated_ms;
      } else if (
        value.user_type == this.state.user_types[2].id &&
        (value.emp_category == null || value.emp_category === "permanent")
      ) {
        //for support user
        this.state.utilizationCounts.supportUserTotalAvailHrs =
          this.state.utilizationCounts.supportUserTotalAvailHrs +
          parseInt(value.available_hrs);
        this.state.utilizationCounts.supportUserMilestoneHrs =
          Number(this.state.utilizationCounts.supportUserMilestoneHrs) +
          Number(value.planned_hrs);
        this.state.utilizationCounts.supportUserSupportHrs =
          Number(this.state.utilizationCounts.supportUserSupportHrs) +
          Number(value.support_hrs);
        this.state.utilizationCounts.supportUserSupportHrs_value =
          Number(this.state.utilizationCounts.supportUserSupportHrs_value) +
          value.support_hrs * value.price;
        this.state.utilizationCounts.supportUserSupportHrs_valueNew =
          this.state.utilizationCounts.supportUserSupportHrs_value.toLocaleString(
            undefined,
            {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }
          );
        this.state.utilizationCounts.supportUserBenchHrs =
          Number(this.state.utilizationCounts.supportUserBenchHrs) +
          value.unallocated_hrs * value.price;
        this.state.utilizationCounts.supportUserBenchHrsNew =
          this.state.utilizationCounts.supportUserBenchHrs.toLocaleString(
            undefined,
            {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }
          );
        this.state.bcDeficit += parseFloat(value.gap_in_allocation);
        this.state.bcDeficits = this.state.bcDeficit.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
        this.state.yieldCounts.allocatedAmount_s += value.allocated_ms;
      } else if (value.user_type == this.state.user_types[4].id) {
        // for vendor users
        this.state.utilizationCounts.vendorTotalAvailHrs =
          this.state.utilizationCounts.vendorTotalAvailHrs +
          parseInt(value.available_hrs);
        this.state.utilizationCounts.vendorMilestoneHrs =
          Number(this.state.utilizationCounts.vendorMilestoneHrs) +
          Number(value.planned_hrs);
        this.state.utilizationCounts.vendorSupportHrs =
          Number(this.state.utilizationCounts.vendorSupportHrs) +
          Number(value.support_hrs);
        this.state.utilizationCounts.vendorSupportHrs_value =
          Number(this.state.utilizationCounts.vendorSupportHrs_value) +
          value.support_hrs * value.price;
        this.state.utilizationCounts.vendorSupportHrs_valueNew =
          this.state.utilizationCounts.vendorSupportHrs_value.toLocaleString(
            undefined,
            {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }
          );
        this.state.utilizationCounts.vendorBenchHrs =
          Number(this.state.utilizationCounts.vendorBenchHrs) +
          value.unallocated_hrs * value.price;
        this.state.utilizationCounts.vendorBenchHrsNew =
          this.state.utilizationCounts.vendorBenchHrs.toLocaleString(
            undefined,
            {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }
          );
        this.state.vendorDeficit += parseFloat(value.gap_in_allocation);
        this.state.vendorDeficits = this.state.vendorDeficit.toLocaleString(
          undefined,
          {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }
        );
        this.state.yieldCounts.allocatedAmount_v += value.allocated_ms;
      } else if (value.user_type == this.state.user_types[5].id) {
        // for extern users
        this.state.utilizationCounts.externTotalAvailHrs =
          this.state.utilizationCounts.externTotalAvailHrs +
          parseInt(value.available_hrs);
        this.state.utilizationCounts.externMilestoneHrs =
          Number(this.state.utilizationCounts.externMilestoneHrs) +
          Number(value.planned_hrs);
        this.state.utilizationCounts.externSupportHrs =
          Number(this.state.utilizationCounts.externSupportHrs) +
          Number(value.support_hrs);
        this.state.utilizationCounts.externSupportHrs_value =
          Number(this.state.utilizationCounts.externSupportHrs_value) +
          value.support_hrs * value.price;
        this.state.utilizationCounts.externSupportHrs_valueNew =
          this.state.utilizationCounts.externSupportHrs_value.toLocaleString(
            undefined,
            {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }
          );
        this.state.utilizationCounts.externBenchHrs =
          Number(this.state.utilizationCounts.externBenchHrs) +
          value.unallocated_hrs * value.price;
        this.state.utilizationCounts.externBenchHrsNew =
          this.state.utilizationCounts.externBenchHrs.toLocaleString(
            undefined,
            {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }
          );
        this.state.externDeficit += parseFloat(value.gap_in_allocation);
        this.state.externDeficits = this.state.externDeficit.toLocaleString(
          undefined,
          {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }
        );
        this.state.yieldCounts.allocatedAmount_v += value.allocated_ms;
      } else if (value.emp_category === "intern") {
        // for intern users
        this.state.internDeficit += parseFloat(value.gap_in_allocation);
        this.state.internDeficits = this.state.internDeficit.toLocaleString(
          undefined,
          {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }
        );
        this.state.yieldCounts.allocatedAmount_i += value.allocated_ms;
      }
      this.state.sum_bench_value =
        this.state.totalResourceYieldValue - this.state.sum_bench_value;
    });
    if (this.state.gridList1.length == 0) {
      this.state.totalResAvHrCal = 0;
      this.state.msAlloc = 0;
      this.state.supphrs = 0;
      this.state.getUserscount = "";
    }
  };

  /**
   * main render
   * @returns
   */
  render() {
    const loginuserRole = this.state.loginUserData;
    if (this.state.redirect == 1) {
      return <PageNotFound url={this.state.angularUrl} />;
    } else {
      return (
        <>
          <div className="page-content px-4">
            <Row>
              <div className="col-xs-12 fourty-fluid fourty-fluid">
                <Row className="mb-3">
                  <div className="col-sm-4 col-sm-4 col-xs-12 text-left">
                    <h1 className="B-G-bold f-s-18 d-gray t-t-upper pull-left mt-10 mr-20 page-head">
                      Reports
                    </h1>
                  </div>
                  <div className="col-lg-3 col-sm-4 col-xs-12">
                    <div className="selectpicker_dropdown hide_cross hide_bar orange_arrow remove_border_btm select_dropdown_picker">
                       <Dropdown title={this.state.current_report.value} activeKey="1">
                          <Dropdown.Item eventKey="1">Practice Wise Allocation Report</Dropdown.Item>
                          <Dropdown.Item as="a" eventKey="2" href="/ms_report" target="_blank">Milestone Value Report</Dropdown.Item>
                          <Dropdown.Item as="a" eventKey="3" href="/collections_report" target="_blank">Collections Report</Dropdown.Item>
                          <Dropdown.Item as="a" eventKey="4" href="/collections_potential_report" target="_blank">Collection Potential Report</Dropdown.Item>
                          <Dropdown.Item as="a" eventKey="5" href="/inventory_report" target="_blank">Inventory Report</Dropdown.Item>
                          <Dropdown.Item as="a" eventKey="6" href="/inventory_allocation_report" target="_blank">Inventory Allocation Metrics</Dropdown.Item>
                          <Dropdown.Item as="a" eventKey="7" href="/inventory_milestone_report" target="_blank">Inventory Milestone Metrics</Dropdown.Item>
                          <Dropdown.Item as="a" eventKey="8" href="/utilization_report" target="_blank">Resource Utilization Report</Dropdown.Item>
                          <Dropdown.Item as="a" eventKey="9" href="/csm_report" target="_blank">Csm Report</Dropdown.Item>
                          <Dropdown.Item as="a" eventKey="10" href="/pmo_ms_report" target="_blank">Pmo Ms Report</Dropdown.Item>
                          <Dropdown.Item as="a" eventKey="11" href="/acv_report" target="_blank">Annual Contract Value Report</Dropdown.Item>
                          <Dropdown.Item as="a" eventKey="12" href="/ms-allocation-report" target="_blank">Ms Allocation Report</Dropdown.Item>
                      </Dropdown>
                    </div>
                  </div>
                </Row>
                <Row>
                  <div className="col-lg-6 clearfix m-b-20">
                    <div className="row align-items-center">
                      <label className="f-s-12 text-muted pt-1 w-13 col-lg-2 col-md-6 col-sm-12 text-left">
                        Line of Service :
                      </label>
                      <div className="col-lg-7 col-md-6 col-sm-12 text-left ">
                        <Row>
                          <div className="col-lg-10 p-l-0">
                            <div className="selectpicker_dropdown hide_bar w-100 selectpicker_dropdown_orange">
                              <Select
                                className="basic-single-select"
                                classNamePrefix={"select_dropdown"}
                                placeholder="All"
                                isClearable
                                isSearchable={true}
                                onChange={(e) => this.filterService(e)}
                                value={this.state.selectService}
                                options={this.state.serviceName}
                                isMulti
                              ></Select>
                            </div>
                          </div>
                          <div className="col-lg-2 p-1-0">
                            {this.state.report.service != undefined &&
                              this.state.report.service.length > 0 && (
                                <a
                                  href="#"
                                  title="Reset"
                                  tooltip-placement="top"
                                  className="reset-icon_old reset-color p-l-5 m-l-4"
                                  onClick={this.reset_page}
                                >
                                  <i className="fs-16 fa fa-refresh"></i>
                                </a>
                              )}
                          </div>
                        </Row>
                      </div>
                    </div>
                  </div>
                  {this.state.loginUserData.role_id != 8 && (
                    <div className="col-lg-4 clearfix m-b-20">
                      <div className="row">
                        <div className="col-sm-5 col-xs-12">
                          <label className="text-uppercase m-b-0 fs-13 p-t-5">
                            Business Unit :
                          </label>
                        </div>
                        <div className="col-sm-7 col-xs-12">
                          <div className="selectpicker_dropdown hide_bar w-100 selectpicker_dropdown_orange">
                            <Select
                              className="basic-single-select"
                              classNamePrefix={"select_dropdown"}
                              placeholder="All"
                              isClearable
                              isSearchable={true}
                              onChange={(e) => this.getReportByHead(e)}
                              value={this.state.selectBuFilter}
                              options={this.state.buName}
                            ></Select>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="col-lg-2 col-sm-2 col-xs-12 justify-content-end text-end">
                    <a href={this.state.angularUrl} className="btn btn-primary">
                      Back
                    </a>
                  </div>
                </Row>

                <div className="center-block text-center">
                  <div className="col-lg-12 col-sm-12 col-xs-12 p-b-15-xs">
                    <div className="p-0 B-G-bold f-s-16 d-gray m-t-8 m-b-0 page-head">
                      <Row className="my-3">
                        <Col className="col-lg-8">
                          <div className="row m-0 bgwhitebg">
                            <div className="col-lg-10 p-2 bor_setclr">
                              <div className="project-list text-left custom-li-size max_ht_scroll scrollbar-design">
                                <ul className="p-0">
                                  <li className="m-b-5">
                                    <a
                                      href="#"
                                      className={
                                        this.state.all_active
                                          ? "B-G-bold f-s-12 l-gray pl-5 pr-5 pr-list mr-5 pr-l-active"
                                          : "B-G-bold f-s-12 l-gray pl-5 pr-5 pr-list mr-5"
                                      }
                                      onClick={(e) =>
                                        this.selectPractice(e, "", "")
                                      }
                                    >
                                      All
                                    </a>
                                  </li>
                                  {this.state.projectPractice.map(
                                    (practiceObj, key) => (
                                      <li className="mb-10">
                                        <a
                                          onClick={(e) =>
                                            this.selectPractice(
                                              e,
                                              practiceObj.id,
                                              practiceObj.name
                                            )
                                          }
                                          href="#"
                                          title={practiceObj.name}
                                          tooltip-placement="top"
                                          className={
                                            practiceObj.is_active
                                              ? "B-G-bold f-s-12 l-gray pl-5 pr-5 pr-list mr-5 pr-l-active"
                                              : "B-G-bold f-s-12 l-gray pl-5 pr-5 pr-list mr-5"
                                          }
                                          ng-class="{'pr-l-active':isExists(practiceObj.id)}"
                                        >
                                          {practiceObj.practice}
                                        </a>
                                      </li>
                                    )
                                  )}
                                </ul>
                              </div>
                            </div>
                            <div className="col-lg-2 text-center p-0">
                              <button
                                onClick={this.submitGenerateReport}
                                className="btn btn-primary B-G-bold t-t-upper p-t-5 p-b-4 p-l-6 p-r-6 fs-11 xs-block-view m-b-5 m-t-35 m-r-15"
                              >
                                <span>search</span>
                              </button>
                            </div>
                          </div>
                        </Col>
                        <Col className="col-lg-4 ">
                          <div className="col-sm-12 col-xs-12 header--dateview mt-3">
                            <p>
                              Practice Head :{" "}
                              <span
                                title={this.state.practiceleadName}
                                tooltip-placement="bottom"
                              >
                                {this.state.practiceleadName}
                              </span>
                            </p>
                            {/* <p className="mb-3">Total inventory : <span>{this.state.getUserscount.length}</span></p> */}
                            <p className="mb-3">
                            Total inventory :{" "}
                           <span>
                              {this.state.getUserscount.length}&nbsp;&nbsp;
                            </span>
                              {(this.state.selectBuFilter.length != 0 || Object.keys(this.state.selectService).length != 0)  && (this.state.getUserscount.length!=0) &&
                              <OverlayTrigger
                                delay={{ hide: 450, show: 300 }}
                                overlay={(props) => (
                                  <Tooltip {...props} className="practice_tooltip">
                                    {this.state.projectPractice && this.state.projectPractice.map(
                                      (practiceObj, key) => (
                                        practiceObj.countList != 0 && (
                                        <span>
                                          {practiceObj.practice} -
                                          {practiceObj.countList
                                            ? practiceObj.countList
                                            : 0}
                                        </span>
                                      )
                                      )
                                    )}
                                  </Tooltip>
                                )}
                                placement="bottom"
                              >
                              <i variant="warning" className="fa fa-exclamation-circle"></i>
                              </OverlayTrigger>
                            }     
                            </p>
                            {/* {this.state.projectPractice.map(
                              (practiceObj, key) => (
                                <li className="">
                                  <span>
                                    {practiceObj.practice}-
                                    {practiceObj.countList
                                      ? practiceObj.countList
                                      : 0}
                                  </span>
                                </li>
                              )
                            )} */}
                            {loginuserRole.role_id != 1 && (
                              <Row>
                                <Col>
                                  {!this.state.isGanttView && (
                                    <Button
                                      onClick={(e) => this.changeGanttView(e)}
                                      value="allpractice"
                                      className="me-2 px-3"
                                    >
                                      <i
                                        ng-if="updateLoader"
                                        className="fa m-r-5"
                                      ></i>{" "}
                                      Gantt View
                                    </Button>
                                  )}
                                  {this.state.isGanttView && (
                                    <Button
                                      onClick={this.getPracticeWiseGridData}
                                      value="allpractice"
                                      className="me-2 px-3"
                                    >
                                      <i
                                        ng-if="updateLoader"
                                        className="fa  m-r-5"
                                      ></i>{" "}
                                      Report View
                                    </Button>
                                  )}{" "}
                                </Col>
                                <Col>
                                  <Button
                                    onClick={(e) =>
                                      this.generateReportByPracticesExportAsExls(e)
                                    }
                                    value="allpractice"
                                    className="me-2 px-3"
                                    disabled={this.state.gridList.length == 0 ? true : false}
                                  >
                                    <i className="fa   m-r-5"></i> Export as
                                    Excel
                                  </Button>
                                </Col>
                                <Col>
                                  {loginuserRole.role_id != 1 &&
                                    this.state.isAllPractices && (
                                      <Button
                                        onClick={(e) =>
                                          this.getPracticeService(e, false)
                                        }
                                        value="allpractice"
                                        className="me-2 px-3"
                                      >
                                        My Practices
                                      </Button>
                                    )}
                                  {loginuserRole.role_id != 1 &&
                                    !this.state.isAllPractices && (
                                      <Button
                                        onClick={(e) =>
                                          this.getPracticeService(e, true)
                                        }
                                        value="mypractice"
                                        className="me-2 px-3"
                                      >
                                        View All Practices
                                      </Button>
                                    )}
                                </Col>
                              </Row>
                            )}
                            {loginuserRole.role_id == 1 &&
                              !this.state.isGanttView && (
                                <Button
                                  onClick={(e) => this.changeGanttView(e)}
                                  value="allpractice"
                                  className="me-2 px-3"
                                >
                                  <i
                                    ng-if="updateLoader"
                                    className="fa m-r-5"
                                  ></i>{" "}
                                  Gantt View
                                </Button>
                              )}
                            {loginuserRole.role_id == 1 &&
                              this.state.isGanttView && (
                                <Button
                                  onClick={this.getPracticeWiseGridData}
                                  value="allpractice"
                                  className="me-2 px-3"
                                >
                                  <i
                                    ng-if="updateLoader"
                                    className="fa  m-r-5"
                                  ></i>{" "}
                                  Report View
                                </Button>
                              )}
                            {loginuserRole.role_id == 1 && (
                              <Button
                                onClick={(e) =>
                                  this.generateReportByPracticesExportAsExls(e)
                                }
                                value="allpractice"
                                className="me-2 px-3"
                                disabled={this.state.gridList.length == 0 ? true : false}
                              >
                                <i className="fa   m-r-5"></i> Export as Excel
                              </Button>
                            )}
                          </div>
                        </Col>
                      </Row>
                      <Row className="my-3">
                        <Col className="col-lg-1 text-start align-items-center">
                          <h1 className="f-s-16 d-gray my-0">Users:</h1>
                        </Col>
                        <Col className="col-lg-2">
                          <div className="selectpicker_dropdown">
                            <select onChange={(e) => this.selectResource(e)}>
                              {this.state.resource_types.map(
                                (category, keyCategory) => (
                                  <option id={category.id}>
                                    {category.value}
                                  </option>
                                )
                              )}
                            </select>
                            <span className="icon-arrow-down pull-right pt-5 p-l-10"></span>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </div>
                <hr className="m-t-5 col-sm-12 m-b-8 p-0"></hr>
                <div className="col-lg-12 col-sm-12 col-xs-12 p-b-15-xs p-r-15-xs">
                  <Row className=" mt-2 mb-3">
                    <Col className="col-lg-8 text-start col p-0">
                      <Row className="m-0">
                        <Col className="col-lg-2 text-start">
                          <label className="p-0 B-G-bold f-s-18 d-gray m-t-3 m-b-10 page-head pull-left">
                            Month Filter:
                          </label>
                        </Col>
                        <Col className="col-lg-1 px-0 general-content text-center">
                          <label className="pt-2">Start :</label>
                        </Col>
                        <Col className="col-lg-2">
                          <div className="row align-items-center mb-2 date_picker_align">
                            {/* <Form.Control className="form_date col-lg-8 col-sm-6 w-auto d-inline-block" type="month" name='start_date' value={this.state.start_month} onChange={(e)=>this.setStartDateForFilter(e)}  />      */}
                            <DatePicker
                              className="datepicker-control-section"
                              onChange={(e) => this.setStartDateForFilter(e)}
                              dateFormat="MM/yyyy"
                              showMonthYearPicker
                              todayButton={"Today"}
                              //dateFormat="MM/yyyy"
                              name="start_date"
                              selected={this.state.filter_start_date}
                            />
                            <span
                              className="cal_icon_date"
                              role="button"
                              tabIndex="0"
                            >
                              {" "}
                              <i className="icon-calendar"></i>
                            </span>
                          </div>
                        </Col>
                        <Col className="col-lg-1 px-0 general-content text-center">
                          <label className="pt-2">End :</label>
                        </Col>
                        <Col className="col-lg-2">
                          <div className="row align-items-center mb-2 date_picker_align">
                            {/* <Form.Control className="form_date col-lg-8 col-sm-6 w-auto d-inline-block" type="month" name='end_date' value={this.state.end_month} onChange={(e)=>this.setEndDateForFilter(e)} />    */}
                            <DatePicker
                              className="datepicker-control-section"
                              onChange={(e) => this.setEndDateForFilter(e)}
                              dateFormat="MM/yyyy"
                              showMonthYearPicker
                              minDate={this.state.filter_start_date}
                              todayButton={"Today"}
                              //dateFormat="MM/yyyy"
                              name="end_date"
                              selected={this.state.filter_end_date}
                            />
                            <span
                              className="cal_icon_date"
                              role="button"
                              tabIndex="0"
                            >
                              {" "}
                              <i className="icon-calendar"></i>
                            </span>
                          </div>
                        </Col>
                        <Col className="col-lg-3">
                          <Button
                            onClick={(e) => this.resetReport(e)}
                            value="Generate"
                            className="me-2 mt-0"
                          >
                            Generate Report{" "}
                          </Button>
                        </Col>
                      </Row>
                    </Col>
                    <Col className="col-lg-4 text-end">
                      <div className="form-group search-icon col-sm-12 col-xs-12">
                        <input
                          type="text"
                          name="project_filter"
                          value={this.state.searchBy}
                          className="form-control B-G-bold f-s-16 d-gray pl-30"
                          placeholder="Search..."
                          onChange={(e) =>
                            this.gridCalculation(this.state.userTypeId, e)
                          }
                        />
                        <i className="fa fa-search"></i>
                        {/* <Button  onClick={this.generateMileStoneValueReportAsExcel} value="Excel" className="me-2">Export as Excel</Button> */}
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <div className="col-md-5 p-r-0">
                      <h3 className="text-left text-uppercase heading--table m-b-5 fs-14 pull-left">
                        Utilization
                      </h3>
                      <div className="white-table-class utilize-box p-t-10 p-b-25 p-l-10 p-r-10">
                        <div className="row p-b-15">
                          <div className="col-sm-12 hours text-start">
                            <span
                              className="popover-link f_16"
                              onClick={(e) => this.filterMilestoneHrs(e, "all")}
                            >
                              Total available hours:
                              <span>
                                {this.state.totalResAvHrCal
                                  ? this.state.totalResAvHrCal
                                  : 0}
                              </span>
                            </span>
                          </div>
                          <div className="col-sm-5 p-l-0 p-t-15 rating text-center p-r-10">
                            <div className="col-sm-12 default">
                              <span
                                className="m-b-7 popover-link f_13"
                                onClick={(e) =>
                                  this.filterMilestoneHrs(e, "milestone")
                                }
                              >
                                Milestone
                              </span>
                              <h3 className="fs-12">{this.state.msAlloc}</h3>
                            </div>
                            <div className="row m-0">
                              <div className="col-lg-6 col-md-12 col-xs-12 info">
                                <span
                                  className="m-b-7 popover-link f_13"
                                  onClick={(e) =>
                                    this.filterMilestoneHrs(e, "support")
                                  }
                                >
                                  Support
                                </span>
                                <h3 className="fs-12">{this.state.supphrs}</h3>
                              </div>
                              <div className="col-lg-6 col-md-12 col-xs-12 danger">
                                <span
                                  className="m-b-7 popover-link f_13"
                                  onClick={(e) =>
                                    this.filterMilestoneHrs(e, "bench")
                                  }
                                >
                                  Bench
                                </span>
                                <h3 className="fs-12">{this.state.benchHrs}</h3>
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-7 hours-table-block tablesec p-b-10 p-l-0 p-r-5">
                            <div className="divTable">
                              <div className="divTableRow hrs-active">
                                <div className="iteration-cell  txt-left table-head">
                                  Hours
                                </div>
                                <div
                                  className={ this.state.emp_category == 'permanent' && this.state.userTypes[0].id== this.state.typeId  ? "iteration-cell text-center filter-head active" : "iteration-cell text-center filter-head" }
                                  onClick={(e) =>
                                    this.filterByUserType(
                                      e,
                                      this.state.userTypes[0].id
                                    )
                                  }
                                >
                                  <span className="badge badge-tag PersianGreen-bg fs-12 px-2">
                                    M
                                  </span>
                                </div>
                                <div
                                  className={ this.state.emp_category == 'permanent' && this.state.userTypes[1].id== this.state.typeId  ? "iteration-cell text-center filter-head active" : "iteration-cell text-center filter-head" }
                                  onClick={(e) =>
                                    this.filterByUserType(
                                      e,
                                      this.state.userTypes[1].id
                                    )
                                  }
                                >
                                  <span className="badge badge-tag Cabaret-bg fs-12 px-2">
                                    B
                                  </span>
                                </div>
                                <div
                                  className={ this.state.emp_category == 'permanent' && this.state.userTypes[2].id== this.state.typeId  ? "iteration-cell text-center filter-head active" : "iteration-cell text-center filter-head" }
                                  onClick={(e) =>
                                    this.filterByUserType(
                                      e,
                                      this.state.userTypes[2].id
                                    )
                                  }
                                >
                                  <span className="badge badge-tag dodgerblue-bg fs-12 px-2">
                                    S
                                  </span>
                                </div>
                                <div
                                  className={ this.state.emp_category == 'permanent' && this.state.userTypes[4].id== this.state.typeId  ? "iteration-cell text-center filter-head active" : "iteration-cell text-center filter-head" }
                                  onClick={(e) =>
                                    this.filterByUserType(
                                      e,
                                      this.state.userTypes[4].id
                                    )
                                  }
                                >
                                  <span className="badge badge-tag vendor-bg fs-12 px-2">
                                    V
                                  </span>
                                </div>
                                <div
                                  className={ this.state.emp_category == 'permanent' && this.state.userTypes[5].id== this.state.typeId  ? "iteration-cell text-center filter-head active" : "iteration-cell text-center filter-head" }
                                  onClick={(e) =>
                                    this.filterByUserType(
                                      e,
                                      this.state.userTypes[5].id
                                    )
                                  }
                                >
                                  <span className="badge badge-tag extern-bg fs-12 px-2">
                                    I
                                  </span>
                                </div>
                                <div
                                  className={ this.state.emp_category == 'intern' ? "iteration-cell text-center filter-head active" : "iteration-cell text-center filter-head" }
                                  onClick={(e) =>
                                    this.filterByUserType(e, "intern")
                                  }
                                >
                                  <span className="badge badge-tag intern-bg fs-12 px-2">
                                    RI
                                  </span>
                                </div>
                              </div>
                              <div className="divTableRow">
                                <div className="iteration-cell1  fs-12 txt-left">
                                  Milestone
                                </div>
                                <div className="iteration-cell1 fs-12 PersianGreen-text filter-head">
                                  {
                                    this.state.utilizationCounts
                                      .moolahMilestoneHrs
                                  }
                                </div>
                                <div className="iteration-cell1 fs-12 Cabaret-text filter-head">
                                  {
                                    this.state.utilizationCounts
                                      .boolahMilestoneHrs
                                  }
                                </div>
                                <div className="iteration-cell1 fs-12 dodgerblue-text filter-head">
                                  {
                                    this.state.utilizationCounts
                                      .supportUserMilestoneHrs
                                  }
                                </div>
                                <div className="iteration-cell1 fs-12 vendor-text filter-head">
                                  {
                                    this.state.utilizationCounts
                                      .vendorMilestoneHrs
                                  }
                                </div>
                                <div className="iteration-cell1 fs-12 extern-text filter-head">
                                  {
                                    this.state.utilizationCounts
                                      .externMilestoneHrs
                                  }
                                </div>
                                <div className="iteration-cell1 fs-12 intern-text filter-head">
                                  0
                                </div>
                              </div>
                              <div className="divTableRow">
                                <div className="iteration-cell1  fs-12 txt-left">
                                  Support
                                </div>
                                <div className="iteration-cell1 fs-12 PersianGreen-text filter-head">
                                  {
                                    this.state.utilizationCounts
                                      .moolahSupportHrs
                                  }
                                </div>
                                <div className="iteration-cell1 fs-12 Cabaret-text filter-head">
                                  {
                                    this.state.utilizationCounts
                                      .boolahSupportHrs
                                  }
                                </div>
                                <div className="iteration-cell1 fs-12 dodgerblue-text filter-head">
                                  {
                                    this.state.utilizationCounts
                                      .supportUserSupportHrs
                                  }
                                </div>
                                <div className="iteration-cell1 fs-12 vendor-text filter-head">
                                  {
                                    this.state.utilizationCounts
                                      .vendorSupportHrs
                                  }
                                </div>
                                <div className="iteration-cell1 fs-12 extern-text filter-head">
                                  {
                                    this.state.utilizationCounts
                                      .externSupportHrs
                                  }
                                </div>
                                <div className="iteration-cell1 fs-12 intern-text filter-head">
                                  0
                                </div>
                              </div>
                              <div className="divTableRow">
                                <div className="iteration-cell1  fs-12 txt-left">
                                  Bench
                                </div>
                                <div className="iteration-cell1 fs-12 PersianGreen-text filter-head">
                                  {this.state.utilizationCounts
                                    .moolahTotalAvailHrs -
                                    (this.state.utilizationCounts
                                      .moolahMilestoneHrs +
                                      this.state.utilizationCounts
                                        .moolahSupportHrs)}
                                </div>
                                <div className="iteration-cell1 fs-12 Cabaret-text filter-head">
                                  {this.state.utilizationCounts
                                    .boolahTotalAvailHrs -
                                    (this.state.utilizationCounts
                                      .boolahMilestoneHrs +
                                      this.state.utilizationCounts
                                        .boolahSupportHrs)}
                                </div>
                                <div className="iteration-cell1 fs-12 dodgerblue-text filter-head">
                                  {this.state.utilizationCounts
                                    .supportUserTotalAvailHrs -
                                    (this.state.utilizationCounts
                                      .supportUserMilestoneHrs +
                                      this.state.utilizationCounts
                                        .supportUserSupportHrs) >
                                  0
                                    ? this.state.utilizationCounts
                                        .supportUserTotalAvailHrs -
                                      (this.state.utilizationCounts
                                        .supportUserMilestoneHrs +
                                        this.state.utilizationCounts
                                          .supportUserSupportHrs)
                                    : 0}
                                </div>
                                <div className="iteration-cell1 fs-12 vendor-text filter-head">
                                  {this.state.utilizationCounts
                                    .vendorTotalAvailHrs -
                                    (this.state.utilizationCounts
                                      .vendorMilestoneHrs +
                                      this.state.utilizationCounts
                                        .vendorSupportHrs)}
                                </div>
                                <div className="iteration-cell1 fs-12 extern-text filter-head">
                                  {this.state.utilizationCounts
                                    .externTotalAvailHrs -
                                    (this.state.utilizationCounts
                                      .externMilestoneHrs +
                                      this.state.utilizationCounts
                                        .externSupportHrs)}
                                </div>
                                <div className="iteration-cell1 fs-12 intern-text filter-head">
                                  0
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* <div className="col-sm-12 hours text-start">
                                                <span className="popover-link f_16" onClick={(e)=>this.filterMilestoneHrs(e,'intern')}>Intern Users:&nbsp;<span>({this.state.intern_count})</span>
                                            </span>
                                            </div> */}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-7">
                      <h3 className="text-left text-uppercase heading--table m-b-5 fs-14 m-b-5">
                        {" "}
                        Yield Performance Summary
                      </h3>
                      <div className="white-table-class utilize-box p-t-0 p-b-0 p-l-10 p-r-10">
                        <div className="row utbox ">
                          <div className="col-sm-12 p-r-5 b-r-gray p-t-5 p-b-10">
                            <Row className="text-start m-0">
                              <Col className="col-sm-6 p-0">
                                <h3 className="mt-3 mb-1 fs-15 text-start">
                                  Yield Summary
                                </h3>
                                <div className="row">
                                  <div className="col-sm-5 p-r-0">
                                    <p className="fs-12">
                                      <i
                                        className="fa fa-exclamation-circle"
                                        uib-tooltip="sum of expected yield"
                                        tooltip-placement="top"
                                      ></i>{" "}
                                      Exp. Yield:
                                    </p>
                                  </div>
                                  <div className="col-sm-7 p-l-0">
                                    <p className="fs-12">
                                      <span className="tundora">
                                        <span className="dis-i-b">
                                          ${" "}
                                          {this.state.totalResourceYieldValue
                                            ? this.state.totalResourceYieldValueNew.toLocaleString(
                                                undefined,
                                                {
                                                  minimumFractionDigits: 2,
                                                  maximumFractionDigits: 2,
                                                }
                                              )
                                            : 0}
                                        </span>{" "}
                                        |{" "}
                                        <span className="dis-i-b">
                                          ${" "}
                                          {this.state.totalResourceYieldValue &&
                                          this.state.getUserscount
                                            ? (
                                                this.state
                                                  .totalResourceYieldValue /
                                                this.state.getUserscount.length
                                              ).toLocaleString(undefined, {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2,
                                              })
                                            : 0}
                                        </span>
                                      </span>
                                    </p>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-sm-5 p-r-0">
                                    <p className="fs-12">
                                      <i
                                        className="fa fa-exclamation-circle"
                                        uib-tooltip="sum of achieved MS value"
                                        tooltip-placement="top"
                                      ></i>{" "}
                                      Actual Yield:{" "}
                                    </p>
                                  </div>

                                  <div className="col-sm-7 p-l-0">
                                    <p className="fs-12">
                                    <span className="tundora">${" "}</span> 
                                      {this.state.yieldCounts
                                        .achievedAmount && (
                                        <span className="tundora">
                                          <span className="dis-i-b">
                                            {this.state.yieldCounts.achievedAmountNew}                                             
                                          </span>
                                        </span>
                                      )}                                     
                                      {" "} {'|'}{" "}
                                      <span className="tundora">
                                          <span className="dis-i-b">
                                            {this.state.yieldCounts
                                              .achievedAmount &&
                                            this.state.getUserscount
                                              ? (
                                                  this.state.yieldCounts
                                                    .achievedAmount /
                                                  this.state.getUserscount
                                                    .length
                                                ).toLocaleString(undefined, {
                                                  minimumFractionDigits: 2,
                                                  maximumFractionDigits: 2,
                                                })
                                              : '$ 0'}
                                            </span>
                                      </span>
                                    </p>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-sm-5 p-r-0">
                                    <p className="fs-12">
                                      <i
                                        className="fa fa-exclamation-circle"
                                        uib-tooltip="sum of practice cost"
                                        tooltip-placement="top"
                                      ></i>
                                      Practice Cost:{" "}
                                    </p>
                                  </div>
                                  <div className="col-sm-7 p-l-0">
                                    <p className="fs-12">
                                      <span className="tundora">
                                        <span className="dis-i-b">
                                          ${" "}
                                          {this.state.totaleffectiveYieldValue
                                            ? this.state.totaleffectiveYieldValueNew.toLocaleString(
                                                undefined,
                                                {
                                                  minimumFractionDigits: 2,
                                                  maximumFractionDigits: 2,
                                                }
                                              )
                                            : " 0"}
                                        </span>{" "}
                                        |{" "}
                                        <span className="dis-i-b">
                                          ${" "}
                                          {this.state
                                            .totaleffectiveYieldValue &&
                                          this.state.getUserscount
                                            ? this.state.totaleffectiveYieldValueCountNew.toLocaleString(
                                                undefined,
                                                {
                                                  minimumFractionDigits: 2,
                                                  maximumFractionDigits: 2,
                                                }
                                              )
                                            : 0}
                                        </span>
                                      </span>{" "}
                                    </p>
                                  </div>
                                </div>
                              </Col>
                              <Col className="col-sm-6 p-0">
                                <h3 className="mt-3 mb-1 fs-15 text-start">
                                  Milestone Summary
                                </h3>
                                <div className="row">
                                  <div className="col-sm-6 p-r-0">
                                    <p className="fs-12">
                                      <i
                                        className="fa fa-exclamation-circle"
                                        uib-tooltip="expected_yield and bench_hours difference"
                                        tooltip-placement="top"
                                      ></i>{" "}
                                      Exp. Allocation:
                                    </p>
                                  </div>
                                  <div className="col-sm-6 p-l-0">
                                    <p className="fs-12">
                                      <span className="tundora">
                                        <span className="dis-i-b">
                                          ${" "}
                                          {this.state.totalResourceYieldValue -
                                          (this.state.utilizationCounts
                                            .moolahBenchHrs +
                                            this.state.utilizationCounts
                                              .boolahBenchHrs +
                                            this.state.utilizationCounts
                                              .supportUserBenchHrs)
                                            ? (
                                                this.state
                                                  .totalResourceYieldValue -
                                                (this.state.utilizationCounts
                                                  .moolahBenchHrs +
                                                  this.state.utilizationCounts
                                                    .boolahBenchHrs +
                                                  this.state.utilizationCounts
                                                    .supportUserBenchHrs)
                                              ).toLocaleString(undefined, {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2,
                                              })
                                            : 0}
                                        </span>{" "}
                                        |{" "}
                                        <span className="dis-i-b">
                                          ${" "}
                                          {this.state.totalResourceYieldValue -
                                            (this.state.utilizationCounts
                                              .moolahBenchHrs +
                                              this.state.utilizationCounts
                                                .boolahBenchHrs +
                                              this.state.utilizationCounts
                                                .supportUserBenchHrs) &&
                                          this.state.getUserscount
                                            ? (
                                                (this.state
                                                  .totalResourceYieldValue -
                                                  (this.state.utilizationCounts
                                                    .moolahBenchHrs +
                                                    this.state.utilizationCounts
                                                      .boolahBenchHrs +
                                                    this.state.utilizationCounts
                                                      .supportUserBenchHrs)) /
                                                this.state.getUserscount.length
                                              ).toFixed(2)
                                            : 0}
                                        </span>
                                      </span>
                                    </p>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-sm-6 p-r-0">
                                    <p className="fs-12">
                                      <i
                                        className="fa fa-exclamation-circle"
                                        uib-tooltip="sum of allocated MS value"
                                        tooltip-placement="top"
                                      ></i>{" "}
                                      Actual Allocation:
                                    </p>
                                  </div>
                                  <div className="col-sm-6 p-l-0">
                                    <p className="fs-12">
                                      {" "}
                                      <span className="tundora">
                                        <span className="dis-i-b">
                                          ${" "}
                                          {this.state.yieldCounts
                                            .allocatedAmount
                                            ? this.state.yieldCounts
                                                .allocatedAmountNew
                                            : " 0"}
                                        </span>{" "}
                                        |{" "}
                                        <span className="dis-i-b">
                                          ${" "}
                                          {this.state.yieldCounts
                                            .allocatedAmount &&
                                          this.state.getUserscount
                                            ? (
                                                this.state.yieldCounts
                                                  .allocatedAmount /
                                                this.state.getUserscount.length
                                              ).toFixed(2)
                                            : 0}
                                        </span>
                                      </span>
                                    </p>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-sm-6 p-r-0">
                                    <p className="fs-12">
                                      <i
                                        className="fa fa-exclamation-circle"
                                        uib-tooltip="sum of allocated MS value"
                                        tooltip-placement="top"
                                      ></i>{" "}
                                      Total Kickoff Value:
                                    </p>
                                  </div>
                                  <div className="col-sm-6 p-l-0">
                                    <p className="fs-12">
                                      {" "}
                                      <span className="tundora">
                                        <span className="dis-i-b">
                                          ${" "}
                                          {this.state.yieldCounts
                                            .kickoffAmount}
                                        </span>{" "}
                                      </span>
                                    </p>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                            <Row className="m-0">
                              <div className="col-sm-6 p-0">
                                <h3 className="my-2 fs-15 text-start">
                                  Revenue leakages
                                </h3>
                              </div>
                              <div className="col-sm-12 p-0 table-block tablesec">
                                <div className="divTable">
                                  <div className="divTableRow">
                                    <div className="iteration-cell1 fs-12 txt-left">
                                      Bench
                                    </div>
                                    <div className="iteration-cell1 fs-12 PersianGreen-text filter-head text-left text-truncate p-l-0">
                                      <span className="badge badge-tag PersianGreen-bg fs-11 px-2 me-2">
                                        M
                                      </span>
                                      <span
                                        className="relvetop"
                                        title={`${this.state.utilizationCounts.moolahBenchHrsNew}`}
                                        tooltip-placement="top"
                                      >
                                        {this.state.utilizationCounts
                                          .moolahBenchHrs
                                          ? "$ " +
                                            this.state.utilizationCounts
                                              .moolahBenchHrsNew
                                          : "NA"}
                                      </span>
                                    </div>
                                    <div className="iteration-cell1 fs-12 Cabaret-text filter-head text-left text-truncate p-l-0">
                                      <span className="badge badge-tag Cabaret-bg fs-11 px-2 me-2">
                                        B
                                      </span>

                                      <span
                                        className="relvetop"
                                        title={`${this.state.utilizationCounts.boolahBenchHrsNew}`}
                                        tooltip-placement="top"
                                      >
                                        {this.state.utilizationCounts
                                          .boolahBenchHrs
                                          ? "$ " +
                                            this.state.utilizationCounts
                                              .boolahBenchHrsNew
                                          : "NA"}
                                      </span>
                                    </div>
                                    <div className="iteration-cell1 fs-12 dodgerblue-text filter-head text-left text-truncate p-l-0">
                                      <span className="badge badge-tag Cabaret-bg fs-11 px-2 me-2">
                                        S
                                      </span>
                                      <span
                                        className="relvetop"
                                        title={`${this.state.utilizationCounts.supportUserBenchHrs}`}
                                        tooltip-placement="top"
                                      >
                                        {this.state.utilizationCounts
                                          .supportUserBenchHrs
                                          ? "$ " +
                                            this.state.utilizationCounts
                                              .supportUserBenchHrsNew
                                          : "NA"}
                                      </span>
                                    </div>
                                    <div className="iteration-cell1 fs-12 vendor-text filter-head text-left text-truncate p-l-0">
                                      <span className="badge badge-tag vendor-bg fs-11 px-2 me-2">
                                        V
                                      </span>
                                      <span
                                        className="relvetop"
                                        title={`${this.state.utilizationCounts.vendorBenchHrs}`}
                                        tooltip-placement="top"
                                      >
                                        {this.state.utilizationCounts
                                          .vendorBenchHrs
                                          ? "$ " +
                                            this.state.utilizationCounts
                                              .vendorBenchHrsNew
                                          : "NA"}
                                      </span>
                                    </div>
                                    <div className="iteration-cell1 fs-12 extern-text filter-head text-left text-truncate p-l-0">
                                      <span className="badge badge-tag extern-bg fs-11 px-2 me-2">
                                        I
                                      </span>
                                      <span
                                        className="relvetop"
                                        title={`${this.state.utilizationCounts.externBenchHrs}`}
                                        tooltip-placement="top"
                                      >
                                        {this.state.utilizationCounts
                                          .externBenchHrs
                                          ? "$ " +
                                            this.state.utilizationCounts
                                              .externBenchHrsNew
                                          : "NA"}
                                      </span>
                                    </div>
                                    <div className="iteration-cell1 fs-12 intern-text filter-head text-left text-truncate p-l-0">
                                      <span className="badge badge-tag intern-bg fs-11 px-2 me-2">
                                        RI
                                      </span>
                                      <span
                                        className="relvetop"
                                        title={`${this.state.utilizationCounts.internBenchHrs}`}
                                        tooltip-placement="top"
                                      >
                                        NA
                                      </span>
                                    </div>
                                  </div>
                                  <div className="divTableRow">
                                    <div className="iteration-cell1 fs-12 txt-left">
                                      Support
                                    </div>
                                    <div className="iteration-cell1 fs-12 PersianGreen-text text-left filter-head text-truncate p-l-0">
                                      <span className="badge badge-tag PersianGreen-bg fs-11 px-2 me-2">
                                        M
                                      </span>
                                      <span
                                        className="relvetop"
                                        title={`${this.state.utilizationCounts.moolahSupportHrs_value}`}
                                        tooltip-placement="top"
                                      >
                                        {this.state.utilizationCounts
                                          .moolahSupportHrs_value
                                          ? "$ " +
                                            this.state.utilizationCounts
                                              .moolahSupportHrs_valueNew
                                          : "NA"}
                                      </span>
                                    </div>
                                    <div className="iteration-cell1 fs-12 Cabaret-text filter-head text-left text-truncate p-l-0">
                                      <span className="badge badge-tag Cabaret-bg fs-11 px-2 me-2">
                                        B
                                      </span>
                                      <span
                                        className="relvetop"
                                        title={`${this.state.utilizationCounts.boolahSupportHrs_value}`}
                                        tooltip-placement="top"
                                      >
                                        {this.state.utilizationCounts
                                          .boolahSupportHrs_value
                                          ? "$ " +
                                            this.state.utilizationCounts
                                              .boolahSupportHrs_valueNew
                                          : "NA"}
                                      </span>
                                    </div>
                                    <div className="iteration-cell1 fs-12 dodgerblue-text filter-head text-left text-truncate p-l-0">
                                      <span className="badge badge-tag Cabaret-bg fs-11 px-2 me-2">
                                        S
                                      </span>
                                      <span
                                        className="relvetop"
                                        title={`${this.state.utilizationCounts.supportUserSupportHrs_value}`}
                                        tooltip-placement="top"
                                      >
                                        {this.state.utilizationCounts
                                          .supportUserSupportHrs_value
                                          ? "$ " +
                                            this.state.utilizationCounts
                                              .supportUserSupportHrs_valueNew
                                          : "NA"}
                                      </span>
                                    </div>
                                    <div className="iteration-cell1 fs-12 vendor-text filter-head text-left text-truncate p-l-0">
                                      <span className="badge badge-tag vendor-bg fs-11 px-2 me-2">
                                        V
                                      </span>
                                      <span
                                        className="relvetop"
                                        title={`${this.state.utilizationCounts.vendorSupportHrs_value}`}
                                        tooltip-placement="top"
                                      >
                                        {this.state.utilizationCounts
                                          .vendorSupportHrs_value
                                          ? "$ " +
                                            this.state.utilizationCounts
                                              .vendorSupportHrs_valueNew
                                          : "NA"}
                                      </span>
                                    </div>
                                    <div className="iteration-cell1 fs-12 extern-text filter-head text-left text-truncate p-l-0">
                                      <span className="badge badge-tag extern-bg fs-11 px-2 me-2">
                                        I
                                      </span>
                                      <span
                                        className="relvetop"
                                        title={`${this.state.utilizationCounts.externSupportHrs_value}`}
                                        tooltip-placement="top"
                                      >
                                        {this.state.utilizationCounts
                                          .externSupportHrs_value
                                          ? "$ " +
                                            this.state.utilizationCounts
                                              .externSupportHrs_valueNew
                                          : "NA"}
                                      </span>
                                    </div>
                                    <div className="iteration-cell1 fs-12 intern-text filter-head text-left text-truncate p-l-0">
                                      <span className="badge badge-tag intern-bg fs-11 px-2 me-2">
                                        RI
                                      </span>
                                      <span
                                        className="relvetop"
                                        title={`${this.state.utilizationCounts.internSupportHrs_value}`}
                                        tooltip-placement="top"
                                      >
                                        NA
                                      </span>
                                    </div>
                                  </div>
                                  <div className="divTableRow">
                                    <div className="iteration-cell1 fs-12 txt-left">
                                      Deficit
                                    </div>
                                    <div className="iteration-cell1 fs-12 PersianGreen-text filter-head text-left text-truncate p-l-0">
                                      <span className="badge badge-tag PersianGreen-bg fs-11 px-2 me-2">
                                        M
                                      </span>
                                      <span
                                        className="relvetop"
                                        title={`${this.state.moolahDeficits}`}
                                        tooltip-placement="top"
                                      >
                                        $ {this.state.moolahDeficits}
                                      </span>
                                    </div>

                                    <div className="iteration-cell1 fs-12 Cabaret-text filter-head text-left text-truncate p-l-0">
                                      <span className="badge badge-tag Cabaret-bg fs-11 px-2 me-2">
                                        B
                                      </span>
                                      <span
                                        className="relvetop"
                                        title={`${this.state.boolahDeficits}`}
                                        tooltip-placement="top"
                                      >
                                        $ {this.state.boolahDeficits}
                                      </span>
                                    </div>
                                    <div className="iteration-cell1 fs-12 dodgerblue-text filter-head text-left text-truncate p-l-0">
                                      <span className="badge badge-tag Cabaret-bg fs-11 px-2 me-2">
                                        S
                                      </span>
                                      <span
                                        className="relvetop"
                                        title={`${this.state.bcDeficits}`}
                                        tooltip-placement="top"
                                      >
                                        ${" "}
                                        {this.state.bcDeficits
                                          ? this.state.bcDeficits
                                          : "0.00"}
                                      </span>
                                    </div>
                                    <div className="iteration-cell1 fs-12 vendor-text filter-head text-left text-truncate p-l-0">
                                      <span className="badge badge-tag vendor-bg fs-11 px-2 me-2">
                                        V
                                      </span>
                                      <span
                                        className="relvetop"
                                        title={`${this.state.vendorDeficits}`}
                                        tooltip-placement="top"
                                      >
                                        ${" "}
                                        {this.state.vendorDeficits
                                          ? this.state.vendorDeficits
                                          : "0.00"}
                                      </span>
                                    </div>
                                    <div className="iteration-cell1 fs-12 extern-text filter-head text-left text-truncate p-l-0">
                                      <span className="badge badge-tag extern-bg fs-11 px-2 me-2">
                                        I
                                      </span>
                                      <span
                                        className="relvetop"
                                        title={`${this.state.externDeficits}`}
                                        tooltip-placement="top"
                                      >
                                        ${" "}
                                        {this.state.externDeficits
                                          ? this.state.externDeficits
                                          : "0.00"}
                                      </span>
                                    </div>
                                    <div className="iteration-cell1 fs-12 intern-text filter-head text-left text-truncate p-l-0">
                                      <span className="badge badge-tag intern-bg fs-11 px-2 me-2">
                                        RI
                                      </span>
                                      <span
                                        className="relvetop"
                                        title={`${this.state.internDeficits}`}
                                        tooltip-placement="top"
                                      >
                                        ${" "}
                                        {this.state.internDeficits
                                          ? this.state.internDeficits
                                          : "0.00"}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Row>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Row>
                </div>
                {!this.state.isGanttView && (
                  <div className="table-responsive white-table-class reports--grid">
                    <div className="scrollable">
                      <div className="scrollable-content">
                        <table className="table m-b-0 table-wrap">
                          <thead>
                            <tr className="theadInner">
                              <th
                                className="text-start"
                                title="Resource"
                                style={{ width: "15%" }}
                              >
                                Resource
                              </th>
                              <th
                                className="text-start"
                                title="Project Name"
                                style={{ width: "12%" }}
                              >
                                Project Name
                              </th>
                              <th className="text-center" title="AVG Cost/Hr">
                                Avg <br /> Cost/Hr
                              </th>
                              <th className="text-center" title="AVG Price/Hr">
                                Avg <br /> Price/Hr
                              </th>
                              <th
                                className="text-center"
                                title="Available Hours"
                              >
                                Available <br /> Hrs
                              </th>

                              <th
                                className="text-center"
                                title="Planned Hours With MS"
                              >
                                Planned <br />
                                Hr MS
                              </th>
                              <th
                                className="text-center"
                                title="Planned Hours For Support"
                              >
                                Support
                              </th>
                              <th
                                className="text-center"
                                title="Unutilized Hrs"
                              >
                                Unutilized
                                <br /> Hrs
                              </th>
                              <th className="text-center" title="Practice Cost">
                                Practice
                                <br /> Cost
                              </th>
                              <th
                                className="text-center"
                                title="Expected Yield"
                              >
                                Expected
                                <br /> yield
                              </th>
                              <th
                                className="text-center"
                                title="Allocated M.S Value"
                              >
                                Allocated
                                <br /> M.S.Value
                              </th>
                              <th
                                className="text-center"
                                title="Achieved M.S Value"
                              >
                                Achieved
                                <br /> M.S.Value
                              </th>
                              <th
                                className="text-center"
                                title="Gap in Allocation"
                              >
                                Allocation
                                <br /> Gap
                              </th>
                              <th className="text-center" title="Deficit">
                                Deficit%
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.loading == true && (
                              <div id="preloader">
                                <div id="status"></div>
                              </div>
                            )}
                            {this.state.loading == false &&
                              this.state.gridList.length == 0 && (
                                <tr>
                                  <td colSpan="8" className="r-no-data text-center">
                                    No data available
                                  </td>
                                </tr>
                              )}
                            {this.state.loading == false &&
                              this.state.gridList.length > 0 &&
                              this.state.gridList
                                .sort((a, b) =>
                                  a.first_name > b.first_name ? 1 : -1
                                )
                                .map((user, keyUser) => (
                                  <tr>
                                    <td className="text-start">
                                      <div className="col-lg-2 col-md-6 col-xs-12 p-0">
                                        {user.user_type ==
                                          this.state.user_types[2].id &&
                                          (user.emp_category == null ||
                                            user.emp_category ===
                                              "permanent") && (
                                            <span className="user-sup practise-li-user bg-blue me-2">
                                              S
                                            </span>
                                          )}
                                        {user.user_type ==
                                          this.state.user_types[1].id &&
                                          (user.emp_category == null ||
                                            user.emp_category ===
                                              "permanent") && (
                                            <span className="user-sup practise-li-user bg-d-pink me-2">
                                              B
                                            </span>
                                          )}
                                        {user.user_type ==
                                          this.state.user_types[0].id &&
                                          (user.emp_category == null ||
                                            user.emp_category ===
                                              "permanent") && (
                                            <span className="user-sup practise-li-user bg-d-green me-2">
                                              M
                                            </span>
                                          )}
                                        {user.user_type ==
                                          this.state.user_types[4].id &&
                                          (user.emp_category == null ||
                                            user.emp_category ===
                                              "permanent") && (
                                            <span className="user-sup practise-li-user bg-yellow me-2">
                                              V
                                            </span>
                                          )}
                                        {user.user_type ==
                                          this.state.user_types[5].id &&
                                          (user.emp_category == null ||
                                            user.emp_category ===
                                              "permanent") && (
                                            <span className="user-sup practise-li-user bg-extern pe-2 me-2">
                                              I
                                            </span>
                                          )}
                                        {user.emp_category === "intern" && (
                                          <span className="user-sup practise-li-user bg-intern pe-2 me-2">
                                            RI
                                          </span>
                                        )}
                                      </div>
                                      <div className="col-lg-10 col-md-6 col-xs-12 p-0">
                                        <span
                                          className="sortname p-t-4 text-truncate name-truncate p-l-0 p-r-0 txt-black"
                                          title={user.first_name}
                                        >
                                          {user.first_name}
                                        </span>
                                        <span
                                          className="name-truncate p-0 s-gray"
                                          title={user.teamlead}
                                        >
                                          TL : {user.teamlead}
                                        </span>
                                      </div>
                                    </td>
                                    <td>
                                      <p className="sortname text-start">
                                        <span
                                          className="name p-l-0 name-truncate p-0 text-truncate txt-black"
                                          style={{ maxWidth: "100px" }}
                                          title={user.project_name}
                                        >
                                          {user.project_name}
                                        </span>
                                      </p>
                                    </td>
                                    <td className="text-center">
                                      ${parseFloat(user.cost)}
                                    </td>
                                    <td className="text-center">
                                      ${parseFloat(user.price)}
                                    </td>
                                    <td className="text-center">
                                      {user.available_hrs}
                                    </td>
                                    <td className="text-center">
                                      {user.planned_hrs}
                                    </td>
                                    <td className="text-center">
                                      {user.support_hrs}
                                    </td>
                                    <td className="text-center">
                                      {user.unallocated_hrs}
                                    </td>
                                    <td className="text-center">
                                      ${user.effective_yield}
                                    </td>
                                    <td className="text-center">
                                      ${user.expected_yield}
                                    </td>
                                    <td className="text-center">
                                      $
                                      {user.allocated_ms
                                        ? user.allocated_ms
                                        : 0}
                                    </td>
                                    <td className="{returnMsArchivedClass(user)}}">
                                      ${user.achieved_ms}
                                    </td>
                                    <td className="text-center">
                                      ${user.gap_in_allocation}
                                    </td>
                                    <td className="text-center">
                                      {user.deficit} %
                                    </td>
                                  </tr>
                                ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                )}
                {this.state.isGanttView && (
                  <div>
                    <div className="col-sm-12 m-b-10 m-t-10 p-0">
                      <div className="row">
                        <div className="col-sm-12">
                          <div className="row">
                            <div className="col-sm-9">
                              <h1 className="p-0 col-sm-4 B-G-bold f-s-18 d-gray m-t-3 m-b-10 page-head">
                                GANTT CHART
                              </h1>
                            </div>
                            <div className="col-sm-1 p-t-5 p-l-0">
                              <span className="btn-danger badge-tag fs-13 p-l-5 p-r-5 m-r-5 pull-left">
                                W
                              </span>
                              <span className="text-red p-t-5">Worst</span>
                            </div>
                            <div className="col-sm-1 p-l-0 p-r-0 p-t-5">
                              <span className="badge-tag btn-average fs-13 p-l-5 p-r-5 m-r-5 pull-left">
                                A
                              </span>
                              <span className="c-orange p-t-5">Average</span>
                            </div>
                            <div className="col-sm-1 p-l-0 p-t-5">
                              <span className="badge-tag btn-success fs-13 p-l-5 p-r-5 m-r-5 pull-left">
                                G
                              </span>
                              <span className="text-success p-t-5">Good</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-12 p-l-0 p-r-0 p-t-0 p-b-10 white-table-className ganttview--reportpage">
                      <div className="table-responsive col-sm-12 p-t-0 p-b-10 white-table-class ganttview--reportpage ng-scope">
                        <table className="col-sm-12 p-0 table-bordered table-striped m-b-0 bor-no">
                          <tbody>
                            <tr className="theadInner">
                              <td
                                className="bor-no p-t-20 p-b-5"
                                width="15%"
                              ></td>
                              {this.state.monthSeries.map((month, keyMonth) => (
                                <td className="text-center p-t-20 p-b-5 bor-no">
                                  {month}
                                </td>
                              ))}
                              <td className="text-center p-t-20 p-b-5 bor-no">
                                Upcommings
                              </td>
                            </tr>
                            {this.state.ganttViewData
                              .sort((a, b) =>
                                a.first_name.localeCompare(b.first_name)
                              )
                              .map((user, keyUserValue) => (
                                <tr>
                                  <td
                                    className="text-right p-r-15 bor-no"
                                    style={{ width: "15%;" }}
                                  >
                                    {user.username}
                                  </td>
                                  {user.monthlyData.map((month_data) => (
                                    <td
                                      className="p-t-8 p-b-8 fs-13 text-center co-white"
                                      tabIndex="0"
                                    >
                                      {month_data.available_hrs && (
                                        <p
                                          className={this.percentageFinder(
                                            month_data.alloted_hrs,
                                            month_data.available_hrs
                                          )}
                                          title={
                                            (month_data.alloted_hrs,
                                            month_data.available_hrs) * 100
                                          }
                                          tooltip-placement="top"
                                        >
                                          &nbsp;
                                        </p>
                                      )}
                                      {!month_data.available_hrs && (
                                        <p
                                          className={this.percentageFinder(
                                            month_data.alloted_hrs,
                                            month_data.available_hrs
                                          )}
                                        >
                                          &nbsp;
                                        </p>
                                      )}
                                    </td>
                                  ))}
                                  <td></td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </Row>
          </div>
        </>
      );
    }
  }
}

export default PW_report;
