import Cookies from "js-cookie";

/**
 * POST Data
 **/ 

export function postData(data = {}) {
    if (Cookies.get("auth_data")) {
      let authData = (Cookies.get("auth_data"));
      return {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        headers: {
         "Content-type": "application/json; charset=UTF-8",
          Authorization: "Bearer " + authData,
        },
        body: JSON.stringify(data), // body data type must match "Content-Type" header
      };
    } else {
      return {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
        body: JSON.stringify(data), // body data type must match "Content-Type" header
      };
    }
}

/**
 * Get Data
 **/ 

 export function getData(data = {}) {
  if (Cookies.get("auth_data")) {
    let authData = (Cookies.get("auth_data"));
    return {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      headers: {
       "Content-type": "application/json; charset=UTF-8",
        Authorization: "Bearer " + authData,
      },
      dataType: 'json', // body data type must match "Content-Type" header
    };
  } else {
    return {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      headers: {
        "Content-Type": "application/json; charset=utf-8",
      },
      dataType: 'json' // body data type must match "Content-Type" header
    };
  }
}

 




