import React, { Component } from "react";

export class CsmCarryDataView extends Component {
  constructor(props) {
    super(props);   
  }

  render() {
    console.log("this.props.csmProjectList",this.props.csmProjectList)
    let index = 1;
    return (
      <>
        <div className="scrollable-content">
          {this.props.loading && (
            <div id="preloader">
              <div id="status"></div>
            </div>
          )}
          {!this.props.loading && 
            (!this.props.total_carry_value &&
              <div>
                <div className="r-no-data text-center">
                  No data available
                </div>
              </div>
            )}
           {!this.props.loading &&
            (this.props.csmProjectList && this.props.csmProjectList.length > 0 && this.props.total_carry_value > 0) && (
             
              <div className="table-responsive white-table-class reports--grid">
                  <div className="scrollable">
                      <div className="scrollable-content">
                          <table className="table m-b-0 table-wrap">
                              <thead>
                                  <tr className="theadInner invntry-mw-th">
                                      <th rowSpan="2" className='thead_csm'>S.No</th>
                                      <th rowSpan="2" className='thead_csm'>Project Name</th>
                                      <th rowSpan="3" className='thead_csm'>Csm Name</th>
                                      { this.props.months_list.map(function(month) {
                                            return (
                                               <th rowSpan="3" className='thead_csm'>CF Month Value - {month} (USD)</th>                                             
                                            )                                       
                                        })
                                      }
                                  </tr>
                              </thead>
                              <tbody>                           
                                { this.props.csmProjectList.map((csmProjectList) => ( 
                                     Object.values(csmProjectList).map((item) => (  
                                       item.csm_users && item.pending >0 &&
                                       <tr id="class_grey_rows_" >
                                          <td>{index++}</td>                                                               
                                          <td className="text-start">
                                              <span className="text-truncate">{item.projectName}</span>
                                          </td>
                                          <td className="text-start">
                                              <span className="text-truncate">{item.csm_users}</span>
                                          </td>  
                                          { Object.values(item.data).map((data) => ( 
                                             <td className="text-start">
                                                 <span className="text-truncate">{data.invoiceCarryValue.toLocaleString(undefined, {
                                                     minimumFractionDigits: 2,
                                                     maximumFractionDigits: 2
                                                   })}</span>
                                              </td>  
                                          ))}
                                                                                  
                                        </tr >
                                     ))
                                  ))
                                }               
                              </tbody>
                          </table>
                      </div>
                  </div>
              </div>                              
            )}


        </div>
      </>
    );
  }
}
